import Head from 'next/head';
import React from 'react';
import { useSelector } from 'react-redux';
import { IResult } from 'ua-parser-js';

import { getMediaUrl } from '@constants/urls';
import { IProductColor, IProductPicture } from '@interfaces/product';
import { IStore } from '@interfaces/store';

const ProductDetailsImageToPriority: React.FunctionComponent<{}> = () => {
  const color = useSelector((state: IStore) => state.productDetails.activeProduct.color);
  const productDetails = useSelector((state: IStore) => state.productDetails.productDetails);

  const currentColor: IProductColor | null = color || productDetails?.colors[0] || null;
  const pictures: IProductPicture[] | null = currentColor?.pictures || null;
  const userAgent: Partial<IResult> | null = useSelector((state: IStore) => state.context.userAgent);
  const initialDeviceSize = userAgent?.device?.type && ['tablet', 'mobile'].includes(userAgent.device.type) ? 'sm' : 'lg';
  if (pictures) {
    if (Array.isArray(pictures) && pictures.length > 0) {
      return (
        <Head>
          {initialDeviceSize === 'lg'
            ? (<link
              rel="preload"
              href={getMediaUrl(pictures[0].urlLarge || '')}
              as="image"
            />)
            : (<>
              {pictures.filter((item) => !item.video).map((productPicture, key) =>
                <link
                  rel="preload"
                  href={getMediaUrl(productPicture?.urlMedium || productPicture?.urlLarge || '')}
                  as="image"
                  key={`ProductDetailPicturePreload${key}`}
                />,
              )}
            </>)}
        </Head>
      );
    }
  }
  return null;
};

export default ProductDetailsImageToPriority;
