export const deapLink = 'DenimDream';
export const deapMks = 'mks-result';

export const androidUATrigger = 'android';
export const iosUATrigger = 'iOS';

export enum EUserAgent {
  android = 'android',
  ios = 'ios',
}
