import { defaultSorting } from '@constants/sort';
import { EProductsListFilter } from '@interfaces/productsList';
import { IProductsListReducer } from '@interfaces/productsList/reducer';

export const initialState: IProductsListReducer = {
  brands: [],
  data: {
    count: 0,
    errors: [],
    page: 0,
    products: [],
    size: 50,
  },
  dataFilter: {
    filterAges: [],
    filterBrands: [],
    filterCategories: [],
    filterColors: [],
    filterMaxPrice: '0',
    filterMinPrice: '0',
    filterSizes: [],
    sexes: [],
  },
  dataMobileFilter: {
    count: 0,
    errors: [],
    filterAges: [],
    filterBrands: [],
    filterCategories: [],
    filterColors: [],
    filterMaxPrice: '0',
    filterMinPrice: '0',
    filterSizes: [],
    page: 0,
    products: [],
    sexes: [],
    size: 50,
  },
  defaultSeoInfo: {
    h1: '',
    short_story: '',
  },
  error: [],
  filter: {
    [EProductsListFilter.ageId]: [],
    [EProductsListFilter.brandId]: [],
    [EProductsListFilter.categoryId]: [],
    [EProductsListFilter.categoryIdP]: [],
    [EProductsListFilter.colorGroupId]: [],
    [EProductsListFilter.textSearch]: '',
    [EProductsListFilter.outlet]: null,
    [EProductsListFilter.priceFrom]: null,
    [EProductsListFilter.priceTo]: null,
    [EProductsListFilter.sexId]: [],
    [EProductsListFilter.sizes]: [],
    [EProductsListFilter.sale]: null,
    [EProductsListFilter.fresh]: null,
    [EProductsListFilter.fits]: [],
    [EProductsListFilter.waists]: [],
  },
  filterMobile: {
    [EProductsListFilter.ageId]: [],
    [EProductsListFilter.brandId]: [],
    [EProductsListFilter.categoryId]: [],
    [EProductsListFilter.categoryIdP]: [],
    [EProductsListFilter.colorGroupId]: [],
    [EProductsListFilter.textSearch]: '',
    [EProductsListFilter.outlet]: null,
    [EProductsListFilter.priceFrom]: null,
    [EProductsListFilter.priceTo]: null,
    [EProductsListFilter.sexId]: [],
    [EProductsListFilter.sizes]: [],
    [EProductsListFilter.sale]: null,
    [EProductsListFilter.fresh]: null,
    [EProductsListFilter.fits]: [],
    [EProductsListFilter.waists]: [],
  },
  filterMobileChanged: false,
  loaded: false,
  loadedFilter: false,
  loading: false,
  loadingFilter: false,
  loadingMobile: false,
  sortDirection: defaultSorting,
  sortDirectionDefault: defaultSorting,
};
