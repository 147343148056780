import { Middleware, MiddlewareAPI } from 'redux';

import { ICallApi, IFaceBookADPayload } from '@interfaces/fetchService';
import { TThunkDispatch } from '@interfaces/index';
import { IStore } from '@interfaces/store';
import {
  getCookieFbFBC,
  getCookieFbFBP,
  getTokenCookie,
  TContext,
} from '@utils/cookies';
import { CALL_API } from 'services/SSRService';
import { INextContext } from '../../store/wrapper';
import { addSearchParamsToUrl } from '../SSRService/utils';

export type TRequestAction = Promise<any>;

type TGetFBC = (context?: TContext) => string | null;
export const getFBC: TGetFBC = (context = null) => {
  return getTokenCookie(context) ?? null;
};


const facebookService: (context?: INextContext) => Middleware = (context) => (store: MiddlewareAPI<TThunkDispatch, IStore>) => (next) => async (action) => {

  const callAPI: ICallApi = action[CALL_API];

  if (typeof callAPI === 'undefined') {
    return next(action);
  }

  const { facebook } = callAPI;

  if (typeof facebook === 'undefined' || !facebook) {
    return next(action);
  }

  const { endpoint } = callAPI;


  const { auth: { token } }: IStore = store.getState();

      const fbc = getCookieFbFBC();
      const fbp = getCookieFbFBP();

      const faceBookPayload: IFaceBookADPayload = {} as IFaceBookADPayload;

          if (token && token.access_token) {
            faceBookPayload.customerAuth = token.access_token;
          }
          if (fbc) {
            faceBookPayload.fbc = fbc;
          }
          if (fbp) {
            faceBookPayload.fbp = fbp;
          }


    if (Object.keys(faceBookPayload)) {
      action[CALL_API].endpoint = addSearchParamsToUrl(endpoint, faceBookPayload);
    }

  return next(action);

  return Promise.resolve(false);
};

export default facebookService;
