export const MESSAGE_SHOW = 'MESSAGE_SHOW';
export type MESSAGE_SHOW = 'MESSAGE_SHOW';

export const MESSAGE_CLEAR = 'MESSAGE_CLEAR';
export type MESSAGE_CLEAR = 'MESSAGE_CLEAR';

export const LOYALTY_PROGRAM_SIGN_UP_DATA_REQUEST = 'LOYALTY_PROGRAM_SIGN_UP_DATA_REQUEST';
export type LOYALTY_PROGRAM_SIGN_UP_DATA_REQUEST = 'LOYALTY_PROGRAM_SIGN_UP_DATA_REQUEST';
export const LOYALTY_PROGRAM_SIGN_UP_DATA_SUCCESS = 'LOYALTY_PROGRAM_SIGN_UP_DATA_SUCCESS';
export type LOYALTY_PROGRAM_SIGN_UP_DATA_SUCCESS = 'LOYALTY_PROGRAM_SIGN_UP_DATA_SUCCESS';
export const LOYALTY_PROGRAM_SIGN_UP_DATA_FAILED = 'LOYALTY_PROGRAM_SIGN_UP_DATA_FAILED';
export type LOYALTY_PROGRAM_SIGN_UP_DATA_FAILED = 'LOYALTY_PROGRAM_SIGN_UP_DATA_FAILED';

export const LOYALTY_PROGRAM_ATTACH_REQUEST = 'LOYALTY_PROGRAM_ATTACH_REQUEST';
export type LOYALTY_PROGRAM_ATTACH_REQUEST = 'LOYALTY_PROGRAM_ATTACH_REQUEST';
export const LOYALTY_PROGRAM_ATTACH_SUCCESS = 'LOYALTY_PROGRAM_ATTACH_SUCCESS';
export type LOYALTY_PROGRAM_ATTACH_SUCCESS = 'LOYALTY_PROGRAM_ATTACH_SUCCESS';
export const LOYALTY_PROGRAM_ATTACH_FAILED = 'LOYALTY_PROGRAM_ATTACH_FAILED';
export type LOYALTY_PROGRAM_ATTACH_FAILED = 'LOYALTY_PROGRAM_ATTACH_FAILED';


export const LOYALTY_PROGRAM_CONFIRM_SIGN_UP_EMAIL_REQUEST = 'LOYALTY_PROGRAM_CONFIRM_SIGN_UP_EMAIL_REQUEST';
export type LOYALTY_PROGRAM_CONFIRM_SIGN_UP_EMAIL_REQUEST = 'LOYALTY_PROGRAM_CONFIRM_SIGN_UP_EMAIL_REQUEST';
export const LOYALTY_PROGRAM_CONFIRM_SIGN_UP_EMAIL_SUCCESS = 'LOYALTY_PROGRAM_CONFIRM_SIGN_UP_EMAIL_SUCCESS';
export type LOYALTY_PROGRAM_CONFIRM_SIGN_UP_EMAIL_SUCCESS = 'LOYALTY_PROGRAM_CONFIRM_SIGN_UP_EMAIL_SUCCESS';
export const LOYALTY_PROGRAM_CONFIRM_SIGN_UP_EMAIL_FAILED = 'LOYALTY_PROGRAM_CONFIRM_SIGN_UP_EMAIL_FAILED';
export type LOYALTY_PROGRAM_CONFIRM_SIGN_UP_EMAIL_FAILED = 'LOYALTY_PROGRAM_CONFIRM_SIGN_UP_EMAIL_FAILED';

export const LOYALTY_PROGRAM_SIGN_UP_COMPLETED = 'LOYALTY_PROGRAM_SIGN_UP_COMPLETED';
export type LOYALTY_PROGRAM_SIGN_UP_COMPLETED = 'LOYALTY_PROGRAM_SIGN_UP_COMPLETED';

export const LOYALTY_PROGRAM_ATTACH_QR_REQUEST = 'LOYALTY_PROGRAM_ATTACH_QR_REQUEST';
export type LOYALTY_PROGRAM_ATTACH_QR_REQUEST = 'LOYALTY_PROGRAM_ATTACH_QR_REQUEST';
export const LOYALTY_PROGRAM_ATTACH_QR_SUCCESS = 'LOYALTY_PROGRAM_ATTACH_QR_SUCCESS';
export type LOYALTY_PROGRAM_ATTACH_QR_SUCCESS = 'LOYALTY_PROGRAM_ATTACH_QR_SUCCESS';
export const LOYALTY_PROGRAM_ATTACH_QR_FAILED = 'LOYALTY_PROGRAM_ATTACH_QR_FAILED';
export type LOYALTY_PROGRAM_ATTACH_QR_FAILED = 'LOYALTY_PROGRAM_ATTACH_QR_FAILED';

export const LOYALTY_PROGRAM_ACCESS_CARD_REQUEST = 'LOYALTY_PROGRAM_ACCESS_CARD_REQUEST';
export type LOYALTY_PROGRAM_ACCESS_CARD_REQUEST = 'LOYALTY_PROGRAM_ACCESS_CARD_REQUEST';
export const LOYALTY_PROGRAM_ACCESS_CARD_SUCCESS = 'LOYALTY_PROGRAM_ACCESS_CARD_SUCCESS';
export type LOYALTY_PROGRAM_ACCESS_CARD_SUCCESS = 'LOYALTY_PROGRAM_ACCESS_CARD_SUCCESS';
export const LOYALTY_PROGRAM_ACCESS_CARD_FAILED = 'LOYALTY_PROGRAM_ACCESS_CARD_FAILED';
export type LOYALTY_PROGRAM_ACCESS_CARD_FAILED = 'LOYALTY_PROGRAM_ACCESS_CARD_FAILED';
