export const homePageComponentsTypes = {
  COMPONENT_TYPE_BANNER: 'home-blocks.hb-banner',
  COMPONENT_TYPE_BENEFITS: 'home-blocks.hb-benefits',
  COMPONENT_TYPE_BESTSELLERS: 'home-blocks.hb-bestsellers',
  COMPONENT_TYPE_BLOG_POSTS: 'home-blocks.hb-blog-posts',
  COMPONENT_TYPE_BRANDS: 'home-blocks.hb-brands',
  COMPONENT_TYPE_COUNTDOWN: 'home-blocks.countdown',
  COMPONENT_TYPE_CUSTOM_CARDS: 'home-blocks.custom-cards-',
  COMPONENT_TYPE_DOWNLOAD_APP_BANNER: 'home-blocks.app-banner',
  COMPONENT_TYPE_EMPTY_LINE: 'home-blocks.empty-line',
  COMPONENT_TYPE_GENDER_CATEGORIES: 'home-blocks.gender-categories',
  COMPONENT_TYPE_INFO: 'home-blocks.hb-info',
  COMPONENT_TYPE_LOOKBOOK: 'home-blocks.lookbook',
  COMPONENT_TYPE_POPULAR_CATEGORIES: 'home-blocks.hb-popular-categories',
  COMPONENT_TYPE_PORTRAIT_BANNER: 'home-blocks.portrait-banner',
  COMPONENT_TYPE_PRODUCTS_SLIDER: 'home-blocks.products-slider',
  COMPONENT_TYPE_RECENTLY_VIEWED_PRODUCTS: 'home-blocks.hb-recently-viewed-products',
  COMPONENT_TYPE_SLIDER: 'home-blocks.hb-slider',
  COMPONENT_TYPE_TEXT: 'home-blocks.hb-text',
  COMPONENT_TYPE_TITLE: 'home-blocks.hb-title',
  COMPONENT_TYPE_TOP_BRANDS: 'home-blocks.hb-top-brands',
  COMPONENT_TYPE_TRENDING_PRODUCTS: 'home-blocks.hb-trending-products',
  COMPONENT_TYPE_VIDEO_BANNER: 'home-blocks.video-banner',
  COMPONENT_TYPE_YOUTUBE_BANNER: 'home-blocks.youtube-banner',
};
