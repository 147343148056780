import React from 'react';
import { Provider } from 'react-redux';

import { wrapper } from './withRedux';


const ReduxProvider = ({ children, pageProps }) => {
  // const propsDecoded = useMemo(() => getDeCompressedProps(pageProps), [pageProps.initialState]);
  const { store } = wrapper.useWrappedStore(pageProps);
  return (
    <Provider store={store}>
      {children}
    </Provider>);
};

export default ReduxProvider;
