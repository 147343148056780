import { EGender } from '@constants/gender';

export const getTabIndexByGender = (sexId: number): number => {
  switch (sexId) {
    case EGender.man:
      return 1;
    case EGender.women:
      return 0;
    case EGender.girl:
    case EGender.boy:
    case 5:
    case 0:
      return 2;
    default:
      return 0;
  }
};

