import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

export const strapiUrl = publicRuntimeConfig.apiUrl;
export const customerApiUrl = publicRuntimeConfig.customerApiUrl;
export const erpApiUrl = publicRuntimeConfig.erpApiUrl;
export const BASE_URL = publicRuntimeConfig.apiV2Url;

export const imageUlrSuffix = publicRuntimeConfig.strapiImageUrl;
export const passwordResetKey = 'password-reset';

// CART
export const cartGetUrl = () => `${BASE_URL}api/v2/order/cart`;
export const cartGetCountUrl = () => `${BASE_URL}api/v2/order/cart/has-items`;
export const cartItemUrl = () => `${BASE_URL}api/v2/order/cart/item`;
export const cartItemListUrl = () => `${BASE_URL}api/v2/order/cart/item/list`;
export const cartUpdateUrl = () => `${BASE_URL}api/v2/order/cart/update`;
export const getProductsByIdsUrl = () => `${BASE_URL}api/v2/product/product-id`;
export const markProductAsViewedUrl = (productId) => `${BASE_URL}api/v2/product/product/${productId}/mark-as-viewed`;

export const getProducts = () => `${BASE_URL}api/v2/product/product`;

export const orderCreateUrl = () => `${BASE_URL}api/v2/order`;

export const cartGenerateByTransactionUrl = (transactionId) => `${BASE_URL}api/v2/order/cart/generate/${transactionId}`;

export const shipmentMethodsUrl = () => `${BASE_URL}api/v2/order/shipment/method`;
export const shipmentLocationsUrl = () => `${BASE_URL}api/v2/order/shipment/location`;
export const shipmentCountryUrl = () => `${BASE_URL}api/v2/order/shipment/country`;

export const orderPaymentConfirmUrl = () => `${BASE_URL}api/v2/order/payment/confirm`;
export const storesUrl = () => `${BASE_URL}api/v2/store/store`;
export const storesBrandUrl = () => `${BASE_URL}api/v2/store/brand`;
export const storesCityUrl = () => `${BASE_URL}api/v2/store/city`;
export const storesCountryUrl = () => `${BASE_URL}api/v2/store/country`;
export const storesMallUrl = () => `${BASE_URL}api/v2/store/mall`;
export const storesAvailabilityUrl = () => `${BASE_URL}api/v2/store/availability`;
export const lotteryUrl = () => `${BASE_URL}api/v2/lottery`;

// WISHLIST
export const wishListUrl = () => `${BASE_URL}api/v2/product/wishlist/`;
export const wishListRemoveItemUrl = (id: number) => `${BASE_URL}api/v2/product/wishlist/${id}`;
export const wishListUpdateItemUrl = (id: number) => `${BASE_URL}api/v2/product/wishlist/${id}`;
export const wishListCountUrl = () => `${BASE_URL}api/v2/product/wishlist/has-items`;
export const wishListGetIDsUrl = () => `${BASE_URL}api/v2/product/wishlist/find-ids`;
export const popularCategoriesUrl = () => `${BASE_URL}api/v2/product/category-popular`;

// SEARCH
export const getSearchTrendingItemsUrl = () => `${BASE_URL}api/v2/product/search/trending`;
export const getSearchHistoryUrl = () => `${BASE_URL}api/v2/product/search/history`;
export const getSearchClearHistoryUrl = () => `${BASE_URL}api/v2/product/search/history/clear`;
export const getSearchResultUrl = () => `${BASE_URL}api/v2/product/product`;
export const getSearchCategoriesUrl = () => `${BASE_URL}api/v2/product/product/categories`;

// AUTH
export const sessionUrl = () => `${BASE_URL}api/v2/session`;
export const sessionUrlProtect = (origin) => `${origin}/api/session`;

// CUSTOMER

export const loginUrl = () => `${customerApiUrl}/token`;
export const registrationUrl = () => `${customerApiUrl}/customers`;
export const getCustomerDeliveryMethodsUrl = () => `${customerApiUrl}/delivery-methods`;
export const getCustomerDeliveryMethodByIdUrl = (id) => `${customerApiUrl}/delivery-methods/${id}`;
export const getCustomerMethod = () => `${customerApiUrl}/customer-profiles`;
export const getCustomerPaymentMethodsUrl = () => `${customerApiUrl}/payment-methods`;
export const getCustomerPaymentMethodItemUrl = (methodId) => `${customerApiUrl}/payment-methods/${methodId}`;
export const getCustomerSocialsUrl = () => `${customerApiUrl}/customer-socials`;
export const getCustomerForgotLinkUrl = () => `${customerApiUrl}/customer-remind-pwds`;
export const getCustomerResetPasswordUrl = (token) => `${customerApiUrl}/customer-reset-pwds/${token}`;
export const getCustomerSignupDataUrl = (id: string) => `${customerApiUrl}/customer-signups/${id}`;
export const getCustomerEmailConfirmUrl = (token) => `${customerApiUrl}/customer-confirm-emails/${token}`;
export const getCustomerResendConfirmationUrl = () => `${customerApiUrl}/customer-remind-finalizes`;
export const customerDeleteUrl = () => `${customerApiUrl}/customers`;
export const customerChangeEmailUrl = (token) => `${customerApiUrl}/customer-change-emails/${token}`;
export const customerSocialsClientsUrl = () => `${customerApiUrl}/customer-socials/clients`;

export const customerSmartIdTokenUrl = () => `${customerApiUrl}/smart-id/pin`;
export const customerSmartIdAttachUrl = () => `${customerApiUrl}/customer-attach-national`;
export const customerSmartIdAttachFinalizeUrl = (token) => `${customerApiUrl}/customer-attach-national-finalize/${token}`;

export const getCustomerOrdersMethodsUrl = () => `${BASE_URL}api/v2/order/customer`;
export const getCustomerOrdersRetailMethodsUrl = () => `${BASE_URL}api/v2/order/customer/retail`;
export const getCustomerOrdersRetailDetailsMethodsUrl = (id) => `${BASE_URL}api/v2/order/customer/retail/` + id;
export const getCustomerOrdersMethodDetailsUrl = (orderId) => `${BASE_URL}api/v2/order/customer/${orderId}`;
export const getCustomerOrderByTransaction = (transactionId: string) => `${BASE_URL}api/v2/order/byTransaction/${transactionId}`;

export const getCustomerSocialsLongUrl = () => `/api/facebook/access_token`;
export const customerChangePasswordUrl = () => `${BASE_URL}customer-change-password`;
export const orderTrackingUrl = (orderId) => `${BASE_URL}api/v2/order/tracking/${orderId}`;
export const orderInvoiceUrl = (invoiceName) => `${BASE_URL}api/v2/order/invoice/${invoiceName}`;
export const orderRetailInvoiceUrl = (retailOrderNumber) => `${BASE_URL}api/v2/order/invoice/retail/${retailOrderNumber}`;


export const customerEmailFinalizesUrl = (token) => `${customerApiUrl}/customer-email-finalizes/${token}`;
export const customerRemindFinalizesUrl = (country, lang) => `${customerApiUrl}/customer-remind-finalizes?lang=${lang}&country=${country}`;
export const customerNotificationSubscriptionsUrl = () => `${customerApiUrl}/customer-profiles/notification-subscriptions`;

export const customerLoyaltyAttachByIdUrl = () => `${customerApiUrl}/trans/signup`;
export const customerLoyaltyQR = () => `${erpApiUrl}/erp/qrScan`;

export const customerOneSignalIdUrl = (userId) => `${customerApiUrl}/customer/one-signal/${userId}/update`;
export const customerAccessCardUrl = (userId) => `${customerApiUrl}/api/customer/${userId}/access-card`;
/// https://dev-customer.denimdream.com/api/gift-card/card-preview?name=Andrey%20Veyalko&price=50&design=GREY

// STRAPI
export const getPageDtaUrl = () => `${strapiUrl}/get-page-data`;
export const getPageDataMainUrl = () => `${strapiUrl}/get-page-data-main`;
export const getContentPageDataUrl = () => `${strapiUrl}/get-content-page-data`;
export const getSizeTableUrl = () => `${strapiUrl}/size-table`;
export const getMediaUrl = (url: string) => (url && url.startsWith('http') ? url : `${imageUlrSuffix}${url}`);
export const getMediaThumbnailUrl = (url: string, type: string = 'frontpage') => {
  const thumbnail = url.replace(`/uploads/${type}-`, '/uploads/thumbnail_');
  return url.startsWith('http') ? thumbnail : `${imageUlrSuffix}${thumbnail}`;
};
export const getLookBookItemsUrl = () => `${strapiUrl}/lookbook`;
export const getAllTranslationsUrl = () => `${strapiUrl}/translations`;
export const getNavBarUrl = (languageCode: string) => `${strapiUrl}/app-nav-bar?locale=${languageCode}`;
export const getPrivatePolicyUrl = (countryCode: string) => `${strapiUrl}/privacy-policy-modal-${countryCode}`;
export const getPagesUrl = (countryCode: string) => `${strapiUrl}/pages-${countryCode.toLowerCase()}s`;
export const getModalUrl = (modalId: string) => `${strapiUrl}/modals/${modalId}`;

// API V2
export const getProductsListUrl = () => `${BASE_URL}api/v2/product/product`;
export const getWrappedCategoriesUrl = () => `${BASE_URL}api/v2/product/category-wrapped`;
export const getAllCategoriesUrl = () => `${BASE_URL}api/v2/product/category-all`;

// PRODUCTS
export const getTrendingProductsUrl = () => `${BASE_URL}api/v2/product/product`;
export const getRecentProductsUrl = () => `${BASE_URL}api/v2/product/recently`;
export const getClearRecentProductsUrl = () => `${BASE_URL}api/v2/product/recently/delete/all`;
export const getSuitableProductUrl = () => `${BASE_URL}api/v2/product/product-suitable`;
export const getProductPerformanceUrl = () => `${BASE_URL}api/v2/product/product-performance`;
export const getProductFilterUrl = () => `${BASE_URL}api/v2/product/product-filter`;
export const getProductFilterUrlLocal = () => `/api/product/product-filter`;

export const getBrandsUrl = () => `${BASE_URL}api/v2/product/brand`;

// GIFT CARD
export const getGiftCardDesignOptionsUrl = ({
  countryCode,
  languageCode,
}) => `${customerApiUrl}/api/gift-card-design?lang=${languageCode}-${countryCode}`;
export const getGiftCardDesignPreviewUrl: () => string = () => `${customerApiUrl}/api/gift-card-design/preview`;

export const getGiftCardUrl = () => `${BASE_URL}api/v2/product/gift-card`;

export const authStrapiUrl = () => `${strapiUrl}/auth/local`;
export const translationStrapiUrl = (key) => `${strapiUrl}/translations/${key}`;


// NextJS API Routes
export const phoneValidationUrl = (origin) => `${origin}/api/phone/validation`;
export const defaultTranslationsUrl = (origin) => `${origin}/api/default-translations`;
export const qrScannerSubmitUrl = (origin) => `${origin}/api/loyalty/qr`;

export const apiLoyaltyAccessCardUrl = (origin) => `${origin}/api/loyalty/accessCard`;
