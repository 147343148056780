// @ts-nocheck
import { FontStyle, TypographyStyle, TypographyUtils } from '@material-ui/core/styles/createTypography';
import { FontCollection } from '@themes/font';

export interface TypographyCollectionSection extends Record<string, TypographyStyle>, FontStyle, TypographyUtils {
}

export interface TypographyCollection
  extends Record<string, Record<string, Record<string, TypographyCollectionSection>>> {
}

export const TypographyCollection: TypographyCollection = {
  body: {
    bold: {
      s: {
        fontFamily: FontCollection.raleway,
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '124%',
      },
    },
    regular: {
      l: {
        fontFamily: FontCollection.raleway,
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        lineHeight: '124%',
      },
      s: {
        fontFamily: FontCollection.raleway,
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        lineHeight: '160%',
      },
    },
  },
  fix: {
    fixRalewayNumbers: {
      fontFeatureSettings: '"lnum"',
      mozFontFeatureSettings: '"lnum"',
      webkitFontFeatureSettings: '"lnum"',
    },
  },
  heading: {
    desktop: {
      l: {
        fontFamily: FontCollection.inter,
        fontSize: '56px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '112%',
      },
      m: {
        fontFamily: FontCollection.inter,
        fontSize: '32px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '124%',
      },
      s: {
        fontFamily: FontCollection.inter,
        fontSize: '32px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '124%',
      },
      xs: {
        fontFamily: FontCollection.inter,
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '124%',
      },
    },
    mobile: {
      l: {
        fontFamily: FontCollection.inter,
        fontSize: '40px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '124%',
      },
      m: {
        fontFamily: FontCollection.inter,
        fontSize: '26px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '124%',
      },
      s: {
        fontFamily: FontCollection.inter,
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '132%',
      },
      xs: {
        fontFamily: FontCollection.raleway,
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '132%',
      },
    },
  },
  link: {
    bold: {
      l: {
        fontFamily: FontCollection.raleway,
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 'bold',
        lineHeight: '124%',
      },
      s: {
        fontFamily: FontCollection.raleway,
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 'bold',
        lineHeight: '160%',
      },
      xs: {
        fontFamily: FontCollection.raleway,
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 'bold',
        lineHeight: '124%',
      },
    },
    mobileMenu: {
      fontFamily: FontCollection.raleway,
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 'bold',
      lineHeight: '280%',
    },
  },
};
