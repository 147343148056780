export const GIFT_CARD_GET_PRODUCT_REQUEST = 'GIFT_CARD_GET_PRODUCT_REQUEST';
export type GIFT_CARD_GET_PRODUCT_REQUEST = 'GIFT_CARD_GET_PRODUCT_REQUEST';
export const GIFT_CARD_GET_PRODUCT_SUCCESS = 'GIFT_CARD_GET_PRODUCT_SUCCESS';
export type GIFT_CARD_GET_PRODUCT_SUCCESS = 'GIFT_CARD_GET_PRODUCT_SUCCESS';
export const GIFT_CARD_GET_PRODUCT_FAILED = 'GIFT_CARD_GET_PRODUCT_FAILED';
export type GIFT_CARD_GET_PRODUCT_FAILED = 'GIFT_CARD_GET_PRODUCT_FAILED';

export const GIFT_CARD_UPDATE_FORM = 'GIFT_CARD_UPDATE_FORM';
export type GIFT_CARD_UPDATE_FORM = 'GIFT_CARD_UPDATE_FORM';

export const GIFT_CARD_GET_DESIGN_OPTIONS_REQUEST = 'GIFT_CARD_GET_DESIGN_OPTIONS_REQUEST';
export type GIFT_CARD_GET_DESIGN_OPTIONS_REQUEST = 'GIFT_CARD_GET_DESIGN_OPTIONS_REQUEST';
export const GIFT_CARD_GET_DESIGN_OPTIONS_SUCCESS = 'GIFT_CARD_GET_DESIGN_OPTIONS_SUCCESS';
export type GIFT_CARD_GET_DESIGN_OPTIONS_SUCCESS = 'GIFT_CARD_GET_DESIGN_OPTIONS_SUCCESS';
export const GIFT_CARD_GET_DESIGN_OPTIONS_FAILED = 'GIFT_CARD_GET_DESIGN_OPTIONS_FAILED';
export type GIFT_CARD_GET_DESIGN_OPTIONS_FAILED = 'GIFT_CARD_GET_DESIGN_OPTIONS_FAILED';

export const GIFT_CARD_INIT_FORM = 'GIFT_CARD_INIT_FORM';
export type GIFT_CARD_INIT_FORM = 'GIFT_CARD_INIT_FORM';
