import { getLocalStorageValue } from '@utils/localStorage';

export const isCookieConsentEnabledInStore = (): boolean => {
  if(typeof window !== 'undefined'){
    const cookieConsentCookie = getLocalStorageValue('ddCookieConsent');
    if (cookieConsentCookie) {
      return cookieConsentCookie === 'true' || (cookieConsentCookie.split(',').some((item) => item === '3:1'));
    }
  }
  return false;
};
