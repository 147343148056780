import { androidUATrigger, EUserAgent, iosUATrigger } from '@constants/app';

export const getUserAgentType = (uaString: string): EUserAgent | null => {
  if(uaString.toLowerCase().includes(androidUATrigger.toLowerCase())){
    return EUserAgent.android;
  } else if(uaString.toLowerCase().includes(iosUATrigger.toLowerCase())){
    return EUserAgent.ios;
  }
  return null;
};
