import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { ColorsPalette } from '@themes/colors';

/*
export const FontCollection_0 = {
  inter: 'Inter, sans-serif',
  montserrat: 'Montserrat',
  quattrocento: 'Quattrocento Sans',
  quicksand: 'Quicksand',
  raleway: 'Raleway, sans-serif',
  satisfy: 'Satisfy',
};
*/
export const FontCollection = {
  inter: 'var(--inter-font)',
  montserrat: 'var(--montserrat-font)',
  quattrocento: 'var(--quattrocentoSans-font)',
  quicksand: 'var(----quicksand-font)',
  raleway: 'var(--raleway-font)',
  satisfy: 'Satisfy',
};

export const TypographyCollection = {
  BodyBlogXL: {
    fontFamily: FontCollection.raleway,
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '160%',
  },
  BodyBoldS: {
    fontFamily: FontCollection.raleway,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 'bold' as 'bold',
    lineHeight: '136%',
  },
  BodyRegularL: {
    fontFamily: FontCollection.raleway,
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 'normal' as 'normal',
    lineHeight: '124%',
  },
  BodyRegularS: {
    fontFamily: FontCollection.raleway,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 'normal' as 'normal',
    lineHeight: '160%',
  },
  HLinkBoldL: {
    fontFamily: FontCollection.raleway,
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '124%',
  },
  HLinkBoldS: {
    fontFamily: FontCollection.raleway,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: '0em',
    lineHeight: '22px',
  },
  HLinkBoldXS: {
    fontFamily: FontCollection.raleway,
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '124%',
  },
  HeadingDesktopL: {
    fontFamily: FontCollection.inter,
    fontSize: '56px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '112%',
  },
  HeadingDesktopM: {
    fontFamily: FontCollection.inter,
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '124%',
  },
  HeadingDesktopS: {
    fontFamily: FontCollection.inter,
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '124%',
  },
  HeadingDesktopXS: {
    fontFamily: FontCollection.inter,
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '132%',
  },
  HeadingDesktopXXS: {
    fontFamily: FontCollection.inter,
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '132%',
  },
  HeadingDesktopXXXS: {
    fontFamily: FontCollection.inter,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '132%',
  },
  HeadingMobileL: {
    fontFamily: FontCollection.inter,
    fontSize: '40px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '124%',
  },
  HeadingMobileM: {
    fontFamily: FontCollection.inter,
    fontSize: '26px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '124%',
  },
  HeadingMobileS: {
    fontFamily: FontCollection.inter,
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '132%',
  },
  HeadingMobileX: {
    fontFamily: FontCollection.raleway,
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '132%',
  },
  HeadingMobileXS: {
    fontFamily: FontCollection.raleway,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '132%',
  },
  HeadingMobileXXS: {
    fontFamily: FontCollection.raleway,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '132%',
  },

  HeadingMobileXXXS: {
    fontFamily: FontCollection.raleway,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '132%',
  },
  List1: {
    fontFamily: FontCollection.raleway,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 'normal' as 'normal',
    lineHeight: '124%',
  },
  default: {
    fontFamily: FontCollection.raleway,
    fontSize: '14px',
    fontStyle: 'normal',
    lineHeight: '124%',
  },
  defaultBold: {
    fontFamily: FontCollection.raleway,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '124%',
  },
};

export default makeStyles((theme: Theme) =>
  createStyles({
    activeText: {
      '&:active': {
        color: ColorsPalette.hoverActionCTA,
      },
      [theme.breakpoints.up('md')]: {
        '&:hover': {
          color: ColorsPalette.hoverActionCTA,
        },
        '&:hover $hoverFill': {
          '& path': {
            fill: ColorsPalette.hoverActionCTA,
          },
        },
        '&:hover $hoverStroke': {
          '& path': {
            stroke: ColorsPalette.hoverActionCTA,
          },
        },
      },
      cursor: 'pointer',
    },
    activeTextDecoration: {
      textDecoration: 'underline',
    },
    activeTextLink: {
      '&:active': {
        color: ColorsPalette.hoverActionCTA,
      },
      '&:hover': {
        color: ColorsPalette.hoverActionCTA,
      },
      cursor: 'pointer',
      fontFamily: FontCollection.raleway,
      fontSize: '14px',
      fontWeight: 'normal',
      lineHeight: '160%',
      textDecoration: 'underline',
      textDecorationLine: 'underline',
    },
    default: {
      ...TypographyCollection.default,
    },
    defaultBold: {
      ...TypographyCollection.defaultBold,
    },
    defaultCursor: {
      cursor: 'default',
    },
    error: {
      color: ColorsPalette.salesDark,
    },
    fontWeightBold: {
      fontWeight: 700,
    },
    hoverFill: {
      '& path': {
        fill: ColorsPalette.primary,
      },
    },
    hoverStroke: {
      '& path': {
        stroke: ColorsPalette.primary,
      },
    },
  })
);
