import { Reducer } from 'redux';

import { itemsPerPage } from '@constants/index';
import { PageTypes } from '@constants/pageTypes';
import { ESortType } from '@constants/sort';
import { IPageActions } from '@interfaces/page/actions';
import { IPageDataActions } from '@interfaces/pageData/actions';
import { EProductsListFilter } from '@interfaces/productsList';
import { IProductsListActions } from '@interfaces/productsList/actions';
import { IProductsListReducer } from '@interfaces/productsList/reducer';

import { getFilterData, getListData } from '@reducers/productsList/utils';
import * as actionsBrands from '../../constants/actions/brands';
import * as actionsPage from '../../constants/actions/page';
import * as actionsPageData from '../../constants/actions/pageData';
import * as actions from '../../constants/actions/productsList';
import { initialState } from './initState';

export const productsListReducer: Reducer<IProductsListReducer, IProductsListActions | IPageActions | IPageDataActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case actions.PRODUCTS_LIST_SHORT_GET_REQUEST:
    case actions.PRODUCTS_LIST_GET_REQUEST:
      return {
        ...state,
        data: { ...initialState.data, ...state.data },
        dataMobileFilter: { ...initialState.dataMobileFilter },
        error: [],
        loaded: false,
        loading: true,
      };
    case actionsPageData.PAGE_DATA_UPDATE_REQUEST:
      return {
        ...state,
        data: { ...initialState.data },
        dataFilter: { ...initialState.dataMobileFilter },
        dataMobileFilter: { ...initialState.dataMobileFilter },
        loading: true,
      };
    case actions.PRODUCTS_LIST_GET_SUCCESS:
      const page = Math.ceil(action.payload?.products?.length / itemsPerPage);
      const filterData = getFilterData(action.payload);
      const listData = getListData(action.payload);
      return {
        ...state,
        data: { ...state.data, ...listData, page },
        dataFilter: { ...state.data, ...filterData },
        dataMobileFilter: { ...state.data, ...action.payload, page },
        loadedFilter: true,
        loading: false,
      };
    case actions.PRODUCTS_LIST_SHORT_GET_SUCCESS:
      const pageN = Math.ceil(action.payload?.products?.length / itemsPerPage);
      const listData2 = getListData(action.payload);
      return {
        ...state,
        data: { ...state.data, ...listData2, page: pageN },
        dataMobileFilter: { ...state.dataMobileFilter, ...listData2, page: pageN },
        loading: false,
      };
    case actions.PRODUCTS_LIST_GET_FILTER_REQUEST:
      return {
        ...state,
        loadingFilter: true,
      };
    case actions.PRODUCTS_LIST_GET_FILTER_SUCCESS:
      return {
        ...state,
        dataFilter: { ...state.data, ...action.payload },
        dataMobileFilter: { ...state.dataMobileFilter, ...action.payload },
        loadedFilter: true,
        loadingFilter: false,
      };
    case actions.PRODUCTS_LIST_GET_FILTER_FAILED:
      return {
        ...state,
        loadingFilter: false,
      };
    case actions.PRODUCTS_LIST_GET_NEXT_PAGE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          page: action.payload.page,
          products: [...state.data.products, ...action.payload.products],
        },
        loading: false,
      };
    case actions.PRODUCTS_LIST_GET_FAILED:
    case actions.PRODUCTS_LIST_SHORT_GET_FAILED:
      return { ...state, error: action.payload, loading: false };
    case actions.PRODUCTS_LIST_SET_DEFAULT_SEO_INFO:
      const { description = '', h1 = '', short_story = '', title = '' } = action.payload || {};
      return { ...state, defaultSeoInfo: { description, h1, short_story, title } };
    case actions.PRODUCTS_LIST_FILTER_CHANGE:
      const newFilterValue = { ...state.filter, ...action.payload };
      return { ...state, filter: { ...newFilterValue }, filterMobile: { ...newFilterValue } };
    case actions.PRODUCTS_LIST_FILTER_CLEAR:
      const newClearedFilterValue = {
        ...initialState.filter,
        sexId: state.filter.sexId,
        textSearch: state.filter.textSearch,
      };
      return {
        ...state,
        filter: { ...newClearedFilterValue },
        filterMobile: { ...newClearedFilterValue },
      };
    case actions.PRODUCTS_LIST_MOBILE_FILTER_GET_REQUEST:
      return { ...state, loadingMobile: true };
    case actions.PRODUCTS_LIST_MOBILE_FILTER_GET_SUCCESS:
      return { ...state, dataMobileFilter: { ...state.dataMobileFilter, ...action.payload }, loadingMobile: false };
    case actions.PRODUCTS_LIST_MOBILE_FILTER_GET_FAILED:
      return { ...state, loadingMobile: false };
    case actions.PRODUCTS_LIST_FILTER_MOBILE_CHANGE:
      if (action.payload[EProductsListFilter.sexId] && action.payload[EProductsListFilter.sexId] !== state.filterMobile[EProductsListFilter.sexId]) {
        return {
          ...state,
          filterMobile: { ...initialState.filterMobile, ...action.payload, [EProductsListFilter.textSearch]: state.filterMobile[EProductsListFilter.textSearch] },
          filterMobileChanged: true,
        };
      } else {
        return { ...state, filterMobile: { ...state.filterMobile, ...action.payload }, filterMobileChanged: true };
      }
    case actions.PRODUCTS_LIST_FILTER_MOBILE_APPLY:
      const newMobileFilterValue = { ...state.filterMobile };
      const newFilterData  = getFilterData(state.dataMobileFilter);
      return {
        ...state,
        data: { ...state.data, ...state.dataMobileFilter },
        dataFilter: {...newFilterData },
        filter: newMobileFilterValue,
        filterMobileChanged: false,
      };
    case actions.PRODUCTS_LIST_FILTER_MOBILE_CANCEL:
      return {
        ...state,
        dataMobileFilter: { ...state.data, ...state.dataFilter },
        filterMobile: { ...state.filter },
        filterMobileChanged: false,
      };
    case actions.PRODUCTS_LIST_FILTER_MOBILE_CLEAR:
      return {
        ...state,
        filterMobile: {
          ...initialState.filterMobile,
          [EProductsListFilter.sexId]: state.filterMobile[EProductsListFilter.sexId],
        },
        filterMobileChanged: true,
      };
    case actions.PRODUCTS_LIST_SORT_CHANGE:
      return {
        ...state,
        sortDirection: action.payload,
      };
    case actions.PRODUCTS_LIST_FINISH_INIT:
      return {
        ...state,
        loaded: action.payload,
      };
    case actionsBrands.BRANDS_GET_SUCCESS:
      return {
        ...state,
        brands: action.payload,
      };
    case actionsPage.PAGE_UNMOUNT:
      if (action.payload === PageTypes.productsList) {
        return { ...initialState };
      } else {
        return state;
      }
    case actionsPageData.PAGE_DATA_SITE_SETTINGS_SAVE:
      if (action.payload && action.payload.listDefaultSort) {
        switch (action.payload.listDefaultSort){
          case 'newFirst':
            return  { ...state, sortDirection: ESortType.newFirst, sortDirectionDefault: ESortType.newFirst };
          case 'price_asc':
            return  { ...state, sortDirection: ESortType.price_asc, sortDirectionDefault: ESortType.price_asc };
          case 'price_desc':
            return  { ...state, sortDirection: ESortType.price_desc, sortDirectionDefault: ESortType.price_desc };
          case 'sale':
            return  { ...state, sortDirection: ESortType.sale, sortDirectionDefault: ESortType.sale };
          default:
            return  { ...state, sortDirection: ESortType.newFirst, sortDirectionDefault: ESortType.newFirst };
        }
      }
      return state;
    case actionsPageData.PAGE_DATA_UPDATE_SUCCESS:
    case actionsPageData.PAGE_DATA_GET_SUCCESS:
      if (action.payload && action.payload.data && action.payload.data.siteSettings && action.payload.data.siteSettings.listDefaultSort) {
        switch (action.payload.data.siteSettings.listDefaultSort){
          case 'newFirst':
            return  { ...state, sortDirection: ESortType.newFirst, sortDirectionDefault: ESortType.newFirst };
          case 'price_asc':
            return  { ...state, sortDirection: ESortType.price_asc, sortDirectionDefault: ESortType.price_asc };
          case 'price_desc':
            return  { ...state, sortDirection: ESortType.price_desc, sortDirectionDefault: ESortType.price_desc };
          case 'sale':
            return  { ...state, sortDirection: ESortType.sale, sortDirectionDefault: ESortType.sale };
          default:
            return  { ...state, sortDirection: ESortType.newFirst, sortDirectionDefault: ESortType.newFirst };
        }
      }
      return state;
    default:
      return state;
  }
};
