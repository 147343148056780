export const ColorsPalette = {
  _gotop_arrow: '#959595',
  _gotop_border: '#c0bdbd',
  coralPink: '#DD8C7D',
  coralPinkLight: '#FCE8E5',
  error1: '#f44336',
  error2: '#FFE2E2',
  hoverActionCTA: '#5F1457',
  menuLinkHoverAction: '#A8249B',
  neutral1: '#E1E1E1',
  neutral2: '#FFFFFF',
  neutral3: '#D4D4D4',
  newDark: '#378A3F',
  newLight: '#DFFAE1',
  outletDark: '#ff6b00',
  primary: '#000000',
  saffronDark: '#BE711E',
  saffronLight: '#FFF6C7',
  salesDark: '#FF0000',
  salesLight: '#FFE2E2',
  secondary: '#B4B8C7',
  tertiary: '#7A7A7A',
  tertiary2: '#A5A5A5',
};
