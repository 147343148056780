export const WOMEN_SEX_ID = '2';

export enum EGender {
  man = 1,
  women = 2,
  boy = 4,
  girl = 5,
}
export enum EGenderType {
  man = 'men',
  kids = 'kids',
  women = 'women',
  boy = 'boy',
  girl = 'girl',
}
