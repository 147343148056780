export const CONTEXT_SAVE_QUERY = 'CONTEXT_SAVE_QUERY';
export type CONTEXT_SAVE_QUERY = 'CONTEXT_SAVE_QUERY';

export const CONTEXT_SAVE_DEVICE = 'CONTEXT_SAVE_DEVICE';
export type CONTEXT_SAVE_DEVICE = 'CONTEXT_SAVE_DEVICE';

export const CONTEXT_SAVE_HOST = 'CONTEXT_SAVE_HOST';
export type CONTEXT_SAVE_HOST = 'CONTEXT_SAVE_HOST';

export const CONTEXT_SET_VIEWPORT = 'CONTEXT_SET_VIEWPORT';
export type CONTEXT_SET_VIEWPORT = 'CONTEXT_SET_VIEWPORT';
