import { IFilterGroupOption } from '@components/pages/productsList/Filter/Group/filterOption';
import { EGender, EGenderType, WOMEN_SEX_ID } from '@constants/gender';
import { PageTypes } from '@constants/pageTypes';
import { ISeoAttributes, IWrappedCategoryMapped } from '@interfaces/category';
import { IMegaMenuLinkDataObject } from '@interfaces/megaMenu';
import { IPageData, IPageReference } from '@interfaces/pageData/reducer';
import {
  EProductsListFilter, IFilterAge, IFilterFits, IFilterWaist, IProductBrand, IProductListFilterBrand,
  IProductListFilterColor, ISexItem,
} from '@interfaces/productsList';
import { IProductListFilter } from '@interfaces/productsList/reducer';
import { IFilterData } from '@reducers/productsList/useState';

export const mapSexes = (options: ISexItem[]): IFilterGroupOption[] => {
  return options.map((item) => ({
    id: Number(item.sexId),
    name: item.sexLocal,
    selected: item?.selected,
    slug: item.slug,
  }));
};

export const mapSizes = (options: string[]): IFilterGroupOption[] => {
  return options?.map((item) => ({ id: item, name: item }));
};

export const mapFits = (options: IFilterFits[]): IFilterGroupOption[] => {
  return options?.map((item) => ({
    active: item.active || false,
    id: item.fitId,
    name: item.fitLocal || item.fit,
  })) || [];
};

export const mapWaist = (options: IFilterWaist[]): IFilterGroupOption[] => {
  return options?.map((item) => ({
    active: item.active || false,
    id: item.waistId,
    name: item.waistLocal || item.waist,
  })) || [];
};

export const getBrandSeoBySexId = (item: IProductListFilterBrand | IProductBrand, pageSexId?: string | number | null): ISeoAttributes | null => {
  if (Array.isArray(item.seoAttributes) && item.seoAttributes.length > 0) {
    if (pageSexId !== undefined && pageSexId !== null) {
      const seoBySexId = item.seoAttributes.find((seoItem) => seoItem.sexId && Number(seoItem.sexId) === Number(pageSexId));
      if (seoBySexId) {
        return seoBySexId;
      }
    }
    return item.seoAttributes[0];
  }
  return null;
};

export const getBrandSlugBySexId = (item: IProductListFilterBrand | IProductBrand, pageSexId?: string | number | null): string => {
  const seoAttr = getBrandSeoBySexId(item, pageSexId);
  if (seoAttr) {
    if (seoAttr.seoUrlSlug) {
      return seoAttr.seoUrlSlug || '';
    } else {
      const defaultSeo = getBrandSeoBySexId(item, null);
      if (defaultSeo) {
        if (defaultSeo.seoUrlSlug) {
          return defaultSeo.seoUrlSlug || '';
        }
      }
    }
  }
  return '';
};

export const mapBrandIds = (options: IProductListFilterBrand[], pageSexId?: string | number | null): IFilterGroupOption[] => {
  return options.map((item) => ({
    active: item.active || false,
    id: item.brandId,
    name: item.brand,
    slug: getBrandSlugBySexId(item, pageSexId),
  }));
};

export const mapColors = (options: IProductListFilterColor[]): IFilterGroupOption[] => {
  return options.map(({ active, colorGroupId, colorGroupLocal, code }) => ({
    active,
    colorCode: code,
    id: colorGroupId,
    name: colorGroupLocal,
  }));
};

export const mapCategories = (options: IWrappedCategoryMapped[], activeCategories?: string[]): IFilterGroupOption[] => {
  return (
    options?.map(({ categoryId, categoryLocal, children, mobilePictureUrl, seoAttributes }) => ({
      active: activeCategories ? activeCategories.includes(categoryId) : true,
      children: mapCategories(children || [], activeCategories),
      icon: mobilePictureUrl || null,
      id: categoryId,
      name: categoryLocal,
      slug: seoAttributes?.seoUrlSlug || '',
    })) || []
  );
};

export const mapAges = (options: IFilterAge[]): IFilterGroupOption[] => {
  return options?.map((item) => ({ active: item.active, id: item.id, name: item.from + '-' + item.to }));
};


export const mapDataToFilter = (groupKey: string, data, activeCategories?: string[], pageSexId?: number | string | null): IFilterGroupOption[] => {
  switch (groupKey) {
    case EProductsListFilter.categoryId:
      return mapCategories(data, activeCategories);
    case EProductsListFilter.categoryIdP:
      return mapCategories(data, activeCategories);
    case EProductsListFilter.colorGroupId:
      return mapColors(data);
    case EProductsListFilter.fits:
      return mapFits(data);
    case EProductsListFilter.sizes:
      return mapSizes(data);
    case EProductsListFilter.brandId:
      return mapBrandIds(data, pageSexId);
    case EProductsListFilter.sexId:
    case EProductsListFilter.sexIdMob:
      return mapSexes(data);
    case EProductsListFilter.waists:
      return mapWaist(data);
    case EProductsListFilter.ageId:
      return mapAges(data);
    default:
      return [];
  }
};

export const getAndMapOutletAndFreshSexes = (
  pageDataData: IPageData,
  pageSexId: number | null | undefined,
): ISexItem[] => {
  return getAllSexes(pageDataData, pageSexId, [EGenderType.man, EGenderType.women]);
};

export const getAndMapChildrenSexes = (pageDataData: IPageData, filterData: IFilterData, pageType: string): ISexItem[] => {
  const pages: IPageReference[] = pageDataData?.pages || [];
  const allowedSexIds = pageType === PageTypes.kids || (pageDataData?.filter?.sexId && [EGender.boy, EGender.girl].includes(pageDataData?.filter?.sexId)) ? [EGender.boy, EGender.girl] : [];
  return filterData[EProductsListFilter.sexId] && filterData[EProductsListFilter.sexId]
    .filter((item) => item && item.hasOwnProperty('sex') && item.hasOwnProperty('sexId') && item.hasOwnProperty('sexLocal') && allowedSexIds.includes(item.sexId))
    .map(({
      sex,
      sexId,
      sexLocal,
    }) => {
      const page = pages.find(({ type, filter: pageFilter }) => type === 'sex' && pageFilter?.sexId === sexId);
      const slug = page ? page.alias : '';

      return {
        sex,
        sexId,
        sexLocal,
        slug,
      };
    }) || [];
};

export const getAllSexes = (
  pageDataData: IPageData,
  pageSexId: number | null | undefined,
  genderTypes: string[] = [EGenderType.man, EGenderType.women, EGenderType.kids],
) => {
  const megaMenuData: IMegaMenuLinkDataObject[] = pageDataData?.['mega-menu'] || [];
  const genderMenuItems: IMegaMenuLinkDataObject[] = megaMenuData.filter(
    ({ isRootItem, dropdownType }) => isRootItem && genderTypes.includes(dropdownType || ''),
  );
  const pages: IPageReference[] = pageDataData?.pages || [];
  return genderMenuItems.map(({ translation, dropdownItems }) => {
    const pageUrl = dropdownItems?.page || '';
    const page = pages.find(({ url }) => `/${url}` === pageUrl);
    const slug = page ? page.alias : pageUrl.split('/').pop();
    const sexId = (page ? page.filter?.sexId : pageSexId) || Number(WOMEN_SEX_ID);

    return {
      sex: translation,
      sexId,
      sexLocal: translation,
      slug,
    };
  });
};

export const getAndMapSexes = (
  pageDataData: IPageData,
  filterData: IFilterData,
  pageType: string,
  pageSexId: number | null | undefined,
): ISexItem[] | undefined => {
  if (pageType === PageTypes.outlet || pageType === PageTypes.fresh) {
    return getAndMapOutletAndFreshSexes(pageDataData, pageSexId);
  } else if (pageType === PageTypes.kids || [EGender.man, EGender.women, EGender.girl, EGender.boy].includes(pageSexId || 0)) {
    return getAndMapChildrenSexes(pageDataData, filterData, pageType);
  }
};

export const getActiveGroupFilter = (
  groupKey: string,
  mobile: boolean,
  filter: IProductListFilter,
  filterMobile: IProductListFilter,
): string[] => {
  let groupFilter: string[] = [];

  if (mobile) {
    if (filterMobile.hasOwnProperty(groupKey)) {
      groupFilter = filterMobile[groupKey];
    }
  } else if (filter.hasOwnProperty(groupKey)) {
    groupFilter = filter[groupKey];
  }

  return groupFilter.map((id: string | number) => String(id));
};
