import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();
export const facebookAppId = publicRuntimeConfig.facebookAppId;
export const previewMode = publicRuntimeConfig.previewMode;
export const mobileMode: boolean = publicRuntimeConfig.mobile === 'true';
export const mobileSession: boolean = publicRuntimeConfig.mobileSession === 'true' && mobileMode;
export const logRocketId = publicRuntimeConfig.logRocketId;
export const originHost = publicRuntimeConfig.origin;
export const nodeEnv = publicRuntimeConfig.nodeEnv;
export const debug: boolean = publicRuntimeConfig.debug === 'true';
export const logRocketEnable: boolean = ['true', true].includes(publicRuntimeConfig.logRocketEnable);
export const googleTagEnable: boolean = ['true', true].includes(publicRuntimeConfig.googleTagEnable);
export const plausibleEnable: boolean = ['true', true].includes(publicRuntimeConfig.plausibleEnable);
export const googleAppIdHard = '133557160899-ijd030r2j9pl3ji17cqed33mfu0ed0e5.apps.googleusercontent.com';
export const sentryDns = publicRuntimeConfig.sentryDns || 'https://5c023598e5084882bf610c6298c1e6e0@o4504887288725504.ingest.sentry.io/4504887676895232';

export const maileBeauty: boolean = ['true', true].includes(publicRuntimeConfig.maileBeauty);
export const dreamCafe: boolean = ['true', true].includes(publicRuntimeConfig.dreamCafe);

export const miniSite = maileBeauty || dreamCafe;


