import { Reducer } from 'redux';

import { ILookBookActions } from '@interfaces/lookbook/actions';
import { ILookBookReducer } from '@interfaces/lookbook/reducer';
import * as actions from '../../constants/actions/lookbook';
import { initialState } from './initState';

export const lookBookReducer: Reducer<ILookBookReducer, ILookBookActions> = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOOKBOOK_GET_ITEMS_SUCCESS:
      return { ...state, items: action.payload };
    case actions.LOOKBOOK_GET_ITEMS_NEXT_SUCCESS:
      return { ...state, items: [...state.items, ...action.payload] };
    case actions.LOOKBOOK_GET_ITEMS_PRODUCTS_SUCCESS:
      return { ...state, products: action.payload.products };
    default:
      return state;
  }
};
