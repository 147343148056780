import { ISignUpParams } from '@interfaces/auth';
import { Dispatch } from 'redux';

import { acAccountLogout } from '@actions/acAuth';
import * as actions from '@constants/actions/account';
import { ERedisCacheKey } from '@constants/cacheCallApi';
import { itemsPerPageOrdersList } from '@constants/index';
import { PageTypes } from '@constants/pageTypes';
import { RequestMethods } from '@constants/types';
import {
  AccountAttributesFields,
  EDeliveryMethodAttributesFields,
  IDeliveryMethod,
  IDeliveryMethodAttributes,
} from '@interfaces/account';
import {
  IAccountClearDetailsFieldError,
  IAccountCloseOrderDetails,
  IAccountGetDeliveryMethodChangePage,
  ICustomerDeliveryFormUnmount,
  ICustomerDeliveryMethodAdd,
} from '@interfaces/account/actions';
import { EDeliveryMethodPage } from '@interfaces/account/reducer';
import { IShipmentMethod } from '@interfaces/cart';
import { ISubscriber, IUserData } from '@interfaces/config/reducer';
import { ESessionStatuses, ICallApiAction, TCallApiAction } from '@interfaces/fetchService';
import { IApiResponse, TThunkResult } from '@interfaces/index';
import { IStore } from '@interfaces/store';

import {
  customerChangeEmailUrl,
  customerChangePasswordUrl,
  customerDeleteUrl,
  customerEmailFinalizesUrl,
  customerNotificationSubscriptionsUrl, customerOneSignalIdUrl,
  customerRemindFinalizesUrl,
  getCustomerDeliveryMethodByIdUrl,
  getCustomerDeliveryMethodsUrl,
  getCustomerMethod,
  getCustomerOrderByTransaction,
  getCustomerOrdersMethodDetailsUrl,
  getCustomerOrdersMethodsUrl,
  getCustomerOrdersRetailDetailsMethodsUrl,
  getCustomerOrdersRetailMethodsUrl,
  getCustomerPaymentMethodItemUrl,
  getCustomerPaymentMethodsUrl,
  getCustomerSocialsLongUrl,
  getCustomerSocialsUrl,
  getProductsByIdsUrl,
  orderTrackingUrl,
  shipmentLocationsUrl,
  shipmentMethodsUrl,
} from '@constants/urls';
import { CALL_API } from '../../services/SSRService';
import { addParamsToUrl } from '../../services/SSRService/utils';


interface IGetProductsParams {
  country: string | null;
  lang: string | null;
  productId: string[] | number[];
}

export const onGetOrderProductsDetailsAction: (params: IGetProductsParams) => any = ({
  country,
  lang,
  productId,
}) => (dispatch) =>
  dispatch({
    [CALL_API]: {
      endpoint: addParamsToUrl(getProductsByIdsUrl(), {
        country,
        lang,
        productId,
      }),
      facebook: true,
      method: RequestMethods.GET_METHOD,
      types: [
        actions.ACCOUNT_GET_PRODUCTS_REQUEST,
        actions.ACCOUNT_GET_PRODUCTS_SUCCESS,
        actions.ACCOUNT_GET_PRODUCTS_FAILED,
      ],
    },
  });

type onGetOrderProductsDetailsType = () => (dispatch: Dispatch, getState: () => IStore) => any;
export const onGetOrderProductsDetails: onGetOrderProductsDetailsType = () => (dispatch, getState) => {
  const {
    account: { ordersHistory, productsInOrders },
    pageData: { countryCode, languageCode },
  } = getState();
  const productId = ordersHistory?.map((item) => item.cartItems.map((cartItem) => cartItem.productId)).flat();
  const set: Set<number> = new Set(productId);
  // @ts-ignore
  const uniqProductsId = [...set];

  const productsIdAvailable = productsInOrders?.map((product) => product.colors.map((color) => color.productId)).flat();
  const setAvailable: Set<number> = new Set(productsIdAvailable);
  // @ts-ignore
  const uniqProductsIdAvailable = [...setAvailable];

  const productsNeed = uniqProductsId.filter((x) => !uniqProductsIdAvailable.includes(x));
  return productsNeed && productsNeed.length > 0
    ? dispatch(
      onGetOrderProductsDetailsAction({
        country: countryCode,
        lang: languageCode,
        productId: productsNeed,
      }),
    )
    : false;
};

export const acCustomerSaveProfile = (data: IUserData): TCallApiAction => ({
  [CALL_API]: {
    body: { data },
    endpoint: getCustomerMethod(),
    method: RequestMethods.PATCH_METHOD,
    types: [
      actions.ACCOUNT_SAVE_DETAILS_REQUEST,
      actions.ACCOUNT_SAVE_DETAILS_SUCCESS,
      actions.ACCOUNT_SAVE_DETAILS_FAILED,
    ],
  },
});

export const acCustomerClearProfileErrors = (data: AccountAttributesFields): IAccountClearDetailsFieldError => ({
  payload: data,
  type: actions.ACCOUNT_CLEAR_DETAILS_FIELD_ERROR,
});

export const acCustomerPaymentsMethodsGet = () => (dispatch) =>
  dispatch({
    [CALL_API]: {
      endpoint: getCustomerPaymentMethodsUrl(),
      method: RequestMethods.GET_METHOD,
      types: [
        actions.ACCOUNT_GET_PAYMENTS_METHODS_REQUEST,
        actions.ACCOUNT_GET_PAYMENTS_METHODS_SUCCESS,
        actions.ACCOUNT_GET_PAYMENTS_METHODS_FAILED,
      ],
    },
  });

export const acCustomerPaymentsMethodDelete = (methodId: string) => (dispatch) =>
  dispatch({
    [CALL_API]: {
      endpoint: getCustomerPaymentMethodItemUrl(methodId),
      method: RequestMethods.DELETE_METHOD,
      types: [
        actions.ACCOUNT_DELETE_PAYMENTS_METHODS_REQUEST,
        actions.ACCOUNT_DELETE_PAYMENTS_METHODS_SUCCESS,
        actions.ACCOUNT_DELETE_PAYMENTS_METHODS_FAILED,
      ],
    },
  });

export const acCustomerDeliveryMethodsGet = () => (dispatch, getState: () => IStore) => {
  const { pageData: { countryCode, languageCode } } = getState();
  return dispatch({
    [CALL_API]: {
      endpoint: addParamsToUrl(getCustomerDeliveryMethodsUrl(), {
        country: countryCode || 'EE',
        lang: languageCode || 'et',
      }),
      method: RequestMethods.GET_METHOD,
      types: [
        actions.ACCOUNT_GET_DELIVERY_METHODS_REQUEST,
        actions.ACCOUNT_GET_DELIVERY_METHODS_SUCCESS,
        actions.ACCOUNT_GET_DELIVERY_METHODS_FAILED,
      ],
    },
  });
};
const acCustomerDeliveryMethodByIdGetAction = (id: string) => ({
  [CALL_API]: {
    endpoint: getCustomerDeliveryMethodByIdUrl(id),
    method: RequestMethods.GET_METHOD,
    types: [
      actions.ACCOUNT_GET_DELIVERY_METHOD_DETAILS_REQUEST,
      actions.ACCOUNT_GET_DELIVERY_METHOD_DETAILS_SUCCESS,
      actions.ACCOUNT_GET_DELIVERY_METHOD_DETAILS_FAILED,
    ],
  },
});

export const acCustomerDeliveryMethodByIdGet = () => (dispatch, getState: () => IStore) => {
  const {
    context: {
      query: { params },
    },
  } = getState();
  if (params && params.length > 3) {
    return dispatch(acCustomerDeliveryMethodByIdGetAction(params[3]));
  }
};

const acCustomerDeliveryMethodByIdSaveAction = (item: IDeliveryMethod, {
  country,
  lang,
}: { country: string, lang: string }) => {
  return {
    [CALL_API]: {
      body: { data: item },
      endpoint: addParamsToUrl(getCustomerDeliveryMethodByIdUrl(item.id), { country, lang }),
      method: RequestMethods.PATCH_METHOD,
      types: [
        actions.ACCOUNT_SAVE_DELIVERY_METHOD_DETAILS_REQUEST,
        actions.ACCOUNT_SAVE_DELIVERY_METHOD_DETAILS_SUCCESS,
        actions.ACCOUNT_SAVE_DELIVERY_METHOD_DETAILS_FAILED,
      ],
    },
  };
};

export const acCustomerDeliveryMethodByIdSave = (itemAttributes) => (dispatch, getState: () => IStore) => {
  const {
    account: { deliveryMethodDetails },
    pageData: { countryCode, languageCode },
  } = getState();
  if (deliveryMethodDetails) {
    return dispatch(
      acCustomerDeliveryMethodByIdSaveAction({
        ...deliveryMethodDetails,
        attributes: { ...deliveryMethodDetails.attributes, ...itemAttributes },
      }, { country: countryCode || 'EE', lang: languageCode || 'et' }),
    );
  }
};

export const acCustomerDeliveryMethodChangePage = (page: EDeliveryMethodPage): IAccountGetDeliveryMethodChangePage => ({
  payload: page,
  type: actions.ACCOUNT_DELIVERY_METHOD_CHANGE_PAGE,
});

const acCustomerShipmentMethodsGetAction = (pageParams) => {
  return {
    [CALL_API]: {
      cache: true,
      cacheOptions: ERedisCacheKey.shipmentMethodsGet,
      endpoint: addParamsToUrl(shipmentMethodsUrl(), pageParams),
      method: RequestMethods.GET_METHOD,
      types: [
        actions.ACCOUNT_GET_SHIPMENT_METHODS_REQUEST,
        actions.ACCOUNT_GET_SHIPMENT_METHODS_SUCCESS,
        actions.ACCOUNT_GET_SHIPMENT_METHODS_FAILED,
      ],
    },
  };
};

export const acCustomerShipmentMethodsGet = () => (dispatch, getState) => {
  const {
    pageData: { countryCode, languageCode },
  } = getState();
  if (languageCode && countryCode) {
    return dispatch(acCustomerShipmentMethodsGetAction({ country: countryCode, lang: languageCode }));
  } else {
    return false;
  }
};

export const acCustomerDeliveryMethodAdd = (item: IShipmentMethod): ICustomerDeliveryMethodAdd => ({
  payload: item,
  type: actions.ACCOUNT_DELIVERY_METHOD_ADD_METHOD,
});

type TShipmentLocation = (
  countryCode: string,
  languageCode: string,
  methodId: string | number,
) => ICallApiAction;
const acCustomerDeliveryMethodLocationAction: TShipmentLocation = (countryCode, languageCode, methodId) => ({
  [CALL_API]: {
    endpoint: addParamsToUrl(shipmentLocationsUrl(), {
      country: countryCode,
      lang: languageCode,
      methodId,
    }),
    method: RequestMethods.GET_METHOD,
    types: [
      actions.ACCOUNT_GET_SHIPMENT_LOCATIONS_REQUEST,
      actions.ACCOUNT_GET_SHIPMENT_LOCATIONS_SUCCESS,
      actions.ACCOUNT_GET_SHIPMENT_LOCATIONS_FAILED,
    ],
  },
});

type acCustomerDeliveryMethodLocationType = (
  externalShipmentMethodId?: number | string,
) => (dispatch, getState: () => IStore) => any;
export const acCustomerDeliveryMethodLocation: acCustomerDeliveryMethodLocationType = (externalShipmentMethodId) => (
  dispatch,
  getState,
) => {
  const {
    account: {
      deliveryMethodToAdd: { methodId },
    },
    pageData: { countryCode, languageCode },
  } = getState();
  const shipmentMethodId = externalShipmentMethodId ? externalShipmentMethodId : methodId;
  if (countryCode && languageCode && shipmentMethodId) {
    return dispatch(acCustomerDeliveryMethodLocationAction(countryCode, languageCode, shipmentMethodId));
  }
  return null;
};

type TCustomerDeliveryMethodCreateAction = (attributes: Partial<IDeliveryMethodAttributes>, location: { country: string, lang: string }) => any;
const acCustomerDeliveryMethodCreateAction: TCustomerDeliveryMethodCreateAction = (attributes, { country, lang }) => ({
  [CALL_API]: {
    body: {
      data: {
        attributes,
        type: 'delivery-method',
      },
    },
    endpoint: addParamsToUrl(getCustomerDeliveryMethodsUrl(), { country, lang }),
    method: RequestMethods.POST_METHOD,
    types: [
      actions.ACCOUNT_ADD_DELIVERY_METHOD_REQUEST,
      actions.ACCOUNT_ADD_DELIVERY_METHOD_SUCCESS,
      actions.ACCOUNT_ADD_DELIVERY_METHOD_FAILED,
    ],
  },
});

export const acCustomerDeliveryMethodCreate = (
  attributes: Partial<IDeliveryMethodAttributes>,
): TThunkResult<Promise<IApiResponse<any> | null>> => (dispatch, getState) => {
  const {
    account: {
      deliveryMethodToAdd: { methodId },
    },
    pageData: { countryCode, languageCode },
  } = getState();
  if (methodId) {
    attributes[EDeliveryMethodAttributesFields.extServiceCode] = methodId.toString();
    return dispatch(acCustomerDeliveryMethodCreateAction(attributes, {
      country: countryCode || 'EE',
      lang: languageCode || 'et',
    }));
  }
  return null;
};

export const acCustomerDeliveryMethodDelete = (id: string) => (dispatch) =>
  dispatch({
    [CALL_API]: {
      endpoint: getCustomerDeliveryMethodByIdUrl(id),
      method: RequestMethods.DELETE_METHOD,
      types: [
        actions.ACCOUNT_DELETE_DELIVERY_METHOD_REQUEST,
        actions.ACCOUNT_DELETE_DELIVERY_METHOD_SUCCESS,
        actions.ACCOUNT_DELETED_DELIVERY_METHOD_FAILED,
      ],
    },
  });

export const initAccountPages = (pageType) => async (dispatch) => {
  switch (pageType) {
    case PageTypes.account_delivery:
      return await dispatch(acCustomerDeliveryMethodsGet());
    default:
      return false;
  }
};

export const acCustomerDeliveryMethodFormUnmount = (): ICustomerDeliveryFormUnmount => ({
  type: actions.ACCOUNT_DELIVERY_METHOD_FORM_UNMOUNT,
});

export const acCustomerOrdersGetAction = ({ country, customerAuth, lang, page, size }) => (dispatch) =>
  dispatch({
    [CALL_API]: {
      endpoint: addParamsToUrl(getCustomerOrdersMethodsUrl(), {
        country,
        customerAuth,
        lang,
        page,
        size,
      }),
      method: RequestMethods.GET_METHOD,
      sessionId: {
        status: ESessionStatuses.createSessionBefore,
      },
      types:
        page === 1
          ? [actions.ACCOUNT_GET_ORDERS_REQUEST, actions.ACCOUNT_GET_ORDERS_SUCCESS, actions.ACCOUNT_GET_ORDERS_FAILED]
          : [
            actions.ACCOUNT_GET_ORDERS_NEXT_REQUEST,
            actions.ACCOUNT_GET_ORDERS_SUCCESS,
            actions.ACCOUNT_GET_ORDERS_FAILED,
          ],
    },
  });

export const acCustomerOrdersGet = (page = 1, pageSize = itemsPerPageOrdersList): any => (
  dispatch,
  getState: () => IStore,
) => {
  const {
    auth: { token },
    pageData: { countryCode, languageCode },
  } = getState();
  if (countryCode && languageCode && token?.access_token) {
    return dispatch(
      acCustomerOrdersGetAction({
        country: countryCode,
        customerAuth: token.access_token,
        lang: languageCode,
        page,
        size: pageSize,
      }),
    );
  }
  return null;
};


export const acCustomerOrdersRetailGetAction = ({ country, customerAuth, lang, page, size }) => (dispatch) =>
  dispatch({
    [CALL_API]: {
      endpoint: addParamsToUrl(getCustomerOrdersRetailMethodsUrl(), {
        country,
        customerAuth,
        lang,
        page,
        size,
      }),
      method: RequestMethods.GET_METHOD,
      sessionId: {
        status: ESessionStatuses.createSessionBefore,
      },
      types:
        page === 1
          ? [actions.ACCOUNT_GET_ORDERS_RETAIL_REQUEST, actions.ACCOUNT_GET_ORDERS_RETAIL_SUCCESS, actions.ACCOUNT_GET_ORDERS_RETAIL_FAILED]
          : [
            actions.ACCOUNT_GET_ORDERS_RETAIL_NEXT_REQUEST,
            actions.ACCOUNT_GET_ORDERS_RETAIL_SUCCESS,
            actions.ACCOUNT_GET_ORDERS_RETAIL_FAILED,
          ],
    },
  });

export const acCustomerOrdersRetailGet = (page = 1, pageSize = itemsPerPageOrdersList): any => (
  dispatch,
  getState: () => IStore,
) => {
  const {
    auth: { token },
    pageData: { countryCode, languageCode },
  } = getState();
  if (countryCode && languageCode  && token?.access_token) {
    return dispatch(
      acCustomerOrdersRetailGetAction({
        country: countryCode,
        customerAuth: token.access_token,
        lang: languageCode,
        page,
        size: pageSize,
      }),
    );
  }
  return null;
};

export const asCustomerOrderByTransaction = ({
  country,
  lang,
  transactionId,
}) => (dispatch: any) => dispatch({
  [CALL_API]: {
    endpoint: addParamsToUrl(getCustomerOrderByTransaction(transactionId), {
      country,
      lang,
    }),
    method: RequestMethods.GET_METHOD,
    sessionId: {
      status: ESessionStatuses.createSessionBefore,
    },
    types: [
      actions.ACCOUNT_GET_ORDER_BY_TRANSACTION_REQUEST,
      actions.ACCOUNT_GET_ORDER_BY_TRANSACTION_SUCCESS,
      actions.ACCOUNT_GET_ORDER_BY_TRANSACTION_FAILED,
    ],
  },
});

export const asCustomerOrderByTransactionGet = (transactionId: string) => (
  dispatch,
  getState: () => IStore,
) => {
  const {
    pageData: { countryCode, languageCode },
  } = getState();
  if (transactionId && countryCode && languageCode) {
    return dispatch(
      asCustomerOrderByTransaction({
        country: countryCode,
        lang: languageCode,
        transactionId,
      }),
    );
  }

  return Promise.resolve(true);
};

export const acCustomerOrderRetailDetailsGetAction = ({
  country,
  customerAuth,
  lang,
  orderId,
}) => (dispatch) =>
  dispatch({
    [CALL_API]: {
      endpoint: addParamsToUrl(getCustomerOrdersRetailDetailsMethodsUrl(orderId), {
        country,
        customerAuth,
        lang,
      }),
      method: RequestMethods.GET_METHOD,
      sessionId: {
        status: ESessionStatuses.createSessionBefore
      },
      types: [
        actions.ACCOUNT_GET_ORDER_RETAIL_DETAILS_REQUEST,
        actions.ACCOUNT_GET_ORDER_RETAIL_DETAILS_SUCCESS,
        actions.ACCOUNT_GET_ORDER_RETAIL_DETAILS_FAILED,
      ],
    },
  });

export const acCustomerOrderRetailDetailsGet = (orderId: string) => (
  dispatch,
  getState: () => IStore,
) => {
  const {
    auth: { token },
    pageData: { countryCode, languageCode },
  } = getState();
  if (countryCode && languageCode && token?.access_token) {
    return dispatch(
      acCustomerOrderRetailDetailsGetAction({
        country: countryCode,
        customerAuth: token.access_token,
        lang: languageCode,
        orderId
      }),
    );
  }
  return null;
};

export const acCustomerOrderDetailsGetAction = ({ country, customerAuth, lang, orderId }) => (dispatch) =>
  dispatch({
    [CALL_API]: {
      endpoint: addParamsToUrl(getCustomerOrdersMethodDetailsUrl(orderId), {
        country,
        customerAuth,
        lang,
      }),
      method: RequestMethods.GET_METHOD,
      sessionId: {
        status: ESessionStatuses.createSessionBefore
      },
      types: [
        actions.ACCOUNT_GET_ORDER_DETAILS_REQUEST,
        actions.ACCOUNT_GET_ORDER_DETAILS_SUCCESS,
        actions.ACCOUNT_GET_ORDER_DETAILS_FAILED,
      ],
    },
  });

export const acCustomerOrderDetailsGet = (orderId) => (
  dispatch,
  getState: () => IStore,
) => {
  const {
    auth: { token },
    pageData: { countryCode, languageCode },
  } = getState();
  if (countryCode && languageCode && token?.access_token) {
    return dispatch(
      acCustomerOrderDetailsGetAction({
        country: countryCode,
        customerAuth: token.access_token,
        lang: languageCode,
        orderId,
      }),
    );
  }
  return null;
};

type onGetOrderDetailsProductsType = () => (dispatch: Dispatch, getState: () => IStore) => any;
export const onGetOrderDetailsProducts: onGetOrderDetailsProductsType = () => (dispatch, getState) => {
  const {
    account: { orderDetails, orderByTransaction },
    pageData: { countryCode, languageCode },
  } = getState();
  const order = orderDetails || orderByTransaction;
  const productId = order?.cartItems?.map((cartItem) => cartItem.productId);
  if (productId && productId?.length > 0) {
    const set: Set<number> = new Set(productId);
    // @ts-ignore
    const uniqProductsId = [...set];
    return dispatch(
      onGetOrderProductsDetailsAction({
        country: countryCode,
        lang: languageCode,
        productId: uniqProductsId,
      }),
    );
  }
};

export interface ILinkToFacebookParams {
  token: string;
  userId: string;
}

export const acCustomerLinkSocialAccount = (attributes: ISignUpParams) => (dispatch) =>
  dispatch({
    [CALL_API]: {
      body: {
        data: {
          attributes: {
            ...attributes
          },
          type: 'customer-social',
        },
      },
      endpoint: getCustomerSocialsUrl(),
      method: RequestMethods.POST_METHOD,
      types: [
        actions.ACCOUNT_LINK_SOCIALS_REQUEST,
        actions.ACCOUNT_LINK_SOCIALS_SUCCESS,
        actions.ACCOUNT_LINK_SOCIALS_FAILED,
      ],
    },
  });

export const acCustomerUnLinkSocialAccount = (socialType: string) => (dispatch) =>
  dispatch({
    [CALL_API]: {
      endpoint: addParamsToUrl(getCustomerSocialsUrl(), { socialType }),
      method: RequestMethods.DELETE_METHOD,
      types: [
        actions.ACCOUNT_UNLINK_SOCIALS_REQUEST,
        actions.ACCOUNT_UNLINK_SOCIALS_SUCCESS,
        actions.ACCOUNT_UNLINK_SOCIALS_FAILED,
      ],
    },
  });

export const acCustomerLinkFacebookAccountLong = (token: string) => (dispatch) =>
  dispatch({
    [CALL_API]: {
      body: { token },
      endpoint: getCustomerSocialsLongUrl(),
      method: RequestMethods.POST_METHOD,
      types: [
        actions.ACCOUNT_LINK_SOCIALS_LONGTERM_REQUEST,
        actions.ACCOUNT_LINK_SOCIALS_LONGTERM_SUCCESS,
        actions.ACCOUNT_LINK_SOCIALS_LONGTERM_FAILED,
      ],
    },
  });

export const acFacebookMe = (token: string) => (dispatch) => {
  const params = {
    access_token: token,
    fields: 'id',
    format: 'json',
    method: 'get',
    pretty: 0,
    suppress_http_code: 1,
    transport: 'cors',
  };

  return dispatch({
    [CALL_API]: {
      body: { token },
      endpoint: addParamsToUrl('https://graph.facebook.com/v13.0/me', params),
      method: RequestMethods.GET_METHOD,
      types: [
        actions.ACCOUNT_LINK_SOCIALS_LONGTERM_REQUEST,
        actions.ACCOUNT_LINK_SOCIALS_LONGTERM_SUCCESS + '1',
        actions.ACCOUNT_LINK_SOCIALS_LONGTERM_FAILED,
      ],
    },
  });
};
export const acCustomerChangePassword = (params: { oldPassword: string; newPassword: string }) => (dispatch) =>
  dispatch({
    [CALL_API]: {
      body: {
        data: {
          attributes: {
            'new-password': params.newPassword,
            'old-password': params.oldPassword,
          },
          type: 'customer-change-password',
        },
      },
      endpoint: customerChangePasswordUrl(),
      method: RequestMethods.POST_METHOD,
      types: [
        actions.ACCOUNT_CHANGE_PASSWORD_REQUEST,
        actions.ACCOUNT_CHANGE_PASSWORD_SUCCESS,
        actions.ACCOUNT_CHANGE_PASSWORD_FAILED,
      ],
    },
  });

export const acConfirmGetStatus = (token: string) => (dispatch) =>
  dispatch({
    [CALL_API]: {
      endpoint: customerEmailFinalizesUrl(token),
      method: RequestMethods.GET_METHOD,
      types: [actions.ACCOUNT_GET_EMAIL_TOKEN_REQUEST, actions.ACCOUNT_GET_EMAIL_TOKEN_SUCCESS, actions.ACCOUNT_GET_EMAIL_TOKEN_FAILED],

    },
  });

export const acConfirmNewRequest = (params: { country: string; email: string; lang: string }) => (dispatch) =>
  dispatch({
    [CALL_API]: {
      body: {
        data: {
          attributes: {
            email: params.email,
          },
          type: 'customer-remind-finalize',
        },
      },
      endpoint: customerRemindFinalizesUrl(params.country, params.lang),
      method: RequestMethods.POST_METHOD,
      types: [
        actions.ACCOUNT_NEW_EMAIL_TOKEN_REQUEST,
        actions.ACCOUNT_NEW_EMAIL_TOKEN_SUCCESS,
        actions.ACCOUNT_NEW_EMAIL_TOKEN_FAILED,
      ],
    },
  });

export const acConfirmFinalize = (token: string | null, form: Record<AccountAttributesFields, any> | null) => (dispatch) =>
  dispatch({
    [CALL_API]: {
      body: {
        data: {
          attributes: form ? {
            [AccountAttributesFields.dateOfBirth]: form[AccountAttributesFields.dateOfBirth],
            [AccountAttributesFields.firstName]: form[AccountAttributesFields.firstName],
            [AccountAttributesFields.lastName]: form[AccountAttributesFields.lastName],
            [AccountAttributesFields.phone]: form[AccountAttributesFields.phone],
            [AccountAttributesFields.gender]: form[AccountAttributesFields.gender],
            [AccountAttributesFields.newsletterRequested]: form[AccountAttributesFields.newsletterRequested],
          } : {},
          type: 'customer-email-finalize',
        },
      },
      endpoint: customerEmailFinalizesUrl(token),
      method: RequestMethods.PATCH_METHOD,
      types: [
        actions.ACCOUNT_FINALIZE_EMAIL_TOKEN_REQUEST,
        actions.ACCOUNT_FINALIZE_EMAIL_TOKEN_SUCCESS,
        actions.ACCOUNT_FINALIZE_EMAIL_TOKEN_FAILED,
      ],
    },
  });

export const initAccountConfirmPages = () => async (dispatch, getState: () => IStore) => {
  const { context } = getState();
  const token = context?.query?.params ? context?.query?.params[3] : null;
  if (token) {
    return await dispatch(acConfirmGetStatus(token));
  }
  return null;
};

export const acNotificationSubscriptionsSave = (data: ISubscriber[]) => (dispatch) =>
  dispatch({
    [CALL_API]: {
      body: {
        data: {
          attributes: {
            subscribers: data,
          },
          type: 'customer-profile-notifications',
        },
      },
      endpoint: customerNotificationSubscriptionsUrl(),
      method: RequestMethods.PATCH_METHOD,
      types: [
        actions.ACCOUNT_NOTIFICATIONS_SETTINGS_SAVE_REQUEST,
        actions.ACCOUNT_NOTIFICATIONS_SETTINGS_SAVE_SUCCESS,
        actions.ACCOUNT_NOTIFICATIONS_SETTINGS_SAVE_FAILED,
      ],
    },
  });

export const acOrderTracking = (orderId: string) => (dispatch, getState: () => IStore) => {
  const {
    pageData: { countryCode, languageCode },
  } = getState();
  return dispatch({
    [CALL_API]: {
      endpoint: addParamsToUrl(orderTrackingUrl(orderId), { country: countryCode, lang: languageCode }),
      method: RequestMethods.GET_METHOD,
      sessionId: {
        status: ESessionStatuses.createSessionBefore,
      },
      types: [actions.ACCOUNT_GET_ORDER_TRACKING_REQUEST, actions.ACCOUNT_GET_ORDER_TRACKING_SUCCESS, actions.ACCOUNT_GET_ORDER_TRACKING_FAILED],

    },
  });
};

export const acCustomerOrderDetailsInit = (orderId) => (dispatch) => {
  return dispatch(acCustomerOrderDetailsGet(orderId)).then(() => {
    dispatch(onGetOrderDetailsProducts());
  });
};

export const acCustomerDelete = () => (dispatch) =>
  dispatch({
    [CALL_API]: {
      endpoint: customerDeleteUrl(),
      method: RequestMethods.DELETE_METHOD,
      types: [
        actions.ACCOUNT_DELETE_REQUEST,
        actions.ACCOUNT_DELETE_SUCCESS,
        actions.ACCOUNT_DELETE_FAILED,
      ],
    },
  }).then((response) => {
    if (response.result && response.payload?.data?.attributes?.result === 'ok') {
      dispatch(acAccountLogout());
    }
  });


export const acCustomerEmailChangeConfirm = (token) => (dispatch) =>
  dispatch({
    [CALL_API]: {
      endpoint: customerChangeEmailUrl(token),
      method: RequestMethods.GET_METHOD,
      types: [
        actions.ACCOUNT_EMAIL_CHANGE_CONFIRM_REQUEST,
        actions.ACCOUNT_EMAIL_CHANGE_CONFIRM_SUCCESS,
        actions.ACCOUNT_EMAIL_CHANGE_CONFIRM_FAILED,
      ],
    },
  }).then((response) => {
    /*
    if (response.result && response.payload?.data?.attributes?.result === 'ok') {
      console.log('YES');
    }

    }*/
  });

export const acCustomerCloseOrderDetails = (): IAccountCloseOrderDetails => ({
  type: actions.ACCOUNT_CLOSE_ORDER_DETAILS,
});
export const acSaveOneSignalUserId = (userId: number) => (dispatch, getState: () => IStore) => {
  const {
    auth: { token },
  } = getState();
  if (token && userId) {
    return dispatch({
      [CALL_API]: {
        body: {},
        endpoint: customerOneSignalIdUrl(userId),
        method: RequestMethods.PUT_METHOD,
        types: [actions.ACCOUNT_SET_OSESIGNAL_REQUEST, actions.ACCOUNT_SET_OSESIGNAL_SUCCESS, actions.ACCOUNT_SET_OSESIGNAL_FAILED],
      },
    });
  }
};
