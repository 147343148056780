import { ECustomerSmartIdFinalizes } from '@interfaces/account/reducer';
import { IAuthReducer } from '@interfaces/auth/reducer';

export const initialState: IAuthReducer = {
  error: {
    forgot: [],
    login: null,
    resendCode: [],
    resetPassword: [],
    sessionId: null,
    signUp: [],
    signUpFacebook: [],
    signUpGoogle: [],
    signUpSmartId: [],
    smartIdFinalize: [],
  },
  gaId: null,
  isAuthorized: false,
  loading: {
    forgot: false,
    login: false,
    resendCode: false,
    resetPassword: false,
    signUp: false,
    signUpFacebook: false,
    signUpGoogle: false,
    signUpSmartId: false,
    smartIdFinalize: false
  },
  loginData: {},
  result: {
    attachSmartId: false,
    resendCode: false,
  },
  sessionId: null,
  signUp: {
    confirmRequired: false,
  },
  signUpPrefill: {
    show: false,
  },
  smartId: {
    hash: null,
    pin: null,
    step: 0,
  },
  smartIdFinalize: ECustomerSmartIdFinalizes.undefined,
  socialApps: [],
  token: null,
  user: null,
};
