import { ISeoAttributes } from '@interfaces/category';
import { IProductInList } from '@interfaces/product';

export interface IProductsListDataFilter {
  filterAges: IFilterAge[];
  filterBrands: IProductListFilterBrand[];
  filterCategories: IProductListFilterCategories[];
  filterColors: IProductListFilterColor[];
  filterFits?: IFilterFits[] | null;
  filterMaxPrice: string;
  filterMinPrice: string;
  filterSizes: string[];
  filterWaists?: IFilterWaist[] | null;
  sexes: ISexItem[];
}

export interface IProductsListData {
  count: number;
  errors: any[];
  page: number;
  size: number;
  products: IProductInList[];
}

export type IProductsList = IProductsListData & IProductsListDataFilter;

export interface IProductListFilterCategories {
  active?: boolean;
  category: string;
  categoryId: string;
  categoryLocal: string;
  popular: boolean;
  important: boolean;
  images: any[];
  mobileImages: any[];
  seoAttributes: Partial<ISeoAttributes>[];
}

export interface IProductListFilterBrand extends IProductBrand {
  active: boolean;
}

export interface IProductListFilterColor {
  active: boolean;
  colorGroup: string;
  colorGroupId: number;
  colorGroupLocal: string;
  code: string;
  position: number;
}

export enum EProductsListFilter {
  ageId  = 'ageId',
  brandId = 'brandId',
  campaignId = 'campaignId',
  categoryId = 'categoryId',
  categoryIdP = 'categoryIdP',
  colorGroupId = 'colorGroupId',
  textSearch = 'textSearch',
  fits = 'fitId',
  fresh = 'fresh',
  outlet = 'outlet',
  pageSize = 'pageSize',
  price = 'price',
  priceFrom = 'priceFrom',
  priceTo = 'priceTo',
  sale = 'sale',
  sexId = 'sexId',
  sexIdMob = 'sexIdMob',
  sizes = 'sizes',
  trendingProducts = 'trendingProducts',
  trendingProductsPeriod = 'trendingProductsPeriod',
  utmSource = 'utm_source',
  utmMedium = 'utm_medium',
  utmCampaign = 'utm_campaign',
  waists = 'waistId',
  gclid = 'gclid',
}

export enum EProductsListSort {
  sort = 'sort',
  sortOrder = 'sortOrder',
}

export interface ISexItem {
  sexId: number;
  sex: string;
  sexLocal: string;
  slug?: string;
  seoAttributes?: ISeoAttributes[];
  selected?: boolean;
}

export interface IProductListSeoAttributes {
  seoStory?: string | null;
  seoH1Tag: string | null;
  seoTitle: string | null;
  seoDescription: string | null;
}

export interface IFilterFits {
  active?: boolean;
  fitId: number;
  fit: string;
  fitLocal?: string;
}

export interface IFilterWaist {
  active?: boolean;
  waistId: number;
  waist: string;
  waistLocal?: string;
}

export interface IFilterAge {
  active?: boolean;
  id: number;
  from: string;
  to: string;
}

export interface IProductBrand {
  brandId: number;
  brand: string;
  seoAttributes?: ISeoAttributes[];
}
