import { AnyAction, applyMiddleware, createStore, Middleware, Store } from 'redux';
import { composeWithDevTools, EnhancerOptions } from 'redux-devtools-extension';
import dynamicMiddlewares from 'redux-dynamic-middlewares';
import thunkMiddleware from 'redux-thunk';

import { debug, nodeEnv } from '@constants/config';
import { IThunkDispatch } from '@interfaces/index';
import { IStore } from '@interfaces/store';

import { combinedReducer, initState } from '../reducers';
import analyticEventService from '../services/AnalyticEvent';
import facebookService from '../services/Facebook';
import sessionService from '../services/SessionId';
import apiMiddleware from '../services/SSRService';
import { createWrapper, MakeStore } from './wrapper';

export type IDDReduxStore = Store<IStore>;

export type TThunkMiddleware<S = {}, A = AnyAction, E = undefined> = Middleware<IThunkDispatch<S, E, A>,
  S,
  IThunkDispatch<S, E, A>>;


const makeStore: MakeStore<IStore> = ( context, preloadedState = initState,) => {

  const middleware = [
    thunkMiddleware,
    facebookService(context),
    sessionService(context),
    analyticEventService(context),
    apiMiddleware(context),
    dynamicMiddlewares
  ];

  const options: EnhancerOptions = { trace: true, traceLimit: 35 };
  const composeEnhancers = composeWithDevTools(options);
  const shouldAddDevTools = nodeEnv === 'development' || debug;
  const applyAll = !shouldAddDevTools ? applyMiddleware(...middleware) : composeEnhancers(applyMiddleware(...middleware));

  return createStore(combinedReducer, preloadedState, applyAll);
};


export const wrapper = createWrapper(makeStore, {debug: false});
