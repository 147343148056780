import { EGender, EGenderType } from '@constants/gender';

export const mapGenderIdToType = (sexId: number | EGender): EGenderType | null => {
  switch (sexId) {
    case EGender.man:
      return EGenderType.man;
    case EGender.women:
      return EGenderType.women;
    case EGender.boy:
      return EGenderType.kids;
    case EGender.girl:
      return EGenderType.kids;
    default:
      return null;
  }
};

export const mapTypeToGenderId = (type: EGenderType | string | null | undefined, mobileMenuGender: number | null): number | null => {
  switch (type) {
    case EGenderType.man:
      return EGender.man;
    case EGenderType.women:
      return EGender.women;
    case EGenderType.kids:
      return mobileMenuGender;
    default:
      return null;
  }
};
export const mapTypeToGenderIds = (type: string | null, mobileMenuGender: number | number[] | null): number | number[] | null => {
  switch (type) {
    case EGenderType.man:
      return EGender.man;
    case EGenderType.women:
      return EGender.women;
    case EGenderType.kids:
      return mobileMenuGender;
    default:
      return null;
  }
};

export const mapGenderTypeToBrandType = (genderType: EGenderType | string): string | null => {
  switch (genderType) {
    case EGenderType.man:
      return 'brandsMen';
    case EGenderType.women:
      return 'brandsWomen';
    case EGenderType.kids:
      return 'brandsKids';
    default:
      return null;
  }
};

export const mapGenderTypeToBottomType = (genderType: EGenderType | string): string | null => {
  switch (genderType) {
    case EGenderType.man:
      return 'bottomMen';
    case EGenderType.women:
      return 'bottomWomen';
    case EGenderType.kids:
      return 'bottomKids';
    default:
      return null;
  }
};

export const mapGenderIdToOptionIndex = (sexId: number): number => {
  switch (sexId) {
    case EGender.man:
      return 1;
    case EGender.women:
      return 2;
    case EGender.boy:
      return 4;
    case EGender.girl:
      return 5;
    default:
      return 2;
  }
};

export const getSexIdTranslationKey = (sexId: number) => {
  switch (sexId) {
    case EGender.man:
      return 'common.optionForMen';
    case EGender.women:
      return 'common.optionForWomen';
    case EGender.boy:
      return 'common.optionForBoys';
    case EGender.girl:
      return 'common.optionForGirls';
    default:
      return null;
  }
};

export const getGenderByNationalId = (nationalId) => {
  if (nationalId) {
    const firstSymbol = String(nationalId).slice(0, 1);
    switch (firstSymbol) {
      case '3':
      case '5':
        return 1; // male
      case '4':
      case '6':
        return 2; // female
      default:
        return null;
    }
  }
  return null;
};
