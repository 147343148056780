import { getBrandSeoBySexId } from '@components/pages/productsList/Filter/Group/utils';
import { EGender } from '@constants/gender';
import { PageTypes } from '@constants/pageTypes';
import { ESort, ESortType } from '@constants/sort';
import { ISeoAttributes, IWrappedCategory, IWrappedCategoryMapped } from '@interfaces/category';
import { IFilter, IPageReference } from '@interfaces/pageData/reducer';
import {
  EProductsListFilter, EProductsListSort, IProductBrand, IProductListFilterBrand, IProductListSeoAttributes,
} from '@interfaces/productsList';
import { IProductListDefaultSeo, IProductListFilter } from '@interfaces/productsList/reducer';
import { IFilterData } from '@reducers/productsList/useState';
import { getDefaultSeoInfo } from '@utils/common';

import { findCategoryBySlug } from '../../../services/SSRService/utils';

export const mapFilterToUrl = (filter: IProductListFilter, filterData?: IFilterData, isProductListRequestUrl = true) => {
  // Only before converting data to url string
  const filtersValues: Partial<IProductListFilter> = {};

  // Filter
  if (isProductListRequestUrl && filter[EProductsListFilter.sexId]) {
    filtersValues[EProductsListFilter.sexId] = filter[EProductsListFilter.sexId];
  }
  if (isProductListRequestUrl || filter[EProductsListFilter.brandId].length > 1) {
    filtersValues[EProductsListFilter.brandId] = filter[EProductsListFilter.brandId];
  }
  const categoryIds = [
    ...filter[EProductsListFilter.categoryId],
    ...filter[EProductsListFilter.categoryIdP],
  ];
  if (isProductListRequestUrl || categoryIds.length > 1) {
    filtersValues[EProductsListFilter.categoryId] = categoryIds;
  }

  if (typeof filter[EProductsListFilter.sale] === 'boolean') {
    filtersValues[EProductsListFilter.sale] = filter[EProductsListFilter.sale];
  }
  if (typeof filter[EProductsListFilter.outlet] === 'boolean') {
    filtersValues[EProductsListFilter.outlet] = filter[EProductsListFilter.outlet];
  }
  if (typeof filter[EProductsListFilter.fresh] === 'boolean') {
    filtersValues[EProductsListFilter.fresh] = filter[EProductsListFilter.fresh];
  }
  if (filter[EProductsListFilter.campaignId]) {
    filtersValues[EProductsListFilter.campaignId] = filter[EProductsListFilter.campaignId];
  }
  if (filter[EProductsListFilter.sizes]) {
    filtersValues[EProductsListFilter.sizes] = filter[EProductsListFilter.sizes];
  }
  if (filter[EProductsListFilter.textSearch]) {
    filtersValues[EProductsListFilter.textSearch] = encodeURI(decodeURI(filter[EProductsListFilter.textSearch]));
  }
  if (filter[EProductsListFilter.colorGroupId]) {
    filtersValues[EProductsListFilter.colorGroupId] = filter[EProductsListFilter.colorGroupId];
  }
  if (
    filter[EProductsListFilter.priceFrom] &&
    filterData && filterData[EProductsListFilter.priceFrom] !== `${filter[EProductsListFilter.priceFrom]}`
  ) {
    filtersValues[EProductsListFilter.priceFrom] = filter[EProductsListFilter.priceFrom];
  }
  if (
    filter[EProductsListFilter.priceTo] &&
    filterData && filterData[EProductsListFilter.priceTo] !== `${filter[EProductsListFilter.priceTo]}`
  ) {
    filtersValues[EProductsListFilter.priceTo] = filter[EProductsListFilter.priceTo];
  }

  if (filter[EProductsListFilter.utmSource]) {
    filtersValues[EProductsListFilter.utmSource] = filter[EProductsListFilter.utmSource];
  }

  if (filter[EProductsListFilter.utmMedium]) {
    filtersValues[EProductsListFilter.utmMedium] = filter[EProductsListFilter.utmMedium];
  }

  if (filter[EProductsListFilter.utmCampaign]) {
    filtersValues[EProductsListFilter.utmCampaign] = filter[EProductsListFilter.utmCampaign];
  }

  if (filter[EProductsListFilter.gclid]) {
    filtersValues[EProductsListFilter.gclid] = filter[EProductsListFilter.gclid];
  }
  if (filter[EProductsListFilter.trendingProducts]) {
    filtersValues[EProductsListFilter.trendingProducts] = filter[EProductsListFilter.trendingProducts];
  }
  if (filter[EProductsListFilter.trendingProductsPeriod]) {
    filtersValues[EProductsListFilter.trendingProductsPeriod] = filter[EProductsListFilter.trendingProductsPeriod];
  }
  if (filter[EProductsListFilter.fits]) {
    filtersValues[EProductsListFilter.fits] = filter[EProductsListFilter.fits];
  }
  if (filter[EProductsListFilter.waists]) {
    filtersValues[EProductsListFilter.waists] = filter[EProductsListFilter.waists];
  }
  if (filter[EProductsListFilter.ageId] && filterData) {
    filtersValues[EProductsListFilter.ageId] = filter[EProductsListFilter.ageId];
  }

  return filtersValues;
};

export const mapSortToUrl = (sortDirection: ESortType, sortDirectionDefault: ESortType) => {
  // Only before converting data to url string
  const sortValues: Partial<IProductListFilter> = {};

  if (sortDirection === sortDirectionDefault) {
    return sortValues;
  }

  if (sortDirection === ESortType.newFirst) {
    sortValues[EProductsListSort.sort] = ESort.CREATED;
    sortValues[EProductsListSort.sortOrder] = 'desc';
  } else if (sortDirection === ESortType.sale) {
    sortValues[EProductsListSort.sort] = ESort.SALE;
    sortValues[EProductsListSort.sortOrder] = 'desc';
  } else {
    sortValues[EProductsListSort.sort] = ESort.PRICE;
    sortValues[EProductsListSort.sortOrder] = sortDirection;
  }

  return sortValues;
};

export const mapSortToApiRequest = (sortDirection: ESortType): Record<EProductsListSort.sort, string> => {
  switch (sortDirection) {
    case ESortType.newFirst:
      return { [EProductsListSort.sort]: 'created,desc' };
    case ESortType.price_asc:
      return { [EProductsListSort.sort]: 'price,asc' };
    case ESortType.price_desc:
      return { [EProductsListSort.sort]: 'price,desc' };
    case ESortType.sale:
      return { [EProductsListSort.sort]: 'sale,desc' };
  }
};

export const getSeoAttributesBySexId = (
  seoAttributes: ISeoAttributes[],
  sexId: number | string,
): ISeoAttributes | null => {
  if (seoAttributes.length > 1) {
    return seoAttributes.find((seoGroup) => seoGroup.sexId && Number(seoGroup.sexId) === Number(sexId)) || null;
  } else {
    return seoAttributes[0] || null;
  }
};

export const getSeoInfoByFilterValue = (
  productListFilterBrands: IProductListFilterBrand[] | IProductBrand[],
  productsListDefaultSeoInfo: IProductListDefaultSeo,
  newFilterValue: IProductListFilter,
  categories: IWrappedCategory<ISeoAttributes | null>[],
  pageSexId?: null | number,
): IProductListSeoAttributes => {
  const {
    seoH1Tag: defaultSeoH1Tag,
    seoStory: defaultSeoStory,
    seoTitle: defaultSeoTitle,
    seoDescription: defaultSeoDescription,
  } = getDefaultSeoInfo(productsListDefaultSeoInfo);

  const seoResult: IProductListSeoAttributes = {
    seoDescription: defaultSeoDescription,
    seoH1Tag: defaultSeoH1Tag,
    seoStory: defaultSeoStory,
    seoTitle: defaultSeoTitle,
  };
  const selectedBrands = newFilterValue[EProductsListFilter.brandId];
  const selectedCategories = [
    ...newFilterValue[EProductsListFilter.categoryId],
    ...newFilterValue[EProductsListFilter.categoryIdP],
  ];
  const mainCategoriesId = categories.map((cat) => cat.categoryId);
  if ((!!selectedBrands.length && !!selectedCategories.length) || selectedBrands.length > 1) {
    // if selected brands and categories or more then one brand
    seoResult.seoH1Tag = defaultSeoH1Tag;
    seoResult.seoStory = defaultSeoStory;
    return seoResult;
  }
  if (selectedBrands.length === 1) {
    // if select only one brand
    const activeBrandId = selectedBrands[0];
    const selectedBrand = productListFilterBrands.find(({ brandId }) => String(brandId) === String(activeBrandId));

    if (selectedBrand) {
      const brandSeoAttr = getBrandSeoBySexId(selectedBrand, pageSexId);
      seoResult.seoH1Tag = brandSeoAttr?.seoH1Tag || '';
      seoResult.seoStory = brandSeoAttr?.seoStory || '';
      seoResult.seoDescription = brandSeoAttr?.seoDescription || '';
      seoResult.seoTitle = brandSeoAttr?.seoTitle || '';

    }
    return seoResult;
  }

  if (selectedCategories.length > 1) {
    // if selected few categories
    const selectedMainCategories = selectedCategories.filter((category) => mainCategoriesId.includes(category));
    if (!!selectedMainCategories.length) {
      // if at least one selected category is main
      seoResult.seoH1Tag = defaultSeoH1Tag;
      seoResult.seoStory = defaultSeoStory;
    } else {
      // if select few subcategories
      const mainCategory = categories.find((category) => {
        const selectedSubCategoriesCount: number =
          category.children?.filter(({ categoryId }) => {
            return selectedCategories.includes(categoryId);
          }).length || 0;
        // checking the presence of all subcategories in one main
        return selectedSubCategoriesCount === selectedCategories.length;
      });
      if (mainCategory) {
        seoResult.seoH1Tag = mainCategory.seoAttributes?.seoH1Tag || '';
        seoResult.seoStory = mainCategory.seoAttributes?.seoStory || '';
        seoResult.seoTitle = mainCategory.seoAttributes?.seoTitle || '';
        seoResult.seoDescription = mainCategory.seoAttributes?.seoDescription || '';
      } else {
        // subcategories in different main
        seoResult.seoH1Tag = defaultSeoH1Tag;
        seoResult.seoStory = defaultSeoStory;
      }
    }
  } else {
    const activeCategoryId = selectedCategories[0];
    if (activeCategoryId) {
      // if selected one category
      categories.some(({ categoryId, seoAttributes, children: subCategories }) => {
        if (categoryId === activeCategoryId) {
          // selected category is main
          seoResult.seoH1Tag = seoAttributes?.seoH1Tag || '';
          seoResult.seoStory = seoAttributes?.seoStory || '';
          seoResult.seoTitle = seoAttributes?.seoTitle || '';
          seoResult.seoDescription = seoAttributes?.seoDescription || '';
          return true;
        } else {
          const isSubCategory = subCategories?.some(
            ({ categoryId: subCategoryId, seoAttributes: subCategorySeoAttributes }) => {
              if (subCategoryId === activeCategoryId) {
                // selected category is subcategory
                seoResult.seoH1Tag = subCategorySeoAttributes?.seoH1Tag || '';
                seoResult.seoStory = subCategorySeoAttributes?.seoStory || '';
                seoResult.seoTitle = subCategorySeoAttributes?.seoTitle || '';
                seoResult.seoDescription = subCategorySeoAttributes?.seoDescription || '';
                return true;
              }
            },
          );
          return isSubCategory;
        }
      });
    } else {
      // if no selected categories
      seoResult.seoH1Tag = defaultSeoH1Tag;
      seoResult.seoStory = defaultSeoStory;
    }
  }
  return seoResult;
};

const difference = (a, b): number => {
  return Math.abs(a - b);
};

const discountArray = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95];

export const calculateDiscountPercent = ({ price, priceDiscount }) => {
  const percent = Math.round(((price - priceDiscount) * 100) / price);
  if (discountArray.includes(percent)) {
    return percent;
  } else {
    const diff = discountArray.map((option) => difference(option, percent));
    const minDiff = Math.min(...diff);
    const diffIndex = diff.indexOf(minDiff);
    return discountArray[diffIndex];
  }
};

export const getSelectedColors = (filter, mappedData) => {
  return mappedData.filter(({ id }) => {
    return filter[EProductsListFilter.colorGroupId].includes(id.toString());
  });
};

export const getProductListUrlByGender = (pages: IPageReference[], gender) => {
  const page = pages.find(({ filter }) => filter?.sexId === gender);
  return page?.url;
};

type TSetPredefinedFilter = (
  pageDataFilter: IFilter,
  categories: IWrappedCategoryMapped[],
  filter: IProductListFilter,
  pageDataType: string | null,
  pageSexId?: string | number | null,
) => Partial<IProductListFilter> | null;
export const setPredefinedFilter: TSetPredefinedFilter = (pageDataFilter, categories, filter, pageDataType, pageSexId) => {
  if (pageDataFilter) {
    let categoryId: string[] = filter.categoryId;
    if (!!!categoryId.length && pageDataFilter.categoryId) {
      categoryId = pageDataFilter.categoryId === pageDataFilter.sexId ? [] : [`${pageDataFilter.categoryId}`];
      if (!!!categoryId.length) {
        const pathname = window.location.pathname;
        const pathnameParts = pathname.split('/');
        if (pathnameParts.length > 5) {
          const category = findCategoryBySlug(pathnameParts.pop() as string, categories);
          if (category) {
            categoryId = [category.categoryId];
          }
        }
      }
    }
    const params: Partial<IProductListFilter> = {};
    if (pageSexId) {
      params[EProductsListFilter.sexId] = [Number(pageSexId)];
    } else if (pageDataType === PageTypes.kids) {
      params[EProductsListFilter.sexId] = [EGender.girl, EGender.boy];
    } else if (pageDataFilter.sexId) {
      params[EProductsListFilter.sexId] = [Number(pageDataFilter.sexId)];
    }
    if (!!categoryId.length) {
      params[EProductsListFilter.categoryId] = categoryId;
    }
    if (pageDataFilter.sale && pageDataType !== PageTypes.outlet) {
      params[EProductsListFilter.sale] = pageDataFilter.sale;
    }
    if (pageDataFilter.fresh) {
      params[EProductsListFilter.fresh] = pageDataFilter.fresh;
    }
    if (pageDataType === PageTypes.fresh) {
      params[EProductsListFilter.fresh] = true;
    }
    if (pageDataFilter.pageSize) {
      params[EProductsListFilter.pageSize] = pageDataFilter.pageSize;
    }
    return params;
  }
  return null;
};

const sortIndex = (paramKey): number => {
  switch (paramKey) {
    case EProductsListFilter.sexId:
      return 100;
    case EProductsListSort.sortOrder:
      return 90;
    case EProductsListSort.sort:
      return 80;
    case EProductsListFilter.categoryId:
      return 70;
    default:
      return 10;
  }
};

export const sortParamsToUrl = (queryParams: Record<any, any>) => {
  const sorted = Object.keys(queryParams).sort((a, b) => sortIndex(b) - sortIndex(a));

  const result = {};
  // tslint:disable-next-line:forin
  for (const propKey in sorted) {
    if (queryParams.hasOwnProperty(sorted[propKey])) {
      result[sorted[propKey]] = queryParams[sorted[propKey]];
    }
  }
  return result;
};
