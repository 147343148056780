export const WISHLIST_ITEMS_GET_REQUEST = 'WISHLIST_ITEMS_GET_REQUEST';
export type WISHLIST_ITEMS_GET_REQUEST = 'WISHLIST_ITEMS_GET_REQUEST';
export const WISHLIST_ITEMS_GET_SUCCESS = 'WISHLIST_ITEMS_GET_SUCCESS';
export type WISHLIST_ITEMS_GET_SUCCESS = 'WISHLIST_ITEMS_GET_SUCCESS';
export const WISHLIST_ITEMS_GET_FAILED = 'WISHLIST_ITEMS_GET_FAILED';
export type WISHLIST_ITEMS_GET_FAILED = 'WISHLIST_ITEMS_GET_FAILED';

export const WISHLIST_ITEMS_GET_NEXT_REQUEST = 'WISHLIST_ITEMS_GET_NEXT_REQUEST';
export type WISHLIST_ITEMS_GET_NEXT_REQUEST = 'WISHLIST_ITEMS_GET_NEXT_REQUEST';
export const WISHLIST_ITEMS_GET_NEXT_SUCCESS = 'WISHLIST_ITEMS_GET_NEXT_SUCCESS';
export type WISHLIST_ITEMS_GET_NEXT_SUCCESS = 'WISHLIST_ITEMS_GET_NEXT_SUCCESS';
export const WISHLIST_ITEMS_GET_NEXT_FAILED = 'WISHLIST_ITEMS_GET_NEXT_FAILED';
export type WISHLIST_ITEMS_GET_NEXT_FAILED = 'WISHLIST_ITEMS_GET_NEXT_FAILED';

export const WISHLIST_ITEMS_ADD_REQUEST = 'WISHLIST_ITEMS_ADD_REQUEST';
export type WISHLIST_ITEMS_ADD_REQUEST = 'WISHLIST_ITEMS_ADD_REQUEST';
export const WISHLIST_ITEMS_ADD_SUCCESS = 'WISHLIST_ITEMS_ADD_SUCCESS';
export type WISHLIST_ITEMS_ADD_SUCCESS = 'WISHLIST_ITEMS_ADD_SUCCESS';
export const WISHLIST_ITEMS_ADD_FAILED = 'WISHLIST_ITEMS_ADD_FAILED';
export type WISHLIST_ITEMS_ADD_FAILED = 'WISHLIST_ITEMS_ADD_FAILED';

export const WISHLIST_ITEMS_REMOVE_REQUEST = 'WISHLIST_ITEMS_REMOVE_REQUEST';
export type WISHLIST_ITEMS_REMOVE_REQUEST = 'WISHLIST_ITEMS_REMOVE_REQUEST';
export const WISHLIST_ITEMS_REMOVE_SUCCESS = 'WISHLIST_ITEMS_REMOVE_SUCCESS';
export type WISHLIST_ITEMS_REMOVE_SUCCESS = 'WISHLIST_ITEMS_REMOVE_SUCCESS';
export const WISHLIST_ITEMS_REMOVE_FAILED = 'WISHLIST_ITEMS_REMOVE_FAILED';
export type WISHLIST_ITEMS_REMOVE_FAILED = 'WISHLIST_ITEMS_REMOVE_FAILED';

export const WISHLIST_UPDATE_ITEM_REQUEST = 'WISHLIST_UPDATE_ITEM_REQUEST';
export type WISHLIST_UPDATE_ITEM_REQUEST = 'WISHLIST_UPDATE_ITEM_REQUEST';
export const WISHLIST_UPDATE_ITEM_SUCCESS = 'WISHLIST_UPDATE_ITEM_SUCCESS';
export type WISHLIST_UPDATE_ITEM_SUCCESS = 'WISHLIST_UPDATE_ITEM_SUCCESS';
export const WISHLIST_UPDATE_ITEM_FAILED = 'WISHLIST_UPDATE_ITEM_FAILED';
export type WISHLIST_UPDATE_ITEM_FAILED = 'WISHLIST_UPDATE_ITEM_FAILED';

export const WISHLIST_UPDATE_ITEM_LOCAL = 'WISHLIST_UPDATE_ITEM_LOCAL';
export type WISHLIST_UPDATE_ITEM_LOCAL = 'WISHLIST_UPDATE_ITEM_LOCAL';

export const WISHLIST_REPLACE_ITEM_REQUEST = 'WISHLIST_REPLACE_ITEM_REQUEST';
export type WISHLIST_REPLACE_ITEM_REQUEST = 'WISHLIST_REPLACE_ITEM_REQUEST';
export const WISHLIST_REPLACE_ITEM_SUCCESS = 'WISHLIST_REPLACE_ITEM_SUCCESS';
export type WISHLIST_REPLACE_ITEM_SUCCESS = 'WISHLIST_REPLACE_ITEM_SUCCESS';
export const WISHLIST_REPLACE_ITEM_FAILED = 'WISHLIST_REPLACE_ITEM_FAILED';
export type WISHLIST_REPLACE_ITEM_FAILED = 'WISHLIST_REPLACE_ITEM_FAILED';

export const WISHLIST_SORT_CHANGE = 'WISHLIST_SORT_CHANGE';
export type WISHLIST_SORT_CHANGE = 'WISHLIST_SORT_CHANGE';

export const WISHLIST_ITEMS_COUNT_REQUEST = 'WISHLIST_ITEMS_COUNT_REQUEST';
export type WISHLIST_ITEMS_COUNT_REQUEST = 'WISHLIST_ITEMS_COUNT_REQUEST';
export const WISHLIST_ITEMS_COUNT_SUCCESS = 'WISHLIST_ITEMS_COUNT_SUCCESS';
export type WISHLIST_ITEMS_COUNT_SUCCESS = 'WISHLIST_ITEMS_COUNT_SUCCESS';
export const WISHLIST_ITEMS_COUNT_FAILED = 'WISHLIST_ITEMS_COUNT_FAILED';
export type WISHLIST_ITEMS_COUNT_FAILED = 'WISHLIST_ITEMS_COUNT_FAILED';

export const WISHLIST_ID_GET_REQUEST = 'WISHLIST_ID_GET_REQUEST';
export type WISHLIST_ID_GET_REQUEST = 'WISHLIST_ID_GET_REQUEST';
export const WISHLIST_ID_GET_SUCCESS = 'WISHLIST_ID_GET_SUCCESS';
export type WISHLIST_ID_GET_SUCCESS = 'WISHLIST_ID_GET_SUCCESS';
export const WISHLIST_ID_GET_FAILED = 'WISHLIST_ID_GET_FAILED';
export type WISHLIST_ID_GET_FAILED = 'WISHLIST_ID_GET_FAILED';
