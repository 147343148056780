import { Reducer } from 'redux';

import { IWishListItem } from '@interfaces/wishList';
import { IWishListActions } from '@interfaces/wishList/actions';
import { IWishListReducer } from '@interfaces/wishList/reducer';
import { mapListToLocal, updateItemLocal } from '@reducers/wishList/utils';
import * as actions from '../../constants/actions/wishList';
import { initialState } from './initState';

export const wishListReducer: Reducer<IWishListReducer, IWishListActions> = (state = initialState, action) => {
  switch (action.type) {
    case actions.WISHLIST_ITEMS_GET_REQUEST:
      return { ...state, list: [], loading: { ...state.loading, list: true }, loaded: false };
    case actions.WISHLIST_REPLACE_ITEM_SUCCESS:
      return {
        ...state,
        list: action.payload,
        listMapped: mapListToLocal(action.payload, action.requestParams),
        loading: { ...state.loading, list: false },
      };
    case actions.WISHLIST_ITEMS_GET_SUCCESS:
      return {
        ...state,
        list: action.payload,
        listMapped: mapListToLocal(action.payload),
        loaded: true,
        loading: { ...state.loading, list: false },
      };
    case actions.WISHLIST_ITEMS_GET_NEXT_SUCCESS:
      const newList = [...state.list, ...action.payload];
      return {
        ...state,
        list: newList,
        listMapped: mapListToLocal(newList),
        loaded: true,
        loading: { ...state.loading, list: false },
      };
    case actions.WISHLIST_ITEMS_GET_FAILED:
      return { ...state, loading: { ...state.loading, list: false } };
    case actions.WISHLIST_ITEMS_REMOVE_SUCCESS:
      const listId = action.payload.ids;
      const clearMappedList = state.listMapped.filter((listMappedItem) => listMappedItem.productId !== action.requestParams.productId);
      const clearList = state.list.filter((listMappedItem) => listId.includes(listMappedItem.colors[0].productId as number));
      return {
        ...state,
        count: action.payload.ids.length,
        list: clearList,
        listId: action.payload.ids,
        listMapped: clearMappedList,
      };
    case actions.WISHLIST_ITEMS_ADD_SUCCESS:
      return { ...state, count: action.payload?.ids?.length, listId: action.payload?.ids, loaded: true };
    case actions.WISHLIST_UPDATE_ITEM_SUCCESS:
      const changedItem = state.listMapped.find((listMappedItem) => listMappedItem.productId === action.requestParams.productId);
      const filteredListMapped = state.listMapped.filter((listMappedItem) => listMappedItem.productId !== action.requestParams.productId);
      if (changedItem) {
        changedItem.productId = action.requestParams.newProductId;
        if (changedItem.sizeName) {
          const newItem = action.payload.find((listItem) => listItem.selectedColor === action.requestParams.newProductId);
          const newProduct = newItem?.colors.find((colorItem) => colorItem.productId === action.requestParams.newProductId) || null;
          const newSize = newProduct?.sizes.find((sizeItem) => sizeItem.size === changedItem.sizeName) || null;
          if (newSize) {
            changedItem.sizeId = newSize?.sizeId || null;
          } else {
            changedItem.sizeId = null;
            changedItem.sizeName = null;
          }
        }
        return { ...state, list: action.payload, listMapped: [...filteredListMapped, changedItem] };
      }
      return { ...state, list: action.payload };
    case actions.WISHLIST_UPDATE_ITEM_LOCAL:
      const itemToUpdate = state.listMapped.find((listItem) => listItem.productId === action.payload.productId);
      if (itemToUpdate) {
        const baseItem: IWishListItem | undefined = state.list.find((listItem) => listItem.selectedColor === action.payload.productId);
        const updatedItem = updateItemLocal(itemToUpdate, action.payload, baseItem);
        const filteredList = state.listMapped.filter((listMappedItem) => listMappedItem.productId !== itemToUpdate.productId);
        return { ...state, listMapped: [...filteredList, updatedItem] };
      } else {
        return state;
      }
    case actions.WISHLIST_SORT_CHANGE:
      return {
        ...state,
        loaded: false,
        sortDirection: action.payload,
      };
    case actions.WISHLIST_ITEMS_COUNT_SUCCESS:
      return {
        ...state,
        count: action.payload.count,
      };
    case actions.WISHLIST_ID_GET_SUCCESS:
      return {
        ...state,
        count: action.payload.length,
        listId: action.payload,
      };
    default:
      return state;
  }
};
