import React from 'react';

import { Inter, Montserrat, Quattrocento_Sans, Quicksand, Raleway } from 'next/font/google';

const fontInter = Inter({
  subsets: ['latin'],
  weight: ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
});

const fontRaleway = Raleway({
  subsets: ['latin'],
  weight: ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
});

const fontQuattrocentoSans = Quattrocento_Sans({
  style: ['italic', 'normal'],
  subsets: ['latin'],
  weight: ['400', '700'],
});

const fontQuicksand = Quicksand({
  subsets: ['latin'],
  weight: ['500'],
});

const fontMontserrat = Montserrat({
  subsets: ['latin'],
  weight: ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
});


const FontsImport: React.FunctionComponent<{}> = () => {

  return (
    <style jsx global>{`
      :root {
        --sab: env(safe-area-inset-bottom);
        --raleway-font: ${fontRaleway.style.fontFamily};
        --inter-font: ${fontInter.style.fontFamily};
        --montserrat-font: ${fontMontserrat.style.fontFamily};
        --quicksand-font: ${fontQuicksand.style.fontFamily};
        --quattrocentoSans-font: ${fontQuattrocentoSans.style.fontFamily};
      }
    `}</style>
  );
};

export default FontsImport;
