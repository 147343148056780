import { ERedisCacheKey } from '@constants/cacheCallApi';
import { PageTypes } from '@constants/pageTypes';
import { RequestMethods } from '@constants/types';
import {
  getAllTranslationsUrl,
  getContentPageDataUrl, getNavBarUrl,
  getPageDataMainUrl,
  getPageDtaUrl, getPagesUrl, getPrivatePolicyUrl,
  getWrappedCategoriesUrl,
} from '@constants/urls';
import { ICallApiAction } from '@interfaces/fetchService';
import {
  IPageDataGoToPageAction, IPageDataSetLanguageAction,
  IPageDataSetLocaleAction,
  IPageDataSetSEOAction, IPageDataSiteSettingsSave,
} from '@interfaces/pageData/actions';
import { ISeo, ISiteSettings } from '@interfaces/pageData/reducer';
import { IStore } from '@interfaces/store';
import { isPreviewModeEnabled } from '@utils/checkPreviewMode';
import { getCountryAndLanguageCode } from '@utils/common';
import { TContext } from '@utils/cookies';
import * as actions from '../../constants/actions/pageData';
import { CALL_API } from '../../services/SSRService';
import { addParamsToUrl } from '../../services/SSRService/utils';

export const onPageDataSetLocaleAction: (countryCode: string, languageCode: string) => IPageDataSetLocaleAction = (
  countryCode,
  languageCode,
) => ({
  payload: { countryCode, languageCode },
  type: actions.PAGE_DATA_SET_LOCALE,
});

export const onPageDataSetLanguageAction: (payload: string) => IPageDataSetLanguageAction = (
  payload,
) => ({
  payload,
  type: actions.PAGE_DATA_SET_LANGUAGE,
});

type TGetPageData = (params: {
  countryCode: string | null;
  languageCode: string | null;
  params?: string | string[];
  isServer: number;
  preview?: boolean;
}) => ICallApiAction;
export const onGetPageDataAction: TGetPageData = (params) => {
  return {
    [CALL_API]: {
      cache: true,
      endpoint: addParamsToUrl(getPageDtaUrl(), params, true),
      method: RequestMethods.GET_METHOD,
      types: [actions.PAGE_DATA_GET_REQUEST, actions.PAGE_DATA_GET_SUCCESS, actions.PAGE_DATA_GET_FAILED],
    },
  };
};

type TGetPageDataUpdate = (params: {
    countryCode: string | null;
    languageCode: string | null;
    params?: string | string[];
    isServer: number;
    preview?: boolean;
  },
  shallow?: boolean) => ICallApiAction;
export const onGetPageDataUpdateAction: TGetPageDataUpdate = (params, shallow) => {

  return {
    [CALL_API]: {
      endpoint: addParamsToUrl(getPageDataMainUrl(), params, true),
      method: RequestMethods.GET_METHOD,
      types: shallow ? [actions.PAGE_DATA_SHALLOW_UPDATE_REQUEST, actions.PAGE_DATA_SHALLOW_UPDATE_SUCCESS, actions.PAGE_DATA_SHALLOW_UPDATE_FAILED] : [actions.PAGE_DATA_UPDATE_REQUEST, actions.PAGE_DATA_UPDATE_SUCCESS, actions.PAGE_DATA_UPDATE_FAILED],
    },
  };
};

export const onGetAllTranslationsAction: () => any = () => (dispatch: any) => dispatch({
  [CALL_API]: {
    endpoint: getAllTranslationsUrl(),
    method: RequestMethods.GET_METHOD,
    types: [
      actions.ALL_TRANSLATIONS_GET_REQUEST,
      actions.ALL_TRANSLATIONS_GET_SUCCESS,
      actions.ALL_TRANSLATIONS_GET_FAILED,
    ],
  },
});

type TGetContentPageData = (params: {
  contentType: string;
  countryCode: string | null;
  languageCode: string | null;
}) => ICallApiAction;
export const onGetContentPageDataAction: TGetContentPageData = ({ contentType, countryCode, languageCode }) => {
  return {
    [CALL_API]: {
      endpoint: addParamsToUrl(getContentPageDataUrl(), { type: contentType, countryCode, languageCode }, true),
      method: RequestMethods.GET_METHOD,
      requestParams: { type: contentType },
      types: [
        actions.CONTENT_PAGE_DATA_GET_REQUEST,
        actions.CONTENT_PAGE_DATA_GET_SUCCESS,
        actions.CONTENT_PAGE_DATA_GET_FAILED,
      ],
    },
  };
};

export const onGetContentPageData = (contentType: string) => (dispatch, getState: () => IStore) => {
  const { pageData: { countryCode, languageCode } } = getState();
  return dispatch(onGetContentPageDataAction({
    contentType,
    countryCode,
    languageCode,
  }));
};


export const onInitPageData = (context: TContext | null = null) => async (dispatch, getState: () => IStore) => {
  const { context: { host, query: { params } } } = getState();
  let countryCode = params ? params[0] : null;
  let languageCode = params ? params[1] : null;
  if (countryCode === null || languageCode === null) {
    const countryAndLanguageCode = getCountryAndLanguageCode(context);
    countryCode = countryAndLanguageCode.countryCode || null;
    languageCode = countryAndLanguageCode.languageCode || null;
  }
  const isServer = typeof window === 'undefined' ? 1 : 0;
  const preview = isPreviewModeEnabled(host);
  const paramsWithoutLocale = params?.slice(2) || [];

  await dispatch(
    onGetPageDataAction({
      countryCode,
      isServer,
      languageCode,
      params: paramsWithoutLocale,
      preview,
    }),
  );
};

export const onUpdatePageData = (params: string[], shallow?: boolean) => async (dispatch, getState: () => IStore) => {
  const { context: { host } } = getState();
  let countryCode = params ? params[0] : null;
  let languageCode = params ? params[1] : null;
  if (countryCode === null || languageCode === null) {
    const countryAndLanguageCode = getCountryAndLanguageCode(null);
    countryCode = countryAndLanguageCode.countryCode || null;
    languageCode = countryAndLanguageCode.languageCode || null;
  }
  const isServer = typeof window === 'undefined' ? 1 : 0;
  const preview = isPreviewModeEnabled(host);
  const paramsWithoutLocale = params?.slice(2) || [];

  await dispatch(
    onGetPageDataUpdateAction({
        countryCode,
        isServer,
        languageCode,
        params: paramsWithoutLocale,
        preview,
      },
      shallow));
};

export const onPageDataSetSEOAction: (seo: ISeo) => IPageDataSetSEOAction = (seo) => ({
  payload: seo,
  type: actions.PAGE_DATA_SET_SEO,
});

type TGetCategoryWrappedAction = (params: { country: string | null; lang: string | null }) => ICallApiAction;
export const onGetCategoryWrappedAction: TGetCategoryWrappedAction = (params) => {
  return {
    [CALL_API]: {
      cache: true,
      cacheOptions: ERedisCacheKey.getCategoryWrapped,
      endpoint: addParamsToUrl(getWrappedCategoriesUrl(), params, false),
      method: RequestMethods.GET_METHOD,
      types: [
        actions.PAGE_DATA_GET_CATEGORY_WRAPPED_REQUEST,
        actions.PAGE_DATA_GET_CATEGORY_WRAPPED_SUCCESS,
        actions.PAGE_DATA_GET_CATEGORY_WRAPPED_FAILED,
      ],
    },
  };
};


export const acPreGoToPage = (pageType: PageTypes): IPageDataGoToPageAction => ({
  payload: { pageType },
  type: actions.PAGE_DATA_GOTOPAGE,
});


export const onGetPrivacyPageData = () => async (dispatch, getState: () => IStore) => {

  const { pageData: { countryCode, languageCode, data: { pages } } } = getState();
  const faqPage = pages?.find((pageItem) => pageItem.type === PageTypes.faq);

  let params: string[] = [];
  if (faqPage) {
    params = faqPage.url.split('/');


    const isServer = typeof window === 'undefined' ? 1 : 0;

    const body = {
      countryCode,
      isServer,
      languageCode,
      params,
    };
    await dispatch({
      [CALL_API]: {
        endpoint: addParamsToUrl(getPageDataMainUrl(), body, true),
        method: RequestMethods.GET_METHOD,
        types: [actions.PAGE_DATA_PRIVACY_REQUEST, actions.PAGE_DATA_PRIVACY_SUCCESS, actions.PAGE_DATA_PRIVACY_FAILED],
      },
    });
  }
};

export const acSaveSiteSettings = (siteSettings: ISiteSettings): IPageDataSiteSettingsSave => ({
  payload: { ...siteSettings },
  type: actions.PAGE_DATA_SITE_SETTINGS_SAVE,
});

type TGetNavBar = (lang: string) => ICallApiAction;
export const onGetNavBar: TGetNavBar = (lang) => {
  return {
    [CALL_API]: {
      cache: true,
      cacheOptions: ERedisCacheKey.getCategoryWrapped,
      endpoint: getNavBarUrl(lang),
      method: RequestMethods.GET_METHOD,
      types: [
        actions.PAGE_DATA_GET_NAVBAR_REQUEST,
        actions.PAGE_DATA_GET_NAVBAR_SUCCESS,
        actions.PAGE_DATA_GET_NAVBAR_FAILED,
      ],
    },
  };
};


type TGetPrivatePolicy = (params: { countryCode: string, languageCode: string }) => ICallApiAction;
export const acGetPrivatePolicy: TGetPrivatePolicy = ({ countryCode, languageCode }) => {
  return {
    [CALL_API]: {
      endpoint: addParamsToUrl(getPrivatePolicyUrl(countryCode || 'EE'), { languageCode: languageCode || 'et' }),
      method: RequestMethods.GET_METHOD,
      types: [
        actions.PAGE_DATA_GET_PRIVATE_POLICY_REQUEST,
        actions.PAGE_DATA_GET_PRIVATE_POLICY_SUCCESS,
        actions.PAGE_DATA_GET_PRIVATE_POLICY_FAILED,
      ],
    },
  };
};

type TGetPages = (countryCode: string, languageCode: string) => ICallApiAction;
export const acGetPages: TGetPages = (countryCode, languageCode= 'et') => {
  return {
    [CALL_API]: {
      endpoint: addParamsToUrl(getPagesUrl(countryCode || 'EE'), {locale: languageCode}),
      method: RequestMethods.GET_METHOD,
      types: [
        actions.PAGE_DATA_GET_PAGES_REQUEST,
        actions.PAGE_DATA_GET_PAGES_SUCCESS,
        actions.PAGE_DATA_GET_PAGES_FAILED,
      ],
    },
  };
};
