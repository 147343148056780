import { IProductDetailsReducer } from '@interfaces/productDetails/reducer';

export const initialState: IProductDetailsReducer = {
  activeProduct: {
    color: null,
    size: null
  },
  errorUnSelected: false,
  loading: false,
  productDetails: null,
  productDetailsPrefilled: false,
};
