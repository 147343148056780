export const NAVIGATION_REPLACING_ROUTE = 'NAVIGATION_REPLACING_ROUTE';
export type NAVIGATION_REPLACING_ROUTE = 'NAVIGATION_REPLACING_ROUTE';

export const NAVIGATION_UPDATE_STATE = 'NAVIGATION_UPDATE_STATE';
export type NAVIGATION_UPDATE_STATE = 'NAVIGATION_UPDATE_STATE';

export const NAVIGATION_CHANGE_STATE = 'NAVIGATION_CHANGE_STATE';
export type NAVIGATION_CHANGE_STATE = 'NAVIGATION_CHANGE_STATE';

export const NAVIGATION_CHANGE_BEFORE_STATE = 'NAVIGATION_CHANGE_BEFORE_STATE';
export type NAVIGATION_CHANGE_BEFORE_STATE = 'NAVIGATION_CHANGE_BEFORE_STATE';
