import { Reducer } from 'redux';

import {
  ALL_TRANSLATIONS_GET_SUCCESS,
  PAGE_DATA_GET_SUCCESS,
  PAGE_DATA_SET_LANGUAGE,
} from '@constants/actions/pageData';
import * as actions from '@constants/actions/translations';
import { IPageDataActions } from '@interfaces/pageData/actions';
import { ITranslationsActions } from '@interfaces/translations/actions';
import { ITranslationsReducer } from '@interfaces/translations/reducer';
import { initialState } from '@reducers/translations/initState';

export const translationsReducer: Reducer<ITranslationsReducer, IPageDataActions | ITranslationsActions> = (state = initialState, action) => {
  switch (action.type) {
    case actions.TRANSLATIONS_PREVIEW_GET_SUCCESS:
      return { ...state, translationsPreview: { ...state.translationsPreview, ...action.payload } };
    case actions.TRANSLATIONS_GET_SUCCESS:
      return { ...state, translations: { ...state.translations, ...action.payload } };
    case ALL_TRANSLATIONS_GET_SUCCESS:
      return { ...state, translationsAll: { ...state.translations, ...action.payload } };
    case PAGE_DATA_GET_SUCCESS:
      const translations = action.payload?.data?.translations || {};
      return { ...state, translations: { ...state.translations, ...translations } };
    case PAGE_DATA_SET_LANGUAGE:
      //  return { ...state, languageCode: action.payload };
      const translationsLang = state.translationsAll[action.payload];
      return { ...state, translations: { ...state.translations, ...translationsLang } };
    default:
      return state;
  }
};
