export type PRODUCT_DETAILS_CHANGE_OPTION = 'PRODUCT_DETAILS_CHANGE_OPTION';
export const PRODUCT_DETAILS_CHANGE_OPTION = 'PRODUCT_DETAILS_CHANGE_OPTION';

export type PRODUCT_DETAILS_GET_REQUEST = 'PRODUCT_DETAILS_GET_REQUEST';
export const PRODUCT_DETAILS_GET_REQUEST = 'PRODUCT_DETAILS_GET_REQUEST';
export type PRODUCT_DETAILS_GET_SUCCESS = 'PRODUCT_DETAILS_GET_SUCCESS';
export const PRODUCT_DETAILS_GET_SUCCESS = 'PRODUCT_DETAILS_GET_SUCCESS';
export type PRODUCT_DETAILS_GET_FAILED = 'PRODUCT_DETAILS_GET_FAILED';
export const PRODUCT_DETAILS_GET_FAILED = 'PRODUCT_DETAILS_GET_FAILED';

export type PRODUCT_DETAILS_UNSELECTED_ERROR = 'PRODUCT_DETAILS_UNSELECTED_ERROR';
export const PRODUCT_DETAILS_UNSELECTED_ERROR = 'PRODUCT_DETAILS_UNSELECTED_ERROR';

export type PRODUCT_DETAILS_UNMOUNT = 'PRODUCT_DETAILS_UNMOUNT';
export const PRODUCT_DETAILS_UNMOUNT = 'PRODUCT_DETAILS_UNMOUNT';

export type PRODUCT_DETAILS_MARK_REQUEST = 'PRODUCT_DETAILS_MARK_REQUEST';
export const PRODUCT_DETAILS_MARK_REQUEST = 'PRODUCT_DETAILS_MARK_REQUEST';
export type PRODUCT_DETAILS_MARK_SUCCESS = 'PRODUCT_DETAILS_MARK_SUCCESS';
export const PRODUCT_DETAILS_MARK_SUCCESS = 'PRODUCT_DETAILS_MARK_SUCCESS';
export type PRODUCT_DETAILS_MARK_FAILED = 'PRODUCT_DETAILS_MARK_FAILED';
export const PRODUCT_DETAILS_MARK_FAILED = 'PRODUCT_DETAILS_MARK_FAILED';

export type PRODUCT_DETAILS_PREFILL = 'PRODUCT_DETAILS_PREFILL';
export const PRODUCT_DETAILS_PREFILL = 'PRODUCT_DETAILS_PREFILL';
