import { IApiErrorResponse } from '@interfaces/fetchService';
import { IProductDetails } from '@interfaces/productDetails';
import { IProductInList } from '../product';
import { IGetCartResponse, IShipmentCountry, IShipmentLocation } from './index';

export enum EPaymentStatus {
  failed = 'FAILED',
  checking = 'CHECKING',
  rejected = 'PAYMENT_FAILED',
  successful = 'PAYMENT_SUCCESS',
  wait = 'PAYMENT_WAITING',
}

export interface ICartReducer {
  acceptTermsConditions: boolean;
  cartItemsDetails: Partial<IProductDetails>;
  count: number;
  error: IApiErrorResponse[];
  errorToWarning: IApiErrorResponse[];
  form: IGetCartResponse;
  loaded: boolean;
  loading: {
    addItem: boolean;
    cartGet: boolean;
    cartProducts: boolean;
    cartUpdate: boolean;
    orderCreate: boolean;
    shipmentLocations: boolean;
  };
  orderId: string | null;
  page: number;
  payment: {
    orderUID: string | null;
    error: IApiErrorResponse[];
    status: EPaymentStatus;
    fei?: string;
  };
  shipmentCountry: IShipmentCountry[];
  shipmentLocations: IShipmentLocation[];
  suggested: IProductInList[];
}
