export namespace RequestMethods {
  export const GET_METHOD = 'GET';
  export type GET_METHOD = typeof GET_METHOD;
  export const PATCH_METHOD = 'PATCH';
  export type PATCH_METHOD = typeof PATCH_METHOD;
  export const POST_METHOD = 'POST';
  export type POST_METHOD = typeof POST_METHOD;
  export const PUT_METHOD = 'PUT';
  export type PUT_METHOD = typeof PUT_METHOD;
  export const DELETE_METHOD = 'DELETE';
  export type DELETE_METHOD = typeof DELETE_METHOD;

  export type Any = GET_METHOD | POST_METHOD | PUT_METHOD | DELETE_METHOD | PATCH_METHOD;
}
