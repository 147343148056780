export type LOOKBOOK_GET_ITEMS_REQUEST = 'LOOKBOOK_GET_ITEMS_REQUEST';
export const LOOKBOOK_GET_ITEMS_REQUEST = 'LOOKBOOK_GET_ITEMS_REQUEST';
export type LOOKBOOK_GET_ITEMS_SUCCESS = 'LOOKBOOK_GET_ITEMS_SUCCESS';
export const LOOKBOOK_GET_ITEMS_SUCCESS = 'LOOKBOOK_GET_ITEMS_SUCCESS';
export type LOOKBOOK_GET_ITEMS_FAILED = 'LOOKBOOK_GET_ITEMS_FAILED';
export const LOOKBOOK_GET_ITEMS_FAILED = 'LOOKBOOK_GET_ITEMS_FAILED';

export type LOOKBOOK_GET_ITEMS_NEXT_REQUEST = 'LOOKBOOK_GET_ITEMS_NEXT_REQUEST';
export const LOOKBOOK_GET_ITEMS_NEXT_REQUEST = 'LOOKBOOK_GET_ITEMS_NEXT_REQUEST';
export type LOOKBOOK_GET_ITEMS_NEXT_SUCCESS = 'LOOKBOOK_GET_ITEMS_NEXT_SUCCESS';
export const LOOKBOOK_GET_ITEMS_NEXT_SUCCESS = 'LOOKBOOK_GET_ITEMS_NEXT_SUCCESS';
export type LOOKBOOK_GET_ITEMS_NEXT_FAILED = 'LOOKBOOK_GET_ITEMS_NEXT_FAILED';
export const LOOKBOOK_GET_ITEMS_NEXT_FAILED = 'LOOKBOOK_GET_ITEMS_NEXT_FAILED';

export type LOOKBOOK_GET_ITEMS_PRODUCTS_REQUEST = 'LOOKBOOK_GET_ITEMS_PRODUCTS_REQUEST';
export const LOOKBOOK_GET_ITEMS_PRODUCTS_REQUEST = 'LOOKBOOK_GET_ITEMS_PRODUCTS_REQUEST';
export type LOOKBOOK_GET_ITEMS_PRODUCTS_SUCCESS = 'LOOKBOOK_GET_ITEMS_PRODUCTS_SUCCESS';
export const LOOKBOOK_GET_ITEMS_PRODUCTS_SUCCESS = 'LOOKBOOK_GET_ITEMS_PRODUCTS_SUCCESS';
export type LOOKBOOK_GET_ITEMS_PRODUCTS_FAILED = 'LOOKBOOK_GET_ITEMS_PRODUCTS_FAILED';
export const LOOKBOOK_GET_ITEMS_PRODUCTS_FAILED = 'LOOKBOOK_GET_ITEMS_PRODUCTS_FAILED';
