export interface ICartItem {
  active?: boolean;
  giftCardIssue?: IGiftCartIssue;
  newColorId?: string | number;
  newSizeId?: string;
  orderedCartItemId?: string;
  price: {
    currencyName: string;
    price: string;
    priceDiscount: string;
  };
  productId: number;
  recommendedForProduct?: number;
  shippingInternational?: boolean;
  sizeId: string | number | null;
  qty: number;
}

export enum EGiftCartIssueField {
  emailAt = 'emailAt',
  emailBody = 'emailBody',
  emailFrom = 'emailFrom',
  emailTo = 'emailTo',
  giftCardDesign = 'giftCardDesignId',
  giftCardGenerationCode = 'giftCardGenerationCode',
  recipientName = 'recipientName',
}

export interface IGiftCartIssue {
  [EGiftCartIssueField.emailAt]: string;
  [EGiftCartIssueField.emailBody]: string | JSX.Element;
  [EGiftCartIssueField.emailFrom]: string | JSX.Element;
  [EGiftCartIssueField.emailTo]: string | JSX.Element;
  [EGiftCartIssueField.recipientName]: string | JSX.Element;
  [EGiftCartIssueField.giftCardDesign]: number;
  [EGiftCartIssueField.giftCardGenerationCode]: string | JSX.Element | null;
}

export interface IShipmentMethod {
  addressRequired: boolean;
  code: string;
  companyName: string;
  currencyName: string;
  emailRequired: boolean;
  filterCities: IShipmentCity[];
  locations?: IShipmentLocation[];
  method: string;
  shipmentMethodId: number;
  methodLocal: string;
  methodLogo: string;
  periodDays: number;
  periodDaysInternational: number;
  phoneRequired: boolean;
  phoneRequiredMask: string;
  price: string;
  priceDiscount: string;
  priceDiscountThreshold: string;
  priceInternational: string;
  shipmentLocationRequired: boolean;
}

export interface ICustomerDeliveryMethod {
  id: string;
  isDefault: boolean;
  shipmentAddress: string;
  shipmentZipcode: string;
  shipmentCity: string;
  shipmentCountry: string;
  shipmentMethodId: number;
  addressRequired: boolean;
  locationRequired: boolean;
  enabled: boolean;
}

export interface IShipmentCity {
  id: number;
  locationCity: {
    city: string;
    cityId: number;
  };
}

export enum ShipmentFormFields {
  shipToCountryId = 'shipToCountryId',
  shipmentAddress = 'shipmentAddress',
  shipmentCity = 'shipmentCity',
  shipmentCountry = 'shipmentCountry',
  // shipmentCounty = 'shipmentCounty',
  shipmentComment = 'shipmentComment',
  shipmentEmail = 'shipmentEmail',
  shipmentFirstName = 'shipmentFirstName',
  shipmentLastName = 'shipmentLastName',
  shipmentLocationId = 'shipmentLocationId',
  shipmentMethodId = 'shipmentMethodId',
  shipmentPhone = 'shipmentPhone',
  shipmentReceiveType = 'shipmentReceiveType',
  shipmentZipcode = 'shipmentZipcode',
}

export interface IShipmentForm {
  [ShipmentFormFields.shipToCountryId]?: number;
  [ShipmentFormFields.shipmentAddress]?: string;
  [ShipmentFormFields.shipmentCity]?: string;
  [ShipmentFormFields.shipmentComment]?: string;
  [ShipmentFormFields.shipmentCountry]?: string;
  // [ShipmentFormFields.shipmentCounty]?: string;
  [ShipmentFormFields.shipmentEmail]?: string;
  [ShipmentFormFields.shipmentFirstName]: string;
  [ShipmentFormFields.shipmentLastName]: string;
  [ShipmentFormFields.shipmentLocationId]?: number;
  [ShipmentFormFields.shipmentMethodId]?: number;
  [ShipmentFormFields.shipmentPhone]?: string;
  [ShipmentFormFields.shipmentReceiveType]: EShipmentReceiveType;
  [ShipmentFormFields.shipmentZipcode]?: string;
}

export interface ICartCoupon {
  code: string;
  discountAmount: number;
  discountPercent: number;
  error: string;
  errorCode: number;
  useWithDiscountProducts: boolean;
}

export interface ICartDiscount {
  amount: number;
  reference: string;
  type: EDiscountType;
}

export enum EDiscountType {
  giftCard = 'gift_card',
  promoCode = 'coupon',
  promoCodeBirthday = 'birthday_coupon',
  promoCodeCampaign = 'campaign_coupon',
}

export interface ICartGiftCard {
  amountLeft: number;
  amountToUse: number;
  amountTotal: number;
  cardCode: string;
  code: string;
  error: string;
  errorCode: number;
}

export interface IPaymentMethodProvider {
  paymentProvider: string;
  methods: IPaymentMethod[];
}

export interface IPaymentMethod {
  id: string;
  name: string;
  link: string;
  logoUrl: string;
  type: string;
}

export enum EShipmentReceiveType {
  ONE_PACK = 'ONE_PACK',
}

export interface IShipmentLocation {
  shipmentLocationId: number;
  locationName: string;
  county: string;
  city: string;
  address: string;
  zipcode: string;
}

export interface IGetCartResponse extends IShipmentForm {
  amountBonusPossible: string;
  amountBonusToUse: string;
  amountBonusTotal: string;
  amountOrderTotal: string;
  amountOrderTotalWithoutShipment: string;
  amountProductsDiscount: string;
  amountProductsTotal: string;
  amountProductsTotalWithFullItemsPrice: string;
  birthdayDiscount: boolean;
  cartItems: ICartItem[];
  coupons: ICartCoupon[];
  customerDeliveryMethod?: ICustomerDeliveryMethod;
  customerDeliveryMethodId?: string;
  deliveryMethodId: null | string;
  discounts: ICartDiscount[];
  giftCards: ICartGiftCard[];
  shipmentReceiveType: EShipmentReceiveType;
  // shipToCountryId: null | number;
  // shipmentMethodId: null | number;
  // shipmentLocationId: null | number;
  // shipmentCountry: null | string;
  // shipmentCounty: null | string;
  // shipmentCity: null | string;
  // shipmentAddress: null | string;
  //  shipmentZipcode: null | string;
  //  shipmentFirstName: null | string;
  // shipmentLastName: null | string;
  //  shipmentEmail: null | string;
  // shipmentPhone: null | string;
  paymentMethod?: null | IPaymentMethod;
  paymentMethodId: null | string;
  paymentMethods: IPaymentMethodProvider[];
  promotionCode: null | string;
  shipmentMethodRequired: boolean;
  shipmentPriceTotal: string;
  shipToCountry: null | string;
  shipToCountries: IShipmentCountry[];
  shipmentMethod: null | IShipmentMethod;
  shipmentLocation: null | IShipmentLocation;
  shipmentMethods: IShipmentMethod[];
  shipmentReceiveTypes: EShipmentReceiveType[];
  useBonus: boolean;
}

export interface IShipmentCountry {
  code: string;
  shipmentCountry: string;
  shipmentCountryId: number;
}

export interface IShipmentCountryPhones extends IShipmentCountry {
  phonePrefix: string;
}

export interface IOrderDataCart extends IShipmentForm {
  amountBonusToUse: string;
  cartItems: ICartItem[];
  coupons: ICartCoupon[];
  customerDeliveryMethodId?: string;
  deliveryMethodId?: string;
  giftCards: ICartGiftCard[];
  paymentMethodId: string | null;
  promotionCode: string | null;
  useBonus: boolean;
}

export interface IOrderData {
  cart: IOrderDataCart;
  saveTransport: boolean;
}

export interface IShipmentLocation {
  address: string;
  city: string;
  county: string;
  locationName: string;
  shipmentLocationId: number;
  zipcode: string;
}

export interface IShipmentCountry {
  shipmentCountryId: number;
  shipmentCountry: string;
  code: string;
}
