import { getSeoAttributesBySexId } from '@components/pages/productsList/utils';
import { ISeoAttributes, IWrappedCategoryMapped, IWrappedCategoryOrigin } from '@interfaces/category';

type TMapWrappedCategory = (category: IWrappedCategoryOrigin, sexId: number) => IWrappedCategoryMapped;
export const mapWrappedCategory: TMapWrappedCategory = (category, sexId) => {

  const seoAttributes: ISeoAttributes | null = getSeoAttributesBySexId(category?.seoAttributes, sexId);
  const children: IWrappedCategoryMapped[] = category?.children?.map((childCategory) => mapWrappedCategory(childCategory, sexId)) || [];
  return { ...category, children, seoAttributes };
};
