import { EShipmentReceiveType, ShipmentFormFields } from '@interfaces/cart';
import { EPaymentStatus, ICartReducer } from '@interfaces/cart/reducer';

export const initialState: ICartReducer = {
  acceptTermsConditions: false,
  cartItemsDetails: {},
  count: 0,
  error: [],
  errorToWarning: [],
  form: {
    amountBonusPossible: '0',
    amountBonusToUse: '0',
    amountBonusTotal: '0',
    amountOrderTotal: '0',
    amountOrderTotalWithoutShipment: '0',
    amountProductsTotal: '0',
    amountProductsTotalWithFullItemsPrice: '0',
    amountProductsDiscount: '0',
    birthdayDiscount: true,
    cartItems: [],
    coupons: [],
    deliveryMethodId: '',
    discounts: [],
    giftCards: [],
    paymentMethodId: '',
    paymentMethods: [],
    promotionCode: '',
    shipToCountries: [],
    shipToCountry: null,
    shipToCountryId: 0,
    [ShipmentFormFields.shipmentAddress]: '',
    [ShipmentFormFields.shipmentCity]: '',
    [ShipmentFormFields.shipmentCountry]: '',
    [ShipmentFormFields.shipmentEmail]: '',
    [ShipmentFormFields.shipmentFirstName]: '',
    [ShipmentFormFields.shipmentLastName]: '',
    shipmentLocation: null,
    [ShipmentFormFields.shipmentLocationId]: 0,
    shipmentMethod: null,
    [ShipmentFormFields.shipmentMethodId]: 0,
    shipmentMethodRequired: true,
    shipmentMethods: [],
    [ShipmentFormFields.shipmentPhone]: '',
    shipmentPriceTotal: '0',
    [ShipmentFormFields.shipmentReceiveType]: EShipmentReceiveType.ONE_PACK,
    shipmentReceiveTypes: [],
    [ShipmentFormFields.shipmentZipcode]: '',
    useBonus: true,
  },
  loaded: false,
  loading: {
    addItem: false,
    cartGet: false,
    cartProducts: false,
    cartUpdate: false,
    orderCreate: false,
    shipmentLocations: false,
  },
  orderId: null,
  page: 1,
  payment: {
    error: [],
    orderUID: null,
    status: EPaymentStatus.wait,
  },
  shipmentCountry: [],
  shipmentLocations: [],
  suggested: [],
};
