import { Reducer } from 'redux';

import { TNativeActions } from '@reducers/nativeSettings/actionTypes';
import * as actions from '@reducers/nativeSettings/const';
import { INativeSettings } from '@reducers/nativeSettings/types';
import { initialState } from './initState';

export const nativeSettingsReducer: Reducer<INativeSettings, TNativeActions> = (state = initialState, action) => {
  switch (action.type) {
    case actions.NATIVE_TRACKING_STATE:
      // @ts-ignore
      const trackingDisabledState = (typeof trackingDisabled !== 'undefined') && ['true', '"true"'].includes(trackingDisabled);
      return { ...state, trackingDisabled: trackingDisabledState };
    case actions.NATIVE_SETTINGS_SET:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
