export const STORES_GET_LIST_REQUEST = 'STORES_GET_LIST_REQUEST';
export type STORES_GET_LIST_REQUEST = 'STORES_GET_LIST_REQUEST';
export const STORES_GET_LIST_SUCCESS = 'STORES_GET_LIST_SUCCESS';
export type STORES_GET_LIST_SUCCESS = 'STORES_GET_LIST_SUCCESS';
export const STORES_GET_LIST_FAILED = 'STORES_GET_LIST_FAILED';
export type STORES_GET_LIST_FAILED = 'STORES_GET_LIST_FAILED';

export const STORES_FILTER_CHANGE = 'STORES_FILTER_CHANGE';
export type STORES_FILTER_CHANGE = 'STORES_FILTER_CHANGE';

export const STORES_MAP_MODAL_OPEN = 'STORES_MAP_MODAL_OPEN';
export type STORES_MAP_MODAL_OPEN = 'STORES_MAP_MODAL_OPEN';
export const STORES_MAP_MODAL_CLOSE = 'STORES_MAP_MODAL_CLOSE';
export type STORES_MAP_MODAL_CLOSE = 'STORES_MAP_MODAL_CLOSE';

export const STORES_MODAL_GEOLOCATION_OPEN = 'STORES_MODAL_GEOLOCATION_OPEN';
export type STORES_MODAL_GEOLOCATION_OPEN = 'STORES_MODAL_GEOLOCATION_OPEN';
export const STORES_MODAL_GEOLOCATION_CLOSE = 'STORES_MODAL_GEOLOCATION_CLOSE';
export type STORES_MODAL_GEOLOCATION_CLOSE = 'STORES_MODAL_GEOLOCATION_CLOSE';

export const STORES_FILTER_COUNTRY_CHANGE = 'STORES_FILTER_COUNTRY_CHANGE';
export type STORES_FILTER_COUNTRY_CHANGE = 'STORES_FILTER_COUNTRY_CHANGE';

export const STORES_GET_BRANDS_REQUEST = 'STORES_GET_BRANDS_REQUEST';
export type STORES_GET_BRANDS_REQUEST = 'STORES_GET_BRANDS_REQUEST';
export const STORES_GET_BRANDS_SUCCESS = 'STORES_GET_BRANDS_SUCCESS';
export type STORES_GET_BRANDS_SUCCESS = 'STORES_GET_BRANDS_SUCCESS';
export const STORES_GET_BRANDS_FAILED = 'STORES_GET_BRANDS_FAILED';
export type STORES_GET_BRANDS_FAILED = 'STORES_GET_BRANDS_FAILED';

export const STORES_GET_CITIES_REQUEST = 'STORES_GET_CITIES_REQUEST';
export type STORES_GET_CITIES_REQUEST = 'STORES_GET_CITIES_REQUEST';
export const STORES_GET_CITIES_SUCCESS = 'STORES_GET_CITIES_SUCCESS';
export type STORES_GET_CITIES_SUCCESS = 'STORES_GET_CITIES_SUCCESS';
export const STORES_GET_CITIES_FAILED = 'STORES_GET_CITIES_FAILED';
export type STORES_GET_CITIES_FAILED = 'STORES_GET_CITIES_FAILED';

export const STORES_GET_COUNTRIES_REQUEST = 'STORES_GET_COUNTRIES_REQUEST';
export type STORES_GET_COUNTRIES_REQUEST = 'STORES_GET_COUNTRIES_REQUEST';
export const STORES_GET_COUNTRIES_SUCCESS = 'STORES_GET_COUNTRIES_SUCCESS';
export type STORES_GET_COUNTRIES_SUCCESS = 'STORES_GET_COUNTRIES_SUCCESS';
export const STORES_GET_COUNTRIES_FAILED = 'STORES_GET_COUNTRIES_FAILED';
export type STORES_GET_COUNTRIES_FAILED = 'STORES_GET_COUNTRIES_FAILED';

export const STORES_GET_MALLS_REQUEST = 'STORES_GET_MALLS_REQUEST';
export type STORES_GET_MALLS_REQUEST = 'STORES_GET_MALLS_REQUEST';
export const STORES_GET_MALLS_SUCCESS = 'STORES_GET_MALLS_SUCCESS';
export type STORES_GET_MALLS_SUCCESS = 'STORES_GET_MALLS_SUCCESS';
export const STORES_GET_MALLS_FAILED = 'STORES_GET_MALLS_FAILED';
export type STORES_GET_MALLS_FAILED = 'STORES_GET_MALLS_FAILED';

export const STORES_GET_AVAILABILITY_REQUEST = 'STORES_GET_AVAILABILITY_REQUEST';
export type STORES_GET_AVAILABILITY_REQUEST = 'STORES_GET_AVAILABILITY_REQUEST';
export const STORES_GET_AVAILABILITY_SUCCESS = 'STORES_GET_AVAILABILITY_SUCCESS';
export type STORES_GET_AVAILABILITY_SUCCESS = 'STORES_GET_AVAILABILITY_SUCCESS';
export const STORES_GET_AVAILABILITY_FAILED = 'STORES_GET_AVAILABILITY_FAILED';
export type STORES_GET_AVAILABILITY_FAILED = 'STORES_GET_AVAILABILITY_FAILED';
