export const siteDateFormat = 'dd.MM.yyyy';
export const siteDateTimeFormat = 'dd.MM.yyyy, HH:mm';
export const siteTimeFormat = 'HH:mm';
export const customerApiDateFormat = 'yyyy-MM-dd';

export const apiDateFormat = 'yyyy-MM-dd';
export const apiTimeFormat = 'HH:mm:ss';
export const apiZoneFormat = 'xxx';
export const apiDateTimeFormat = `${apiDateFormat}'T'${apiTimeFormat}`;
export const apiDateTimeZoneFormat = `${apiDateFormat}'T'${apiTimeFormat}${apiZoneFormat}`;
