import React from 'react';
import { useSelector } from 'react-redux';

import HomePageSliderImageToPriority from '@components/SEO/LargestContentfulPaint/HomePageSliderImageToPriority';
import ProductDetailsImageToPriority from '@components/SEO/LargestContentfulPaint/ProductDetailsImageToPriority';
import { PageTypes } from '@constants/pageTypes';
import { IStore } from '@interfaces/store';


const LCP: React.FunctionComponent<{}> = () => {
  const pageType = useSelector((state: IStore) => state.pageData.type);
  if (pageType === PageTypes.home) {
    return <HomePageSliderImageToPriority />;
  }
  if (pageType === PageTypes.productDetail) {
    return <ProductDetailsImageToPriority />;
  }
  return null;
};

export default LCP;
