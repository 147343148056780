export type TRANSLATIONS_SET = 'TRANSLATIONS_SET';
export const TRANSLATIONS_SET = 'TRANSLATIONS_SET';

export const AUTH_STRAPI_REQUEST = 'AUTH_STRAPI_REQUEST';
export type AUTH_STRAPI_REQUEST = 'AUTH_STRAPI_REQUEST';
export const AUTH_STRAPI_SUCCESS = 'AUTH_STRAPI_SUCCESS';
export type AUTH_STRAPI_SUCCESS = 'AUTH_STRAPI_SUCCESS';
export const AUTH_STRAPI_FAILED = 'AUTH_STRAPI_FAILED';
export type AUTH_STRAPI_FAILED = 'AUTH_STRAPI_FAILED';

export const TRANSLATIONS_GET_STRAPI_REQUEST = 'TRANSLATIONS_GET_STRAPI_REQUEST';
export type TRANSLATIONS_GET_STRAPI_REQUEST = 'TRANSLATIONS_GET_STRAPI_REQUEST';
export const TRANSLATIONS_GET_STRAPI_SUCCESS = 'TRANSLATIONS_GET_STRAPI_SUCCESS';
export type TRANSLATIONS_GET_STRAPI_SUCCESS = 'TRANSLATIONS_GET_STRAPI_SUCCESS';
export const TRANSLATIONS_GET_STRAPI_FAILED = 'TRANSLATIONS_GET_STRAPI_FAILED';
export type TRANSLATIONS_GET_STRAPI_FAILED = 'TRANSLATIONS_GET_STRAPI_FAILED';

export const TRANSLATIONS_SAVE_STRAPI_REQUEST = 'TRANSLATIONS_SAVE_STRAPI_REQUEST';
export type TRANSLATIONS_SAVE_STRAPI_REQUEST = 'TRANSLATIONS_SAVE_STRAPI_REQUEST';
export const TRANSLATIONS_SAVE_STRAPI_SUCCESS = 'TRANSLATIONS_SAVE_STRAPI_SUCCESS';
export type TRANSLATIONS_SAVE_STRAPI_SUCCESS = 'TRANSLATIONS_SAVE_STRAPI_SUCCESS';
export const TRANSLATIONS_SAVE_STRAPI_FAILED = 'TRANSLATIONS_SAVE_STRAPI_FAILED';
export type TRANSLATIONS_SAVE_STRAPI_FAILED = 'TRANSLATIONS_SAVE_STRAPI_FAILED';

export const TRANSLATIONS_GET_REQUEST = 'TRANSLATIONS_GET_REQUEST';
export type TRANSLATIONS_GET_REQUEST = 'TRANSLATIONS_GET_REQUEST';
export const TRANSLATIONS_GET_SUCCESS = 'TRANSLATIONS_GET_SUCCESS';
export type TRANSLATIONS_GET_SUCCESS = 'TRANSLATIONS_GET_SUCCESS';
export const TRANSLATIONS_GET_FAILED = 'TRANSLATIONS_GET_FAILED';
export type TRANSLATIONS_GET_FAILED = 'TRANSLATIONS_GET_FAILED';


export const TRANSLATIONS_PREVIEW_GET_REQUEST = 'TRANSLATIONS_PREVIEW_GET_REQUEST';
export type TRANSLATIONS_PREVIEW_GET_REQUEST = 'TRANSLATIONS_PREVIEW_GET_REQUEST';
export const TRANSLATIONS_PREVIEW_GET_SUCCESS = 'TRANSLATIONS_PREVIEW_GET_SUCCESS';
export type TRANSLATIONS_PREVIEW_GET_SUCCESS = 'TRANSLATIONS_PREVIEW_GET_SUCCESS';
export const TRANSLATIONS_PREVIEW_GET_FAILED = 'TRANSLATIONS_PREVIEW_GET_FAILED';
export type TRANSLATIONS_PREVIEW_GET_FAILED = 'TRANSLATIONS_PREVIEW_GET_FAILED';
