import { Reducer } from 'redux';

import * as actionsPage from '@constants/actions/page';
import { PageTypes } from '@constants/pageTypes';
import { IGiftCardActions } from '@interfaces/giftCard/actions';
import { IGiftCardStore } from '@interfaces/giftCard/store';
import { IPageActions } from '@interfaces/page/actions';
import * as actions from '../../constants/actions/giftCard';
import { giftCardIssueInit, initialState } from './initState';

export const giftCardReducer: Reducer<IGiftCardStore, IGiftCardActions | IPageActions> = (state = initialState, action) => {
  switch (action.type) {
    case actions.GIFT_CARD_GET_PRODUCT_REQUEST:
      return { ...state, loading: { ...state.loading, product: true } };
    case actions.GIFT_CARD_GET_PRODUCT_SUCCESS:
      const product = action.payload.colors?.[0];
      const size = product?.sizes?.[0] || null;
      return size ? {
        ...state,
        giftCard: {
          ...state.giftCard,
          giftCardIssue: { ...giftCardIssueInit },
          productId: product?.productId || state.giftCard.productId,
          sizeId: size?.sizeId || state.giftCard.sizeId,
        },
        loading: { ...state.loading, product: false },
        product: action.payload,
      } : { ...state, error: true, loading: { ...state.loading, product: false } };
    case actions.GIFT_CARD_GET_PRODUCT_FAILED:
      return { ...state, error: true, loading: { ...state.loading, product: false } };
    case actions.GIFT_CARD_UPDATE_FORM:
      return { ...state, giftCard: { ...state.giftCard, ...action.payload } };
    case actions.GIFT_CARD_GET_DESIGN_OPTIONS_SUCCESS:
      return { ...state, giftCardDesignOptions: action.payload };
    case actions.GIFT_CARD_INIT_FORM:
      return { ...state, loading: { ...state.loading, init: action.payload } };
    case actionsPage.PAGE_UNMOUNT:
      if (action.payload === PageTypes.giftCard) {
        return { ...initialState };
      } else {
        return state;
      }
    default:
      return state;
  }
};
