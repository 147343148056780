import { EGiftCartIssueField, ShipmentFormFields } from '@interfaces/cart';

export const ErrorKey = {
  1000: 'PROBLEM_UNDEFINED',
  1001: 'EMAIL_EMPTY',
  1002: 'PASSWORD_EMPTY',
  1003: 'IP_EMPTY',
  1004: 'SESSION_ID_EMPTY',
  1005: 'EMAIL_UNKNOWN',
  1006: 'USER_EMPTY',
  1007: 'USER_EXISTS',
  1008: 'COUNTRY_CODE_EMPTY',
  1009: 'COUNTRY_UNKNOWN',
  1010: 'LANGUAGE_CODE_EMPTY',
  1011: 'LANGUAGE_UNKNOWN',
  1012: 'COUNTRY_ID_UNKNOWN',
  1013: 'TOKEN_EMPTY',
  1014: 'TOKEN_UNKNOWN',
  1015: 'PRODUCT_ID_INCORRECT_FORMAT',
  2001: 'PASSWORD_SAME',
  2002: 'PASSWORD_WRONG_CURRENT',
  3001: 'AUTHENTICATION_FAILED',
  3002: 'DEAUTHENTICATION_FAILED',
  3003: 'SESSION_UNKNOWN',
  4001: 'ORDER_SHOPPINGCART_EMPTY',
  4002: 'ORDER_SHIPMENT_EMPTY',
  4003: 'ORDER_ERROR',
  4004: 'ORDER_ID_EMPTY',
  4005: 'ORDER_CONFIRMATION_ERROR',
  4006: 'ORDER_CONFIRMATION_ID_EMPTY',
  4007: 'ORDER_UNKNOWN',
  4008: 'ORDER_SHOPPING_CART_NO_RECEIVE_TYPE',
  5001: 'ORDER_PRODUCT_UNKNOWN',
  5002: 'ORDER_SHIPMENT_LOCATION_UNKNOWN',
  5003: 'ORDER_SHIPMENT_METHOD_EMPTY',
  5004: 'ORDER_SHIPMENT_METHOD_UNKNOWN',
  5005: 'ORDER_PRODUCT_PRICE_UNKNOWN',
  5006: 'ORDER_PAYMENT_AMOUNT_MISMATCH',
  5007: 'ORDER_PAYMENT_CURRENCY_MISMATCH',
  5008: 'ORDER_CHARGED',
  5009: 'ORDER_CONFIRMED',
  5010: 'ORDER_SHIPMENT_METHOD_ADDRESS_REQUIRED',
  5011: 'ORDER_SHIPMENT_METHOD_PHONE_REQUIRED',
  5012: 'ORDER_SHIPMENT_METHOD_EMAIL_REQUIRED',
  5013: 'ORDER_PAYMENT_METHOD_ID_MISSING',
  5014: 'ORDER_PAYMENT_ONE_CLICK_FAILED',
  5015: 'ORDER_SHIPMENT_METHOD_NAMES_REQUIRED',
  5016: 'ORDER_PAYMENT_ONE_CLICK',
  5017: 'ORDER_PRODUCT_QTY_PROBLEM',
  5018: 'ORDER_GIFT_CARD_ISSUE_PROBLEM',
  5019: 'ORDER_GIFT_CARD_USAGE_PROBLEM',
  5020: 'ORDER_GIFT_CARD_PRICE_PROBLEM',
  5021: 'ORDER_PROMOTION_CODE_PROBLEM',
  5022: 'ORDER_GIFT_CARD_ISSUE_EMAIL_TO_INCORRECT',
  5023: 'ORDER_FEEDBACK_SAVE',
  5024: 'ORDER_COUPON_USAGE_PROBLEM',
  5025: 'ORDER_FEEDBACK_FETCH',
  5026: 'ORDER_PRODUCT_STOCK_ERROR',
  5031: 'giftCard',
  5044: 'wishlist_item_already_exist',
  6001: 'MAILING_LIST_ERROR',
  6002: 'MAILING_LIST_EXISTS',
  6003: 'MAILING_LIST_USER_EXISTS',
  7001: 'COMMENT_EMPTY',
  7007: 'Payment is not valid',
  8001: 'DENINDER_NO_SESSION',
  8002: 'DENINDER_ACTION_MISSING'
};


export const errorKeysTr = {
  AUTHENTICATION_FAILED: 'AUTHENTICATION_FAILED',
  COMMENT_EMPTY: 'COMMENT_EMPTY',
  COUNTRY_CODE_EMPTY: 'Country code could not be null or empty',
  COUNTRY_ID_UNKNOWN: 'Country identification could not be null',
  COUNTRY_UNKNOWN: 'Country does not exist by code',
  DEAUTHENTICATION_FAILED: 'DEAUTHENTICATION_FAILED',
  DENINDER_ACTION_MISSING: 'DENINDER_ACTION_MISSING',
  DENINDER_NO_SESSION: 'DENINDER_NO_SESSION',
  EMAIL_EMPTY: 'Customer auth could not be null or empty',
  EMAIL_UNKNOWN: 'Customer auth does not exist',
  IP_EMPTY: 'IP could not be null or empty',
  LANGUAGE_CODE_EMPTY: 'Language code could not be null or empty',
  MAILING_LIST_ERROR: 'MAILING_LIST_ERROR',
  MAILING_LIST_EXISTS: 'MAILING_LIST_EXISTS',
  MAILING_LIST_USER_EXISTS: 'MAILING_LIST_USER_EXISTS',
  ORDER_CONFIRMATION_ERROR: 'Error occurred creating order',
  ORDER_CONFIRMATION_ID_EMPTY: 'ORDER_CONFIRMATION_ID_EMPTY',
  ORDER_COUPON_USAGE_PROBLEM: 'ORDER_COUPON_USAGE_PROBLEM',
  ORDER_ERROR: 'Error occurred creating order',
  ORDER_FEEDBACK_FETCH: 'Order feedback not found by orderUID: %s',
  ORDER_FEEDBACK_SAVE: 'Order feedback does not exist or is already saved',
  ORDER_GIFT_CARD_ISSUE_EMAIL_TO_INCORRECT: 'Order gift card data problem - wrong email format, email: %s',
  ORDER_GIFT_CARD_ISSUE_PROBLEM: 'Order gift card not found by code (cardCode: %s)',
  ORDER_GIFT_CARD_PRICE_PROBLEM: 'Gift card price for purchase must be greater then more zero - [productId: %s, sizeId: %s]',
  ORDER_GIFT_CARD_USAGE_PROBLEM: 'Order gift card not present in the request - %s',
  ORDER_ID_EMPTY: 'Order ID could not be null or empty',
  ORDER_PAYMENT_AMOUNT_MISMATCH: 'Order payment amount mismatch',
  ORDER_PAYMENT_CURRENCY_MISMATCH: 'Order payment currency mismatch',
  ORDER_PAYMENT_METHOD_ID_MISSING: 'Order payment one-click method is missing or incorrect',
  ORDER_PAYMENT_ONE_CLICK: 'ORDER_PAYMENT_ONE_CLICK',
  ORDER_PAYMENT_ONE_CLICK_FAILED: 'Order payment one-click payment failed',
  ORDER_PRODUCT_QTY_PROBLEM: 'Cart item qty problem',
  ORDER_PRODUCT_STOCK_ERROR: 'Order product stock error (ProductId: %s, SizeId: %s)',
  ORDER_PRODUCT_UNKNOWN: 'Order product unknown (ProductId: %s, SizeId: %s)',
  ORDER_PROMOTION_CODE_PROBLEM: 'Not found coupon or gift card by promotion code: [%s]',
  ORDER_SHIPMENT_EMPTY: 'Shipment method unknown',
  ORDER_SHIPMENT_LOCATION_UNKNOWN: 'Shipment location unknown',
  ORDER_SHIPMENT_METHOD_ADDRESS_REQUIRED: 'Shipment address is required',
  ORDER_SHIPMENT_METHOD_EMAIL_REQUIRED: 'Shipment email is required',
  ORDER_SHIPMENT_METHOD_NAMES_REQUIRED: 'Shipment first name and last name is required',
  ORDER_SHIPMENT_METHOD_PHONE_REQUIRED: 'Shipment phone is required',
  ORDER_SHIPMENT_METHOD_UNKNOWN: 'Shipment method unknown: %s',
  ORDER_SHOPPINGCART_EMPTY: 'Shopping cart could not be null',
  ORDER_SHOPPING_CART_NO_RECEIVE_TYPE: 'Shopping cart is international and shipment receive type is null. Please select receive type',
  ORDER_UNKNOWN: 'ORDER_UNKNOWN',
  PASSWORD_EMPTY: 'PASSWORD_EMPTY',
  PASSWORD_SAME: 'PASSWORD_SAME',
  PASSWORD_WRONG_CURRENT: 'PASSWORD_WRONG_CURRENT',
  PROBLEM_UNDEFINED: 'Undefined problem',
  PRODUCT_ID_INCORRECT_FORMAT: '',
  SESSION_ID_EMPTY: 'Session ID could not be null or empty',
  SESSION_UNKNOWN: 'SESSION_UNKNOWN',
  TOKEN_EMPTY: 'TOKEN_EMPTY',
  TOKEN_UNKNOWN: 'TOKEN_UNKNOWN',
  USER_EMPTY: 'USER_EMPTY'
};

export const commonErrors: number[] = [90000, 100000, 6000, 6001, 1005, 80000, 4004, 12000, 5023, 5025];

export const cartErrors: number[] = [4001, 4008, 5017];

export const bonusErrors: number[] = [5018, 5019, 5020, 5021, 5024, 5030, 5031, 5051];

export const buyGiftCard: number[] = [5022];

export const productsErrors: number[] = [4011, 5001, 5005, 5026, 5017];

export const shipmentMethodErrors: number[] = [4002, 5003, 5004];

export const shipmentFormErrors = {
  [ShipmentFormFields.shipToCountryId]: [1009],
  [ShipmentFormFields.shipmentAddress]: [5010],
  [ShipmentFormFields.shipmentCity]: [5029],
  [ShipmentFormFields.shipmentComment]: [],
  [ShipmentFormFields.shipmentEmail]: [5012, 5045],
  [ShipmentFormFields.shipmentFirstName]: [5015],
  [ShipmentFormFields.shipmentLastName]: [5027],
  [ShipmentFormFields.shipmentLocationId]: [5002, 5034],
  [ShipmentFormFields.shipmentPhone]: [5011, 5046],
  [ShipmentFormFields.shipmentZipcode]: [5028]
};

export const giftCardFormErrors = {
  [EGiftCartIssueField.emailTo]: [5022],
  [EGiftCartIssueField.emailBody]: [5050],
};

export const paymentErrors: number[] = [4013, 5006, 5007, 5013, 5014, 5016, 5032];
export const orderErrors: number[] = [4001, 4008, 4003, 4005, 4006, 4007];

export const step1Errors = [...productsErrors, ...bonusErrors];
export const step1ErrorsNoBlock = [...productsErrors, ...bonusErrors].filter((code)=> code !== 5021);
export const step2Errors = [...Object.values(shipmentFormErrors).flat(), ...shipmentMethodErrors];
export const step3Errors = [...paymentErrors, ...orderErrors];
/*
INVALID_REQUEST(90000, "Invalid request, please check params."),
    SERVER_ERROR(100000, "Internal server error");
    SESSION_NOT_FOUND_IN_COUNTRY(6000, "Not found session in country: [country: %s, sessionId: %s]"),
    SESSION_INVALID(6001, "Session is invalid. Please send request for get new session."),
    CUSTOMER_NOT_FOUND(1005, "Customer does not exist"),

    ORDER_SHOPPING_CART_EMPTY(4001, "Shopping cart could not be null"),
    ORDER_SHOPPING_CART_NO_RECEIVE_TYPE(4008, "Order cart is international and shipment receive type is null. Please select receive type"),



    ORDER_ERROR(4003,"Error occurred creating order"),
    ORDER_CONFIRMATION_ERROR(4005, "Error occurred creating order"),
    ORDER_CONFIRMATION_ID_EMPTY(4006, "Order confirmation orderUID is empty"),
    ORDER_UNKNOWN(4007, "Order unknown"),

    ORDER_PRODUCT_UNKNOWN(5001, "Order product unknown: [productId: %s, sizeId: %s]"),
    ORDER_PRODUCT_PRICE_UNKNOWN(5005, "Order product price unknown"),
    ORDER_PRODUCT_STOCK_ERROR(5026, "Order product stock error [productId: %s, sizeId: %s]"),
    ORDER_PRODUCT_QTY_PROBLEM(5017, "Cart item qty problem"),


    ORDER_PAYMENT_AMOUNT_MISMATCH(5006, "Order payment amount mismatch"),
    ORDER_PAYMENT_CURRENCY_MISMATCH(5007, "Order payment currency mismatch"),

    ORDER_CHARGED(5008, "Order changed"),
    ORDER_CONFIRMED(5009, "Order confirmed"),


    ORDER_SHIPMENT_EMPTY(4002, "Order shipment empty"),
    ORDER_SHIPMENT_METHOD_UNKNOWN(5004, "Order shipment method unknown: [%s]"),
    ORDER_SHIPMENT_METHOD_EMPTY(5003, "Order shipment method is empty"),

  COUNTRY_UNKNOWN(1009, "Country does not exist by code"),
    ORDER_SHIPMENT_LOCATION_UNKNOWN(5002, "Order shipment location unknown"),
    ORDER_SHIPMENT_METHOD_ADDRESS_REQUIRED(5010, "Shipment address is required"),
    ORDER_SHIPMENT_METHOD_PHONE_REQUIRED(5011, "Shipment phone is required"),
    ORDER_SHIPMENT_METHOD_EMAIL_REQUIRED(5012, "Shipment email is required"),
    ORDER_SHIPMENT_METHOD_LAST_NAME_REQUIRED(5027, "Order shipment last name is required"),
    ORDER_SHIPMENT_ZIPCODE_REQUIRED(5028, "Order shipment zipcode is required"),
    ORDER_SHIPMENT_CITY_REQUIRED(5029, "Order shipment city is required"),
    ORDER_SHIPMENT_METHOD_FIRST_NAME_REQUIRED(5015, "Order shipment firstname or last name is required"),




    ORDER_GIFT_CARD_ISSUE_PROBLEM(5018, "Order gift card item issue data is empty. [productId: %s, sizeId: %s]"),
    ORDER_GIFT_CARD_USAGE_PROBLEM(5019, "Order gift card not not found by code: [%s]"),
    ORDER_GIFT_CARD_PRICE_PROBLEM(5020, "Order gift card price for purchase must be greater then more zero - [productId: %s, sizeId: %s]"),
    ORDER_PROMOTION_CODE_PROBLEM(5021, "Not found coupon or gift card by promotion code: [%s]"),
    ORDER_GIFT_CARD_ACTIVE_PROBLEM(5030, "Order gift card usage problem - gift card is not active: [%s]"),
    ORDER_GIFT_CARD_AMOUNT_EXCESS(5031, "Order gift card [%s] usage problem - amount to use [%s] exceed amount left [%s]"),
    ORDER_COUPON_USAGE_PROBLEM(5024, "Order coupon not not found by code: [%s]"),

    ORDER_GIFT_CARD_ISSUE_EMAIL_TO_INCORRECT(5022, "Order gift card data problem - wrong email format, email: [%s]"),



    ORDER_PAYMENT_METHOD_ID_MISSING(5013, "Order payment one-click method is missing or incorrect"),
    ORDER_PAYMENT_ONE_CLICK_FAILED(5014, "Order payment one-click payment failed"),
    ORDER_PAYMENT_ONE_CLICK(5016, "Order payment one-click payment failed"),


    ORDER_CONFIRMATION_NOT_NEEDED_USED_ONE_CLICK_PAYMENT(5032, "Used one click payment, confirmation is not needed"),
    ORDER_CONFIRMATION_IS_CHARGED(5033, "Order confirmation 2012596797396 > Order charge state false"),


    GLOBAL_VALIDATION(80000, "Validation invalid"),


    ORDER_ID_EMPTY(4004, "Order ID could not be null or empty"),
    SECURITY_BASIC_VALIDATION(12000, "Invalid basic authentication token."),
    ORDER_FEEDBACK_SAVE(5023, "Order feedback does not exist or is already saved"),
    ORDER_FEEDBACK_FETCH(5025, "Order feedback not found"),
*/
