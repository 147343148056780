export interface ICacheOption {
  ttl: number;
}

export enum ERedisCacheKey {
  default = 'default',
  getBrands = 'getBrands',
  getCategoryWrapped = 'getCategoryWrapped',
  getNavBarSettings = 'getNavBarSettings',
  getProductListFilters = 'getProductListFilters',
  getTranslations = 'getTranslations',
  shipmentMethodsGet = 'shipmentMethodsGet',
  storesGetCountries = 'storesGetCountries',
}

export const CacheFetchOptions: Record<string, ICacheOption> = {
  [ERedisCacheKey.default]: { ttl: 60 * 5 }, // 5 min
  [ERedisCacheKey.getBrands]: { ttl: 60 * 60 * 6 }, // 6 hour
  [ERedisCacheKey.getCategoryWrapped]: { ttl: 60 * 15 },
  [ERedisCacheKey.getNavBarSettings]: { ttl: 60 * 60 * 6 },
  [ERedisCacheKey.getProductListFilters]: { ttl: 60 * 15 },
  getSocials: { ttl: 60 * 60 * 3},
  [ERedisCacheKey.getTranslations]: { ttl: 60 * 15 * 24 * 7 },
  [ERedisCacheKey.shipmentMethodsGet]: { ttl: 60 * 10 },
  [ERedisCacheKey.storesGetCountries]: { ttl: 60 * 10 },
};
