import { ILotteryReducer } from '@interfaces/lottery/reducer';

export const initialState: ILotteryReducer = {
  errors: {
    registerLottery: [],
  },
  loading: {
    registerLottery: false,
  },
  success: {
    registerLottery: false,
  }
};