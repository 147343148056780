import Router from 'next/router';

import { PageTypes } from '@constants/pageTypes';
import { IWrappedCategorySectionMapped } from '@interfaces/category';
import { IPageData, IPageReference } from '@interfaces/pageData/reducer';

import { mobileMode } from '@constants/config';

const catchNavigation = (e) => {
  if (!e.cancelled) {
    throw e;
  }
};


type IPushOrReloadRoute = (route: string, shallow?: boolean | null) => void;
export const pushOrReloadRoute: IPushOrReloadRoute = (
  route,
  shallow = null,
) => {
  const asPath = Router.asPath;
  if (route === asPath || clearRouteString(route) === clearRouteString(asPath)) {
    Router.reload();
  } else {
    if (mobileMode) {
      Router.push(prepareRouteString(route), '', { shallow: shallow !== null ? shallow : true }).catch(catchNavigation);
    } else {
      Router.push(prepareRouteString(route), '', { shallow: shallow || false }).catch(catchNavigation);
    }
  }
};

type TReplaceRoute = (route: string, shallow?: boolean) => void;
export const replaceRoute: TReplaceRoute = (route, shallow = false) => {
  return Router.replace(route, '', { shallow: mobileMode || shallow }).catch(catchNavigation);
};

export const getPageUrlByType = (data: IPageData, pageType: PageTypes) => {
  return data?.pages?.find((page) => page.type === pageType)?.url || '';
};

export const getPageByType = (pages: IPageReference[], pageType: PageTypes): IPageReference | null => {
  return pages?.find((page) => page.type === pageType) || null;
};

export const getPageBySexId = (pages: IPageReference[], sexId: number): IPageReference | null => {
  return pages?.find((page) => page.type === PageTypes.sex && page.filter?.sexId === sexId) || null;
};

export const getCategoryWrappedSectionBySexId = (sexId: number, categoryWrapped: IWrappedCategorySectionMapped[]): IWrappedCategorySectionMapped | undefined => {
  return categoryWrapped.find((wrappedCategory) => Number(sexId) === Number(wrappedCategory.sexId));
};

export const getCategoryUrlById = (categoryId: string, sexId: number, categoryWrapped: IWrappedCategorySectionMapped[]): string | null => {
  const categoriesSection: IWrappedCategorySectionMapped | undefined = getCategoryWrappedSectionBySexId(sexId, categoryWrapped);
  const path: string[] = [];
  if (categoriesSection?.categories) {
    const categoriesLength = categoriesSection.categories.length;
    for (let i = 0; i < categoriesLength; i++) {
      const checkParentCategory = categoriesSection.categories[i];
      if (Number(checkParentCategory.categoryId) === Number(categoryId)) {
        path.push(checkParentCategory?.seoAttributes?.seoUrlSlug ?? checkParentCategory.category);
        break;
      } else {
        if (checkParentCategory.children) {
          const matchedChild = checkParentCategory.children.find((childCategory) => Number(childCategory.categoryId) === Number(categoryId));
          if (matchedChild !== undefined) {
            path.push(checkParentCategory?.seoAttributes?.seoUrlSlug ?? checkParentCategory.category);
            path.push(matchedChild?.seoAttributes?.seoUrlSlug ?? matchedChild.category);
            break;
          }
        }
      }
    }
  }
  return path.join('/');
};


const clearRouteString = (route: string): string => {
  let result = route;
  if (result.startsWith('/')) {
    result = result.slice(1);
  }
  if (result.endsWith('/')) {
    result = result.slice(0, -1);
  }
  return result;
};

const prepareRouteString = (route: string): string => {
  if (!route.startsWith('/')) {
    return '/' + route;
  }
  return route;
};

export const removeHash = () => {
  const loc = window.location;
  if ('pushState' in history)
    history.pushState({}, document.title, loc.pathname + loc.search);
  else {
    // Prevent scrolling by storing the page's current scroll offset
    const scrollV = document.body.scrollTop;
    const scrollH = document.body.scrollLeft;

    loc.hash = '';

    // Restore the scroll offset, should be flicker free
    document.body.scrollTop = scrollV;
    document.body.scrollLeft = scrollH;
  }
};
