import { getGiftCardDesignPreviewUrl } from '@constants/urls';
import {
  EGiftCartIssueField,
  EShipmentReceiveType,
  ICartItem,
  IShipmentForm,
  ShipmentFormFields,
} from '@interfaces/cart';
import { IGiftCardViewOptions } from '@interfaces/giftCard';
import { IProductColor, IProductInList, IProductSize } from '@interfaces/product';
import { IWishListItem } from '@interfaces/wishList';
import { ColorsPalette } from '@themes/colors';
import { getColorsWithSizes } from '@utils/common';
import { addParamsToUrl } from '../../../services/SSRService/utils';

export interface IItem {
  name: string;
  value: number;
}

export interface SizeMapped {
  name: string;
  stockQty: number;
  value: number;
}

type getProductDetailsType = (
  productId: number,
  products: (IProductInList | IWishListItem)[],
) => {
  cartItemProduct: IProductInList | null;
  colors: IItem[];
  currentColor?: IProductColor;
  sizes: SizeMapped[];
};

export const getProductDetails: getProductDetailsType = (productId, products) => {
  const cartItemProduct =
    (products &&
      products.find((productItem) =>
        productItem.colors.find((productColor) => productColor.productId.toString() === productId.toString()),
      )) ||
    null;
  const colorsWithSizes = getColorsWithSizes(cartItemProduct?.colors);
  const colors =
    colorsWithSizes.map((color) => ({ value: color.productId, name: color.colorPictureUrl || color.code[0] })) || [];

  const currentColor = cartItemProduct?.colors?.find((color) => color.productId.toString() === productId.toString());
  const sizes: SizeMapped[] = currentColor
    ? currentColor.sizes?.map(mapColors)
    : [];

  return { cartItemProduct, colors, currentColor, sizes };
};

export const mapColors = (size: IProductSize): SizeMapped => {
  return ({
    name: size.size,
    stockQty: size.stockQty,
    value: size.sizeId,
  });
};

export const getAllProductsIds = (products: (IProductInList | IWishListItem)[]) => {
  const productsIds =
    (products && products.map((productItem) => productItem.colors.map((colorCase) => colorCase.productId)).flat()) ||
    [];
  return productsIds;
};

export const getShipmentForm = (form): IShipmentForm => {
  const shipmentForm = {
    [ShipmentFormFields.shipmentEmail]: form[ShipmentFormFields.shipmentEmail] || '',
    [ShipmentFormFields.shipmentFirstName]: form[ShipmentFormFields.shipmentFirstName] || '',
    [ShipmentFormFields.shipmentLastName]: form[ShipmentFormFields.shipmentLastName] || '',
    [ShipmentFormFields.shipmentMethodId]: form[ShipmentFormFields.shipmentMethodId] || 0,
    [ShipmentFormFields.shipmentReceiveType]:
    form[ShipmentFormFields.shipmentReceiveType] || EShipmentReceiveType.ONE_PACK,
  };
  if (form[ShipmentFormFields.shipmentCity]) {
    shipmentForm[ShipmentFormFields.shipmentCity] = form[ShipmentFormFields.shipmentCity];
  }
  if (form[ShipmentFormFields.shipmentAddress]) {
    shipmentForm[ShipmentFormFields.shipmentAddress] = form[ShipmentFormFields.shipmentAddress];
  }
  if (form[ShipmentFormFields.shipmentLocationId]) {
    shipmentForm[ShipmentFormFields.shipmentLocationId] = form[ShipmentFormFields.shipmentLocationId];
  }
  if (form[ShipmentFormFields.shipToCountryId]) {
    shipmentForm[ShipmentFormFields.shipToCountryId] = form[ShipmentFormFields.shipToCountryId];
  }
  if (form[ShipmentFormFields.shipmentCountry]) {
    shipmentForm[ShipmentFormFields.shipmentCountry] = form[ShipmentFormFields.shipmentCountry];
  }
  if (form[ShipmentFormFields.shipmentPhone]) {
    shipmentForm[ShipmentFormFields.shipmentPhone] = form[ShipmentFormFields.shipmentPhone];
  } else {
    shipmentForm[ShipmentFormFields.shipmentPhone] = '';
  }
  if (form[ShipmentFormFields.shipmentZipcode]) {
    shipmentForm[ShipmentFormFields.shipmentZipcode] = form[ShipmentFormFields.shipmentZipcode];
  }
  if (form[ShipmentFormFields.shipmentComment]) {
    shipmentForm[ShipmentFormFields.shipmentComment] = form[ShipmentFormFields.shipmentComment];
  }
  return shipmentForm;
};

export const getItemImageSrc = (cartItemProduct: IProductInList | null, item: ICartItem) => {
  const productColors = cartItemProduct?.colors || [];
  const selectedColor = productColors.find((color) => color.productId === item.productId);
  return selectedColor?.pictures[0]?.urlThumbnail || null;
};

export const getGiftCardImageSrc = (item: ICartItem, countryCode, languageCode): string | null => {

  const params: IGiftCardViewOptions = item.giftCardIssue ? {
    country: countryCode,
    designId: item.giftCardIssue[EGiftCartIssueField.giftCardDesign],
    lang: languageCode,
    name: item.giftCardIssue[EGiftCartIssueField.recipientName],
    price: item.price.price,
  } : {} as IGiftCardViewOptions;

  return item.giftCardIssue ? addParamsToUrl(getGiftCardDesignPreviewUrl(), params) : null;
};

export const generateItemBackgroundValue = (color: IProductColor | null | undefined) => {
  if (color?.colorPictureUrl) {
    return `url(${color?.colorPictureUrl})`;
  }
  return '';
};

export const generateItemBackgroundValue2 = (color: IProductColor | null | undefined) => {
  if (color) {
    if (color.code?.length > 0) {

      if (color.code?.length > 1) {
        return `linear-gradient(0.25turn, ${color.code.join(',')})`;
      } else {
        return `#${color.code[0]}`;
      }
    } else if (color.colorGroup?.code) {
      return `#${color.colorGroup.code}`;
    } else if (color?.colorPictureUrl?.includes('pic.denimdream.com')) {
      return `url(${color?.colorPictureUrl})`;
    }
  }
  return '';
};

export const generateItemTextColorValue = (color: IProductColor | null | undefined) => {
  if (color && ['f5f5dc', 'ffffff'].includes(color.colorGroup?.code?.toLowerCase())) {
    return ColorsPalette.primary;
  }
  return ColorsPalette.neutral2;
};
