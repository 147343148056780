import { GetServerSidePropsContext } from 'next';

import { mobileSession } from '@constants/config';
import { getSessionIdCookie, removeSessionCookie, setSessionIdCookie } from '@utils/cookies';
import {
  getSessionIdFromLocalStorage,
  resetSessionIdInLocalStorage,
  setSessionIdInLocalStorage,
} from '@utils/localStorage';

type TGetSessionId = (countryCode: string, context?: GetServerSidePropsContext | null) => string | null;
export const getSessionId: TGetSessionId = (countryCode, context = null) => {
  return mobileSession ? getSessionIdFromLocalStorage(countryCode) : getSessionIdCookie(context, countryCode);
};

type TSetSessionId = (countryCode: string, sessionId: string, context?: GetServerSidePropsContext | null) => void;
export const setSessionId: TSetSessionId = (countryCode, sessionId, context = null) => {
  mobileSession ? setSessionIdInLocalStorage(countryCode, sessionId) : setSessionIdCookie(context, countryCode, sessionId);
};

type TRemoveSessionId = (countryCode: string, context?: GetServerSidePropsContext | null) => void;
export const removeSessionId: TRemoveSessionId = (countryCode, context = null) => {
  mobileSession ? resetSessionIdInLocalStorage(countryCode) : removeSessionCookie(context, countryCode);
};
