export type SEARCH_PANEL_OPEN = 'SEARCH_PANEL_OPEN';
export const SEARCH_PANEL_OPEN = 'SEARCH_PANEL_OPEN';

export type SEARCH_PANEL_CLOSE = 'SEARCH_PANEL_CLOSE';
export const SEARCH_PANEL_CLOSE = 'SEARCH_PANEL_CLOSE';

export type SEARCH_VALUE_CHANGE = 'SEARCH_VALUE_CHANGE';
export const SEARCH_VALUE_CHANGE = 'SEARCH_VALUE_CHANGE';

export type SEARCH_TRENDING_GET_REQUEST = 'SEARCH_TRENDING_GET_REQUEST';
export const SEARCH_TRENDING_GET_REQUEST = 'SEARCH_TRENDING_GET_REQUEST';

export type SEARCH_TRENDING_GET_SUCCESS = 'SEARCH_TRENDING_GET_SUCCESS';
export const SEARCH_TRENDING_GET_SUCCESS = 'SEARCH_TRENDING_GET_SUCCESS';

export type SEARCH_TRENDING_GET_FAILED = 'SEARCH_TRENDING_GET_FAILED';
export const SEARCH_TRENDING_GET_FAILED = 'SEARCH_TRENDING_GET_FAILED';

export type SEARCH_HISTORY_GET_REQUEST = 'SEARCH_HISTORY_GET_REQUEST';
export const SEARCH_HISTORY_GET_REQUEST = 'SEARCH_HISTORY_GET_REQUEST';

export type SEARCH_HISTORY_GET_SUCCESS = 'SEARCH_HISTORY_GET_SUCCESS';
export const SEARCH_HISTORY_GET_SUCCESS = 'SEARCH_HISTORY_GET_SUCCESS';

export type SEARCH_HISTORY_GET_FAILED = 'SEARCH_HISTORY_GET_FAILED';
export const SEARCH_HISTORY_GET_FAILED = 'SEARCH_HISTORY_GET_FAILED';

export type SEARCH_CLEAR_HISTORY_POST_REQUEST = 'SEARCH_CLEAR_HISTORY_POST_REQUEST';
export const SEARCH_CLEAR_HISTORY_POST_REQUEST = 'SEARCH_CLEAR_HISTORY_POST_REQUEST';

export type SEARCH_CLEAR_HISTORY_POST_SUCCESS = 'SEARCH_CLEAR_HISTORY_POST_SUCCESS';
export const SEARCH_CLEAR_HISTORY_POST_SUCCESS = 'SEARCH_CLEAR_HISTORY_POST_SUCCESS';

export type SEARCH_CLEAR_HISTORY_POST_FAILED = 'SEARCH_CLEAR_HISTORY_POST_FAILED';
export const SEARCH_CLEAR_HISTORY_POST_FAILED = 'SEARCH_CLEAR_HISTORY_POST_FAILED';

export type SEARCH_RESULT_GET_REQUEST = 'SEARCH_RESULT_GET_REQUEST';
export const SEARCH_RESULT_GET_REQUEST = 'SEARCH_RESULT_GET_REQUEST';
export type SEARCH_RESULT_GET_SUCCESS = 'SEARCH_RESULT_GET_SUCCESS';
export const SEARCH_RESULT_GET_SUCCESS = 'SEARCH_RESULT_GET_SUCCESS';
export type SEARCH_RESULT_GET_FAILED = 'SEARCH_RESULT_GET_FAILED';
export const SEARCH_RESULT_GET_FAILED = 'SEARCH_RESULT_GET_FAILED';

export type SEARCH_MATCHING_PRODUCTS_CLEAR = 'SEARCH_MATCHING_PRODUCTS_CLEAR';
export const SEARCH_MATCHING_PRODUCTS_CLEAR = 'SEARCH_MATCHING_PRODUCTS_CLEAR';

export type SEARCH_CATEGORY_GET_REQUEST = 'SEARCH_CATEGORY_GET_REQUEST';
export const SEARCH_CATEGORY_GET_REQUEST = 'SEARCH_CATEGORY_GET_REQUEST';
export type SEARCH_CATEGORY_GET_SUCCESS = 'SEARCH_CATEGORY_GET_SUCCESS';
export const SEARCH_CATEGORY_GET_SUCCESS = 'SEARCH_CATEGORY_GET_SUCCESS';
export type SEARCH_CATEGORY_GET_FAILED = 'SEARCH_CATEGORY_GET_FAILED';
export const SEARCH_CATEGORY_GET_FAILED = 'SEARCH_CATEGORY_GET_FAILED';

export type SEARCH_GENDER_CHANGE = 'SEARCH_GENDER_CHANGE';
export const SEARCH_GENDER_CHANGE = 'SEARCH_GENDER_CHANGE';
