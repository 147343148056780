import { Action } from 'redux';

import { RequestMethods } from '@constants/types';
import { ICustomerApiError } from '@interfaces/account/reducer';
import { IApiResponse } from '@interfaces/index';
import { CALL_API } from '../services/SSRService';

export enum ActionStatuses {
  DISABLED_STATUS_BAR,
  ENABLED_SUCCESS_STATUS_BAR,
  ENABLED_FAILURE_STATUS_BAR,
  ENABLED_STATUS_BAR
}

export enum ESessionStatuses {
  SESSION_REQUIRED,
  canCallWithoutSession,
  dontCallWithoutSession,
  createSessionBefore
}

export interface ICallApi {
  body?: any;
  cache?: boolean;
  cacheOptions?: string;
  endpoint: string;
  facebook?: boolean;
  headers?: object;
  method: RequestMethods.Any;
  requestParams?: Record<any, any>;
  sessionId?: {
    status: ESessionStatuses;
  };
  showLoader?: boolean;
  status?: {
    key: ActionStatuses,
    errorMessage?: string
    successMessage?: string,
  };
  types: [string, string, string];
}

export interface IFieldError {
  item: number;
  size: number;
  qty: number;
  missingQty: number;
}

export interface IApiErrorResponse {
  code?: number;
  errorCode: number;
  fieldErrors?: IFieldError[];
  message: string;
  parentMessage?: string;
  timestamp: number;
}

export interface IMiddlewareErrorResponse extends Action {
  payload: IApiErrorResponse[];
  result: boolean;
  status: number;
  type: string;
}

export interface IMiddlewareSuccessResponse<T> extends Action {
  payload: T;
  result: boolean;
  status: number;
  type: string;
}

export interface IApiErrorAction<T> extends Action {
  payload: IApiErrorResponse[];
  type: T;
}

export interface ICustomerApiErrorAction<T> extends Action {
  payload: { errors: ICustomerApiError[] };
  type: T;

}

export interface IStrapiApiErrorAction<T> extends Action {
  payload: any;
  type: T;
}

export interface ICallApiAction {
  [CALL_API]: ICallApi;
}

export type TCallApiAction = ICallApiAction | Promise<IApiResponse<any> | null>;

export interface IFetchError {
  json: any;
  status: number;
}

export const NO_CACHE = 'NO_CACHE_LRU';

export interface IFaceBookADPayload {
  customerAuth: any;
  fen: string;
  fbc: string;
  fbp: string;
}
