import { getBrandSlugBySexId } from '@components/pages/productsList/Filter/Group/utils';
import { EGender } from '@constants/gender';
import { PageTypes } from '@constants/pageTypes';
import { ISeoAttributes, IWrappedCategoryMapped } from '@interfaces/category';
import { IPageReference } from '@interfaces/pageData/reducer';
import { EProductsListFilter, IProductBrand, IProductListFilterBrand } from '@interfaces/productsList';
import { IProductListFilter } from '@interfaces/productsList/reducer';

export const addParamsToUrl = (url: string, params: object, toStrapi: boolean = false, encode: boolean = false) => {
  let result = `${url}?`;
  for (const key in params) {
    if (params.hasOwnProperty(key) && params[key] !== undefined) {
      if (Array.isArray(params[key])) {
        if (params[key].length > 0) {
          params[key].map((arrayValue) => {
            result = toStrapi ? `${result}${key}[]=${arrayValue}&` : `${result}${key}=${encode ? encodeURIComponent(arrayValue) : arrayValue}&`;
          });
        }
      } else {
        result = `${result}${key}=${encode ? encodeURIComponent(params[key]) : params[key]}&`;
      }
    }
  }

  return result.slice(0, -1);
};

export const buildCategoryUrlPath = (
  filterParams: Partial<IProductListFilter>,
  allCategories: IWrappedCategoryMapped[],
  brands: IProductBrand[],
  pageSexId: null | number | undefined,
): string => {
  const brandIds: number[] = filterParams[EProductsListFilter.brandId] || [];
  const countBrandIds = brandIds.length;
  let path: string = '';
  if (countBrandIds === 1) {
    const brandId = String(brandIds[0]);
    const brand: IProductBrand | undefined = brands && brands?.find((brandItem) => String(brandItem.brandId || '') === brandId);
    if (brand) {
      const brandSlug = getBrandSlugBySexId(brand, pageSexId);
      if (brandSlug) {
        path = `/${brandSlug}`;
      }
    }
  }

  const categoryIds: string[] = filterParams[EProductsListFilter.categoryId] || [];
  const parentCategoryIds: string[] = [];
  for (let i = 0, count = categoryIds.length; i < count; i += 1) {
    const categoryId: string = String(categoryIds[i]);
    const categoryParentCategoryId: string | null = findParentId(categoryId, allCategories);
    if (categoryParentCategoryId !== null && !parentCategoryIds.includes(categoryParentCategoryId)) {
      parentCategoryIds.push(categoryParentCategoryId);
    }
  }

  if (parentCategoryIds.length !== 1) {
    return path;
  }

  const parentCategoryId: string = parentCategoryIds[0];
  const parentCategory: IWrappedCategoryMapped | undefined = findCategory(parentCategoryId, allCategories);
  const parentCategorySlug: string | undefined = parentCategory?.seoAttributes?.seoUrlSlug || undefined;

  if (parentCategorySlug === undefined) {
    return '';
  }

  path += `/${parentCategorySlug}`;
  if (categoryIds.length === 1 && String(categoryIds[0]) !== parentCategoryId) {
    const category: IWrappedCategoryMapped | undefined = findCategory(String(categoryIds[0]), parentCategory?.children || []);
    const categorySlug: string | undefined = category?.seoAttributes?.seoUrlSlug || undefined;

    if (categorySlug !== undefined) {
      path += `/${categorySlug}`;
    }
  }
  return path;
};

export const findBrandBySlug = (
  slug: string,
  brands: IProductListFilterBrand[],
  sexId?: (number | string)[],
): IProductListFilterBrand | undefined => {
  const sexIdToNumber = sexId?.map((value) => Number(value)) || [];
  let firstBrandBySlug: IProductListFilterBrand | undefined;

  const findSlug = (seoAttributes: ISeoAttributes) => seoAttributes.seoUrlSlug?.toLowerCase() === slug?.toLowerCase();
  const findId = (seoAttributes: ISeoAttributes) => seoAttributes.sexId && sexIdToNumber.includes(Number(seoAttributes.sexId));

  if (!!brands?.length) {
    for (let i = 0, count = brands.length; i < count; i += 1) {
      const brand = brands[i];
      const seoAttr = brand?.seoAttributes;
      if (!!seoAttr?.length) {
        if (!!sexIdToNumber?.length) {
          const foundAttr = seoAttr.find(findId);
          if (foundAttr && findSlug(foundAttr)) {
            return brand;
          } else if(seoAttr.some(findSlug)) {
            firstBrandBySlug = brand;
          }
        } else if(seoAttr.some(findSlug)) {
          return brand;
        }
      }
    }
  }

  return firstBrandBySlug;
};

export const findCategoryBySlug = (slug: string, categories: IWrappedCategoryMapped[]): IWrappedCategoryMapped | undefined => {
  for (let i = 0, count = categories.length; i < count; i += 1) {
    const category: IWrappedCategoryMapped = categories[i];
    const categorySlug = category?.seoAttributes?.seoUrlSlug || undefined;
    if (categorySlug === slug) {
      return category;
    }
    const children = category.children || [];
    for (let k = 0, c = children.length; k < c; k += 1) {
      const childCategory = children[k];
      const childCategorySlug = childCategory?.seoAttributes?.seoUrlSlug || undefined;
      if (childCategorySlug === slug) {
        return childCategory;
      }
    }
  }
  return undefined;
};

export const changePathname = (pathname: string, filter: IProductListFilter, pages: IPageReference[]) => {
  const sexIdList = filter[EProductsListFilter.sexId];
  if (!sexIdList.length) {
    return pathname;
  }
  const filterSexId = Number(sexIdList[0]);
  const parts: string[] = pathname.split('/');
  parts.shift(); // empty string
  const countryCode = parts.shift();
  const languageCode = parts.shift();
  const sexSlug = parts.shift();
  if (sexSlug) {
    const sexPageUrl = `${countryCode}/${languageCode}/${sexSlug}`;
    const sexPage = pages.find(({ url }) => url === sexPageUrl) || { filter: { sexId: null }, type: '' };
    if (sexPage?.filter?.sexId || sexPage?.type === PageTypes.kids) {
      if (sexPage?.type === PageTypes.kids && [EGender.girl, EGender.boy].includes(filterSexId)) {
        return pathname;
      } else if (sexPage?.filter?.sexId !== filterSexId) {
        const sexPageById = pages.find(({ filter: f, type }) => type === 'sex' && f?.sexId === filterSexId) || {
          url: null,
        };
        if (sexPageById.url) {
          return `/${sexPageById.url}${!!parts.length ? `/${parts.join('/')}` : ''}`;
        }
      }
    }
  }

  return pathname;
};

export const getCategoriesPath = (filter: any, filterData: any, data: any) => {
  const brandId = !!filter[EProductsListFilter.brandId].length && filter[EProductsListFilter.brandId].length === 1 ? filter[EProductsListFilter.brandId][0] : null;
  const brand = brandId ? filterData[EProductsListFilter.brandId].find(({ brandId: id }) => id === brandId) : null;
  const seoAttributes = brand && Array.isArray(brand.seoAttributes) ? brand.seoAttributes[0] : brand?.seoAttributes;
  const brandSlug = seoAttributes?.seoUrlSlug ? `/${seoAttributes?.seoUrlSlug}` : '';

  const {
    categorySlug,
    parentCategorySlug,
  } = getCategorySlugs(data?.id, filterData);
  return `${brandSlug}${parentCategorySlug}${categorySlug}`;
};

export const getBrandsPath = (filter: any, filterData: any, data: any) => {
  const categoryId = !!filter[EProductsListFilter.categoryId].length && filter[EProductsListFilter.categoryId].length === 1 ? filter[EProductsListFilter.categoryId][0] : null;
  const {
    categorySlug,
    parentCategorySlug,
  } = getCategorySlugs(categoryId, filterData);
  return `/${data.slug}${parentCategorySlug}${categorySlug}`.replace('//', '/');
};

const getCategorySlugs = (categoryId: number | string, filterData: any) => {
  let parentCategory = categoryId ? filterData[EProductsListFilter.categoryId].find(({ categoryId: id }) => id === categoryId) : null;
  let category: any = null;
  if (categoryId && !parentCategory) {
    // tslint:disable-next-line:prefer-for-of
    for (let i=0; i<filterData[EProductsListFilter.categoryId]?.length; i++) {
      const { children } = filterData[EProductsListFilter.categoryId][i];
      const c = children.find(({ categoryId: id }) => id === categoryId);
      if (c) {
        parentCategory = filterData[EProductsListFilter.categoryId][i];
        category = c;
        break;
      }
    }
  }
  const categorySlug = category ? `/${category?.seoAttributes?.seoUrlSlug}` : '';
  const parentCategorySlug = parentCategory ? `/${parentCategory?.seoAttributes?.seoUrlSlug}` : '';

  return {
    categorySlug,
    parentCategorySlug,
  };
};

function findCategory(id: string, categories: IWrappedCategoryMapped[]): IWrappedCategoryMapped | undefined {
  return categories.find((category: IWrappedCategoryMapped) => {
    const categoryId = category.categoryId || '';
    return categoryId === id;
  });
}

function findParentId(id: string, categories: IWrappedCategoryMapped[], parent?: IWrappedCategoryMapped): string | null {
  for (let i = 0, count = categories.length; i < count; i += 1) {
    const category: IWrappedCategoryMapped = categories[i];
    const categoryId: string = category.categoryId;
    if (categoryId === id) {
      if (parent !== undefined) {
        return parent.categoryId;
      }
      return categoryId;
    }
    const children = category.children || [];
    if (findParentId(id, children, category) !== null) {
      return categoryId;
    }
  }

  return null;
}

export const replaceSessionIdInUrl = (url: string, sessionId: string | null): string => {
  const urlObj = new URL(url);
  if (urlObj.searchParams.has('sessionId') && sessionId) {
    urlObj.searchParams.set('sessionId', sessionId);
  }
  return urlObj.href;
};

export const addSessionIdInUrl = (url: string, sessionId: string | null): string => {
  const urlObj = new URL(url);
  if (sessionId) {
    urlObj.searchParams.set('sessionId', sessionId);
  }
  return urlObj.href;
};

export const addSearchParamsToUrl = (url: string, fbPayload: Record<string, any>): string => {
  const urlObj = new URL(url);
  if (fbPayload) {
    Object.keys(fbPayload).forEach((key)=>{
      urlObj.searchParams.set(key, fbPayload[key]);
    });
  }
  return urlObj.href;
};

const findPageByAlias = (alias: string, pages: IPageReference[]): IPageReference | undefined => {
  return pages.find((page) => page.alias === alias);
};

export const getBasePathAndSexSlug = (pageDataType: string | null, pathname: string, pages: IPageReference[]): [string | undefined, string | undefined] => {
  const pathParts = pathname.split('/');
  let sexSlug: string | undefined;
  let basePath: string | undefined;

  if (pathParts.length === 4) {
    // /EE/et/Naised
    // /EE/et/Lapsed
    basePath = `/${pathParts[1]}/${pathParts[2]}/${pathParts[3]}`;

    // /EE/et/Naised
    if (pageDataType === PageTypes.productsList) {
      sexSlug = pathParts[3];
    }
  } else {
    // /EE/et/Naised/Outlet
    // /EE/et/Naised/Uued-tooted
    if (pageDataType === PageTypes.outlet || pageDataType === PageTypes.fresh) {
      sexSlug = pathParts[3];
      basePath = `/${pathParts[1]}/${pathParts[2]}/${pathParts[3]}/${pathParts[4]}`;
    }

    // /EE/et/Lapsed/Uleroivad
    if (pageDataType === PageTypes.kids) {
      basePath = `/${pathParts[1]}/${pathParts[2]}/${pathParts[3]}`;
    }

    // /EE/et/Lapsed/Poisid
    // /EE/et/Naised/Uleroivad
    // /EE/et/Lapsed/Poisid/Uleroivad
    if (pageDataType === PageTypes.productsList) {
      const childPage = findPageByAlias(pathParts[4], pages);
      if (childPage) {
        // /EE/et/Lapsed/Poisid (/Uleroivad)
        sexSlug = pathParts[4];
        basePath = `/${pathParts[1]}/${pathParts[2]}/${pathParts[3]}/${pathParts[4]}`;
      } else {
        // /EE/et/Naised/Uleroivad
        basePath = `/${pathParts[1]}/${pathParts[2]}/${pathParts[3]}`;
      }
    }
  }

  return [basePath, sexSlug];
};

export const createGenderUrl = (pageDataType: string | null, pathname: string, pages: IPageReference[], slug: string | undefined): string => {
  const [basePath, sexSlug] = getBasePathAndSexSlug(pageDataType, pathname, pages);

  if (sexSlug && !!slug) {
    return pathname.replace(`/${sexSlug}`, `/${slug}`).replace('//', '/');
  } else if (!!basePath && !!slug) {
    return pathname.replace(basePath, `${basePath}/${slug}`).replace('//', '/');
  } else if (!!slug) {
    return `${pathname}/${slug}`.replace('//', '/');
  }

  return pathname.replace('//', '/');
};

export const createCategoryUrl = (pageDataType: string | null, pathname: string, pages: IPageReference[], path: string): string => {
  // /EE/et/Naised/Uleroivad
  return createBrandAndCategoryUrl(pageDataType, pathname, pages, path);
};

export const createBrandUrl = (pageDataType: string | null, pathname: string, pages: IPageReference[], path: string): string => {
  // /EE/et/Naised/Guess/Uleroivad
  return createBrandAndCategoryUrl(pageDataType, pathname, pages, path);
};

export const createBrandAndCategoryUrl = (pageDataType: string | null, pathname: string, pages: IPageReference[], path: string): string => {
  const [basePath] = getBasePathAndSexSlug(pageDataType, pathname, pages);

  if (!!path) {
    return `${basePath}/${path}`.replace('//', '/');
  }

  return basePath || pathname;
};

export const createOtherUrl = (pathname: string): string => {
  return pathname;
};

export const createUrl = (groupKey: string, pageDataType: string | null, pathname: string, pages: IPageReference[], path: string) => {
  let url: string = '';

  switch (groupKey) {
    case EProductsListFilter.sexId:
      url = createGenderUrl(pageDataType, pathname, pages, path);
      break;
    case EProductsListFilter.categoryId:
      url = createCategoryUrl(pageDataType, pathname, pages, path);
      break;
    case EProductsListFilter.brandId:
      url = createBrandUrl(pageDataType, pathname, pages, path);
      break;
    default:
      url = createOtherUrl(pathname);
  }

  return url;
};
