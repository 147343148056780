import { ILoyaltyProgramReducer } from '@interfaces/loyaltyProgram/reducer';

export const initialState: ILoyaltyProgramReducer = {
  accessCard: null,
  accessCardLoading: false,
  attachError: null,
  attachLoading: false,
  confirmData: null,
  confirmDataError: null,
  confirmLoading: true,
  errors: null,
  signUpCompleted: false,
  signUpData: null,
  signUpDataError: null,
  signUpDataLoading: false,
  successMessages: null,
};
