import {
  ECustomerEmailFinalizes,
  ECustomerEmailFinalizesRemind,
  EDeliveryMethodPage,
  IAccountStore,
} from '@interfaces/account/reducer';

export const initialState: IAccountStore = {
  customerEmailChange: null,
  customerEmailFinalizes: ECustomerEmailFinalizes.finish,
  customerEmailFinalizesData: null,
  customerEmailFinalizesRemind: ECustomerEmailFinalizesRemind.undefined,
  deliveryMethodDetails: null,
  deliveryMethodLocations: [],
  deliveryMethodPage: EDeliveryMethodPage.main,
  deliveryMethodToAdd: {
    methodId: null,
  },
  deliveryMethods: [],
  errors: {
    changePassword: [],
    customerEmailFinalizesError: [],
    customerEmailFinalizesRemindError: [],
    deleteUser: [],
    deliveryMethodDelete: [],
    deliveryMethodDetails: [],
    deliveryMethodSave: [],
    deliveryMethods: [],
    linkSocial: [],
    notifications: [],
    orderByTransaction: [],
    orderDetails: [],
    orderTracking: [],
    ordersHistory: [],
    ordersRetailDetails: [],
    ordersRetailHistory: [],
    payments: [],
    shipmentMethods: [],
    user: [],
  },
  loading: {
    changePassword: false,
    deleteUser: false,
    deliveryMethodDelete: false,
    deliveryMethodDetails: false,
    deliveryMethodSave: false,
    deliveryMethods: false,
    linkSocial: false,
    locations: false,
    orderByTransaction: false,
    orderDetails: false,
    orderTracking: false,
    ordersHistory: true,
    ordersRetailDetails: false,
    ordersRetailHistory: true,
    paymentMethods: false,
    products: false,
    saveDetails: false,
    shipmentMethods: false,
  },
  orderByTransaction: null,
  orderDetails: null,
  orderTracking: [],
  ordersHistory: [],
  ordersHistoryPagination: {
    hasNextPage: false,
    page: 1,
    size: 10,
  },
  ordersRetailDetails: null,
  ordersRetailHistory: [],
  ordersRetailHistoryPagination: {
    hasNextPage: false,
    page: 1,
    size: 10,
  },
  paymentMethods: [],
  productsInOrders: [],
  shipmentMethods: [],
  user: null,
};
