import { IStoresReducer } from '@interfaces/stores/reducer';

export const initialState: IStoresReducer = {
  brands: [],
  cities: [],
  countries: [],
  filter: {
    brandId: null,
    cityId: null,
    countryId: null,
    groupId: null
  },
  filterApplied: {
    brandId: null,
    cityId: null,
    countryId: null,
    groupId: null
  },
  geolocationError: null,
  geolocationModal: false,
  list: [],
  loading: {
    brands: false,
    cities: false,
    countries: false,
    list: false,
    malls: false
  },
  malls: [],
  showMap: false,
  showMapItem: null,
  sideMenu: false
};
