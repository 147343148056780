import { mobileSession } from '@constants/config';
import { getTokenCookie, removeTokenCookie, setTokenCookie, TContext } from '@utils/cookies';
import { getTokenFromLocalStorage, resetTokenLocalStorage, setTokenLocalStorage } from '@utils/localStorage';

type TGetToken = (context?: TContext) => string | null;
export const getToken: TGetToken = (context = null) => {
  return mobileSession ? getTokenFromLocalStorage() : getTokenCookie(context) ?? null;
};

type TSetToken = (value: string, context?: TContext) => void;
export const setToken: TSetToken = (value, context = null) => {
  mobileSession ? setTokenLocalStorage(value) : setTokenCookie(context, value);
};

type TResetToken = (context?: TContext) => void;
export const resetToken: TResetToken = (context = null) => {
  mobileSession ? resetTokenLocalStorage() : removeTokenCookie(context);
};
