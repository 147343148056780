import { IAlertActions } from '@interfaces/alert/actions';
import { IAlertReducer } from '@interfaces/alert/reducer';
import { initialState } from '@reducers/alert/initState';
import { Reducer } from 'redux';
import * as actions from '../../constants/actions/alert';

export const alertReducer: Reducer<IAlertReducer, IAlertActions> = (state = initialState, action) => {
  switch (action.type) {
    case actions.ALERT_SHOW:
      return { ...state, show: true, ...action.payload };
    case actions.ALERT_CLEAR:
      return { ...initialState };
    default:
      return state;
  }
};
