import { getProductDetails, SizeMapped } from '@components/pages/cart/utils';
import { ICartItem } from '@interfaces/cart';
import { IWishListItem, IWishListItemLocal } from '@interfaces/wishList';
import { IWishListUpdateParams } from '../../actions/acWishList';

export const mapListToLocal = (list: IWishListItem[], requestParams?: Partial<IWishListUpdateParams>): IWishListItemLocal[] => {
  return list.map((item) => ({
    productId: item.selectedColor,
    qty: 1,
    sizeId: String(item.selectedColor) === String(requestParams?.newProductId) ? requestParams?.newSizeId || null : null,
    sizeName: null,
  }));
};

export const updateItemLocal = (item: IWishListItemLocal, newItem: Partial<ICartItem>, baseItem: IWishListItem | undefined): IWishListItemLocal => {
  let sizeName: string | null = null;
  const productId = Number(newItem.newColorId) || item.productId;
  const sizeId = newItem.newSizeId || item.sizeId;
  const currentColor = baseItem?.colors.find((colorItem) => colorItem.productId === productId);
  if (currentColor) {
    sizeName = currentColor.sizes.find((sizeItem) => sizeItem.sizeId === sizeId)?.size || null;
  }

  return {
    productId,
    qty: newItem.qty || item.qty,
    sizeId,
    sizeName,
  };
};

export const validateLocalItem = (item: IWishListItemLocal, list: IWishListItem[]): boolean => {
  const { sizes } = getProductDetails(item.productId, list);
  const currentSize: SizeMapped | null = item.sizeId && sizes?.find((size) => size.value === item.sizeId) || null;
  const isStock: boolean = currentSize && currentSize?.stockQty > 0 || false;
  return item.sizeId !== null && item.qty > 0 && item.productId !== null && isStock;
};

export const mapLocalItemToCart = (localItem: IWishListItemLocal, list: IWishListItem[]): ICartItem => {
  const { currentColor } = getProductDetails(localItem.productId, list);
  return {
    active: true,
    price: {
      currencyName: currentColor?.price.currencyName || 'EUR',
      price: currentColor?.price.price + '' || '',
      priceDiscount: currentColor?.price.priceDiscount + '' || '',
    },
    productId: localItem?.productId,
    qty: localItem?.qty || 1,
    sizeId: localItem?.sizeId || '',
  };
};
