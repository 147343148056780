import { Reducer } from 'redux';

export interface IPerformanceReducer {
  getServerSideProps: {
    firstStep: number;
    getApiData: number;
    getCustomerData: number;
    getPageData: number;
    startTime: number;
  };
}

export const initialState = {
  getServerSideProps: {
    firstStep: 0,
    getApiData: 0,
    getCustomerData: 0,
    getPageData: 0,
    startTime: 0,
  }
};

export const performanceReducer: Reducer<IPerformanceReducer, any> = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
