import { googleTagEnable } from '@constants/config';
import { isCookieConsentEnabledInStore } from '@utils/cookieConsent';

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID;

export const setConfig = (clientId) => {
  checkAndCallGTag('config', `${GA_TRACKING_ID}`, {
    page_path: window.location.pathname,
    clientId: clientId,
  });
};

export const setClientId = (clientId) => {
  checkAndCallGTag({
    event: 'trackerReady',
    cid: clientId,
  });
};

export const logRocketEvent = (sessionURL) => {
  checkAndCallGTag({
    event: 'LogRocket',
    eventAction: sessionURL,
  });
};

/**
 * Pageview
 * When a page is loaded or a browser history event happens (when enabled via enhanced measurement).
 */
export const pageview = () => {
  checkAndCallGTag({
    event: 'page_view',
  });
};

/**
 * Accept Cookies
 * Push the event when the user click on the cookie accept banner with the detail of choices
 * @TODO This must be implemented.
 */
export const cookieConsent = () => {
  checkAndCallGTag({
    event: 'cookie_consent',
    cookie_preferences: true, // true or false
    cookie_statistics: false, // true or false
    cookie_marketing: true, // true or false
  });
};

/**
 * Created Account
 * @TODO onSignUpFacebook
 * @param {*} userId
 */
export const signUp = (userId) => {
  checkAndCallGTag({
    event: 'sign_up',
    user_id: userId, // dynamic value, value should be your internal User ID value (string)
    method: 'method', // optional
  });
};

/**
 * Logged in
 * The event have to fire when the user log in or at the begin of a session
 * if the user it is automatically logged
 * @param {*} userId
 */
export const login = (userId, method) => {
  checkAndCallGTag({
    event: 'login',
    user_id: userId, // dynamic value, value should be your internal User ID value (string)
    method, // optional
  });
};

/**
 * Select Item
 * This event has to fire when the user clicks on the miniature of the product,
 * the list field can be populated according to the different page section.
 * @param {*} item
 */
export const selectItem = (userId, item) => {
  const items = [{ ...item, isRegistered: !!userId }];
  const totalPrice = calculateTotalPrice(items);
  clearEcommerce();
  checkAndCallGTag({
    event: 'select_item',
    user_id: userId,
    ecommerce: {
      currency: 'EUR',
      value: totalPrice,
      items,
    },
  });
};

/**
 * Viewed Product Page
 * Push the following code block when the user loads a product page
 * @param {*} userId
 * @param {*} item
 */
/*
export const viewItem = (userId, item) => {
  const items = [{ ...item, isRegistered: !!userId }];
  const totalPrice = calculateTotalPrice(items);
  clearEcommerce();
  checkAndCallGTag({
    event: 'view_item',
    user_id: userId, // if available at the moment
    ecommerce: {
      currency: 'EUR', // optional (recommended if the provide multi currencies)
      value: totalPrice,
      items,
    },
  });
};
*/

/**
 * Calls the checkAndCallGTag function with the provided category ID.
 *
 * @param {string | number | string[]} id - The ID of the category to be selected.
 */
export const selectCategory = (id) => {
  checkAndCallGTag({
    event: 'select_content',
    content_type: 'category',
    content_id: id,
  });
};

/**
 * Calls the GTag function with the search event and search term.
 *
 * @param {string} query - The term to search for.
 * @return {void} This function does not return anything.
 */
export const searchEvent = (query) => {
  checkAndCallGTag({
    event: 'search',
    search_term: query,
  });
};

/**
 * Remove From Cart
 * This event have to be loaded every time an user click on the “remove from cart” button,
 * if he click 3 times have to be pushed 3 times
 * @param {*} userId
 * @param {*} item
 */

/*
export const removeFromCart = (userId, item) => {
  const items = [{...createProductItemUsingData(item), isRegistered: !!userId}];
  const totalPrice = calculateTotalPrice(items);
  clearEcommerce();
  checkAndCallGTag({
    event: 'remove_from_cart',
    user_id: userId, // if available at the moment
    ecommerce: {
      currency: 'EUR', // optional (recommended if the provide multi currencies)
      value: totalPrice,
      items,
    },
  });
};
*/

/**
 * Begin Checkout
 * Push the following code block when the checkout process start,
 * for example when the user clicks on the checkout button or
 * the checkout page load (have to be loaded only once in dataLayer)
 * @param {*} userId
 * @param {*} items
 */

export const beginCheckout = (userId, items) => {
  const ecomItems = items.map((item) => ({ ...createProductItemUsingData(item), isRegistered: !!userId }));
  const totalPrice = calculateTotalPrice(ecomItems);
  clearEcommerce();
  checkAndCallGTag({
    event: 'begin_checkout',
    user_id: userId, // if available at the moment
    ecommerce: {
      currency: 'EUR', // optional (recommended if the provide multi currencies)
      value: totalPrice,
      items: ecomItems,
    },
  });
};

/**
 * Add Payment
 * Push the following code block when the user fill in the payment information and
 * select the payment method (have to be loaded only once in dataLayer)
 * @param {*} userId
 * @param {*} shippingTier
 * @param {*} items
 */
/*
export const addShippingInfo = (userId, shippingTier, items) => {
  const ecomItems = items.map((item) => ({ ...createProductItemUsingData(item), isRegistered: !!userId }));
  const totalPrice = calculateTotalPrice(ecomItems);
  clearEcommerce();
  checkAndCallGTag({
    event: 'add_shipping_info',
    user_id: userId, // if available at the moment
    ecommerce: {
      currency: 'EUR', // optional (recommended if the provide multi currencies)
      value: totalPrice,
      shipping_tier: shippingTier,
      items: ecomItems,
    },
  });
};
*/


/**
 * Purchase
 * Push the event in the order confirmation page
 * it have to be loaded only once in dataLayer no matter
 * if the page will be reloaded or accessed in a second time
 * @param {*} userId
 * @param {*} value
 * @param {*} shipping
 * @param {*} affiliation
 * @param {*} transactionId
 * @param {*} coupon
 * @param {*} items
 * @param {string | undefined} fei
 */
export const purchase = (userId, value, shipping, affiliation, transactionId, coupon, items, fei) => {
  const ecomItems = items.map((item) => ({ ...createProductItemUsingData(item), isRegistered: !!userId }));
  // const totalPrice = calculateTotalPrice(ecomItems);
  clearEcommerce();
  console.log('purchase fei', fei);
  checkAndCallGTag({
    event: 'transaction', // purchase_success
    user_id: userId, // if available at the moment
    ecommerce: {
      purchase: {
        actionField: {
          id: transactionId,
          affiliation,
          revenue: value,
          shipping,
          coupon,
        },
      },
    },
  });

  checkAndCallGTag({
    event: 'purchase',
    event_id: fei,
    user_id: userId,
    ecommerce: {
      transaction_id: transactionId,
      shipping,
      currency: 'EUR',
      value,
      coupon,
      items: ecomItems,
    },
  });
};

// /toode/|/product/|/prece/|/produktas/|/tuote/
export const fireRemarketingTagOfferDetail = (productId, productPrice) => {
  checkAndCallGTag({
    event: 'fireRemarketingTag',
    google_tag_params: {
      dynx_itemid: productId,
      dynx_pagetype: 'offerdetail',
      dynx_totalvalue: productPrice,
    },
  });
};

// /ostukorv/|/cart/|/iepirkumu-grozs/|/krepselis/|/ostoskori/
export const fireRemarketingTagConversionIntent = (cartItems, cartTotal) => {
  checkAndCallGTag({
    event: 'fireRemarketingTag',
    google_tag_params: {
      dynx_itemid: cartItems.map((item) => item.productId),
      dynx_pagetype: 'conversionintent',
      dynx_totalvalue: cartTotal,
    },
  });
};

// /order-confirmed/
export const fireRemarketingTagConversion = (products, revenue) => {
  checkAndCallGTag({
    event: 'fireRemarketingTag',
    google_tag_params: {
      dynx_itemid: products.map((product) => product.productId),
      dynx_pagetype: 'conversion',
      dynx_totalvalue: revenue,
    },
  });
};

export const clearEcommerce = () => {
  checkAndCallGTag({ ecommerce: null });
};

/**
 * Create Product Item
 * @param {*} name
 * @param {*} id
 * @param {*} price
 * @param {*} brand
 * @param {*} category
 * @param {*} variant
 * @param {*} quantity
 * @returns
 */
export const createProductItem = (
  name,
  id,
  price,
  discountPrice,
  brand,
  category,
  variant,
  quantity,
  listName = null,
  sale,
  outlet,
  fresh,
  categoryId,
  sexId
) => {
  const hasDiscount = !!discountPrice && Number(discountPrice) > 0;
  return {
    item_id: id,
    item_name: name,
    price: hasDiscount ? discountPrice : price,
    discount: hasDiscount ? (Number(price) - Number(discountPrice)).toFixed(2) : 0,
    item_brand: brand,
    item_category: category,
    item_variant: variant,
    quantity,
    item_list_name: listName,
    item_sale: sale,
    item_outlet: outlet,
    item_fresh: fresh,
    item_categoryId: categoryId,
    item_sexId: sexId,
  };
};

export const createPurchaseProduct = (data) => {
  const response = {};
  [
    'name',
    'id',
    'brand',
    'category',
    'variant',
    'quantity',
    'coupon',
    'sale',
    'outlet',
    'fresh',
    'categoryId',
    'sexId',
  ].forEach((key) => {
    if (data[key] !== undefined) {
      response[key] = data[key];
    }
  });

  return response;
};

export const calculateTotalPrice = (items) => {
  return items.reduce((acc, item) => acc + item.price * item.quantity, 0);
};

export const createProductItemUsingData = (data) => {
  return createProductItem(
    data?.name,
    data?.id,
    data?.price,
    data?.discount,
    data?.brand,
    data?.category,
    data?.variant,
    data?.quantity || 1,
    data?.listName || null,
    data?.sale,
    data?.outlet,
    data?.fresh,
    data?.categoryId,
    data?.sexId
  );
};

/**
 * Retrieves product data based on the given product and color.
 *
 * @param {IProductInList} product
 * @param {IProductColor} color
 * @return {Object}
 */
export const getProductData = (product, color) => {
  // @TODO: temporary solution. Will be changed when API will return proper data
  const brand = product?.brand?.brand;
  const model = product?.model; //?.substr(0, product?.model.indexOf(product?.article) - 1);
  const name = `${brand} ${model}`;
  const id = product?.colors[0]?.productId;
  const price = product?.colors[0]?.price?.price;
  const discount = product?.colors[0]?.price?.priceDiscount;
  const category = product?.category?.category;
  const sex = product?.sex?.sexLocal;
  const quantity = 1;
  const sexId = product?.sex?.sexId;
  const categoryId = product?.category?.categoryId;
  const variant = color ? color.colorLocal || color.colorName : product?.colors[0]?.colorLocal;
  const sale = color?.sale;
  const outlet = color?.outlet;
  const fresh = color?.fresh;

  return {
    brand,
    id,
    name,
    price,
    discount,
    quantity,
    category,
    categoryId,
    model,
    sex,
    sexId,
    variant,
    sale,
    outlet,
    fresh,
  };
};
/**
 * Check if gtag exist and call.
 *
 * @param {data} any
 * @return {Object}
 */
export const checkAndCallGTag = (data) => {
  const isCookieConsentEnabled = isCookieConsentEnabledInStore();
  if (!googleTagEnable || !isCookieConsentEnabled) {
    return;
  }

  if (!!window?.gtag) {
    window.gtag(data);
  } else {
    setTimeout(() => {
      checkAndCallGTag(data);
    }, 10);
  }
};
