import { ActionCreator } from 'redux';

import { IAlertClear, IAlertShow } from '@interfaces/alert/actions';
import * as actions from '../../constants/actions/alert';

export const acAlertShowAction = (
  message: string | JSX.Element,
  actionLabel?: string | JSX.Element,
  actionFunction?: (() => void) | null,
  snackBarClickHandler?: () => void
) => (dispatch) => {
  // @ts-ignore
  if (typeof window !== 'undefined') {
    // @ts-ignore
    if (window.alertTimerID) {
      // @ts-ignore
      clearTimeout(window.alertTimerID);
    }
    // @ts-ignore
    window.alertTimerID = setTimeout(() => dispatch(acAlertClear()), 5000);
  }
  return dispatch(acAlertShow(message, actionLabel, actionFunction, snackBarClickHandler));
};

export const acAlertShow: ActionCreator<IAlertShow> = (
  message: string,
  actionLabel,
  actionFunction,
  snackBarClickHandler
) => ({
  payload: { message, actionLabel, actionFunction, snackBarClickHandler },
  type: actions.ALERT_SHOW,
});

export const acAlertClear: ActionCreator<IAlertClear> = () => ({
  type: actions.ALERT_CLEAR,
});
