import { Middleware, MiddlewareAPI } from 'redux';
import { v4 as uuidv4 } from 'uuid';

import { ICallApi } from '@interfaces/fetchService';
import { TThunkDispatch } from '@interfaces/index';
import { IStore } from '@interfaces/store';
import { addToCart, addToWishlist, viewItem } from '@utils/gtagTS';
import { INextContext } from '../../store/wrapper';
import { CALL_API } from '../SSRService';
import { addSearchParamsToUrl } from '../SSRService/utils';
import { EAnalyticEventName } from './types';

export type TRequestAction = Promise<any>;

export const CALL_AD = Symbol('Call Analytic');

export interface ICallADPayload {
  productData: any;
}

export interface ICallAD {
  eventId?: string;
  event: EAnalyticEventName;
  payload?: ICallADPayload;
}

const analyticEventService: (context?: INextContext) => Middleware = (context) => (store: MiddlewareAPI<TThunkDispatch, IStore>) => (next) => async (action) => {

  const callAD: ICallAD = action[CALL_AD];

  if (typeof callAD === 'undefined') {
    return next(action);
  }

  const callAPI: ICallApi = action[CALL_API];

  const { event: eventName, payload } = callAD;

  if (typeof eventName === 'undefined' || !eventName) {
    return next(action);
  }

  const eventId = uuidv4();
  action[CALL_AD].eventId = eventId;

  const { auth: { user } }: IStore = store.getState();
  const userId = user?.data?.id;

  switch (eventName){
    case EAnalyticEventName.add_to_wishlist:
      addToWishlist(userId, payload?.productData, eventId);
      break;
    case EAnalyticEventName.add_to_cart:
      addToCart(userId, payload?.productData, eventId);
      break;
    case EAnalyticEventName.view_item:
      viewItem(userId, payload?.productData, eventId);
      break;
  }



  if(typeof callAPI) {
    const { requestParams = {} } = callAPI;
    requestParams.fei = eventId;
    action[CALL_API].endpoint = addSearchParamsToUrl(action[CALL_API].endpoint, { fei: eventId });
  }
  return next(action);
};

export default analyticEventService;
