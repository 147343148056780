import { Reducer } from 'redux';

import * as actionsPage from '@constants/actions/page';
import * as actionsPageData from '@constants/actions/pageData';
import { paymentErrors, productsErrors, shipmentFormErrors, shipmentMethodErrors } from '@constants/errors';
import { IGetCartResponse, ShipmentFormFields } from '@interfaces/cart';
import { ICartActions } from '@interfaces/cart/actions';
import { EPaymentStatus, ICartReducer } from '@interfaces/cart/reducer';
import { IPageActions } from '@interfaces/page/actions';
import { IPageDataActions } from '@interfaces/pageData/actions';
import * as actions from '../../constants/actions/cart';
import { initialState } from './initState';

export const cartReducer: Reducer<ICartReducer, ICartActions | IPageDataActions | IPageActions> = (state = initialState, action) => {
  switch (action.type) {
    case actions.CART_CHANGE_PAGE:
      return { ...state, page: action.payload };
    case actions.CART_CHANGE_SHIPMENT:
      return {
        ...state,
        error: state?.error ? [...state.error.filter((errorItem) => !shipmentMethodErrors.includes(errorItem.errorCode))] : [],
        form: {
          ...state.form,
          [ShipmentFormFields.shipmentLocationId]: 0,
          [ShipmentFormFields.shipmentMethodId]: action.payload,
        },
      };
    case actions.CART_CHANGE_PAYMENT:
      return {
        ...state,
        error: state?.error ? [...state.error.filter((errorItem) => !paymentErrors.includes(errorItem.errorCode))] : [],
        form: { ...state.form, paymentMethodId: action.payload },
      };
    case actions.CART_GENERATE_BY_TRANSACTION_SUCCESS:
      return {
        ...state,
        error: [],
        form: { ...action.payload.cart, promotionCode: '' },
        loaded: true,
        orderId: action.payload.orderId,
      };
    case actions.CART_ADD_ITEM_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, addItem: true },
      };
    case actions.CART_ADD_ITEM_SUCCESS:
      return {
        ...state,
        count: action.payload.cartItems.length,
        error: [],
        form: { ...action.payload, promotionCode: '' },
        loading: { ...state.loading, addItem: false },
      };
    case actions.CART_ADD_DETAIL_ITEM_INFO:
      const detailItem = action.payload;
      if (!!Object.keys(state.cartItemsDetails).length) {
        const isExistProduct = state.cartItemsDetails.products?.some(({ modelId }) => modelId === detailItem.modelId);
        if (isExistProduct) {
          return { ...state };
        }
        const currentCount = state.cartItemsDetails.count || 0;
        const existProducts = state.cartItemsDetails.products || [];
        return {
          ...state,
          cartItemsDetails: {
            ...state.cartItemsDetails,
            count: currentCount + 1,
            products: [...existProducts, detailItem],
          },
        };
      } else {
        return {
          ...state,
          cartItemsDetails: {
            count: 1,
            errors: [],
            filterBrands: [],
            filterColors: [],
            filterSizes: [],
            products: [detailItem],
          },
        };
      }
    case actions.CART_UPDATE_SUCCESS:
      return { ...state, error: [], form: { ...action.payload, promotionCode: '' } };
    case actions.CART_ADD_ITEM_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: { ...state.loading, addItem: false },
      };
    case actions.CART_UPDATE_ITEM_REQUEST:
      const removedErrorsWarning = state.errorToWarning ? state.errorToWarning.filter((item, key) => {
        return item && (item.fieldErrors && item.fieldErrors[0].size !== action.requestParams.sizeId
          && item.fieldErrors[0].item !== action.requestParams.productId);
      }) : [];
      return { ...state, errorToWarning: removedErrorsWarning };
    case actions.CART_UPDATE_ITEM_SUCCESS:
    case actions.CART_DELETE_ITEM_SUCCESS:
      const { coupons, giftCards,  ...payload } = action.payload;
      const additionalChanges: Partial<IGetCartResponse> = {};
      if (action.payload.hasOwnProperty('shipmentMethodRequired') && !action.payload.shipmentMethodRequired) {
        if (state.form.hasOwnProperty('shipmentMethodRequired') && state.form) {
          additionalChanges.shipmentMethod = initialState.form.shipmentMethod;
        }
      }
      if (state.form.discounts && !!state.form.discounts.length) {
        return {
          ...state,
          count: action.payload.cartItems.length,
          error: state?.error ? [...state.error.filter((errorItem) => !productsErrors.includes(errorItem.errorCode))] : [],
          form: { ...state.form, cartItems: payload.cartItems, promotionCode: '', ...additionalChanges },
        };
      } else {
        return {
          ...state,
          count: action.payload.cartItems.length,
          error: state?.error ? [...state.error.filter((errorItem) => !productsErrors.includes(errorItem.errorCode))] : [],
          form: { ...state.form, ...payload, promotionCode: '', ...additionalChanges },
        };
        // we need to make UPDATE_CART request after this case to get actual total amount
      }
    case actions.CART_UPDATE_ITEM_FAILED:
    case actions.CART_GENERATE_BY_TRANSACTION_FAILED:
    case actions.CART_UPDATE_FAILED:
      return { ...state, error: action.payload };
    case actions.CART_ITEMS_GET_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, cartGet: true },
      };
    case actions.CART_ITEMS_GET_SUCCESS:
      return {
        ...state,
        count: action.payload.cartItems.length,
        form: action.payload,
        loaded: true,
        loading: { ...state.loading, cartGet: false },
      };
    case actions.CART_ITEMS_GET_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: { ...state.loading, cartGet: false },
      };
    case actions.CART_GET_PRODUCTS_REQUEST:
      return { ...state, loading: { ...state.loading, cartProducts: true } };
    case actions.CART_GET_PRODUCTS_SUCCESS:
      return { ...state, cartItemsDetails: action.payload, loading: { ...state.loading, cartProducts: false } };
    case actions.CART_GET_PRODUCTS_FAILED:
      return { ...state, loading: { ...state.loading, cartProducts: false } };
    case actions.CART_SHIPMENT_FORM_UPDATE:
      if (action.fields && state.error && state.error.length > 0) {
        const errorKeys = action.fields.map((field) => shipmentFormErrors[field]).flat();
        const filterError = state.error.filter((errorItem) => !errorKeys.includes(errorItem.errorCode));
        return {
          ...state,
          error: filterError,
          form: { ...state.form, ...action.payload },
        };
      }
      return { ...state, form: { ...state.form, ...action.payload } };
    case actions.CART_LOCATIONS_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, shipmentLocations: true },
        shipmentLocations: { ...initialState.shipmentLocations },
      };
    case actions.CART_LOCATIONS_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, shipmentLocations: false },
        shipmentLocations: action.payload,
      };
    case actions.CART_LOCATIONS_FAILED:
      return { ...state, loading: { ...state.loading, shipmentLocations: false } };
    case actions.CART_COUNTRY_SUCCESS:
      if (action.payload.length === 1) {
        const shipmentCountryId = action.payload[0].shipmentCountryId;
        return {
          ...state,
          form: { ...state.form, [ShipmentFormFields.shipToCountryId]: shipmentCountryId },
          shipmentCountry: action.payload,
        };
      }
      return {
        ...state,
        shipmentCountry: action.payload,
      };
    case actions.CART_PROMOCODE_SET:
      return { ...state, form: { ...state.form, promotionCode: action.payload } };
    case actions.CART_COUPON_SETITEM:
      const couponsState = state.form.coupons || [];
      return { ...state, form: { ...state.form, coupons: [...couponsState, action.payload] } };
    case actions.CART_GIFTCARD_SETITEM:
      const giftCardsState = state.form.giftCards || [];
      return { ...state, form: { ...state.form, giftCards: [...giftCardsState, action.payload] } };
    case actions.CART_PROMOCODE_REMOVE:
      return {
        ...state,
        form: {
          ...state.form,
          coupons:
            (state.form.coupons && state.form.coupons.filter((couponItem) => couponItem.code !== action.payload)) || [],
          giftCards:
            (state.form.giftCards &&
              state.form.giftCards.filter((giftCardItem) => giftCardItem.code !== action.payload)) ||
            [],
        },
      };
    case actions.CART_PROMOCODE_UPDATE_ITEM:
      const giftCardsClear =
        (state.form.giftCards && state.form.giftCards.filter((giftItem) => giftItem.code !== action.payload.code)) ||
        [];
      return {
        ...state,
        form: {
          ...state.form,
          giftCards: [...giftCardsClear, action.payload],
        },
      };
    case actions.CART_CHANGE_FORM:
      return {
        ...state,
        form: { ...state.form, ...action.payload },
      };
    case actions.CART_PAYMENT_FAILED:
      return {
        ...state,
        page: 3,
        payment: { ...state.payment, orderUID: action.payload, status: EPaymentStatus.rejected },
      };
    case actions.ORDER_CREATE_REQUEST:
      return {
        ...state,
        error: [],
        loading: { ...state.loading, orderCreate: true },
      };
    case actions.ORDER_CREATE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, orderCreate: false },
      };
    case actions.ORDER_CREATE_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: { ...state.loading, orderCreate: false },
      };
    case actions.CART_TERMS:
      return {
        ...state,
        acceptTermsConditions: action.payload,
      };
    case actions.ORDER_PAYMENT_CONFIRM_REQUEST:
      return {
        ...state,
        payment: { ...state.payment, error: [], status: EPaymentStatus.checking },
      };
    case actions.ORDER_PAYMENT_CONFIRM_FAILED:
      return {
        ...state,
        payment: { ...state.payment, error: action.payload, status: EPaymentStatus.failed },
      };
    case actions.ORDER_PAYMENT_CONFIRM_SUCCESS:
      const { id, status, transactionId = null, fei } = action.payload;
      return {
        ...state,
        payment: { ...state.payment, orderUID: id, status, transactionId, fei },
      };
    case actions.CART_COUNT_SUCCESS:
      return {
        ...state,
        count: action.payload.count,
      };
    case actions.CART_MOVE_ERROR_TO_WARNING:
      return {
        ...state,
        errorToWarning: [...state.errorToWarning, action.payload],
      };
    case actions.CART_REMOVE_ERROR_TO_WARNING:
      const removedErrors = state.errorToWarning.filter((item, key) => {
        return item && item.errorCode !== action.payload.errorCode
          && (item.fieldErrors && action.payload.fieldErrors
            && item.fieldErrors[0].size !== action.payload.fieldErrors[0].size
            && item.fieldErrors[0].item !== action.payload.fieldErrors[0].item);
      });
      return {
        ...state,
        errorToWarning: removedErrors,
      };
    case actionsPageData.PAGE_DATA_SET_LOCALE:
      return {
        ...state,
        cartItemsDetails: initialState.cartItemsDetails,
        count: initialState.count,
        form: initialState.form,
        loaded: false,
      };
    case actionsPage.PAGE_UNMOUNT:
      return {
        ...state,
        page: initialState.page,
      };
    case actions.CART_ERROR_CLEAR:
      return {
        ...state,
        error: [],
      };
    default:
      return state;
  }
};
