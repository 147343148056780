export const AUTH_CREATE_SESSION_REQUEST = 'AUTH_CREATE_SESSION_REQUEST';
export type AUTH_CREATE_SESSION_REQUEST = 'AUTH_CREATE_SESSION_REQUEST';
export const AUTH_CREATE_SESSION_SUCCESS = 'AUTH_CREATE_SESSION_SUCCESS';
export type AUTH_CREATE_SESSION_SUCCESS = 'AUTH_CREATE_SESSION_SUCCESS';
export const AUTH_CREATE_SESSION_FAILED = 'AUTH_CREATE_SESSION_FAILED';
export type AUTH_CREATE_SESSION_FAILED = 'AUTH_CREATE_SESSION_FAILED';

export const AUTH_SESSION_SET = 'AUTH_SESSION_SET';
export type AUTH_SESSION_SET = 'AUTH_SESSION_SET';

export const AUTH_GA_ID_SET = 'AUTH_GA_ID_SET';
export type AUTH_GA_ID_SET = 'AUTH_GA_ID_SET';

export const AUTH_TOKEN_SET = 'AUTH_TOKEN_SET';
export type AUTH_TOKEN_SET = 'AUTH_TOKEN_SET';

export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export type AUTH_LOGOUT = 'AUTH_LOGOUT';

export const AUTH_TOKEN_EXPIRED = 'AUTH_TOKEN_EXPIRED';
export type AUTH_TOKEN_EXPIRED = 'AUTH_TOKEN_EXPIRED';

export const CUSTOMER_GET_PROFILE_REQUEST = 'CUSTOMER_GET_PROFILE_REQUEST';
export type CUSTOMER_GET_PROFILE_REQUEST = 'CUSTOMER_GET_PROFILE_REQUEST';
export const CUSTOMER_GET_PROFILE_SUCCESS = 'CUSTOMER_GET_PROFILE_SUCCESS';
export type CUSTOMER_GET_PROFILE_SUCCESS = 'CUSTOMER_GET_PROFILE_SUCCESS';
export const CUSTOMER_GET_PROFILE_FAILED = 'CUSTOMER_GET_PROFILE_FAILED';
export type CUSTOMER_GET_PROFILE_FAILED = 'CUSTOMER_GET_PROFILE_FAILED';

export const ACCOUNT_LOGIN_REQUEST = 'ACCOUNT_LOGIN_REQUEST';
export type ACCOUNT_LOGIN_REQUEST = 'ACCOUNT_LOGIN_REQUEST';
export const ACCOUNT_LOGIN_SUCCESS = 'ACCOUNT_LOGIN_SUCCESS';
export type ACCOUNT_LOGIN_SUCCESS = 'ACCOUNT_LOGIN_SUCCESS';
export const ACCOUNT_LOGIN_FAILED = 'ACCOUNT_LOGIN_FAILED';
export type ACCOUNT_LOGIN_FAILED = 'ACCOUNT_LOGIN_FAILED';

export const ACCOUNT_TOKEN_REFRESH_REQUEST = 'ACCOUNT_TOKEN_REFRESH_REQUEST';
export type ACCOUNT_TOKEN_REFRESH_REQUEST = 'ACCOUNT_TOKEN_REFRESH_REQUEST';
export const ACCOUNT_TOKEN_REFRESH_SUCCESS = 'ACCOUNT_TOKEN_REFRESH_SUCCESS';
export type ACCOUNT_TOKEN_REFRESH_SUCCESS = 'ACCOUNT_TOKEN_REFRESH_SUCCESS';
export const ACCOUNT_TOKEN_REFRESH_FAILED = 'ACCOUNT_TOKEN_REFRESH_FAILED';
export type ACCOUNT_TOKEN_REFRESH_FAILED = 'ACCOUNT_TOKEN_REFRESH_FAILED';

export const ACCOUNT_SIGNUP_REQUEST = 'ACCOUNT_SIGNUP_REQUEST';
export type ACCOUNT_SIGNUP_REQUEST = 'ACCOUNT_SIGNUP_REQUEST';
export const ACCOUNT_SIGNUP_SUCCESS = 'ACCOUNT_SIGNUP_SUCCESS';
export type ACCOUNT_SIGNUP_SUCCESS = 'ACCOUNT_SIGNUP_SUCCESS';
export const ACCOUNT_SIGNUP_FAILED = 'ACCOUNT_SIGNUP_FAILED';
export type ACCOUNT_SIGNUP_FAILED = 'ACCOUNT_SIGNUP_FAILED';

export const ACCOUNT_SIGNUP_FACEBOOK_REQUEST = 'ACCOUNT_SIGNUP_FACEBOOK_REQUEST';
export type ACCOUNT_SIGNUP_FACEBOOK_REQUEST = 'ACCOUNT_SIGNUP_FACEBOOK_REQUEST';
export const ACCOUNT_SIGNUP_FACEBOOK_SUCCESS = 'ACCOUNT_SIGNUP_FACEBOOK_SUCCESS';
export type ACCOUNT_SIGNUP_FACEBOOK_SUCCESS = 'ACCOUNT_SIGNUP_FACEBOOK_SUCCESS';
export const ACCOUNT_SIGNUP_FACEBOOK_FAILED = 'ACCOUNT_SIGNUP_FACEBOOK_FAILED';
export type ACCOUNT_SIGNUP_FACEBOOK_FAILED = 'ACCOUNT_SIGNUP_FACEBOOK_FAILED';

export const ACCOUNT_SIGNUP_GOOGLE_REQUEST = 'ACCOUNT_SIGNUP_GOOGLE_REQUEST';
export type ACCOUNT_SIGNUP_GOOGLE_REQUEST = 'ACCOUNT_SIGNUP_GOOGLE_REQUEST';
export const ACCOUNT_SIGNUP_GOOGLE_SUCCESS = 'ACCOUNT_SIGNUP_GOOGLE_SUCCESS';
export type ACCOUNT_SIGNUP_GOOGLE_SUCCESS = 'ACCOUNT_SIGNUP_GOOGLE_SUCCESS';
export const ACCOUNT_SIGNUP_GOOGLE_FAILED = 'ACCOUNT_SIGNUP_GOOGLE_FAILED';
export type ACCOUNT_SIGNUP_GOOGLE_FAILED = 'ACCOUNT_SIGNUP_GOOGLE_FAILED';

export const ACCOUNT_FORGOT_REQUEST = 'ACCOUNT_FORGOT_REQUEST';
export type ACCOUNT_FORGOT_REQUEST = 'ACCOUNT_FORGOT_REQUEST';
export const ACCOUNT_FORGOT_SUCCESS = 'ACCOUNT_FORGOT_SUCCESS';
export type ACCOUNT_FORGOT_SUCCESS = 'ACCOUNT_FORGOT_SUCCESS';
export const ACCOUNT_FORGOT_FAILED = 'ACCOUNT_FORGOT_FAILED';
export type ACCOUNT_FORGOT_FAILED = 'ACCOUNT_FORGOT_FAILED';

export const ACCOUNT_RESET_PASSWORD_REQUEST = 'ACCOUNT_RESET_PASSWORD_REQUEST';
export type ACCOUNT_RESET_PASSWORD_REQUEST = 'ACCOUNT_RESET_PASSWORD_REQUEST';
export const ACCOUNT_RESET_PASSWORD_SUCCESS = 'ACCOUNT_RESET_PASSWORD_SUCCESS';
export type ACCOUNT_RESET_PASSWORD_SUCCESS = 'ACCOUNT_RESET_PASSWORD_SUCCESS';
export const ACCOUNT_RESET_PASSWORD_FAILED = 'ACCOUNT_RESET_PASSWORD_FAILED';
export type ACCOUNT_RESET_PASSWORD_FAILED = 'ACCOUNT_RESET_PASSWORD_FAILED';

export const ACCOUNT_RESET_PASSWORD_CHECK_REQUEST = 'ACCOUNT_RESET_PASSWORD_CHECK_REQUEST';
export type ACCOUNT_RESET_PASSWORD_CHECK_REQUEST = 'ACCOUNT_RESET_PASSWORD_CHECK_REQUEST';
export const ACCOUNT_RESET_PASSWORD_CHECK_SUCCESS = 'ACCOUNT_RESET_PASSWORD_CHECK_SUCCESS';
export type ACCOUNT_RESET_PASSWORD_CHECK_SUCCESS = 'ACCOUNT_RESET_PASSWORD_CHECK_SUCCESS';
export const ACCOUNT_RESET_PASSWORD_CHECK_FAILED = 'ACCOUNT_RESET_PASSWORD_CHECK_FAILED';
export type ACCOUNT_RESET_PASSWORD_CHECK_FAILED = 'ACCOUNT_RESET_PASSWORD_CHECK_FAILED';

export const LOYALTY_PROGRAM_REGISTER_REQUEST = 'LOYALTY_PROGRAM_REGISTER_REQUEST';
export type LOYALTY_PROGRAM_REGISTER_REQUEST = 'LOYALTY_PROGRAM_REGISTER_REQUEST';
export const LOYALTY_PROGRAM_REGISTER_SUCCESS = 'LOYALTY_PROGRAM_REGISTER_SUCCESS';
export type LOYALTY_PROGRAM_REGISTER_SUCCESS = 'LOYALTY_PROGRAM_REGISTER_SUCCESS';
export const LOYALTY_PROGRAM_REGISTER_FAILED = 'LOYALTY_PROGRAM_REGISTER_FAILED';
export type LOYALTY_PROGRAM_REGISTER_FAILED = 'LOYALTY_PROGRAM_REGISTER_FAILED';

export const ACCOUNT_RESEND_CONFIRMATION_REQUEST = 'ACCOUNT_RESEND_CONFIRMATION_REQUEST';
export type ACCOUNT_RESEND_CONFIRMATION_REQUEST = 'ACCOUNT_RESEND_CONFIRMATION_REQUEST';
export const ACCOUNT_RESEND_CONFIRMATION_SUCCESS = 'ACCOUNT_RESEND_CONFIRMATION_SUCCESS';
export type ACCOUNT_RESEND_CONFIRMATION_SUCCESS = 'ACCOUNT_RESEND_CONFIRMATION_SUCCESS';
export const ACCOUNT_RESEND_CONFIRMATION_FAILED = 'ACCOUNT_RESEND_CONFIRMATION_FAILED';
export type ACCOUNT_RESEND_CONFIRMATION_FAILED = 'ACCOUNT_RESEND_CONFIRMATION_FAILED';

export const LOTTERY_REGISTER_REQUEST = 'LOTTERY_REGISTER_REQUEST';
export type LOTTERY_REGISTER_REQUEST = 'LOTTERY_REGISTER_REQUEST';
export const LOTTERY_REGISTER_SUCCESS = 'LOTTERY_REGISTER_SUCCESS';
export type LOTTERY_REGISTER_SUCCESS = 'LOTTERY_REGISTER_SUCCESS';
export const LOTTERY_REGISTER_FAILED = 'LOTTERY_REGISTER_FAILED';
export type LOTTERY_REGISTER_FAILED = 'LOTTERY_REGISTER_FAILED';
export const LOTTERY_REGISTER_CLEAR_FIELD_ERROR = 'LOTTERY_REGISTER_CLEAR_FIELD_ERROR';
export type LOTTERY_REGISTER_CLEAR_FIELD_ERROR = 'LOTTERY_REGISTER_CLEAR_FIELD_ERROR';

export const AUTH_SOCIALS_CLIENTS_REQUEST = 'AUTH_SOCIALS_CLIENTS_REQUEST';
export type AUTH_SOCIALS_CLIENTS_REQUEST = 'AUTH_SOCIALS_CLIENTS_REQUEST';
export const AUTH_SOCIALS_CLIENTS_SUCCESS = 'AUTH_SOCIALS_CLIENTS_SUCCESS';
export type AUTH_SOCIALS_CLIENTS_SUCCESS = 'AUTH_SOCIALS_CLIENTS_SUCCESS';
export const AUTH_SOCIALS_CLIENTS_FAILED = 'AUTH_SOCIALS_CLIENTS_FAILED';
export type AUTH_SOCIALS_CLIENTS_FAILED = 'AUTH_SOCIALS_CLIENTS_FAILED';

export const AUTH_PREFILL = 'AUTH_PREFILL';
export type AUTH_PREFILL = 'AUTH_PREFILL';

export const AUTH_CLEAR_SIGNUP_ERRORS = 'AUTH_CLEAR_SIGNUP_ERRORS';
export type AUTH_CLEAR_SIGNUP_ERRORS = 'AUTH_CLEAR_SIGNUP_ERRORS';

export const AUTH_SMART_ID_REQUEST = 'AUTH_SMART_ID_REQUEST';
export type AUTH_SMART_ID_REQUEST = 'AUTH_SMART_ID_REQUEST';
export const AUTH_SMART_ID_SUCCESS = 'AUTH_SMART_ID_SUCCESS';
export type AUTH_SMART_ID_SUCCESS = 'AUTH_SMART_ID_SUCCESS';
export const AUTH_SMART_ID_FAILED = 'AUTH_SMART_ID_FAILED';
export type AUTH_SMART_ID_FAILED = 'AUTH_SMART_ID_FAILED';

export const ATTACH_SMART_ID_REQUEST = 'ATTACH_SMART_ID_REQUEST';
export type ATTACH_SMART_ID_REQUEST = 'ATTACH_SMART_ID_REQUEST';
export const ATTACH_SMART_ID_SUCCESS = 'ATTACH_SMART_ID_SUCCESS';
export type ATTACH_SMART_ID_SUCCESS = 'ATTACH_SMART_ID_SUCCESS';
export const ATTACH_SMART_ID_FAILED = 'ATTACH_SMART_ID_FAILED';
export type ATTACH_SMART_ID_FAILED = 'ATTACH_SMART_ID_FAILED';

export const ATTACH_SMART_ID_FINALIZE_REQUEST = 'ATTACH_SMART_ID_FINALIZE_REQUEST';
export type ATTACH_SMART_ID_FINALIZE_REQUEST = 'ATTACH_SMART_ID_FINALIZE_REQUEST';
export const ATTACH_SMART_ID_FINALIZE_SUCCESS = 'ATTACH_SMART_ID_FINALIZE_SUCCESS';
export type ATTACH_SMART_ID_FINALIZE_SUCCESS = 'ATTACH_SMART_ID_FINALIZE_SUCCESS';
export const ATTACH_SMART_ID_FINALIZE_FAILED = 'ATTACH_SMART_ID_FINALIZE_FAILED';
export type ATTACH_SMART_ID_FINALIZE_FAILED = 'ATTACH_SMART_ID_FINALIZE_FAILED';
