export const ACCOUNT_GET_PRODUCTS_REQUEST = 'ACCOUNT_GET_PRODUCTS_REQUEST';
export type ACCOUNT_GET_PRODUCTS_REQUEST = 'ACCOUNT_GET_PRODUCTS_REQUEST';
export const ACCOUNT_GET_PRODUCTS_SUCCESS = 'ACCOUNT_GET_PRODUCTS_SUCCESS';
export type ACCOUNT_GET_PRODUCTS_SUCCESS = 'ACCOUNT_GET_PRODUCTS_SUCCESS';
export const ACCOUNT_GET_PRODUCTS_FAILED = 'ACCOUNT_GET_PRODUCTS_FAILED';
export type ACCOUNT_GET_PRODUCTS_FAILED = 'ACCOUNT_GET_PRODUCTS_FAILED';

export const ACCOUNT_SAVE_DETAILS_REQUEST = 'ACCOUNT_SAVE_DETAILS_REQUEST';
export type ACCOUNT_SAVE_DETAILS_REQUEST = 'ACCOUNT_SAVE_DETAILS_REQUEST';
export const ACCOUNT_SAVE_DETAILS_SUCCESS = 'ACCOUNT_SAVE_DETAILS_SUCCESS';
export type ACCOUNT_SAVE_DETAILS_SUCCESS = 'ACCOUNT_SAVE_DETAILS_SUCCESS';
export const ACCOUNT_SAVE_DETAILS_FAILED = 'ACCOUNT_SAVE_DETAILS_FAILED';
export type ACCOUNT_SAVE_DETAILS_FAILED = 'ACCOUNT_SAVE_DETAILS_FAILED';

export const ACCOUNT_CLEAR_DETAILS_FIELD_ERROR = 'ACCOUNT_CLEAR_DETAILS_FIELD_ERROR';
export type ACCOUNT_CLEAR_DETAILS_FIELD_ERROR = 'ACCOUNT_CLEAR_DETAILS_FIELD_ERROR';

export const ACCOUNT_GET_PAYMENTS_METHODS_REQUEST = 'ACCOUNT_GET_PAYMENTS_METHODS_REQUEST';
export type ACCOUNT_GET_PAYMENTS_METHODS_REQUEST = 'ACCOUNT_GET_PAYMENTS_METHODS_REQUEST';
export const ACCOUNT_GET_PAYMENTS_METHODS_SUCCESS = 'ACCOUNT_GET_PAYMENTS_METHODS_SUCCESS';
export type ACCOUNT_GET_PAYMENTS_METHODS_SUCCESS = 'ACCOUNT_GET_PAYMENTS_METHODS_SUCCESS';
export const ACCOUNT_GET_PAYMENTS_METHODS_FAILED = 'ACCOUNT_GET_PAYMENTS_METHODS_FAILED';
export type ACCOUNT_GET_PAYMENTS_METHODS_FAILED = 'ACCOUNT_GET_PAYMENTS_METHODS_FAILED';

export const ACCOUNT_DELETE_PAYMENTS_METHODS_REQUEST = 'ACCOUNT_DELETE_PAYMENTS_METHODS_REQUEST';
export type ACCOUNT_DELETE_PAYMENTS_METHODS_REQUEST = 'ACCOUNT_DELETE_PAYMENTS_METHODS_REQUEST';
export const ACCOUNT_DELETE_PAYMENTS_METHODS_SUCCESS = 'ACCOUNT_DELETE_PAYMENTS_METHODS_SUCCESS';
export type ACCOUNT_DELETE_PAYMENTS_METHODS_SUCCESS = 'ACCOUNT_DELETE_PAYMENTS_METHODS_SUCCESS';
export const ACCOUNT_DELETE_PAYMENTS_METHODS_FAILED = 'ACCOUNT_DELETE_PAYMENTS_METHODS_FAILED';
export type ACCOUNT_DELETE_PAYMENTS_METHODS_FAILED = 'ACCOUNT_DELETE_PAYMENTS_METHODS_FAILED';

export const ACCOUNT_GET_DELIVERY_METHODS_REQUEST = 'ACCOUNT_GET_DELIVERY_METHODS_REQUEST';
export type ACCOUNT_GET_DELIVERY_METHODS_REQUEST = 'ACCOUNT_GET_DELIVERY_METHODS_REQUEST';
export const ACCOUNT_GET_DELIVERY_METHODS_SUCCESS = 'ACCOUNT_GET_DELIVERY_METHODS_SUCCESS';
export type ACCOUNT_GET_DELIVERY_METHODS_SUCCESS = 'ACCOUNT_GET_DELIVERY_METHODS_SUCCESS';
export const ACCOUNT_GET_DELIVERY_METHODS_FAILED = 'ACCOUNT_GET_DELIVERY_METHODS_FAILED';
export type ACCOUNT_GET_DELIVERY_METHODS_FAILED = 'ACCOUNT_GET_DELIVERY_METHODS_FAILED';

export const ACCOUNT_GET_DELIVERY_METHOD_DETAILS_REQUEST = 'ACCOUNT_GET_DELIVERY_METHOD_DETAILS_REQUEST';
export type ACCOUNT_GET_DELIVERY_METHOD_DETAILS_REQUEST = 'ACCOUNT_GET_DELIVERY_METHOD_DETAILS_REQUEST';
export const ACCOUNT_GET_DELIVERY_METHOD_DETAILS_SUCCESS = 'ACCOUNT_GET_DELIVERY_METHOD_DETAILS_SUCCESS';
export type ACCOUNT_GET_DELIVERY_METHOD_DETAILS_SUCCESS = 'ACCOUNT_GET_DELIVERY_METHOD_DETAILS_SUCCESS';
export const ACCOUNT_GET_DELIVERY_METHOD_DETAILS_FAILED = 'ACCOUNT_GET_DELIVERY_METHOD_DETAILS_FAILED';
export type ACCOUNT_GET_DELIVERY_METHOD_DETAILS_FAILED = 'ACCOUNT_GET_DELIVERY_METHOD_DETAILS_FAILED';

export const ACCOUNT_SAVE_DELIVERY_METHOD_DETAILS_REQUEST = 'ACCOUNT_SAVE_DELIVERY_METHOD_DETAILS_REQUEST';
export type ACCOUNT_SAVE_DELIVERY_METHOD_DETAILS_REQUEST = 'ACCOUNT_SAVE_DELIVERY_METHOD_DETAILS_REQUEST';
export const ACCOUNT_SAVE_DELIVERY_METHOD_DETAILS_SUCCESS = 'ACCOUNT_SAVE_DELIVERY_METHOD_DETAILS_SUCCESS';
export type ACCOUNT_SAVE_DELIVERY_METHOD_DETAILS_SUCCESS = 'ACCOUNT_SAVE_DELIVERY_METHOD_DETAILS_SUCCESS';
export const ACCOUNT_SAVE_DELIVERY_METHOD_DETAILS_FAILED = 'ACCOUNT_SAVE_DELIVERY_METHOD_DETAILS_FAILED';
export type ACCOUNT_SAVE_DELIVERY_METHOD_DETAILS_FAILED = 'ACCOUNT_SAVE_DELIVERY_METHOD_DETAILS_FAILED';

export const ACCOUNT_DELIVERY_METHOD_CHANGE_PAGE = 'ACCOUNT_DELIVERY_METHOD_CHANGE_PAGE';
export type ACCOUNT_DELIVERY_METHOD_CHANGE_PAGE = 'ACCOUNT_DELIVERY_METHOD_CHANGE_PAGE';

export const ACCOUNT_DELIVERY_METHOD_ADD_METHOD = 'ACCOUNT_DELIVERY_METHOD_ADD_METHOD';
export type ACCOUNT_DELIVERY_METHOD_ADD_METHOD = 'ACCOUNT_DELIVERY_METHOD_ADD_METHOD';

export const ACCOUNT_GET_SHIPMENT_METHODS_REQUEST = 'ACCOUNT_GET_SHIPMENT_METHODS_REQUEST';
export type ACCOUNT_GET_SHIPMENT_METHODS_REQUEST = 'ACCOUNT_GET_SHIPMENT_METHODS_REQUEST';
export const ACCOUNT_GET_SHIPMENT_METHODS_SUCCESS = 'ACCOUNT_GET_SHIPMENT_METHODS_SUCCESS';
export type ACCOUNT_GET_SHIPMENT_METHODS_SUCCESS = 'ACCOUNT_GET_SHIPMENT_METHODS_SUCCESS';
export const ACCOUNT_GET_SHIPMENT_METHODS_FAILED = 'ACCOUNT_GET_SHIPMENT_METHODS_FAILED';
export type ACCOUNT_GET_SHIPMENT_METHODS_FAILED = 'ACCOUNT_GET_SHIPMENT_METHODS_FAILED';

export const ACCOUNT_GET_SHIPMENT_LOCATIONS_REQUEST = 'ACCOUNT_GET_SHIPMENT_LOCATIONS_REQUEST';
export type ACCOUNT_GET_SHIPMENT_LOCATIONS_REQUEST = 'ACCOUNT_GET_SHIPMENT_LOCATIONS_REQUEST';
export const ACCOUNT_GET_SHIPMENT_LOCATIONS_SUCCESS = 'ACCOUNT_GET_SHIPMENT_LOCATIONS_SUCCESS';
export type ACCOUNT_GET_SHIPMENT_LOCATIONS_SUCCESS = 'ACCOUNT_GET_SHIPMENT_LOCATIONS_SUCCESS';
export const ACCOUNT_GET_SHIPMENT_LOCATIONS_FAILED = 'ACCOUNT_GET_SHIPMENT_LOCATIONS_FAILED';
export type ACCOUNT_GET_SHIPMENT_LOCATIONS_FAILED = 'ACCOUNT_GET_SHIPMENT_LOCATIONS_FAILED';

export const ACCOUNT_ADD_DELIVERY_METHOD_REQUEST = 'ACCOUNT_ADD_DELIVERY_METHOD_REQUEST';
export type ACCOUNT_ADD_DELIVERY_METHOD_REQUEST = 'ACCOUNT_ADD_DELIVERY_METHOD_REQUEST';
export const ACCOUNT_ADD_DELIVERY_METHOD_SUCCESS = 'ACCOUNT_ADD_DELIVERY_METHOD_SUCCESS';
export type ACCOUNT_ADD_DELIVERY_METHOD_SUCCESS = 'ACCOUNT_ADD_DELIVERY_METHOD_SUCCESS';
export const ACCOUNT_ADD_DELIVERY_METHOD_FAILED = 'ACCOUNT_ADD_DELIVERY_METHOD_FAILED';
export type ACCOUNT_ADD_DELIVERY_METHOD_FAILED = 'ACCOUNT_ADD_DELIVERY_METHOD_FAILED';

export const ACCOUNT_DELETE_DELIVERY_METHOD_REQUEST = 'ACCOUNT_DELETE_DELIVERY_METHOD_REQUEST';
export type ACCOUNT_DELETE_DELIVERY_METHOD_REQUEST = 'ACCOUNT_DELETE_DELIVERY_METHOD_REQUEST';
export const ACCOUNT_DELETE_DELIVERY_METHOD_SUCCESS = 'ACCOUNT_DELETE_DELIVERY_METHOD_SUCCESS';
export type ACCOUNT_DELETE_DELIVERY_METHOD_SUCCESS = 'ACCOUNT_DELETE_DELIVERY_METHOD_SUCCESS';
export const ACCOUNT_DELETED_DELIVERY_METHOD_FAILED = 'ACCOUNT_DELETED_DELIVERY_METHOD_FAILED';
export type ACCOUNT_DELETE_DELIVERY_METHOD_FAILED = 'ACCOUNT_DELETED_DELIVERY_METHOD_FAILED';

export const ACCOUNT_DELIVERY_METHOD_FORM_UNMOUNT = 'ACCOUNT_DELIVERY_METHOD_FORM_UNMOUNT';
export type ACCOUNT_DELIVERY_METHOD_FORM_UNMOUNT = 'ACCOUNT_DELIVERY_METHOD_FORM_UNMOUNT';

export const ACCOUNT_GET_ORDERS_REQUEST = 'ACCOUNT_GET_ORDERS_REQUEST';
export type ACCOUNT_GET_ORDERS_REQUEST = 'ACCOUNT_GET_ORDERS_REQUEST';
export const ACCOUNT_GET_ORDERS_NEXT_REQUEST = 'ACCOUNT_GET_ORDERS_NEXT_REQUEST';
export type ACCOUNT_GET_ORDERS_NEXT_REQUEST = 'ACCOUNT_GET_ORDERS_NEXT_REQUEST';
export const ACCOUNT_GET_ORDERS_SUCCESS = 'ACCOUNT_GET_ORDERS_SUCCESS';
export type ACCOUNT_GET_ORDERS_SUCCESS = 'ACCOUNT_GET_ORDERS_SUCCESS';
export const ACCOUNT_GET_ORDERS_FAILED = 'ACCOUNT_GET_ORDERS_FAILED';
export type ACCOUNT_GET_ORDERS_FAILED = 'ACCOUNT_GET_ORDERS_FAILED';

export const ACCOUNT_GET_ORDERS_RETAIL_REQUEST = 'ACCOUNT_GET_ORDERS_RETAIL_REQUEST';
export type ACCOUNT_GET_ORDERS_RETAIL_REQUEST = 'ACCOUNT_GET_ORDERS_RETAIL_REQUEST';
export const ACCOUNT_GET_ORDERS_RETAIL_NEXT_REQUEST = 'ACCOUNT_GET_ORDERS_RETAIL_NEXT_REQUEST';
export type ACCOUNT_GET_ORDERS_RETAIL_NEXT_REQUEST = 'ACCOUNT_GET_ORDERS_RETAIL_NEXT_REQUEST';
export const ACCOUNT_GET_ORDERS_RETAIL_SUCCESS = 'ACCOUNT_GET_ORDERS_RETAIL_SUCCESS';
export type ACCOUNT_GET_ORDERS_RETAIL_SUCCESS = 'ACCOUNT_GET_ORDERS_RETAIL_SUCCESS';
export const ACCOUNT_GET_ORDERS_RETAIL_FAILED = 'ACCOUNT_GET_ORDERS_RETAIL_FAILED';
export type ACCOUNT_GET_ORDERS_RETAIL_FAILED = 'ACCOUNT_GET_ORDERS_RETAIL_FAILED';

export const ACCOUNT_GET_ORDER_BY_TRANSACTION_REQUEST = 'ACCOUNT_GET_ORDER_BY_TRANSACTION_REQUEST';
export type ACCOUNT_GET_ORDER_BY_TRANSACTION_REQUEST = 'ACCOUNT_GET_ORDER_BY_TRANSACTION_REQUEST';
export const ACCOUNT_GET_ORDER_BY_TRANSACTION_SUCCESS = 'ACCOUNT_GET_ORDER_BY_TRANSACTION_SUCCESS';
export type ACCOUNT_GET_ORDER_BY_TRANSACTION_SUCCESS = 'ACCOUNT_GET_ORDER_BY_TRANSACTION_SUCCESS';
export const ACCOUNT_GET_ORDER_BY_TRANSACTION_FAILED = 'ACCOUNT_GET_ORDER_BY_TRANSACTION_FAILED';
export type ACCOUNT_GET_ORDER_BY_TRANSACTION_FAILED = 'ACCOUNT_GET_ORDER_BY_TRANSACTION_FAILED';

export const ACCOUNT_GET_ORDER_DETAILS_REQUEST = 'ACCOUNT_GET_ORDER_DETAILS_REQUEST';
export type ACCOUNT_GET_ORDER_DETAILS_REQUEST = 'ACCOUNT_GET_ORDER_DETAILS_REQUEST';
export const ACCOUNT_GET_ORDER_DETAILS_SUCCESS = 'ACCOUNT_GET_ORDER_DETAILS_SUCCESS';
export type ACCOUNT_GET_ORDER_DETAILS_SUCCESS = 'ACCOUNT_GET_ORDER_DETAILS_SUCCESS';
export const ACCOUNT_GET_ORDER_DETAILS_FAILED = 'ACCOUNT_GET_ORDER_DETAILS_FAILED';
export type ACCOUNT_GET_ORDER_DETAILS_FAILED = 'ACCOUNT_GET_ORDER_DETAILS_FAILED';

export const ACCOUNT_GET_ORDER_RETAIL_DETAILS_REQUEST = 'ACCOUNT_GET_ORDER_RETAIL_DETAILS_REQUEST';
export type ACCOUNT_GET_ORDER_RETAIL_DETAILS_REQUEST = 'ACCOUNT_GET_ORDER_RETAIL_DETAILS_REQUEST';
export const ACCOUNT_GET_ORDER_RETAIL_DETAILS_SUCCESS = 'ACCOUNT_GET_ORDER_RETAIL_DETAILS_SUCCESS';
export type ACCOUNT_GET_ORDER_RETAIL_DETAILS_SUCCESS = 'ACCOUNT_GET_ORDER_RETAIL_DETAILS_SUCCESS';
export const ACCOUNT_GET_ORDER_RETAIL_DETAILS_FAILED = 'ACCOUNT_GET_ORDER_RETAIL_DETAILS_FAILED';
export type ACCOUNT_GET_ORDER_RETAIL_DETAILS_FAILED = 'ACCOUNT_GET_ORDER_RETAIL_DETAILS_FAILED';

export const ACCOUNT_LINK_SOCIALS_REQUEST = 'ACCOUNT_LINK_SOCIALS_REQUEST';
export type ACCOUNT_LINK_SOCIALS_REQUEST = 'ACCOUNT_LINK_SOCIALS_REQUEST';
export const ACCOUNT_LINK_SOCIALS_SUCCESS = 'ACCOUNT_LINK_SOCIALS_SUCCESS';
export type ACCOUNT_LINK_SOCIALS_SUCCESS = 'ACCOUNT_LINK_SOCIALS_SUCCESS';
export const ACCOUNT_LINK_SOCIALS_FAILED = 'ACCOUNT_LINK_SOCIALS_FAILED';
export type ACCOUNT_LINK_SOCIALS_FAILED = 'ACCOUNT_LINK_SOCIALS_FAILED';

export const ACCOUNT_UNLINK_SOCIALS_REQUEST = 'ACCOUNT_UNLINK_SOCIALS_REQUEST';
export type ACCOUNT_UNLINK_SOCIALS_REQUEST = 'ACCOUNT_UNLINK_SOCIALS_REQUEST';
export const ACCOUNT_UNLINK_SOCIALS_SUCCESS = 'ACCOUNT_UNLINK_SOCIALS_SUCCESS';
export type ACCOUNT_UNLINK_SOCIALS_SUCCESS = 'ACCOUNT_UNLINK_SOCIALS_SUCCESS';
export const ACCOUNT_UNLINK_SOCIALS_FAILED = 'ACCOUNT_UNLINK_SOCIALS_FAILED';
export type ACCOUNT_UNLINK_SOCIALS_FAILED = 'ACCOUNT_UNLINK_SOCIALS_FAILED';

export const ACCOUNT_LINK_SOCIALS_LONGTERM_REQUEST = 'ACCOUNT_LINK_SOCIALS_LONGTERM_REQUEST';
export type ACCOUNT_LINK_SOCIALS_LONGTERM_REQUEST = 'ACCOUNT_LINK_SOCIALS_LONGTERM_REQUEST';
export const ACCOUNT_LINK_SOCIALS_LONGTERM_SUCCESS = 'ACCOUNT_LINK_SOCIALS_LONGTERM_SUCCESS';
export type ACCOUNT_LINK_SOCIALS_LONGTERM_SUCCESS = 'ACCOUNT_LINK_SOCIALS_LONGTERM_SUCCESS';
export const ACCOUNT_LINK_SOCIALS_LONGTERM_FAILED = 'ACCOUNT_LINK_SOCIALS_LONGTERM_FAILED';
export type ACCOUNT_LINK_SOCIALS_LONGTERM_FAILED = 'ACCOUNT_LINK_SOCIALS_LONGTERM_FAILED';

export const ACCOUNT_CHANGE_PASSWORD_REQUEST = 'ACCOUNT_CHANGE_PASSWORD_REQUEST';
export type ACCOUNT_CHANGE_PASSWORD_REQUEST = 'ACCOUNT_CHANGE_PASSWORD_REQUEST';
export const ACCOUNT_CHANGE_PASSWORD_SUCCESS = 'ACCOUNT_CHANGE_PASSWORD_SUCCESS';
export type ACCOUNT_CHANGE_PASSWORD_SUCCESS = 'ACCOUNT_CHANGE_PASSWORD_SUCCESS';
export const ACCOUNT_CHANGE_PASSWORD_FAILED = 'ACCOUNT_CHANGE_PASSWORD_FAILED';
export type ACCOUNT_CHANGE_PASSWORD_FAILED = 'ACCOUNT_CHANGE_PASSWORD_FAILED';

export const ACCOUNT_GET_EMAIL_TOKEN_REQUEST = 'ACCOUNT_GET_EMAIL_TOKEN_REQUEST';
export type ACCOUNT_GET_EMAIL_TOKEN_REQUEST = 'ACCOUNT_GET_EMAIL_TOKEN_REQUEST';
export const ACCOUNT_GET_EMAIL_TOKEN_SUCCESS = 'ACCOUNT_GET_EMAIL_TOKEN_SUCCESS';
export type ACCOUNT_GET_EMAIL_TOKEN_SUCCESS = 'ACCOUNT_GET_EMAIL_TOKEN_SUCCESS';
export const ACCOUNT_GET_EMAIL_TOKEN_FAILED = 'ACCOUNT_GET_EMAIL_TOKEN_FAILED';
export type ACCOUNT_GET_EMAIL_TOKEN_FAILED = 'ACCOUNT_GET_EMAIL_TOKEN_FAILED';

export const ACCOUNT_NEW_EMAIL_TOKEN_REQUEST = 'ACCOUNT_NEW_EMAIL_TOKEN_REQUEST';
export type ACCOUNT_NEW_EMAIL_TOKEN_REQUEST = 'ACCOUNT_NEW_EMAIL_TOKEN_REQUEST';
export const ACCOUNT_NEW_EMAIL_TOKEN_SUCCESS = 'ACCOUNT_NEW_EMAIL_TOKEN_SUCCESS';
export type ACCOUNT_NEW_EMAIL_TOKEN_SUCCESS = 'ACCOUNT_NEW_EMAIL_TOKEN_SUCCESS';
export const ACCOUNT_NEW_EMAIL_TOKEN_FAILED = 'ACCOUNT_NEW_EMAIL_TOKEN_FAILED';
export type ACCOUNT_NEW_EMAIL_TOKEN_FAILED = 'ACCOUNT_NEW_EMAIL_TOKEN_FAILED';

export const ACCOUNT_FINALIZE_EMAIL_TOKEN_REQUEST = 'ACCOUNT_FINALIZE_EMAIL_TOKEN_REQUEST';
export type ACCOUNT_FINALIZE_EMAIL_TOKEN_REQUEST = 'ACCOUNT_FINALIZE_EMAIL_TOKEN_REQUEST';
export const ACCOUNT_FINALIZE_EMAIL_TOKEN_SUCCESS = 'ACCOUNT_FINALIZE_EMAIL_TOKEN_SUCCESS';
export type ACCOUNT_FINALIZE_EMAIL_TOKEN_SUCCESS = 'ACCOUNT_FINALIZE_EMAIL_TOKEN_SUCCESS';
export const ACCOUNT_FINALIZE_EMAIL_TOKEN_FAILED = 'ACCOUNT_FINALIZE_EMAIL_TOKEN_FAILED';
export type ACCOUNT_FINALIZE_EMAIL_TOKEN_FAILED = 'ACCOUNT_FINALIZE_EMAIL_TOKEN_FAILED';

export const ACCOUNT_NOTIFICATIONS_SETTINGS_SAVE_REQUEST = 'ACCOUNT_NOTIFICATIONS_SETTINGS_SAVE_REQUEST';
export type ACCOUNT_NOTIFICATIONS_SETTINGS_SAVE_REQUEST = 'ACCOUNT_NOTIFICATIONS_SETTINGS_SAVE_REQUEST';
export const ACCOUNT_NOTIFICATIONS_SETTINGS_SAVE_SUCCESS = 'ACCOUNT_NOTIFICATIONS_SETTINGS_SAVE_SUCCESS';
export type ACCOUNT_NOTIFICATIONS_SETTINGS_SAVE_SUCCESS = 'ACCOUNT_NOTIFICATIONS_SETTINGS_SAVE_SUCCESS';
export const ACCOUNT_NOTIFICATIONS_SETTINGS_SAVE_FAILED = 'ACCOUNT_NOTIFICATIONS_SETTINGS_SAVE_FAILED';
export type ACCOUNT_NOTIFICATIONS_SETTINGS_SAVE_FAILED = 'ACCOUNT_NOTIFICATIONS_SETTINGS_SAVE_FAILED';

export const ACCOUNT_GET_ORDER_TRACKING_REQUEST = 'ACCOUNT_GET_ORDER_TRACKING_REQUEST';
export type ACCOUNT_GET_ORDER_TRACKING_REQUEST = 'ACCOUNT_GET_ORDER_TRACKING_REQUEST';
export const ACCOUNT_GET_ORDER_TRACKING_SUCCESS = 'ACCOUNT_GET_ORDER_TRACKING_SUCCESS';
export type ACCOUNT_GET_ORDER_TRACKING_SUCCESS = 'ACCOUNT_GET_ORDER_TRACKING_SUCCESS';
export const ACCOUNT_GET_ORDER_TRACKING_FAILED = 'ACCOUNT_GET_ORDER_TRACKING_FAILED';
export type ACCOUNT_GET_ORDER_TRACKING_FAILED = 'ACCOUNT_GET_ORDER_TRACKING_FAILED';

export const ACCOUNT_DELETE_REQUEST = 'ACCOUNT_DELETE_REQUEST';
export type ACCOUNT_DELETE_REQUEST = 'ACCOUNT_DELETE_REQUEST';
export const ACCOUNT_DELETE_SUCCESS = 'ACCOUNT_DELETE_SUCCESS';
export type ACCOUNT_DELETE_SUCCESS = 'ACCOUNT_DELETE_SUCCESS';
export const ACCOUNT_DELETE_FAILED = 'ACCOUNT_DELETE_FAILED';
export type ACCOUNT_DELETE_FAILED = 'ACCOUNT_DELETE_FAILED';

export const ACCOUNT_EMAIL_CHANGE_CONFIRM_REQUEST = 'ACCOUNT_EMAIL_CHANGE_CONFIRM_REQUEST';
export type ACCOUNT_EMAIL_CHANGE_CONFIRM_REQUEST = 'ACCOUNT_EMAIL_CHANGE_CONFIRM_REQUEST';
export const ACCOUNT_EMAIL_CHANGE_CONFIRM_SUCCESS = 'ACCOUNT_EMAIL_CHANGE_CONFIRM_SUCCESS';
export type ACCOUNT_EMAIL_CHANGE_CONFIRM_SUCCESS = 'ACCOUNT_EMAIL_CHANGE_CONFIRM_SUCCESS';
export const ACCOUNT_EMAIL_CHANGE_CONFIRM_FAILED = 'ACCOUNT_EMAIL_CHANGE_CONFIRM_FAILED';
export type ACCOUNT_EMAIL_CHANGE_CONFIRM_FAILED = 'ACCOUNT_EMAIL_CHANGE_CONFIRM_FAILED';

export const ACCOUNT_CLOSE_ORDER_DETAILS = 'ACCOUNT_CLOSE_ORDER_DETAILS';
export type ACCOUNT_CLOSE_ORDER_DETAILS = 'ACCOUNT_CLOSE_ORDER_DETAILS';

export const ACCOUNT_SET_OSESIGNAL_REQUEST = 'ACCOUNT_SET_OSESIGNAL_REQUEST';
export type ACCOUNT_SET_OSESIGNAL_REQUEST = 'ACCOUNT_SET_OSESIGNAL_REQUEST';
export const ACCOUNT_SET_OSESIGNAL_SUCCESS = 'ACCOUNT_SET_OSESIGNAL_SUCCESS';
export type ACCOUNT_SET_OSESIGNAL_SUCCESS = 'ACCOUNT_SET_OSESIGNAL_SUCCESS';
export const ACCOUNT_SET_OSESIGNAL_FAILED = 'ACCOUNT_SET_OSESIGNAL_FAILED';
export type ACCOUNT_SET_OSESIGNAL_FAILED = 'ACCOUNT_SET_OSESIGNAL_FAILED';
