import { Reducer } from 'redux';

import { IAccountActions } from '@interfaces/account/actions';
import { ECustomerSmartIdFinalizes } from '@interfaces/account/reducer';
import { IAuthActions } from '@interfaces/auth/actions';
import { IAuthReducer } from '@interfaces/auth/reducer';
import { IPageActions } from '@interfaces/page/actions';
import { initialState } from '@reducers/auth/initState';
import { setToken } from '@utils/token';
import * as accountActions from '../../constants/actions/account';
import * as actions from '../../constants/actions/auth';
import * as pageActions from '../../constants/actions/page';

export const authReducer: Reducer<IAuthReducer, IAuthActions | IPageActions | IAccountActions> = (state = initialState, action) => {
  switch (action.type) {
    case actions.AUTH_CREATE_SESSION_SUCCESS:
      return { ...state, sessionId: action.payload?.sessionId };
    case actions.AUTH_CREATE_SESSION_FAILED:
      return { ...state, error: { ...state.error, sessionId: action.payload } };
    case actions.AUTH_SESSION_SET:
      return { ...state, sessionId: action.payload };
    case actions.AUTH_GA_ID_SET:
      return { ...state, gaId: action.payload };
    case actions.AUTH_TOKEN_SET:
      return { ...state, token: action.payload };
    case actions.ACCOUNT_TOKEN_REFRESH_REQUEST:
      return {
        ...state,
        error: { ...state.error, login: null },
        loading: { ...state.loading, login: true },
        result: { ...initialState.result },
      };
    case actions.ACCOUNT_LOGIN_REQUEST:
      return {
        ...state,
        error: { ...state.error, login: null },
        loading: { ...state.loading, login: true },
        loginData: action.requestParams,
        result: { ...initialState.result },
      };
    case actions.ACCOUNT_TOKEN_REFRESH_SUCCESS:
    case actions.ACCOUNT_LOGIN_SUCCESS:
      setToken(JSON.stringify(action.payload), null);
      return { ...state, loading: { ...state.loading, login: false }, loginData: {}, token: action.payload };
    case accountActions.ACCOUNT_FINALIZE_EMAIL_TOKEN_SUCCESS:
      const token = action.payload?.data?.attributes?.token || null;
      if (token) {
        setToken(JSON.stringify(token), null);
        return { ...state, loading: { ...state.loading, login: false }, loginData: {}, token };
      }
      return state;
    case actions.ACCOUNT_TOKEN_REFRESH_FAILED:
      return { ...state, loading: { ...state.loading, login: false } };
    case actions.AUTH_TOKEN_EXPIRED:
      return { ...state, isAuthorized: false, user: null };
    case actions.AUTH_LOGOUT:
      return { ...state, isAuthorized: false, token: null, user: null };
    case actions.ACCOUNT_LOGIN_FAILED:
      if (action.payload?.error_description === 'e.facebook.canCreate') {
        return { ...state };
      }
      return {
        ...state,
        error: { ...state.error, login: action.payload?.error_description || action.payload?.error || '' },
        loading: { ...state.loading, login: false },
      };
    case actions.CUSTOMER_GET_PROFILE_SUCCESS:
      return { ...state, isAuthorized: true, user: action.payload };
    case actions.ACCOUNT_SIGNUP_REQUEST:
      return {
        ...state,
        error: { ...state.error, signUp: [] },
        loading: { ...state.loading, signUp: true },
        result: { ...initialState.result },
      };
    case actions.ACCOUNT_SIGNUP_FACEBOOK_REQUEST:
      return {
        ...state,
        error: { ...state.error, signUp: [], signUpFacebook: [] },
        loading: { ...state.loading, signUpFacebook: true },
        result: { ...initialState.result },
      };
    case actions.ACCOUNT_SIGNUP_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, signUp: false },
        signUpPrefill: { ...initialState.signUpPrefill },
      };
    case actions.ACCOUNT_SIGNUP_FACEBOOK_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, signUpFacebook: false },
      };
    case actions.ACCOUNT_SIGNUP_FAILED:
      return {
        ...state,
        error: { ...state.error, signUp: action.payload?.errors || [] },
        loading: { ...state.loading, signUp: false },
      };
    case actions.ACCOUNT_SIGNUP_FACEBOOK_FAILED:
      return {
        ...state,
        error: { ...state.error, signUpFacebook: action.payload?.errors || [] },
        loading: { ...state.loading, signUpFacebook: false },
      };
    case actions.ACCOUNT_FORGOT_REQUEST:
      return {
        ...state,
        error: { ...state.error, forgot: [] },
        loading: { ...state.loading, forgot: true },
        result: { ...initialState.result },
      };
    case actions.ACCOUNT_FORGOT_SUCCESS:
      return { ...state, loading: { ...state.loading, forgot: false } };
    case actions.ACCOUNT_FORGOT_FAILED:
      return {
        ...state,
        error: { ...state.error, forgot: action.payload?.errors || [] },
        loading: { ...state.loading, forgot: false },
      };
    case actions.ACCOUNT_RESET_PASSWORD_REQUEST:
      return {
        ...state,
        error: { ...state.error, resetPassword: [] },
        loading: { ...state.loading, resetPassword: true },
        result: { ...initialState.result },
      };
    case actions.ACCOUNT_RESET_PASSWORD_SUCCESS:
      return { ...state, loading: { ...state.loading, resetPassword: false } };
    case actions.ACCOUNT_RESET_PASSWORD_FAILED:
      return {
        ...state,
        error: { ...state.error, resetPassword: action.payload?.errors || [] },
        loading: { ...state.loading, resetPassword: false },
      };
    case actions.ACCOUNT_RESEND_CONFIRMATION_REQUEST:
      return {
        ...state,
        error: { ...state.error, resendCode: [] },
        loading: { ...state.loading, resendCode: true },
        result: { ...state.result, resendCode: false },
      };
    case actions.ACCOUNT_RESEND_CONFIRMATION_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, resendCode: false },
        loginData: {},
        result: { ...state.result, resendCode: true },
      };
    case actions.ACCOUNT_RESEND_CONFIRMATION_FAILED:
      return {
        ...state,
        error: { ...state.error, resendCode: action.payload?.errors || [] },
        loading: { ...state.loading, resendCode: false },
      };
    case pageActions.PAGE_SIDEMENU_STATE:
    case pageActions.PAGE_SIDEMENU_PAGE:
      return {
        ...state,
        error: { ...state.error, login: null, resendCode: [], signUp: [] },
        result: { ...initialState.result },
        smartId: { ...initialState.smartId },
      };

    case actions.ACCOUNT_SIGNUP_GOOGLE_REQUEST:
      return {
        ...state,
        error: { ...state.error, signUp: [], signUpGoogle: [] },
        loading: { ...state.loading, signUpGoogle: true },
        result: { ...initialState.result },
      };
    case actions.ACCOUNT_SIGNUP_GOOGLE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, signUpGoogle: false },
      };
    case actions.ACCOUNT_SIGNUP_GOOGLE_FAILED:
      return {
        ...state,
        error: { ...state.error, signUpGoogle: action.payload?.errors || [] },
        loading: { ...state.loading, signUpGoogle: false },
      };
    case actions.AUTH_SOCIALS_CLIENTS_SUCCESS:
      return {
        ...state,
        socialApps: action.payload,
      };
    case actions.AUTH_PREFILL:
      return {
        ...state,
        result: { ...state.result, attachSmartId: false },
        signUpPrefill: action.payload,
      };
    case actions.AUTH_SMART_ID_REQUEST:
      return {
        ...state,
        error: { ...state.error, login: initialState.error.login },
        loading: { ...state.loading, login: true },
      };
    case actions.AUTH_SMART_ID_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, login: false },
        smartId: {
          ...state.smartId, ...action.payload, step: 1,
        },
      };
    case actions.AUTH_SMART_ID_FAILED:
      return {
        ...state,
        error: { ...state.error, login: action.payload?.error_description || action.payload?.error || '' },
        loading: { ...state.loading, login: false },
      };
    case actions.ATTACH_SMART_ID_REQUEST:
      return {
        ...state,
        error: { ...state.error, signUp: initialState.error.signUp },
        loading: { ...state.loading, signUp: true },
      };
    case actions.ATTACH_SMART_ID_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, signUp: false },
        result: { ...state.result, attachSmartId: true },
      };
    case actions.ATTACH_SMART_ID_FAILED:
      return {
        ...state,
        error: { ...state.error, signUp: action.payload?.errors || [] },
        loading: { ...state.loading, signUp: false },
      };
    case actions.ATTACH_SMART_ID_FINALIZE_REQUEST:
      return {
        ...state,
        error: { ...state.error, smartIdFinalize: initialState.error.smartIdFinalize },
        loading: { ...state.loading, smartIdFinalize: true },
      };
    case actions.ATTACH_SMART_ID_FINALIZE_SUCCESS:
      if (action.payload) {
        setToken(JSON.stringify(action.payload), null);
      }
      return {
        ...state,
        loading: { ...state.loading, smartIdFinalize: false },
        result: { ...state.result, smartIdFinalize: true },
        smartIdFinalize: ECustomerSmartIdFinalizes.finish,
        token: action.payload || state.token
      };
    case actions.ATTACH_SMART_ID_FINALIZE_FAILED:
      return {
        ...state,
        error: { ...state.error, smartIdFinalize: action.payload?.errors || initialState.error.smartIdFinalize },
        loading: { ...state.loading, smartIdFinalize: false },
        smartIdFinalize: ECustomerSmartIdFinalizes.error,
      };
    case actions.AUTH_CLEAR_SIGNUP_ERRORS:
      const filteredUserError = [...state.error.signUp].filter((errorItem) => errorItem.source?.pointer !== `data/attributes/${action.payload}`);
      return { ...state, error: { ...state.error, signUp: filteredUserError } };
    default:
      return state;
  }
};
