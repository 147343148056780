export const base64ToString = (base64) => {
  const binString = atob(base64).split('').map((x) => {
    return '\x00\x01\x02\x03\x04\x05\x06\x07\x08\x09\x0A\x0B\x0C\x0D\x0E\x0F\x10\x11\x12\x13\x14\x15\x16\x17\x18\x19\x1A\x1B\x1C\x1D\x1E\x1F !"#$%&\'()*+,-./0123456789:;<=>?@ABCDEFGHIJKLMNOPQRSTUVWXYZ[\\]^_`abcdefghijklmnopqrstuvwxyz{|}~ЂЃ‚ѓ„…†‡€‰Љ‹ЊЌЋЏђ‘’“”•–—˜™љ›њќћџ ЎўЈ¤Ґ¦§Ё©Є«¬*®Ї°±Ііґµ¶·ё№є»јЅѕїАБВГДЕЖЗИЙКЛМНОПРСТУФХЦЧШЩЪЫЬЭЮЯабвгдежзийклмнопрстуфхцчшщъыьэюя'[x.charCodeAt(0)];
  }).join('');
  return binString;
};

export const objectToBase64 = (object) => {
  const json = JSON.stringify(object);
  return Buffer.from(json).toString('base64');
};

export const base64ToBytes = (base64) => {
  const binString = atob(base64);
  // @ts-ignore
  return Uint8Array.from(binString, (m) => m.codePointAt(0));
};


export const base64ToString2 = (base64) => {
  return new TextDecoder().decode(base64ToBytes(base64));
};

// From https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem.
function bytesToBase64(bytes) {
  const binString = String.fromCodePoint(...bytes);
  return btoa(binString);
}

export const base64encode = (string) => bytesToBase64(new TextEncoder().encode(string));
export const base64decode = (string) => new TextDecoder().decode(base64ToBytes(string));
