import { Reducer } from 'redux';

import { ILoyaltyProgramActions } from '@interfaces/loyaltyProgram/actions';
import { ILoyaltyProgramReducer } from '@interfaces/loyaltyProgram/reducer';
import { initialState } from '@reducers/loyaltyProgram/initState';

import * as actions from '../../constants/actions/loyaltyProgram';

export const loyaltyProgramReducer: Reducer<ILoyaltyProgramReducer, ILoyaltyProgramActions> = (state = initialState, action) => {
  switch (action.type) {
    case actions.MESSAGE_SHOW:
      return { ...state, ...action.payload };
    case actions.MESSAGE_CLEAR:
      return { ...state, successMessages: initialState.successMessages };
    case actions.LOYALTY_PROGRAM_SIGN_UP_DATA_REQUEST:
      return {
        ...state,
        signUpCompleted: false,
        signUpData: null,
        signUpDataError: null,
        signUpDataLoading: true,
      };
    case actions.LOYALTY_PROGRAM_SIGN_UP_DATA_SUCCESS:
      return {
        ...state,
        signUpData: { ...state.signUpData, ...action.payload },
        signUpDataError: null,
        signUpDataLoading: false,
      };
    case actions.LOYALTY_PROGRAM_SIGN_UP_DATA_FAILED:
      return {
        ...state,
        signUpDataError: action.payload?.errors || [],
        signUpDataLoading: false,
      };
    case actions.LOYALTY_PROGRAM_SIGN_UP_COMPLETED:
      return {
        ...state,
        signUpCompleted: true,
      };
    case actions.LOYALTY_PROGRAM_ATTACH_REQUEST:
      return {
        ...state,
        attachError: null,
        attachLoading: true,
      };
    case actions.LOYALTY_PROGRAM_ATTACH_SUCCESS:
      return {
        ...state,
        attachLoading: false,
        signUpCompleted: true,
      };
    case actions.LOYALTY_PROGRAM_ATTACH_FAILED:
      return {
        ...state,
        attachError: action.payload?.errors || [],
        attachLoading: false,
      };
    case actions.LOYALTY_PROGRAM_CONFIRM_SIGN_UP_EMAIL_REQUEST:
      return {
        ...state,
        confirmData: null,
        confirmError: null,
        confirmLoading: true,
      };
    case actions.LOYALTY_PROGRAM_CONFIRM_SIGN_UP_EMAIL_SUCCESS:
      return {
        ...state,
        confirmData: { ...state.confirmData, ...action.payload },
        confirmError: null,
        confirmLoading: false,
      };
    case actions.LOYALTY_PROGRAM_CONFIRM_SIGN_UP_EMAIL_FAILED:
      return {
        ...state,
        confirmError: action.payload?.errors || [],
        confirmLoading: false,
      };
    case actions.LOYALTY_PROGRAM_ACCESS_CARD_REQUEST:
      return {
        ...state,
        accessCardLoading: true,
      };
    case actions.LOYALTY_PROGRAM_ACCESS_CARD_SUCCESS:
      return {
        ...state,
        accessCard: action.payload,
        accessCardLoading: false,
      };
    case actions.LOYALTY_PROGRAM_ACCESS_CARD_FAILED:
      return {
        ...state,
        accessCardLoading: false,
      };
    default:
      return state;
  }
};
