import { defaultSorting } from '@constants/sort';
import { IWishListReducer } from '@interfaces/wishList/reducer';

export const initialState: IWishListReducer = {
  count: 0,
  list: [],
  listId: [],
  listMapped: [],
  loaded: false,
  loading: {
    list: false,
  },
  sortDirection: defaultSorting,
};
