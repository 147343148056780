import { getTabIndexByGender } from '@components/layout/header/MobileMenu/utils';
import { EGender } from '@constants/gender';
import { ESideMenuPage, IPageStore } from '@interfaces/page/store';

export const initialState: IPageStore = {
  chatTawk: 0,
  chatTawkLoading: false,
  countrySelect: false,
  firstRun: false,
  listSizeBig: true,
  mobileMenu: {
    category: null,
    gender: null,
    open: false,
    preventInit: false,
    tab: getTabIndexByGender(EGender.women),
  },
  mobilePageUpdate: false,
  salePriceSwitch: false,
  salePriceSwitchCampaign: [],
  sessionLoading: false,
  sideMenu: false,
  sideMenuPage: ESideMenuPage.registration,
  userMenu: false,
};
