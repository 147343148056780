import redis, { RedisClient } from 'redis';

import { CacheFetchOptions, ERedisCacheKey } from '@constants/cacheCallApi';
import { RequestMethods } from '@constants/types';
import { NO_CACHE } from '@interfaces/fetchService';
import { IRedisSettings, ISiteSettings } from '@interfaces/pageData/reducer';

const isServer = typeof window === 'undefined' ? 1 : 0;
const redisUrl: string | null = process.env?.REDIS_URL ? process.env.REDIS_URL : null;

export const cachePrefix = 'nextJs-';
const client: RedisClient | null = isServer && redisUrl ? redis.createClient(redisUrl) : null;


export const getSiteSettings = async (): Promise<ISiteSettings | null> => {
  return new Promise((resolve, reject) => {
    if (!client) {
      return reject({ code: NO_CACHE });
    }
    client.get('site-settings', (err: Error | null, res: string | null) => {
      if (err || !res) {
        return resolve(null);
      } else {
        resolve(JSON.parse(res ?? null));
      }
    });
  });
};

export const makeRedisRequest = async (key: string, withPrefix: boolean = true): Promise<string | undefined> => {
  return new Promise((resolve, reject) => {
    if (!client) {
      return reject({ code: NO_CACHE });
    }
    const keyResult = withPrefix ? cachePrefix + key : key;
    client.get(keyResult, (err: Error | null, res: string | null) => {
      if (err || !res) {
        return reject({ code: NO_CACHE });
      } else {
        return resolve(res);
      }
    });
  });
};

const clearCacheKey = (key: string) => {
  try {
    const url = new URL(key);
    if (url.searchParams.has('sessionId')) {
      url.searchParams.delete('sessionId');
    }
    if (url.searchParams.has('ga')) {
      url.searchParams.delete('ga');
    }
    return url.href.replace('https://', '').replace('http://', '');
  } catch (err) {
    // @ts-ignore
    console.log(`Error while parsing config - ${err?.message}`);
    return key;
  }
};

export const getCachedData = async (
  endpoint: string,
  method: RequestMethods.Any,
): Promise<any> => {
  if (client && [RequestMethods.GET_METHOD].includes(method) && endpoint) {
    const clearedKey = clearCacheKey(endpoint);
    const resultData = await makeRedisRequest(clearedKey);
    if (resultData) {
      return Promise.resolve({ json: JSON.parse(resultData), status: 200 });
    } else {
      return Promise.reject({ code: NO_CACHE });
    }
  }
  return Promise.reject({ code: NO_CACHE });
};

export const setCachedData = async (
  endpoint: string,
  data,
  cacheOptions?: string | undefined,
) => {
  const clearedKey = clearCacheKey(endpoint);
  let ttl = CacheFetchOptions.default.ttl;
  if (cacheOptions) {
    const cacheSettings = await getCacheSettings(cacheOptions);
    if (cacheSettings?.seconds) {
      ttl = Number(cacheSettings.seconds);
    }
  }
  client?.setex(cachePrefix + clearedKey, ttl, JSON.stringify(data));
};


export const getCacheSettings = async (key: ERedisCacheKey | string): Promise<IRedisSettings | null> => {
  const settings = await getSiteSettings();
  return settings?.redisTTL?.find((redisItem) => redisItem.key === key) ?? null;
};
