import { Reducer } from 'redux';

import {
  LOTTERY_REGISTER_CLEAR_FIELD_ERROR,
  LOTTERY_REGISTER_FAILED,
  LOTTERY_REGISTER_REQUEST,
  LOTTERY_REGISTER_SUCCESS
} from '@constants/actions/auth';
import { ILotteryActions, IRegisterLotteryFailed } from '@interfaces/lottery/actions';
import { ILotteryReducer } from '@interfaces/lottery/reducer';
import { initialState } from './initState';

export const lotteryReducer: Reducer<ILotteryReducer, ILotteryActions> = (state = initialState, action) => {
  switch (action.type) {
    case LOTTERY_REGISTER_REQUEST:
      return {
        ...state,
        loading: {
          ...state.loading,
          registerLottery: true
        },
      };
    case LOTTERY_REGISTER_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          registerLottery: false
        },
        success: {
          ...state.success,
          registerLottery: true,
        }
      };
    case LOTTERY_REGISTER_FAILED:
      return {
        ...state,
        errors: {
          ...state.errors,
          registerLottery: (action as IRegisterLotteryFailed).payload
        },
        loading: {
          ...state.loading,
          registerLottery: false
        }
      };
    case LOTTERY_REGISTER_CLEAR_FIELD_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          registerLottery: [],
        }
      };
    default:
      return state;
  }
};