import { ICartDiscount, ICartItem, IPaymentMethod, IShipmentMethod } from '@interfaces/cart';
import { IProductInList } from '@interfaces/product';
import { IStoreBrand, IStoreCity } from '@interfaces/stores/reducer';
import { ReactNode } from 'react';

export interface IAccountMenuItem {
  action?: () => void;
  icon: ReactNode;
  label: string | JSX.Element;
  type?: string;
}

export enum EOrderStatus {
  inProgress,
  completed,
}

/*
export interface IOrderFromHistory {
  date: string;
  items: ICartItem[];
  number: number;
  status: EOrderStatus;
}
*/
export enum AccountAttributesFields {
  dateOfBirth = 'date-of-birth',
  email = 'email',
  firstName = 'first-name',
  gender = 'gender',
  lastName = 'last-name',
  nationalId = 'national-id',
  newsletterRequested = 'newsletter-requested',
  phone = 'phone',
}

export enum EDeliveryMethodBase {
  Courier = 'courier', // (address based) - identified as 'courier'
  postalOffice = 'postoffice', // (location id based) - identified as 'postaloffice'
  Locker = 'locker', // (location id based) - identified as 'locker'
}

export enum EDeliveryMethodAttributesFields {
  address = 'address',
  addressRequired = 'address-required',
  available = 'availabled',
  city = 'city',
  country = 'country',
  defaultFlag = 'default-flag',
  deliveryType = 'delivery-type',
  extLocationCode = 'ext-location-code',
  extLocationName = 'ext-location-name',
  extServiceCode = 'ext-service-code',
  locationRequired = 'location-required',
  postalCode = 'postal-code',
  vendor = 'vendor',
}

export interface IDeliveryMethodAttributes {
  [EDeliveryMethodAttributesFields.address]?: string;
  [EDeliveryMethodAttributesFields.addressRequired]: boolean;
  [EDeliveryMethodAttributesFields.available]: boolean; // whether this method is still available
  [EDeliveryMethodAttributesFields.city]: string;
  [EDeliveryMethodAttributesFields.country]: string;
  [EDeliveryMethodAttributesFields.defaultFlag]: boolean;
  [EDeliveryMethodAttributesFields.deliveryType]: EDeliveryMethodBase;
  [EDeliveryMethodAttributesFields.extLocationCode]: null | string;
  [EDeliveryMethodAttributesFields.extLocationName]: null | string;
  [EDeliveryMethodAttributesFields.extServiceCode]: string;
  [EDeliveryMethodAttributesFields.locationRequired]: boolean;
  [EDeliveryMethodAttributesFields.postalCode]: string;
  [EDeliveryMethodAttributesFields.vendor]: string;
}

export interface IDeliveryMethod {
  type: 'delivery-method';
  id?: string;
  attributes: IDeliveryMethodAttributes;
  relationships?: {};
}

export interface IOrderFromHistory {
  amountWithoutShipment: string;
  discounts: ICartDiscount[];
  orderId: number;
  orderStatusId: number;
  orderStatus: string;
  orderLocationName: string;
  paymentMethod: IPaymentMethod;
  shipmentFirstName: string;
  shipmentLastName: string;
  shipmentEmail: string;
  shipmentPhone: string;
  shipmentCountry: string;
  shipmentCity: string;
  shipmentAddress: string;
  shipmentMethod: IShipmentMethod;
  shipmentLocation?: any;
  shipmentZipcode: string;
  orderTimestamp: number;
  amount: number;
  amountShipment: number;
  amountBonus: number | string;
  bonusSaldo: number;
  bonusEarned: number;
  invoiceName?: string;
  trackingCode: string;
  trackingUrl: string;
  cartItems: ICartItem[];
  isTrackableOrder: boolean;
}

export enum EOrderStatus {
  PROCESSING = 1,
  ACCUMULATING = 2,
  SHIPPED = 3,
  DELIVERED = 4,
  RETURNED = 5,
}

export enum EAccountNotificationType {
  null = 'null',
  app = 'APP',
  email = 'EMAIL',
  newsletterRequested = 'newsletterRequested',
  push = 'push',
  sms = 'SMS',
}

export interface ICustomerPaymentMethod {
  attributes: {
    brand: string;
    ['default-flag']: boolean;
    number: string;
  };
  id: string;
  relationships: {};
  type: 'payment-method';
}

export interface IOrderDeliveryStatus {
  created: string;
  status: string;
  statusId: number;
}

export enum ERetailPaymentType {
  CARD = 'CARD',
  CASH = 'CASH',
  MIXED = 'MIXED'
}

export interface IRetailItem {
  id: number;
  productExtId: number;
  price: string | number;
  qty: number;
  size: string;
  brand: string;
  model: string;
  color: string;
  collection: string;
  giftCard: boolean;
  pictureUrl?: string;
  estoreProduct?: IProductInList;
}


export interface IOrderFromRetail {
  orderNumber: number;
  retailOrderNumber: string;
  created: number;
  status: string;
  statusName: ERetailOrderStatus;
  price: number;
  priceSumCash: number;
  priceSumCard: number;
  priceType: ERetailPaymentType;
  location: {
    locationId: number;
    locationName: string;
    address: string;
    phone: string;
    email: string;
    brand: IStoreBrand;
    city: IStoreCity;
    group: {
      mallId: number;
      name: string;
    }
  };
  items: IRetailItem[];
}


export enum EOrdersListType {
  online,
  retail
}

export enum ERetailOrderStatus {
  RETURNED = 'Returned',
  SHIPPED = 'Shipped'
}
