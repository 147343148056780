export const scrollToTopSmooth = () => {
  if (typeof window !== 'undefined')
    window.scrollTo({ top: 0, behavior: 'smooth' });
};

export const scrollToSmooth = (scrollY: number) => {
  if (typeof window !== 'undefined')
    window.scrollTo({ top: scrollY, behavior: 'smooth' });
};

export const scrollToTopQuick = () => {
  if (typeof window !== 'undefined')
    window.scrollTo(0, -100);
};
