import { GetServerSidePropsContext } from 'next';
import Router from 'next/router';
import slugify from 'slugify';

import { mobileMode, mobileSession } from '@constants/config';
import { IProductListSeoAttributes } from '@interfaces/productsList';
import { getFirstRunCookie, setFirstRunCookie, TContext } from '@utils/cookies';
import {
  getFirstRunFromLocalStorage,
  setFirstRunLocalStorage,
} from '@utils/localStorage';

import { homePage } from '@constants/routes';
import { IProductColor, IProductInList } from '@interfaces/product';
import { getLocale } from '@utils/countryLang';

export const generateProductUrl = (product: IProductInList, productDetailSlug: string, colorId?: number) => {
  const firstColor = product?.colors?.[0] ?? { productId: 0, pictures: [] };

  const brand = product?.brand?.brand;
  const model = product?.model;
  const slug = slugify(model + ' ' + brand, {
    lower: true,
  });

  return `${productDetailSlug}/${slug}_${colorId ? colorId : firstColor.productId}`;
};

export const getDefaultSeoInfo = ({ h1 = '', short_story = '', description = '', title = '' }): IProductListSeoAttributes => {
  return { seoH1Tag: h1, seoStory: short_story, seoDescription: description, seoTitle: title };
};

export const generateArrayByItemsCount = (ItemsCount) => {
  return [...Array(ItemsCount)];
};

export const getColorsWithSizes = (colors: IProductColor[] = []) => colors.filter(({ sizes }) => !!sizes.length);
export const getSoredColors = (colors: IProductColor[] = []) => colors?.sort((a, b) => a.colorId > b.colorId ? -1 : 1);

export function getCountryAndLanguageCode(
  context: TContext | null = null,
): {
  countryCode?: string;
  languageCode?: string;
} {
  const path = getLocale(context);
  if (path) {
    const [countryCode, languageCode] = path.substring(1).split('/');
    return {
      countryCode,
      languageCode,
    };
  }

  return {
    countryCode: 'EE',
    languageCode: 'et',
  };
}

export const goBackHandler = (props) => {
  if (window?.history?.state?.page) {
    Router.back();
    return Promise.resolve(true);
  } else {
    const { countryCode, languageCode } = props;
    return Router.replace(homePage(countryCode, languageCode), '', { shallow: mobileMode });
  }
};


type TGetFirstRun = (context?: GetServerSidePropsContext | null) => string | null;
export const getFirstRun: TGetFirstRun = (context = null) => {
  return mobileSession ? getFirstRunFromLocalStorage() : getFirstRunCookie(context) ?? null;
};

type TSetFirstRun = (value: string, context?: GetServerSidePropsContext | null) => void;
export const setFirstRun: TSetFirstRun = (value, context = null) => {
  mobileSession ? setFirstRunLocalStorage(value) : setFirstRunCookie(context, value);
};
