import { Middleware, MiddlewareAPI } from 'redux';

import { acAuthCreateSessionAction } from '@actions/acAuth';
import { ESessionStatuses, ICallApi } from '@interfaces/fetchService';
import { TThunkDispatch } from '@interfaces/index';
import { IStore } from '@interfaces/store';
import { CALL_API } from 'services/SSRService';
import { INextContext } from '../../store/wrapper';
import { addSessionIdInUrl } from '../SSRService/utils';

export type TRequestAction = Promise<any>;

const sessionService: (context?: INextContext) => Middleware = (context) => (store: MiddlewareAPI<TThunkDispatch, IStore>) => (next) => async (action) => {

  const callAPI: ICallApi = action[CALL_API];

  if (typeof callAPI === 'undefined') {
    return next(action);
  }

  const { sessionId } = callAPI;
  const { auth: { sessionId: clientSessionId } }: IStore = store.getState();

  if (typeof sessionId === 'undefined' || sessionId.status === ESessionStatuses.canCallWithoutSession) {
    return next(action);
  }

  if (sessionId?.status === ESessionStatuses.createSessionBefore && clientSessionId === null) {
    await store.dispatch(acAuthCreateSessionAction(context));
  }

  const { auth: { sessionId: clientSessionIdNew } }: IStore = store.getState();
  if ([ESessionStatuses.createSessionBefore, ESessionStatuses.dontCallWithoutSession].includes(sessionId?.status) && typeof clientSessionIdNew === 'string') {
    const { endpoint } = callAPI;
    action[CALL_API].endpoint = addSessionIdInUrl(endpoint, clientSessionIdNew);

    return next(action);
  }
  return Promise.resolve(false);
};

export default sessionService;
