import { IDeliveryMethod } from '@interfaces/account';
import { ECustomerSmartIdFinalizes, ICustomerApiError } from '@interfaces/account/reducer';
import { IAuthPrefillProps } from '@interfaces/auth/index';
import { IUserData } from '@interfaces/config/reducer';

export interface IAuthReducer {
  error: {
    forgot: ICustomerApiError[];
    login: null | string;
    resendCode: ICustomerApiError[];
    resetPassword: ICustomerApiError[];
    sessionId: any;
    signUp: ICustomerApiError[];
    signUpFacebook: ICustomerApiError[];
    signUpGoogle: ICustomerApiError[];
    signUpSmartId: ICustomerApiError[];
    smartIdFinalize: ICustomerApiError[];
  };
  gaId: string | null;
  isAuthorized: boolean;
  loading: {
    forgot: boolean;
    login: boolean;
    resendCode: boolean;
    resetPassword: boolean;
    signUp: boolean;
    signUpFacebook: boolean;
    signUpGoogle: boolean;
    signUpSmartId: boolean;
    smartIdFinalize: boolean;
  };
  loginData: ILoginData;
  result: {
    attachSmartId: boolean;
    resendCode: boolean;
  };
  sessionId: string | null;
  signUp: {
    confirmRequired: boolean;
  };
  signUpPrefill: IAuthPrefillProps;
  socialApps: ISocialApp[];
  smartId: {
    hash: string | null;
    pin: string | null;
    step: number;
  };
  smartIdFinalize: ECustomerSmartIdFinalizes;
  token: null | IAuthToken;
  user: null | IGetCustomerResponse;
}

export interface IAuthToken {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  token_type: string;
}

export interface IGetCustomerResponse {
  data: IUserData;
  included: IDeliveryMethod[];
}

export interface ILoginData {
  facebooktoken?: string;
  facebookuserid?: string;
  email?: string;
  password?: string;
}

export interface ISocialApp {
  socialType: ESocialTypes;
  clientIds: string[];
}

export enum ESocialTypes {
  apple = 'apple',
  facebook = 'facebook',
  google = 'google',
  smartId = 'smart_id'
}
