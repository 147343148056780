import { EGenderType } from '@constants/gender';
import { ISearchReducer } from '@interfaces/search/reducer';

export const initialState: ISearchReducer = {
  gender: EGenderType.women,
  inputValue: '',
  isOpenPanel: false,
  loadingCategories: false,
  loadingProducts: false,
  matchingCategories: [],
  matchingProducts: [],
};
