import { ResponsiveType } from '@components/common/ReactMultiCarousel/types';

export const imageCarouselResponsiveParams: ResponsiveType = {
  desktop: {
    breakpoint: { max: 3000, min: 1200 },
    items: 5,
    partialVisibilityGutter: 60,
  },
  mobile: {
    breakpoint: { max: 460, min: 0 },
    items: 1.33,
    // partialVisibilityGutter: 15,
  },
  smallDesktop: {
    breakpoint: { max: 1200, min: 992 },
    items: 4,
    partialVisibilityGutter: 60,
  },
  tablet: {
    breakpoint: { max: 650, min: 460 },
    items: 2,
    partialVisibilityGutter: 50,
  },
  wideTablet: {
    breakpoint: { max: 992, min: 650 },
    items: 3,
    partialVisibilityGutter: 50,
  },
};

export const listSizeBig: string = 'listSizeBig';
export const localStorageCookieConsent: string = 'ddCookieConsent';

export const itemsPerPage = 50;
export const itemsPerPageWishList = 20;
export const itemsPerPageOrdersList = 10;

export const thresholdStockLow = 5;

export const reactZoomPanPinchVelocity = 85;
export const anyProductWasViewed: string = 'viewedProduct';

export const showLoyaltyCountries =  ['EE', 'LT', 'LV'];
export const showLoyaltyCountriesAccount =  ['EU','EE', 'LT', 'LV'];

export enum EPaymentProviders {
  maksekeskus = 'Maksekeskus',
  modena = 'Modena'
}
