import React from 'react';

if (process.env.NODE_ENV === 'development' && process.env.WDYR === 'true') {
  if (typeof window !== 'undefined') {
    // tslint:disable-next-line:no-var-requires
    const whyDidYouRender = require('@welldone-software/why-did-you-render');
    whyDidYouRender(React, {
      trackAllPureComponents: true,
    });
  }
}
