import { CookieSerializeOptions } from 'cookie';
import { GetServerSidePropsContext } from 'next';
import nookies, { destroyCookie, parseCookies, setCookie as setNookie } from 'nookies';

import { ECountries } from '@constants/countries';
import { listSizeBig } from '@constants/index';
import { nativeSettingsBrowserStorageKey } from '@reducers/nativeSettings/const';

export const KEY_TOKEN = 'token';
export const KEY_COUNTRY_LANGUAGE = 'denimdreamlanguageselection';
const KEY_STRAPI_USER_TOKEN = 'strapiUserToken';
const KEY_BONUS_GIFTCARD = 'eStoreBonusGiftCard';
const KEY_BONUS_COUPON = 'eStoreBonusGiftCoupon';
const KEY_COOKIE_CONSENT = 'cookieConsent';
export const KEY_COOKIE_FIRSTRUN = 'firstRun';

const KEY_FACEBOOK_FBC = '_fbc';
const KEY_FACEBOOK_FBP = '_fbp';

export const KEY_VIEWPORT_SETTINGS = 'dd_viewport';

export const KEY_CLOSED_NOTIFICATION = 'dd_closed_notification';


export const gaIdCookieName = '_ga';

export enum ESessionIds {
  EE = 'sessionId_EE',
  EU = 'sessionId_EU',
  FI = 'sessionId_FI',
  LT = 'sessionId_LT',
  LV = 'sessionId_LV',
}

interface ISessionIdCookie {
  [ESessionIds.EE]?: string;
  [ESessionIds.EU]?: string;
  [ESessionIds.FI]?: string;
  [ESessionIds.LT]?: string;
  [ESessionIds.LV]?: string;
}

interface ILanguageselectionCookie {
  denimdreamlanguageselection?: string;
}

interface ICookies extends ISessionIdCookie, ILanguageselectionCookie {
  token?: string;
}

export type TContext = GetServerSidePropsContext | null;
type TGetCookie = (context: TContext) => string | undefined;
type TSetCookie = (context: TContext, value: string) => void;
type TRemoveCookie = (context: TContext | null) => void;

export const getSessionIdCookieKey = (countryCode: string): ESessionIds | undefined => {
  switch (countryCode) {
    case ECountries.EE:
      return ESessionIds.EE;
    case ECountries.EU:
      return ESessionIds.EU;
    case ECountries.FI:
      return ESessionIds.FI;
    case ECountries.LT:
      return ESessionIds.LT;
    case ECountries.LV:
      return ESessionIds.LV;
  }
};
const defaultOptions = {
  maxAge: 311040000,
  path: '/',
};

const defaultOptionsGiftCard = {
  maxAge: 259200,
  path: '/',
};


const loadCookies = (context: TContext): ICookies => {
  return context ? nookies.get(context) : parseCookies();
};

const getCookie = (context: TContext | null, key: string): string | undefined => {
  const cookies = loadCookies(context);
  return cookies[key];
};

const setCookie = (context: TContext, key: string, value: string, options?: CookieSerializeOptions): void => {
  context ? nookies.set(context, key, value, options) : setNookie(null, key, value, options);
};
export const removeCookie = (context: TContext, key: string, options?: CookieSerializeOptions): void => {
  context ? nookies.destroy(context, key, options) : destroyCookie({}, key, options);
};

/**
 * Load all cookies in a frontend environment
 */
loadCookies(null);

/**
 * Get cookie
 */

export const getBonusGiftCardsCookie = (context: TContext, country: string) => getCookie(context, KEY_BONUS_GIFTCARD + '_' + country);
export const getBonusPromoCodesCookie: TGetCookie = (context) => getCookie(context, KEY_BONUS_COUPON);
export const getTokenCookie: TGetCookie = (context) => getCookie(context, KEY_TOKEN);
export const getCountryLanguagePathCookie: TGetCookie = (context) => getCookie(context, KEY_COUNTRY_LANGUAGE);
export const getStrapiUserCookie: TGetCookie = (context) => getCookie(context, KEY_STRAPI_USER_TOKEN);
export const getFirstRunCookie: TGetCookie = (context) => getCookie(context, KEY_COOKIE_FIRSTRUN);
export const getSessionIdCookie = (context: GetServerSidePropsContext | null, countryCode: string): string | null => {
  const cookieKey = getSessionIdCookieKey(countryCode);
  if (cookieKey) {
    return getCookie(context, cookieKey) ?? null;
  }
  return null;
};
export const getCookieConsentCookie: TGetCookie = (context) => getCookie(context, KEY_COOKIE_CONSENT);
export const getListSizeCookie: TGetCookie = (context) => getCookie(context, listSizeBig);
export const getNativeSettingsCookie: TGetCookie = (context) => getCookie(context, nativeSettingsBrowserStorageKey);

/**
 * Set cookie
 */

export const setBonusGiftCardCookie = (value: string, country: string) => setCookie(null, KEY_BONUS_GIFTCARD + '_' + country, value, defaultOptionsGiftCard);
export const setBonusCouponsCookie = (value: string) => setCookie(null, KEY_BONUS_COUPON, value, defaultOptionsGiftCard);
export const setTokenCookie: TSetCookie = (context, value) => setCookie(context, KEY_TOKEN, value, defaultOptions);
export const setCountryLanguagePathCookie: TSetCookie = (context, value) => setCookie(context, KEY_COUNTRY_LANGUAGE, value, defaultOptions);
export const setStrapiUserCookie: TSetCookie = (context, value) =>
  setCookie(context, KEY_STRAPI_USER_TOKEN, value, { ...defaultOptions, maxAge: 86400 });
export const setSessionIdCookie = (
  context: GetServerSidePropsContext | null,
  countryCode: string,
  sessionId: string,
): void => {
  const cookieKey = getSessionIdCookieKey(countryCode);
  if (cookieKey) {
    setCookie(context, cookieKey, sessionId, defaultOptions);
  }
};
export const setCookieConsentCookie: TSetCookie = (context, value) => setCookie(context, KEY_COOKIE_CONSENT, value, defaultOptions);

export const setFirstRunCookie: TSetCookie = (context, value) => setCookie(context, KEY_COOKIE_FIRSTRUN, value, defaultOptions);
export const setListSizeCookie: TSetCookie = (context, value) => setCookie(context, listSizeBig, value, defaultOptions);
export const setNativeSettingsCookie: TSetCookie = (context, value) => setCookie(context, nativeSettingsBrowserStorageKey, value, defaultOptions);
/**
 * Remove cookie
 */
export const removeBonusGiftCardCookie = (context: TContext, country: string) => removeCookie(context, KEY_BONUS_GIFTCARD + '_' + country);
export const removeBonusCouponCookie: TRemoveCookie = (context) => removeCookie(context, KEY_BONUS_COUPON);
export const removeTokenCookie: TRemoveCookie = (context) => removeCookie(context, KEY_TOKEN, defaultOptions);

type TRemoveSessionCookie = (context: TContext | null, countryCode: string) => void;
export const removeSessionCookie: TRemoveSessionCookie = (context, countryCode) => {
  const cookieKey = getSessionIdCookieKey(countryCode);
  if (cookieKey)
    removeCookie(context, cookieKey, defaultOptions);
};

export const removeBonuses = (context: TContext, country: string) => {
  removeBonusGiftCardCookie(context, country);
  removeBonusCouponCookie(context);
};

export const removeBonusCodeFromCookie = async (code: string, countryCode: string) => {
  const promoCookie = getBonusPromoCodesCookie(null);
  if (promoCookie) {
    const promoArray = JSON.parse(promoCookie);
    if (Array.isArray(promoArray) && promoArray.length > 0) {
      const filteredPromo = promoArray.filter((promo) => promo.code !== code);
      if (filteredPromo.length !== promoArray.length) {
        await setBonusCouponsCookie(JSON.stringify(filteredPromo));
      }
    }
  }
  const giftCardCookie = getBonusGiftCardsCookie(null, countryCode);
  if (giftCardCookie) {
    const giftCardArray = JSON.parse(giftCardCookie);
    if (Array.isArray(giftCardArray) && giftCardArray.length > 0) {
      const filteredGiftCard = giftCardArray.filter((promo) => promo.code !== code);
      if (filteredGiftCard.length !== giftCardArray.length) {
        await setBonusGiftCardCookie(JSON.stringify(filteredGiftCard), countryCode);
      }
    }
  }
  return true;
};


/**
 * Get Facebook AD values
 */
export const getCookieFbFBC = () => getCookie(null, KEY_FACEBOOK_FBC);
export const getCookieFbFBP = () => getCookie(null, KEY_FACEBOOK_FBP);


export const setViewPortSettings: TSetCookie = (context, value) => setCookie(context, KEY_VIEWPORT_SETTINGS, value, defaultOptions);
export const getViewPortSettings = () => getCookie(null, KEY_VIEWPORT_SETTINGS);

export const setClosedNotification: TSetCookie = (context, value) => setCookie(context, KEY_CLOSED_NOTIFICATION, value, defaultOptions);
export const getClosedNotification = () => getCookie(null, KEY_CLOSED_NOTIFICATION);
