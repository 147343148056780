import { useState } from 'react';

interface ITryToScrollToProps {
  latestTimeToTry: number;
  y: number;
}

export const SCROLL_RESTORATION_TIMEOUT_MS = 6000;
export const TRY_TO_SCROLL_INTERVAL_MS = 50;

export const useScrollRestore = () => {
  const [timeoutHandle, setTimeoutHandle] = useState<null | any>(null);
  const [yPosition, setYposition] = useState<null | number>(null);

  // Try to scroll to the scrollTarget, but only if we can actually scroll
  // there. Otherwise keep trying until we time out, then scroll as far as
  // we can.
  const tryToScrollTo = (scrollTarget: ITryToScrollToProps) => {
    // Stop any previous calls to "tryToScrollTo".
    if (timeoutHandle) {
      clearTimeout(timeoutHandle);
    }
    if (window && document) {
      const body = document?.body;
      const html = document?.documentElement;

      const documentHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);

      if ((documentHeight - (window?.innerHeight / 2)) >= scrollTarget.y || Date.now() > scrollTarget.latestTimeToTry) {
        window?.scrollTo(0, scrollTarget.y);
        setYposition(null);
      } else {
        setTimeoutHandle(setTimeout(() => {
          return tryToScrollTo(scrollTarget);
        }, TRY_TO_SCROLL_INTERVAL_MS));
      }
    }
  };

  const restorePosition = (valueY?: number) => {
    const valueYY: number | null = yPosition && yPosition - 130;
    const value: number | null = valueY ?? valueYY ?? null;
    if (value) {
      setTimeout(() => {
        return tryToScrollTo({
          latestTimeToTry: Date.now() + SCROLL_RESTORATION_TIMEOUT_MS,
          y: value,
        });
      });
    }
  };

  return {
    restorePosition,
    setYposition,
    yPosition,
  };
};

let timeoutHandleExt;
export const tryToScrollToExt = (scrollTarget: ITryToScrollToProps) => {
  // Stop any previous calls to "tryToScrollTo".
  if (timeoutHandleExt) {
    clearTimeout(timeoutHandleExt);
  }
  if (window && document) {
    const body = document?.body;
    const html = document?.documentElement;

    const documentHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);

    if (documentHeight - window?.innerHeight >= scrollTarget.y || Date.now() > scrollTarget.latestTimeToTry) {
      window?.scrollTo(0, scrollTarget.y);
    } else {
      timeoutHandleExt = setTimeout(() => {
        return tryToScrollToExt(scrollTarget);
      }, TRY_TO_SCROLL_INTERVAL_MS);
    }
  }
};
