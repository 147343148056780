import { acPreGoToPage } from '@actions/acPageData';
import * as actions from '@constants/actions/navigation';
import { mobileMode } from '@constants/config';
import { PageTypes, productsPageTypes } from '@constants/pageTypes';
import { SCROLL_RESTORATION_TIMEOUT_MS, tryToScrollToExt } from '@hooks/scrollRestore';
import { IHistoryState } from '@interfaces/history';
import {
  INavigationChangeBeforeRouteAction,
  INavigationChangeRouteAction,
  INavigationReplacingRouteAction,
  INavigationUpdateStateAction,
} from '@interfaces/navigation/actions';
import { IPageReference } from '@interfaces/pageData/reducer';
import { IStore } from '@interfaces/store';
import { goBackHandler } from '@utils/common';
import { pushOrReloadRoute, replaceRoute } from '@utils/routes';

export const acGoBack = () => (dispatch, getState: () => IStore): Promise<boolean> => {
  const { pageData: { countryCode, languageCode } } = getState();
 return goBackHandler({ countryCode, languageCode });
};

export const acReplaceRoute = (route: string, shallow?: boolean) => async (dispatch) => {
  await dispatch(acReplaceRouteState(true));
  await replaceRoute(route, shallow);
  await dispatch(acHistoryUpdatePageType());
  await dispatch(acReplaceRouteState(false));
};

export const acReplaceRouteState = (state: boolean): INavigationReplacingRouteAction => ({
  payload: state,
  type: actions.NAVIGATION_REPLACING_ROUTE,
});

interface IOptions {
  type: 'sexId';
  value: number | string;
}

export const acNavigationUpdateState = (scrollY): INavigationUpdateStateAction => ({
  payload: scrollY,
  type: actions.NAVIGATION_UPDATE_STATE,
});

export const acGoToPage = (pageType: PageTypes, replace?: boolean, options?: IOptions) => async (dispatch, getState: () => IStore) => {
  const { pageData: { data: { pages }, lastMainPage } } = getState();
  if (mobileMode && lastMainPage && !productsPageTypes.includes(pageType) && window) {
    await dispatch(acNavigationUpdateState(window.scrollY));
  }
  const pageToGo: IPageReference | undefined = pages?.find((page) => {
    if (options === undefined) {
      return page.type === pageType;
    }

    let conditions = page.type === pageType;
    switch (options.type) {
      case 'sexId':
        conditions = conditions && page.filter?.sexId === options.value;
        break;
    }

    return conditions;
  });

  if (pageToGo?.url) {
    if (mobileMode) {
      dispatch(acPreGoToPage(pageToGo.type as PageTypes));
    }
    if (replace) {
      replaceRoute(pageToGo.url);
    } else {
      pushOrReloadRoute(pageToGo.url);
    }

  }
};

export const acNavigationGoBackTo = (pageIndex: number | null): boolean => {
  const currentIDx = history?.state?.page || null;
  if (currentIDx !== null && pageIndex !== null && history && Number(pageIndex) > Number(currentIDx)) {
    history.go(pageIndex - currentIDx);
    return true;
  }
  return false;
};

export const acGoToLastMainPage = () => async (dispatch, getState: () => IStore) => {
  const { pageData: { lastMainPage } } = getState();
  if (lastMainPage?.url) {
    if (mobileMode) {
      dispatch(acPreGoToPage(lastMainPage.pageType as PageTypes));
    }
    const currentIDx = history?.state?.page || null;
    const lastMainPageIDx = lastMainPage.page || null;
    if (currentIDx !== null && lastMainPageIDx !== null && history) {
      history.go(lastMainPageIDx - currentIDx);
      if (lastMainPage.__scrollY) {
        tryToScrollToExt({
          latestTimeToTry: Date.now() + SCROLL_RESTORATION_TIMEOUT_MS,
          y: lastMainPage.__scrollY,
        });
      }
    } else {
      replaceRoute(lastMainPage.as);
    }
  }
};

export const acRouteChangeState = (state: boolean): INavigationChangeRouteAction => ({
  payload: state,
  type: actions.NAVIGATION_CHANGE_STATE,
});

export const acRouteChangeBeforeState = (state: boolean, historyState: IHistoryState | null = null): INavigationChangeBeforeRouteAction => ({
  payload: { state, historyState},
  type: actions.NAVIGATION_CHANGE_BEFORE_STATE,
});

export const acHistoryUpdatePageType = () => (dispatch, getState: () => IStore) => {
  const { pageData: { type } } = getState();
  if (typeof window !== 'undefined')
    window.history.replaceState({ ...window.history.state, pageType: type }, '');
};
