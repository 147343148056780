import { Reducer } from 'redux';

import * as actions from '@constants/actions/navigation';
import { INavigationActions } from '@interfaces/navigation/actions';
import { INavigationStore } from '@interfaces/navigation/store';
import { initialState } from './initState';

export const navigationReducer: Reducer<INavigationStore, INavigationActions> = (state = initialState, action) => {
  switch (action.type) {
    case actions.NAVIGATION_REPLACING_ROUTE:
      return { ...state, replacingRoute: action.payload };
    case actions.NAVIGATION_CHANGE_STATE:
      if (action.payload) {
        return { ...state, changedRoute: true, changingRoute: action.payload };
      }
      return { ...state, changedRoute: true, beforePopState: false, beforePopStateHistory: null, changingRoute: action.payload };
    case actions.NAVIGATION_CHANGE_BEFORE_STATE:
      const nextBeforePopStateHistory = action.payload.state ? action.payload.historyState || null : null;
      return {
        ...state,
        beforePopState: action.payload.state,
        beforePopStateHistory: nextBeforePopStateHistory,
        changedRoute: true,
      };
    default:
      return state;
  }
};
