import { calculateTotalPrice, checkAndCallGTag, clearEcommerce, createProductItemUsingData } from '@utils/gtag';
import { EAnalyticEventName } from '../services/AnalyticEvent/types';


interface  IEventItem {
  item_id: number;
  item_name: string;
  price: string;
}

type TAddToWishlistEvent = (userId: string | undefined, items: IEventItem | undefined, eventId: string | undefined) => void;

export const addToWishlist: TAddToWishlistEvent = (userId, item, eventId) => {
  const items = [{ ...createProductItemUsingData(item), isRegistered: !!userId }];
  const totalPrice = calculateTotalPrice(items);
  clearEcommerce();
  checkAndCallGTag({
    ecommerce: {
      currency: 'EUR',
      items,
      value: totalPrice,
    },
    event: EAnalyticEventName.add_to_wishlist,
    event_id: eventId,
    user_id: userId,
  });
};


type TAddToCartEvent = (userId: string | undefined, items: IEventItem | undefined, eventId: string | undefined) => void;

export const addToCart: TAddToCartEvent = (userId, item, eventId) => {
  const items = [{ ...createProductItemUsingData(item), isRegistered: !!userId }];
  const totalPrice = calculateTotalPrice(items);
  clearEcommerce();
  checkAndCallGTag({
    ecommerce: {
      currency: 'EUR', // optional (recommended if the provide multi currencies)
      items,
      value: totalPrice,
    },
    event: EAnalyticEventName.add_to_cart,
    event_id: eventId,
    user_id: userId, // if available at the moment
  });
};

type TViewItemEvent = (userId: string | undefined, items: IEventItem | undefined, eventId: string | undefined) => void;

export const viewItem: TViewItemEvent = (userId, item, eventId) => {
  const items = [{ ...item, isRegistered: !!userId }];
  const totalPrice = calculateTotalPrice(items);
  clearEcommerce();
  checkAndCallGTag({
    ecommerce: {
      currency: 'EUR', // optional (recommended if the provide multi currencies)
      items,
      value: totalPrice,
    },
    event: EAnalyticEventName.view_item,
    event_id: eventId,
    user_id: userId, // if available at the moment
  });
};
