export type PRODUCTS_LIST_GET_REQUEST = 'PRODUCTS_LIST_GET_REQUEST';
export const PRODUCTS_LIST_GET_REQUEST = 'PRODUCTS_LIST_GET_REQUEST';
export type PRODUCTS_LIST_GET_SUCCESS = 'PRODUCTS_LIST_GET_SUCCESS';
export const PRODUCTS_LIST_GET_SUCCESS = 'PRODUCTS_LIST_GET_SUCCESS';
export type PRODUCTS_LIST_GET_FAILED = 'PRODUCTS_LIST_GET_FAILED';
export const PRODUCTS_LIST_GET_FAILED = 'PRODUCTS_LIST_GET_FAILED';

export type PRODUCTS_LIST_SHORT_GET_REQUEST = 'PRODUCTS_LIST_SHORT_GET_REQUEST';
export const PRODUCTS_LIST_SHORT_GET_REQUEST = 'PRODUCTS_LIST_SHORT_GET_REQUEST';
export type PRODUCTS_LIST_SHORT_GET_SUCCESS = 'PRODUCTS_LIST_SHORT_GET_SUCCESS';
export const PRODUCTS_LIST_SHORT_GET_SUCCESS = 'PRODUCTS_LIST_SHORT_GET_SUCCESS';
export type PRODUCTS_LIST_SHORT_GET_FAILED = 'PRODUCTS_LIST_SHORT_GET_FAILED';
export const PRODUCTS_LIST_SHORT_GET_FAILED = 'PRODUCTS_LIST_SHORT_GET_FAILED';

export type PRODUCTS_LIST_GET_FILTER_REQUEST = 'PRODUCTS_LIST_GET_FILTER_REQUEST';
export const PRODUCTS_LIST_GET_FILTER_REQUEST = 'PRODUCTS_LIST_GET_FILTER_REQUEST';
export type PRODUCTS_LIST_GET_FILTER_SUCCESS = 'PRODUCTS_LIST_GET_FILTER_SUCCESS';
export const PRODUCTS_LIST_GET_FILTER_SUCCESS = 'PRODUCTS_LIST_GET_FILTER_SUCCESS';
export type PRODUCTS_LIST_GET_FILTER_FAILED = 'PRODUCTS_LIST_GET_FILTER_FAILED';
export const PRODUCTS_LIST_GET_FILTER_FAILED = 'PRODUCTS_LIST_GET_FILTER_FAILED';

export type PRODUCTS_LIST_MOBILE_FILTER_GET_REQUEST = 'PRODUCTS_LIST_MOBILE_FILTER_GET_REQUEST';
export const PRODUCTS_LIST_MOBILE_FILTER_GET_REQUEST = 'PRODUCTS_LIST_MOBILE_FILTER_GET_REQUEST';
export type PRODUCTS_LIST_MOBILE_FILTER_GET_SUCCESS = 'PRODUCTS_LIST_MOBILE_FILTER_GET_SUCCESS';
export const PRODUCTS_LIST_MOBILE_FILTER_GET_SUCCESS = 'PRODUCTS_LIST_MOBILE_FILTER_GET_SUCCESS';
export type PRODUCTS_LIST_MOBILE_FILTER_GET_FAILED = 'PRODUCTS_LIST_MOBILE_FILTER_GET_FAILED';
export const PRODUCTS_LIST_MOBILE_FILTER_GET_FAILED = 'PRODUCTS_LIST_MOBILE_FILTER_GET_FAILED';

export type PRODUCTS_LIST_GET_NEXT_PAGE_REQUEST = 'PRODUCTS_LIST_GET_NEXT_PAGE_REQUEST';
export const PRODUCTS_LIST_GET_NEXT_PAGE_REQUEST = 'PRODUCTS_LIST_GET_NEXT_PAGE_REQUEST';
export type PRODUCTS_LIST_GET_NEXT_PAGE_SUCCESS = 'PRODUCTS_LIST_GET_NEXT_PAGE_SUCCESS';
export const PRODUCTS_LIST_GET_NEXT_PAGE_SUCCESS = 'PRODUCTS_LIST_GET_NEXT_PAGE_SUCCESS';
export type PRODUCTS_LIST_GET_NEXT_PAGE_FAILED = 'PRODUCTS_LIST_GET_NEXT_PAGE_FAILED';
export const PRODUCTS_LIST_GET_NEXT_PAGE_FAILED = 'PRODUCTS_LIST_GET_NEXT_PAGE_FAILED';

export type PRODUCTS_LIST_FILTER_CHANGE = 'PRODUCTS_LIST_FILTER_CHANGE';
export const PRODUCTS_LIST_FILTER_CHANGE = 'PRODUCTS_LIST_FILTER_CHANGE';

export type PRODUCTS_LIST_FILTER_MOBILE_CHANGE = 'PRODUCTS_LIST_FILTER_MOBILE_CHANGE';
export const PRODUCTS_LIST_FILTER_MOBILE_CHANGE = 'PRODUCTS_LIST_FILTER_MOBILE_CHANGE';

export type PRODUCTS_LIST_FILTER_MOBILE_APPLY = 'PRODUCTS_LIST_FILTER_MOBILE_APPLY';
export const PRODUCTS_LIST_FILTER_MOBILE_APPLY = 'PRODUCTS_LIST_FILTER_MOBILE_APPLY';

export type PRODUCTS_LIST_FILTER_MOBILE_CANCEL = 'PRODUCTS_LIST_FILTER_MOBILE_CANCEL';
export const PRODUCTS_LIST_FILTER_MOBILE_CANCEL = 'PRODUCTS_LIST_FILTER_MOBILE_CANCEL';

export type PRODUCTS_LIST_FILTER_MOBILE_CLEAR = 'PRODUCTS_LIST_FILTER_MOBILE_CLEAR';
export const PRODUCTS_LIST_FILTER_MOBILE_CLEAR = 'PRODUCTS_LIST_FILTER_MOBILE_CLEAR';

export type PRODUCTS_LIST_FILTER_CLEAR = 'PRODUCTS_LIST_FILTER_CLEAR';
export const PRODUCTS_LIST_FILTER_CLEAR = 'PRODUCTS_LIST_FILTER_CLEAR';

export type PRODUCTS_LIST_SORT_CHANGE = 'PRODUCTS_LIST_SORT_CHANGE';
export const PRODUCTS_LIST_SORT_CHANGE = 'PRODUCTS_LIST_SORT_CHANGE';

export type PRODUCTS_LIST_SET_DEFAULT_SEO_INFO = 'PRODUCTS_LIST_SET_DEFAULT_SEO_INFO';
export const PRODUCTS_LIST_SET_DEFAULT_SEO_INFO = 'PRODUCTS_LIST_SET_DEFAULT_SEO_INFO';

export type PRODUCTS_LIST_FINISH_INIT = 'PRODUCTS_LIST_FINISH_INIT';
export const PRODUCTS_LIST_FINISH_INIT = 'PRODUCTS_LIST_FINISH_INIT';
