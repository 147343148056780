import { currency } from '@constants/currency';
import { EGiftCartIssueField, ICartItem } from '@interfaces/cart';
import { IGiftCardStore } from '@interfaces/giftCard/store';

export const giftCardIssueInit = {
  [EGiftCartIssueField.emailAt]: '',
  [EGiftCartIssueField.emailBody]: '',
  [EGiftCartIssueField.emailFrom]: '',
  [EGiftCartIssueField.emailTo]: '',
  [EGiftCartIssueField.giftCardDesign]: -1,
  [EGiftCartIssueField.giftCardGenerationCode]: null,
  [EGiftCartIssueField.recipientName]: '',
};

const giftCardInit: ICartItem = {
  giftCardIssue: {
    ...giftCardIssueInit,
  },
  price: {
    currencyName: currency.EUR,
    price: '50',
    priceDiscount: '0',
  },
  productId: 107399,
  qty: 1,
  sizeId: '476306',
};

export const initialState: IGiftCardStore = {
  error: false,
  giftCard: { ...giftCardInit },
  giftCardDesignOptions: [],
  loading: {
    init: false,
    product: true,
  },
  product: null,
};
