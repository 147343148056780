export enum ESort {
  CREATED = 'created',
  PRICE = 'price',
  SALE = 'sale',
}

export enum ESortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export enum ESortType {
  newFirst = 'created',
  price_asc = 'asc',
  price_desc = 'desc',
  sale = 'sale',

}
export const sortByTrending = 'trending,asc';
export const defaultSorting = ESortType.newFirst;
