import { IPopularCategoriesActions } from '@interfaces/popularCategories/actions';
import { IPopularCategoriesStore } from '@interfaces/popularCategories/reducer';
import { Reducer } from 'redux';
import * as actions from '../../constants/actions/popularCategories';
import { initialState } from './initState';

export const popularCategoriesReducer: Reducer<IPopularCategoriesStore, IPopularCategoriesActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case actions.POPULAR_CATEGORIES_REQUEST:
      return { ...state, loading: true };
    case actions.POPULAR_CATEGORIES_SUCCESS:
      return { ...state, list: action.payload, loading: false };
    case actions.POPULAR_CATEGORIES_FAILED:
      return { ...state, loading: false };
    default:
      return state;
  }
};
