import { ICommonReducer } from '@interfaces/common/reducer';

export const initialState: ICommonReducer = {
  closedNotifications: [],
  homePageProducts: [],
  loaded: {
    homePageProducts: false,
    recentProducts: false,
    suitableProducts: false,
    trendingProducts: false,
  },
  loading: {
    homePageProducts: false,
    recentProducts: false,
    suitableProducts: false,
    trendingProducts: false,
  },
  recentProducts: [],
  recentProductsState: false,
  suitableProducts: [],
  translationModal: {
    isOpen: false,
    key: '',
    options: null,
  },
  trendingProducts: [],
};
