import * as actions from '@constants/actions/sizeTable';
import { ISizeTableActions } from '@interfaces/sizeTable/actions';
import { ISizeTableReducer } from '@interfaces/sizeTable/reducer';

export const initialState: ISizeTableReducer = {
  loading: false,
  table: [],
};

export const sizeTableReducer = (state = initialState, action: ISizeTableActions) => {
  switch (action.type) {
    case actions.SIZE_TABLE_GET_REQUEST:
      return { ...state, loading: true, table: [] };
    case actions.SIZE_TABLE_GET_SUCCESS:
      return { ...state, loading: false, table: action.payload };
    case actions.SIZE_TABLE_GET_FAILED:
      return { ...state, loading: false };
    default:
      return state;
  }
};
