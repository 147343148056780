import { FieldType, ICountry } from './interfaces';

export const fieldNames: Record<FieldType, FieldType> = {
  address: 'address',
  city: 'city',
  country: 'country',
  countryCode: 'countryCode',
  dateOfBirth: 'dateOfBirth',
  email: 'email',
  exists: 'exists',
  firstName: 'firstName',
  gender: 'gender',
  languageCode: 'languageCode',
  lastName: 'lastName',
  magazineRequested: 'magazineRequested',
  nationalId: 'nationalId',
  newsletterRequested: 'newsletterRequested',
  password: 'password',
  passwordagain: 'passwordagain',
  phone: 'phone',
  postalCode: 'postalCode',
  promoRequested: 'promoRequested',
  signUpId: 'signUpId',
};

export const countries: ICountry[] = [
  {
    code: 'EE',
    languageCodes: ['et', 'en', 'ru']
  },
  {
    code: 'EU',
    languageCodes: ['en']
  },
  {
    code: 'FI',
    languageCodes: ['fi']
  },
  {
    code: 'LT',
    languageCodes: ['lt']
  },
  {
    code: 'LV',
    languageCodes: ['lv', 'ru']
  }
];

export const getCountryByLanguageCode = (languageCode, currentCountryCode) => {
  const countryCodes = countries.filter((c) => c.languageCodes.includes(languageCode));
  if(!countryCodes || countryCodes.length === 0 || countryCodes.map((c) => c.code).includes(currentCountryCode)) {
    return currentCountryCode;
  }

  return countryCodes[0].code;
};

export const currentDateToString = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
};

export const convertCamelCaseObjectToHyphenObject = (inputObject: any) => {
  const outputObject: Record<string, string> = {};

  [...Object.keys(inputObject)].forEach((key)  => {
    const newKey = key.replace(/[A-Z]/g, (m) => '-' + m.toLowerCase());
    outputObject[newKey] = inputObject[key];
  });

  return outputObject;
};

export const convertHyphenObjectToCamelCaseObject = (inputObject: any) => {
  const outputObject: Record<string, string> = {};

  [...Object.keys(inputObject)].forEach((key)  => {
    const newKey = key.replace(/-([a-z])/g, (m) => m[1].toUpperCase());
    outputObject[newKey] = inputObject[key];
  });

  return outputObject;
};
