import { IBlogActions } from '@interfaces/blog/actions';
import { IBlogReducer } from '@interfaces/blog/reducer';
import { Reducer } from 'redux';

import * as actions from '../../constants/actions/blog';
import { initialState } from './initState';

export const blogReducer: Reducer<IBlogReducer, IBlogActions> = (state = initialState, action) => {
  switch (action.type) {
    case actions.BLOG_GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload.products,
      };
    default:
      return state;
  }
};
