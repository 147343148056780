export const capitalizeFirstLetter = (stringTo: string | JSX.Element): string | JSX.Element => {
  return typeof stringTo === 'string' ? stringTo.charAt(0).toUpperCase() + stringTo.slice(1).toLowerCase() : stringTo;
};


export const containsEncodedComponents = (str: string): boolean => {
  // ie ?,=,&,/ etc
  return decodeURI(str) !== decodeURIComponent(str);
};

export const decodeURIComponentPlus = (str: string): string => {
  return decodeURIComponent(str.replace(/\+/g, ' '));
};

export const checkAndDecodeURIComponentPlus = (str: string): string => {
  return containsEncodedComponents(str) ? checkAndDecodeURIComponentPlus(decodeURIComponentPlus(str)) : str;
};

export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
