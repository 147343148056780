export const PAGE_DATA_GET_REQUEST = 'PAGE_DATA_GET_REQUEST';
export type PAGE_DATA_GET_REQUEST = 'PAGE_DATA_GET_REQUEST';
export const PAGE_DATA_GET_SUCCESS = 'PAGE_DATA_GET_SUCCESS';
export type PAGE_DATA_GET_SUCCESS = 'PAGE_DATA_GET_SUCCESS';
export const PAGE_DATA_GET_FAILED = 'PAGE_DATA_GET_FAILED';
export type PAGE_DATA_GET_FAILED = 'PAGE_DATA_GET_FAILED';

export const PAGE_DATA_UPDATE_REQUEST = 'PAGE_DATA_UPDATE_REQUEST';
export type PAGE_DATA_UPDATE_REQUEST = 'PAGE_DATA_UPDATE_REQUEST';
export const PAGE_DATA_UPDATE_SUCCESS = 'PAGE_DATA_UPDATE_SUCCESS';
export type PAGE_DATA_UPDATE_SUCCESS = 'PAGE_DATA_UPDATE_SUCCESS';
export const PAGE_DATA_UPDATE_FAILED = 'PAGE_DATA_UPDATE_FAILED';
export type PAGE_DATA_UPDATE_FAILED = 'PAGE_DATA_UPDATE_FAILED';

export const PAGE_DATA_SHALLOW_UPDATE_REQUEST = 'PAGE_DATA_SHALLOW_UPDATE_REQUEST';
export type PAGE_DATA_SHALLOW_UPDATE_REQUEST = 'PAGE_DATA_SHALLOW_UPDATE_REQUEST';
export const PAGE_DATA_SHALLOW_UPDATE_SUCCESS = 'PAGE_DATA_SHALLOW_UPDATE_SUCCESS';
export type PAGE_DATA_SHALLOW_UPDATE_SUCCESS = 'PAGE_DATA_SHALLOW_UPDATE_SUCCESS';
export const PAGE_DATA_SHALLOW_UPDATE_FAILED = 'PAGE_DATA_SHALLOW_UPDATE_FAILED';
export type PAGE_DATA_SHALLOW_UPDATE_FAILED = 'PAGE_DATA_SHALLOW_UPDATE_FAILED';

export type PAGE_DATA_SET_LOCALE = 'PAGE_DATA_SET_LOCALE';
export const PAGE_DATA_SET_LOCALE = 'PAGE_DATA_SET_LOCALE';
export type PAGE_DATA_SET_LANGUAGE = 'PAGE_DATA_SET_LANGUAGE';
export const PAGE_DATA_SET_LANGUAGE = 'PAGE_DATA_SET_LANGUAGE';

export type PAGE_DATA_SET_SEO = 'PAGE_DATA_SET_SEO';
export const PAGE_DATA_SET_SEO = 'PAGE_DATA_SET_SEO';

export const PAGE_DATA_GET_CATEGORY_WRAPPED_REQUEST = 'PAGE_DATA_GET_CATEGORY_WRAPPED_REQUEST';
export type PAGE_DATA_GET_CATEGORY_WRAPPED_REQUEST = 'PAGE_DATA_GET_CATEGORY_WRAPPED_REQUEST';
export const PAGE_DATA_GET_CATEGORY_WRAPPED_SUCCESS = 'PAGE_DATA_GET_CATEGORY_WRAPPED_SUCCESS';
export type PAGE_DATA_GET_CATEGORY_WRAPPED_SUCCESS = 'PAGE_DATA_GET_CATEGORY_WRAPPED_SUCCESS';
export const PAGE_DATA_GET_CATEGORY_WRAPPED_FAILED = 'PAGE_DATA_GET_CATEGORY_WRAPPED_FAILED';
export type PAGE_DATA_GET_CATEGORY_WRAPPED_FAILED = 'PAGE_DATA_GET_CATEGORY_WRAPPED_FAILED';

export const CONTENT_PAGE_DATA_GET_REQUEST = 'CONTENT_PAGE_DATA_GET_REQUEST';
export type CONTENT_PAGE_DATA_GET_REQUEST = 'CONTENT_PAGE_DATA_GET_REQUEST';
export const CONTENT_PAGE_DATA_GET_SUCCESS = 'CONTENT_PAGE_DATA_GET_SUCCESS';
export type CONTENT_PAGE_DATA_GET_SUCCESS = 'CONTENT_PAGE_DATA_GET_SUCCESS';
export const CONTENT_PAGE_DATA_GET_FAILED = 'CONTENT_PAGE_DATA_GET_FAILED';
export type CONTENT_PAGE_DATA_GET_FAILED = 'CONTENT_PAGE_DATA_GET_FAILED';

export const ALL_TRANSLATIONS_GET_REQUEST = 'ALL_TRANSLATIONS_GET_REQUEST';
export type ALL_TRANSLATIONS_GET_REQUEST = 'ALL_TRANSLATIONS_GET_REQUEST';
export const ALL_TRANSLATIONS_GET_SUCCESS = 'ALL_TRANSLATIONS_GET_SUCCESS';
export type ALL_TRANSLATIONS_GET_SUCCESS = 'ALL_TRANSLATIONS_GET_SUCCESS';
export const ALL_TRANSLATIONS_GET_FAILED = 'ALL_TRANSLATIONS_GET_FAILED';
export type ALL_TRANSLATIONS_GET_FAILED = 'ALL_TRANSLATIONS_GET_FAILED';

export const PAGE_DATA_GOTOPAGE = 'PAGE_DATA_GOTOPAGE';
export type PAGE_DATA_GOTOPAGE = 'PAGE_DATA_GOTOPAGE';

export const PAGE_DATA_PRIVACY_REQUEST = 'PAGE_DATA_PRIVACY_REQUEST';
export type PAGE_DATA_PRIVACY_REQUEST = 'PAGE_DATA_PRIVACY_REQUEST';
export const PAGE_DATA_PRIVACY_SUCCESS = 'PAGE_DATA_PRIVACY_SUCCESS';
export type PAGE_DATA_PRIVACY_SUCCESS = 'PAGE_DATA_PRIVACY_SUCCESS';
export const PAGE_DATA_PRIVACY_FAILED = 'PAGE_DATA_PRIVACY_FAILED';
export type PAGE_DATA_PRIVACY_FAILED = 'PAGE_DATA_PRIVACY_FAILED';

export const PAGE_DATA_SITE_SETTINGS_SAVE = 'PAGE_DATA_SITE_SETTINGS_SAVE';
export type PAGE_DATA_SITE_SETTINGS_SAVE = 'PAGE_DATA_SITE_SETTINGS_SAVE';

export const PAGE_DATA_GET_NAVBAR_REQUEST = 'PAGE_DATA_GET_NAVBAR_REQUEST';
export type PAGE_DATA_GET_NAVBAR_REQUEST = 'PAGE_DATA_GET_NAVBAR_REQUEST';
export const PAGE_DATA_GET_NAVBAR_SUCCESS = 'PAGE_DATA_GET_NAVBAR_SUCCESS';
export type PAGE_DATA_GET_NAVBAR_SUCCESS = 'PAGE_DATA_GET_NAVBAR_SUCCESS';
export const PAGE_DATA_GET_NAVBAR_FAILED = 'PAGE_DATA_GET_NAVBAR_FAILED';
export type PAGE_DATA_GET_NAVBAR_FAILED = 'PAGE_DATA_GET_NAVBAR_FAILED';

export const PAGE_DATA_GET_PRIVATE_POLICY_REQUEST = 'PAGE_DATA_GET_PRIVATE_POLICY_REQUEST';
export type PAGE_DATA_GET_PRIVATE_POLICY_REQUEST = 'PAGE_DATA_GET_PRIVATE_POLICY_REQUEST';
export const PAGE_DATA_GET_PRIVATE_POLICY_SUCCESS = 'PAGE_DATA_GET_PRIVATE_POLICY_SUCCESS';
export type PAGE_DATA_GET_PRIVATE_POLICY_SUCCESS = 'PAGE_DATA_GET_PRIVATE_POLICY_SUCCESS';
export const PAGE_DATA_GET_PRIVATE_POLICY_FAILED = 'PAGE_DATA_GET_PRIVATE_POLICY_FAILED';
export type PAGE_DATA_GET_PRIVATE_POLICY_FAILED = 'PAGE_DATA_GET_PRIVATE_POLICY_FAILED';

export const PAGE_DATA_GET_PAGES_REQUEST = 'PAGE_DATA_GET_PAGES_REQUEST';
export type PAGE_DATA_GET_PAGES_REQUEST = 'PAGE_DATA_GET_PAGES_REQUEST';
export const PAGE_DATA_GET_PAGES_SUCCESS = 'PAGE_DATA_GET_PAGES_SUCCESS';
export type PAGE_DATA_GET_PAGES_SUCCESS = 'PAGE_DATA_GET_PAGES_SUCCESS';
export const PAGE_DATA_GET_PAGES_FAILED = 'PAGE_DATA_GET_PAGES_FAILED';
export type PAGE_DATA_GET_PAGES_FAILED = 'PAGE_DATA_GET_PAGES_FAILED';
