import { IStoreActions } from '@interfaces/index';
import { IStore } from '@interfaces/store';
import { accountReducer } from '@reducers/account';
import { initialState as accountInitialState } from '@reducers/account/initState';
import { alertReducer } from '@reducers/alert';
import { initialState as alertInitialState } from '@reducers/alert/initState';
import { blogReducer } from '@reducers/blog';
import { initialState as blogInitialState } from '@reducers/blog/initState';
import { commonReducer } from '@reducers/common';
import { initialState as commonInitialState } from '@reducers/common/initState';
import { nativeSettingsReducer } from '@reducers/nativeSettings';
import { initialState as nativeSettingsInitialState } from '@reducers/nativeSettings/initState';
import { pageReducer } from '@reducers/page';
import { initialState as pageInitialState } from '@reducers/page/initState';
import { initialState as performanceInitialState, performanceReducer } from '@reducers/performance';
import { productDetailsReducer } from '@reducers/productDetails';
import { initialState as productDetailsInitialState } from '@reducers/productDetails/initState';
import { productsListReducer } from '@reducers/productsList';
import { initialState as productsListInitialState } from '@reducers/productsList/initState';
import { searchReducer } from '@reducers/search';
import { initialState as searchInitialState } from '@reducers/search/initState';
import { translationsReducer } from '@reducers/translations';
import { wishListReducer } from '@reducers/wishList';
import { initialState as wishListInitialState } from '@reducers/wishList/initState';
import { AnyAction, CombinedState, combineReducers, Reducer, ReducersMapObject } from 'redux';

import { authReducer } from './auth';
import { initialState as authInitialState } from './auth/initState';
import { cartReducer } from './cart';
import { initialState as cartInitialState } from './cart/initState';
import { contextReducer } from './context';
import { initialState as contextInitialState } from './context/initState';
import { giftCardReducer } from './giftCard';
import { initialState as giftCardInitialState } from './giftCard/initState';
import { lookBookReducer } from './lookbook';
import { initialState as lookBookInitialState } from './lookbook/initState';
import { lotteryReducer } from './lottery';
import { initialState as lotteryInitialState } from './lottery/initState';
import { loyaltyProgramReducer } from './loyaltyProgram';
import { initialState as loyaltyProgramInitialState } from './loyaltyProgram/initState';
import { navigationReducer } from './navigation';
import { initialState as navigationInitialState } from './navigation/initState';
import { initialState as pageDataInitialState, pageDataReducer } from './pageData';
import { popularCategoriesReducer } from './popularCategories';
import { initialState as popularCategoriesInitialState } from './popularCategories/initState';
import { sizeTableReducer } from './sizeTable';
import { initialState as sizeTableInitialState } from './sizeTable/index';
import { storesReducer } from './stores';
import { initialState as storesInitialState } from './stores/initState';
import { initialState as translationsInitialState } from './translations/initState';

export const HYDRATE = '__NEXT_REDUX_WRAPPER_HYDRATE__';

export const withHydrate = (reducer, key: string): Reducer<any> => (state = undefined, action) => {
  if (action.type === HYDRATE && action.payload?.hasOwnProperty(key)) {
    return { ...state, ...action.payload[key] };
  } else {
    return reducer(state, action);
  }
};

const reducers: ReducersMapObject<IStore, any> = {
  account: withHydrate(accountReducer, 'account'),
  alert: withHydrate(alertReducer, 'alert'),
  auth: withHydrate(authReducer, 'auth'),
  blog: withHydrate(blogReducer, 'blog'),
  cart: withHydrate(cartReducer, 'cart'),
  common: withHydrate(commonReducer, 'common'),
  context: withHydrate(contextReducer, 'context'),
  giftCard: withHydrate(giftCardReducer, 'giftCard'),
  lookBook: withHydrate(lookBookReducer, 'lookBook'),
  lottery: withHydrate(lotteryReducer, 'lottery'),
  loyaltyProgram: loyaltyProgramReducer,
  nativeSettings: withHydrate(nativeSettingsReducer, 'nativeSettings'),
  navigation: withHydrate(navigationReducer, 'navigation'),
  page: withHydrate(pageReducer, 'page'),
  pageData: withHydrate(pageDataReducer, 'pageData'),
  performance: withHydrate(performanceReducer, 'performance'),
  popularCategories: withHydrate(popularCategoriesReducer, 'popularCategories'),
  productDetails: withHydrate(productDetailsReducer, 'productDetails'),
  productsList: withHydrate(productsListReducer, 'productsList'),
  search: withHydrate(searchReducer, 'search'),
  sizeTable: withHydrate(sizeTableReducer, 'sizeTable'),
  stores: withHydrate(storesReducer, 'stores'),
  translations: withHydrate(translationsReducer, 'translations'),
  wishList: withHydrate(wishListReducer, 'wishList'),
};

export const combinedReducer: Reducer<CombinedState<IStore>, IStoreActions & AnyAction> = combineReducers<IStore>(reducers);

export const initState: IStore = {
  account: accountInitialState,
  alert: alertInitialState,
  auth: authInitialState,
  blog: blogInitialState,
  cart: cartInitialState,
  common: commonInitialState,
  context: contextInitialState,
  giftCard: giftCardInitialState,
  lookBook: lookBookInitialState,
  lottery: lotteryInitialState,
  loyaltyProgram: loyaltyProgramInitialState,
  nativeSettings: nativeSettingsInitialState,
  navigation: navigationInitialState,
  page: pageInitialState,
  pageData: pageDataInitialState,
  performance: performanceInitialState,
  popularCategories: popularCategoriesInitialState,
  productDetails: productDetailsInitialState,
  productsList: productsListInitialState,
  search: searchInitialState,
  sizeTable: sizeTableInitialState,
  stores: storesInitialState,
  translations: translationsInitialState,
  wishList: wishListInitialState,
};
