import { Reducer } from 'redux';

import { itemsPerPageOrdersList } from '@constants/index';
import { IAccountActions } from '@interfaces/account/actions';
import {
  ECustomerEmailFinalizes,
  ECustomerEmailFinalizesRemind,
  IAccountStore,
  IUserState,
} from '@interfaces/account/reducer';
import { IAuthActions } from '@interfaces/auth/actions';
import * as actions from '../../constants/actions/account';
import * as actionsAuth from '../../constants/actions/auth';
import { initialState } from './initState';

export const accountReducer: Reducer<IAccountStore, IAccountActions | IAuthActions> = (state = initialState, action) => {
  switch (action.type) {
    case actions.ACCOUNT_GET_PRODUCTS_REQUEST:
      return { ...state, loading: { ...state.loading, products: true } };
    case actions.ACCOUNT_GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, products: false },
        productsInOrders: [...state.productsInOrders, ...action.payload.products],
      };
    case actions.ACCOUNT_GET_PRODUCTS_FAILED:
      return { ...state, loading: { ...state.loading, products: false } };
    case actionsAuth.CUSTOMER_GET_PROFILE_SUCCESS:
      return { ...state, user: action.payload };
    case actions.ACCOUNT_SAVE_DETAILS_REQUEST:
      return { ...state, loading: { ...state.loading, saveDetails: true } };
    case actions.ACCOUNT_SAVE_DETAILS_SUCCESS:
      const newDataAttributes = { ...action.payload.data.attributes };
      if (newDataAttributes.hasOwnProperty('email-change')) {
        delete newDataAttributes['email-change'];
      }
      return {
        ...state,
        loading: { ...state.loading, saveDetails: false },
        user: {
          ...state.user, ...action.payload,
          data: {
            ...state.user?.data, ...action.payload.data,
            attributes: { ...state.user?.data.attributes, ...newDataAttributes },
          },
        },
      };
    case actions.ACCOUNT_SAVE_DETAILS_FAILED:
      return {
        ...state,
        errors: { ...state.errors, user: action.payload?.errors || [] },
        loading: { ...state.loading, saveDetails: false },
      };
    case actions.ACCOUNT_CLEAR_DETAILS_FIELD_ERROR:
      const filteredUserError = [...state.errors.user].filter((errorItem) => errorItem.source?.pointer !== `data/attributes/${action.payload}`);
      return { ...state, errors: { ...state.errors, user: filteredUserError } };
    case actions.ACCOUNT_GET_PAYMENTS_METHODS_REQUEST:
      return { ...state, loading: { ...state.loading, paymentMethods: true } };
    case actions.ACCOUNT_GET_PAYMENTS_METHODS_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, paymentMethods: false },
        paymentMethods: action.payload.data,
      };
    case actions.ACCOUNT_GET_PAYMENTS_METHODS_FAILED:
      return {
        ...state,
        errors: { ...state.errors, payments: action.payload?.errors || [] },
        loading: { ...state.loading, paymentMethods: false },
      };
    case actions.ACCOUNT_GET_DELIVERY_METHODS_REQUEST:
      return { ...state, loading: { ...state.loading, deliveryMethods: true } };
    case actions.ACCOUNT_GET_DELIVERY_METHODS_SUCCESS:
      return {
        ...state,
        deliveryMethods: action.payload.data,
        loading: { ...state.loading, deliveryMethods: false },
      };
    case actions.ACCOUNT_GET_DELIVERY_METHODS_FAILED:
      return {
        ...state,
        errors: { ...state.errors, deliveryMethods: action.payload?.errors || [] },
        loading: { ...state.loading, deliveryMethods: false },
      };
    case actions.ACCOUNT_GET_DELIVERY_METHOD_DETAILS_REQUEST:
      return {
        ...state,
        deliveryMethodDetails: null,
        loading: { ...state.loading, deliveryMethodDetails: true },
      };
    case actions.ACCOUNT_GET_DELIVERY_METHOD_DETAILS_SUCCESS:
      return {
        ...state,
        deliveryMethodDetails: action.payload.data,
        loading: { ...state.loading, deliveryMethodDetails: false },
      };
    case actions.ACCOUNT_GET_DELIVERY_METHOD_DETAILS_FAILED:
      return {
        ...state,
        errors: { ...state.errors, deliveryMethodDetails: action.payload?.errors || [] },
        loading: { ...state.loading, deliveryMethodDetails: false },
      };
    case actions.ACCOUNT_DELIVERY_METHOD_CHANGE_PAGE:
      return {
        ...state,
        deliveryMethodPage: action.payload,
      };
    case actions.ACCOUNT_GET_SHIPMENT_METHODS_REQUEST:
      return { ...state, loading: { ...state.loading, shipmentMethods: true } };
    case actions.ACCOUNT_GET_SHIPMENT_METHODS_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, shipmentMethods: false },
        shipmentMethods: action.payload,
      };
    case actions.ACCOUNT_GET_SHIPMENT_METHODS_FAILED:
      return {
        ...state,
        errors: { ...state.errors, deliveryMethodDetails: action.payload?.errors || [] },
        loading: { ...state.loading, shipmentMethods: false },
      };
    case actions.ACCOUNT_DELIVERY_METHOD_ADD_METHOD:
      return {
        ...state,
        deliveryMethodToAdd: { ...state.deliveryMethodToAdd, methodId: action.payload.shipmentMethodId },
        errors: { ...state.errors, deliveryMethodSave: [] },
      };
    case actions.ACCOUNT_GET_SHIPMENT_LOCATIONS_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, locations: true },
      };
    case actions.ACCOUNT_GET_SHIPMENT_LOCATIONS_SUCCESS:
      return {
        ...state,
        deliveryMethodLocations: action.payload,
        loading: { ...state.loading, locations: false },
      };
    case actions.ACCOUNT_GET_SHIPMENT_LOCATIONS_FAILED:
      return {
        ...state,
        loading: { ...state.loading, locations: false },
      };
    case actions.ACCOUNT_ADD_DELIVERY_METHOD_REQUEST:
      return {
        ...state,
        errors: { ...state.errors, deliveryMethodSave: [] },
        loading: { ...state.loading, deliveryMethodSave: true },
      };
    case actions.ACCOUNT_ADD_DELIVERY_METHOD_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, deliveryMethodSave: false },
      };
    case actions.ACCOUNT_ADD_DELIVERY_METHOD_FAILED:
      return {
        ...state,
        errors: { ...state.errors, deliveryMethodSave: action.payload?.errors || [] },
        loading: { ...state.loading, deliveryMethodSave: false },
      };
    case actions.ACCOUNT_DELETE_DELIVERY_METHOD_REQUEST:
      return { ...state, loading: { ...state.loading, deliveryMethodDelete: true } };
    case actions.ACCOUNT_DELETE_DELIVERY_METHOD_SUCCESS:
      return { ...state, loading: { ...state.loading, deliveryMethodDelete: false } };
    case actions.ACCOUNT_DELETED_DELIVERY_METHOD_FAILED:
      return {
        ...state, errors: { ...state.errors, deliveryMethodDelete: action.payload?.errors || [] },
        loading: { ...state.loading, deliveryMethodDelete: false },
      };
    case actions.ACCOUNT_DELIVERY_METHOD_FORM_UNMOUNT:
      return {
        ...state,
        deliveryMethodDetails: null,
        deliveryMethodLocations: [],
        deliveryMethodToAdd: { ...initialState.deliveryMethodToAdd },
        errors: { ...state.errors, deliveryMethodDetails: [], deliveryMethodDelete: [], deliveryMethodSave: [] },
      };
    case actions.ACCOUNT_GET_ORDERS_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, ordersHistory: true },
        ordersHistory: [],
        ordersHistoryPagination: {
          ...state.ordersHistoryPagination,
          page: 1,
        },
      };
    case actions.ACCOUNT_GET_ORDERS_NEXT_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, ordersHistory: true },
        ordersHistoryPagination: {
          ...state.ordersHistoryPagination,
          page: state.ordersHistoryPagination.page + 1,
        },
      };
    case actions.ACCOUNT_GET_ORDERS_SUCCESS:
      const newOnlineList = [...state.ordersHistory, ...action.payload];
      const pageOnline = Math.ceil(newOnlineList.length / itemsPerPageOrdersList) || 1;
      return {
        ...state,
        loading: { ...state.loading, ordersHistory: false },
        ordersHistory: newOnlineList,
        ordersHistoryPagination: {
          ...state.ordersRetailHistoryPagination,
          hasNextPage: newOnlineList.length === pageOnline * itemsPerPageOrdersList,
          page: pageOnline,
          size: itemsPerPageOrdersList,
        },
      };
    case actions.ACCOUNT_GET_ORDERS_FAILED:
      return {
        ...state,
        errors: { ...state.errors, ordersHistory: action.payload },
        loading: { ...state.loading, ordersHistory: false },
      };
    case actions.ACCOUNT_GET_ORDERS_RETAIL_REQUEST:
      return {
        ...state,
        errors: { ...state.errors, ordersRetailHistory: [] },
        loading: { ...state.loading, ordersRetailHistory: true },
        ordersRetailHistory: [],
        ordersRetailHistoryPagination: {
          ...state.ordersRetailHistoryPagination,
          page: 1,
        },
      };
    case actions.ACCOUNT_GET_ORDERS_RETAIL_NEXT_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, ordersRetailHistory: true },
        ordersRetailHistoryPagination: {
          ...state.ordersRetailHistoryPagination,
          page: state.ordersRetailHistoryPagination.page + 1,
        },
      };
    case actions.ACCOUNT_GET_ORDERS_RETAIL_SUCCESS:
      const newList = [...state.ordersRetailHistory, ...action.payload];
      const page = Math.ceil(newList.length / itemsPerPageOrdersList) || 1;
      return {
        ...state,
        errors: { ...state.errors, ordersRetailHistory: [] },
        loading: { ...state.loading, ordersRetailHistory: false },
        ordersRetailHistory: newList,
        ordersRetailHistoryPagination: {
          ...state.ordersRetailHistoryPagination,
          hasNextPage: newList.length === page * itemsPerPageOrdersList,
          page,
          size: itemsPerPageOrdersList,
        },
      };
    case actions.ACCOUNT_GET_ORDERS_RETAIL_FAILED:
      return {
        ...state,
        errors: { ...state.errors, ordersRetailHistory: action.payload },
        loading: { ...state.loading, ordersRetailHistory: false },
      };
    case actions.ACCOUNT_GET_ORDER_RETAIL_DETAILS_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, ordersRetailDetails: true },
        ordersRetailDetails: null,
      };
    case actions.ACCOUNT_GET_ORDER_RETAIL_DETAILS_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, ordersRetailDetails: false },
        ordersRetailDetails: action.payload,
      };
    case actions.ACCOUNT_GET_ORDER_RETAIL_DETAILS_FAILED:
      return { ...state, loading: { ...state.loading, ordersRetailDetails: false } };
    case actions.ACCOUNT_GET_ORDER_BY_TRANSACTION_REQUEST:
      return { ...state, loading: { ...state.loading, orderByTransaction: true } };
    case actions.ACCOUNT_GET_ORDER_BY_TRANSACTION_SUCCESS:
      return { ...state, loading: { ...state.loading, orderByTransaction: false }, orderByTransaction: action.payload };
    case actions.ACCOUNT_GET_ORDER_BY_TRANSACTION_FAILED:
      return { ...state, loading: { ...state.loading, orderByTransaction: false } };
    case actions.ACCOUNT_GET_ORDER_DETAILS_REQUEST:
      return { ...state, loading: { ...state.loading, orderDetails: true } };
    case actions.ACCOUNT_GET_ORDER_DETAILS_SUCCESS:
      return { ...state, loading: { ...state.loading, orderDetails: false }, orderDetails: action.payload };
    case actions.ACCOUNT_GET_ORDER_DETAILS_FAILED:
      return { ...state, loading: { ...state.loading, orderDetails: false } };
    case actions.ACCOUNT_LINK_SOCIALS_REQUEST:
    case actions.ACCOUNT_UNLINK_SOCIALS_REQUEST:
      return { ...state, errors: { ...state.errors, linkSocial: [] }};
      /*
    case actions.ACCOUNT_LINK_SOCIALS_SUCCESS:
    case actions.ACCOUNT_UNLINK_SOCIALS_SUCCESS:
      return { ...state }; */
    case actions.ACCOUNT_LINK_SOCIALS_FAILED:
    case actions.ACCOUNT_UNLINK_SOCIALS_FAILED:
      return {
        ...state,
        errors: { ...state.errors, linkSocial: action.payload?.errors || [] },
      };
    case actions.ACCOUNT_CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        errors: { ...state.errors, changePassword: [] },
        loading: { ...state.loading, changePassword: true },
      };
    case actions.ACCOUNT_CHANGE_PASSWORD_SUCCESS:
      return { ...state, loading: { ...state.loading, changePassword: false }, orderDetails: action.payload };
    case actions.ACCOUNT_CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        errors: { ...state.errors, changePassword: action.payload?.errors || [] },
        loading: { ...state.loading, changePassword: false },
      };
    case actions.ACCOUNT_GET_EMAIL_TOKEN_REQUEST:
      return {
        ...state,
        customerEmailFinalizesData: null,
      };
    case actions.ACCOUNT_GET_EMAIL_TOKEN_SUCCESS:
      return {
        ...state,
        customerEmailFinalizes: action.payload.data.attributes.exists ? ECustomerEmailFinalizes.exists : ECustomerEmailFinalizes.expired,
        customerEmailFinalizesData: action.payload.data,
      };
    case actions.ACCOUNT_GET_EMAIL_TOKEN_FAILED:
      return {
        ...state,
        customerEmailFinalizes: ECustomerEmailFinalizes.error,
      };
    case actions.ACCOUNT_NEW_EMAIL_TOKEN_REQUEST:
      return {
        ...state,
        customerEmailFinalizesRemind: ECustomerEmailFinalizesRemind.undefined,
        errors: { ...state.errors, customerEmailFinalizesRemindError: [] },
      };
    case actions.ACCOUNT_NEW_EMAIL_TOKEN_SUCCESS:
      return {
        ...state,
        customerEmailFinalizesRemind: action.payload.data?.attributes['already-confirmed'] ? ECustomerEmailFinalizesRemind.confirmed : ECustomerEmailFinalizesRemind.notConfirmed,
      };
    case actions.ACCOUNT_NEW_EMAIL_TOKEN_FAILED:
      return {
        ...state,
        errors: { ...state.errors, customerEmailFinalizesRemindError: action.payload?.errors || [] },
      };
    case actions.ACCOUNT_FINALIZE_EMAIL_TOKEN_REQUEST:
      return {
        ...state,
        errors: { ...state.errors, customerEmailFinalizesError: [] },
      };
    case actions.ACCOUNT_FINALIZE_EMAIL_TOKEN_SUCCESS:
      return {
        ...state,
        customerEmailFinalizes: action.payload.data.attributes.exists ? ECustomerEmailFinalizes.finish : ECustomerEmailFinalizes.expired,

      };
    case actions.ACCOUNT_FINALIZE_EMAIL_TOKEN_FAILED:
      return {
        ...state,
        errors: { ...state.errors, customerEmailFinalizesError: action.payload?.errors || [] },
      };

    case actions.ACCOUNT_NOTIFICATIONS_SETTINGS_SAVE_REQUEST:
      return {
        ...state,
        errors: { ...state.errors, notifications: [] },
      };
    case actions.ACCOUNT_NOTIFICATIONS_SETTINGS_SAVE_SUCCESS:
      const updatedData: IUserState | null = state.user ? {
        ...state.user,
        data: {
          ...state.user?.data,
          attributes: { ...state.user?.data.attributes, subscribers: action.payload.data.attributes.subscribers },
        },
      } : null;
      return {
        ...state,
        user: updatedData,
      };
    case actions.ACCOUNT_NOTIFICATIONS_SETTINGS_SAVE_FAILED:
      return {
        ...state,
        errors: { ...state.errors, notifications: action.payload?.errors || [] },
      };
    case actions.ACCOUNT_DELETE_PAYMENTS_METHODS_REQUEST:
      return {
        ...state,
        errors: { ...state.errors, payments: [] },
      };
    case actions.ACCOUNT_DELETE_PAYMENTS_METHODS_SUCCESS:
      return {
        ...state,
        paymentMethods: state.paymentMethods.filter((item) => item.id !== action.payload?.data?.id),
      };

    case actions.ACCOUNT_DELETE_PAYMENTS_METHODS_FAILED:
      return {
        ...state,
        errors: { ...state.errors, payments: action.payload?.errors || [] },
      };
    case actions.ACCOUNT_GET_ORDER_TRACKING_REQUEST:
      return {
        ...state,
        errors: { ...state.errors, orderTracking: [] },
        loading: { ...state.loading, orderTracking: true },
      };
    case actions.ACCOUNT_GET_ORDER_TRACKING_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, orderTracking: false },
        orderTracking: action.payload,
      };

    case actions.ACCOUNT_GET_ORDER_TRACKING_FAILED:
      return {
        ...state,
        errors: { ...state.errors, orderTracking: action.payload || [] },
        loading: { ...state.loading, orderTracking: false },
      };
    case actions.ACCOUNT_DELETE_REQUEST:
      return {
        ...state,
        errors: { ...state.errors, deleteUser: [] },
        loading: { ...state.loading, deleteUser: true },
      };
    case actions.ACCOUNT_DELETE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, deleteUser: false },
        user: initialState.user,
      };
    case actions.ACCOUNT_DELETE_FAILED:
      return {
        ...state,
        errors: {
          ...state.errors,
          deleteUser: action.payload?.errors,
        },
        loading: { ...state.loading, deleteUser: false },
      };
    case actions.ACCOUNT_EMAIL_CHANGE_CONFIRM_REQUEST:
      return {
        ...state,
        customerEmailChange: null,
      };
    case actions.ACCOUNT_EMAIL_CHANGE_CONFIRM_SUCCESS:
      return {
        ...state,
        customerEmailChange: action.payload?.data?.attributes?.result || false,
      };
    case actions.ACCOUNT_EMAIL_CHANGE_CONFIRM_FAILED:
      return {
        ...state,
        customerEmailChange: false,
      };
    case actions.ACCOUNT_CLOSE_ORDER_DETAILS:
      return { ...state, ordersRetailDetails: null, orderDetails: null };
    case actionsAuth.AUTH_LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
};
