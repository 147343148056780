import { Reducer } from 'redux';

import { ISearchActions } from '@interfaces/search/actions';
import { ISearchReducer } from '@interfaces/search/reducer';
import * as actions from '../../constants/actions/search';
import { initialState } from './initState';

export const searchReducer: Reducer<ISearchReducer, ISearchActions> = (state = initialState, action) => {
  switch (action.type) {
    case actions.SEARCH_PANEL_OPEN:
      return { ...state, isOpenPanel: true };
    case actions.SEARCH_PANEL_CLOSE:
      return { ...state, isOpenPanel: false, inputValue: '', matchingCategories: [], matchingProducts: [] };
    case actions.SEARCH_VALUE_CHANGE:
      return { ...state, inputValue: action.payload };
    case actions.SEARCH_RESULT_GET_REQUEST:
      return { ...state, matchingProducts: [], loadingProducts: true };
    case actions.SEARCH_RESULT_GET_SUCCESS:
      return { ...state, matchingProducts: action.payload.products, loadingProducts: false };
    case actions.SEARCH_RESULT_GET_FAILED:
      return { ...state, loadingProducts: false };
    case actions.SEARCH_CATEGORY_GET_REQUEST:
      return { ...state, matchingCategories: [], loadingCategories: true };
    case actions.SEARCH_CATEGORY_GET_SUCCESS:
      return { ...state, matchingCategories: action.payload, loadingCategories: false };
    case actions.SEARCH_CATEGORY_GET_FAILED:
      return { ...state, loadingCategories: false };
    case actions.SEARCH_MATCHING_PRODUCTS_CLEAR:
      return { ...state, matchingCategories: [], matchingProducts: [] };
    case actions.SEARCH_GENDER_CHANGE:
      return { ...state, gender: action.payload };
    default:
      return state;
  }
};
