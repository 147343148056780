// @ts-nocheck
import { createTheme, Theme } from '@mui/material/styles';
import { ColorsPalette } from '@themes/colors';
import { FontCollection } from '@themes/font';
import { TypographyCollection } from '@themes/typography';

declare module '@mui/styles' {
  interface DefaultTheme extends Theme {
    any: {};
  }
}

const ThemeDenim: Theme = createTheme({
  breakpoints: {
    values: {
      lg: 1280,
      md: 960,
      sm: 600,
      xl: 1920,
      xs: 0,
    },
  },
  buttonCursorDecoration: 'pointer',

  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          '&.Mui-expanded': {
            margin: '3px 0',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          paddingLeft: 24,
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          borderRadius: 5,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          '&.withHidedScroll': {
            overflow: 'hidden',
            position: 'fixed',
          },
          backgroundColor: '#ffffff',
          ...TypographyCollection.fix.fixRalewayNumbers,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paperFullScreen: {
          border: 0,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&$focused': {
            color: ColorsPalette.hoverActionCTA,
          },
          root: {
            '&:hover': {
              border: 'none',
            },
          },
        },
        root: {
          '&$focused': {
            color: ColorsPalette.hoverActionCTA,
          },
          borderRadius: 12,
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: ColorsPalette.tertiary,
          fontFamily: FontCollection.raleway,
          fontSize: '14px',
          fontWeight: 400,
          paddingLeft: '10px',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: ColorsPalette.neutral3,
        },
      },
    },
    MuiList: {
      styleOverrides: {
        padding: {
          paddingBottom: '0',
          paddingTop: '0',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        multiline: {
          padding: 0,
        },
        notchedOutline: {
          '& legend': {
            fontSize: '14px',
            marginLeft: '8px',
          },
          borderColor: 'transparent',
          cursor: 'pointer',
        },
        root: {
          '&:hover&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.26)',
          },
          '&:hover:not($focused) .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
          },
          '@media (hover: none)': {
            '&:hover $notchedOutline': {
              borderColor: 'transparent',
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent',
            },
          },
          borderRadius: 12,
        },
        select: {
          '&:focus': {
            backgroundColor: 'none',
            borderRadius: 12,
          },
          fontWeight: 400,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          paddingBottom: 16,
        },
      },
    },
  },
  customShadows: {
    l: '-0.948454px -0.948454px 40px rgba(255, 255, 255, 0.05), 1.89691px 1.89691px 40px rgba(71, 71, 71, 0.4)',
    s: '-0.948454px -0.948454px 3.79381px rgba(255, 255, 255, 0.05), 4px 4px 12px rgba(0, 0, 0, 0.12)',
  },

  overrides: {
    Mui: {
      focused: {},
    },
    MuiPickersCalendarHeader: {
      daysHeader: {
        display: 'none',
      },
      switchHeader: {
        '&$MuiTypography-root': {
          fontWeight: 700,
        },
      },
      transitionContainer: {
        '& p': {
          fontWeight: 700,
        },
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: ColorsPalette.hoverActionCTA,
        color: ColorsPalette.neutral2,
        fontWeight: 700,
      },
    },
    MuiTypography: {
      root: {},
    },
  },
  palette: {
    action: {
      hover: '#5F1457',
    },
    background: {
      default: '#FFFFFF',
      paper: '#FFFFFF',
    },
    // @ts-ignore
    colors: {
      blue: {
        dark: '#5E86E0',
        light: '#E5ECF3',
      },
      coralPink: {
        dark: '#DD8C7D',
        light: '#FCE8E5',
      },
      mobileMenuLink: '#CACDD0',
      neutral: {
        1: '#EBEBEB',
        2: '#FFFFFF',
      },
      new: {
        dark: '#378A3F',
        light: '#DFFAE1',
      },
      outlet: {
        dark: '#FF7816',
        light: '#FFE4CB',
      },
      overlay: 'rgba(0, 0, 0, 0.3)',
      primary: '#000000',
      saffron: {
        dark: '#BE711E',
        light: '#FFF6C7',
      },
      sales: {
        dark: '#FF0000',
        light: '#FFE2E2',
      },
      secondary: '#B4B8C7',
      tertiary: '#7A7A7A',
      text: '#000000',
      white: '#FFFFFF',
    },
    divider: '#EBEBEB',
    info: {
      contrastText: '#fff',
      dark: '#1976d2',
      light: '#64b5f6',
      main: '#2196f3',
    },
    primary: { main: '#000000' },
    success: {
      contrastText: 'rgba(0, 0, 0, 0.87)',
      dark: '#388e3c',
      light: '#81c784',
      main: '#4caf50',
    },
    text: {
      disabled: '#7A7A7A',
      primary: '#000000',
      secondary: '#B4B8C7',
    },
    warning: {
      contrastText: 'rgba(0, 0, 0, 0.87)',
      dark: '#f57c00',
      light: '#ffb74d',
      main: '#ff9800',
    },
  },
  props: {
    MuiAppBar: {
      elevation: 1,
      variant: 'outlined',
    },
    MuiCard: {
      variant: 'outlined',
    },
    MuiExpansionPanel: {
      square: true,
      variant: 'outlined',
    },
    MuiPaper: {
      square: true,
      variant: 'outlined',
    },
  },
  shadows: [
    '1.89691px 1.89691px 40px rgba(71, 71, 71, 0.4), -0.948454px -0.948454px 40px rgba(255, 255, 255, 0.05)',
    '-0.948454px -0.948454px 3.79381px rgba(255, 255, 255, 0.05), 4px 4px 12px rgba(0, 0, 0, 0.12)',
    '-0.948454px -0.948454px 3.79381px rgba(255, 255, 255, 0.05), 4px 4px 12px rgba(0, 0, 0, 0.12)',
    '-0.948454px -0.948454px 3.79381px rgba(255, 255, 255, 0.05), 4px 4px 12px rgba(0, 0, 0, 0.12)',
    '-0.948454px -0.948454px 3.79381px rgba(255, 255, 255, 0.05), 4px 4px 12px rgba(0, 0, 0, 0.12)',
    '-0.948454px -0.948454px 3.79381px rgba(255, 255, 255, 0.05), 4px 4px 12px rgba(0, 0, 0, 0.12)',
    '-0.948454px -0.948454px 3.79381px rgba(255, 255, 255, 0.05), 4px 4px 12px rgba(0, 0, 0, 0.12)',
    '-0.948454px -0.948454px 3.79381px rgba(255, 255, 255, 0.05), 4px 4px 12px rgba(0, 0, 0, 0.12)',
    '-0.948454px -0.948454px 3.79381px rgba(255, 255, 255, 0.05), 4px 4px 12px rgba(0, 0, 0, 0.12)',
    '-0.948454px -0.948454px 3.79381px rgba(255, 255, 255, 0.05), 4px 4px 12px rgba(0, 0, 0, 0.12)',
    '-0.948454px -0.948454px 3.79381px rgba(255, 255, 255, 0.05), 4px 4px 12px rgba(0, 0, 0, 0.12)',
    '-0.948454px -0.948454px 3.79381px rgba(255, 255, 255, 0.05), 4px 4px 12px rgba(0, 0, 0, 0.12)',
    '-0.948454px -0.948454px 3.79381px rgba(255, 255, 255, 0.05), 4px 4px 12px rgba(0, 0, 0, 0.12)',
    '-0.948454px -0.948454px 3.79381px rgba(255, 255, 255, 0.05), 4px 4px 12px rgba(0, 0, 0, 0.12)',
    '-0.948454px -0.948454px 3.79381px rgba(255, 255, 255, 0.05), 4px 4px 12px rgba(0, 0, 0, 0.12)',
    '-0.948454px -0.948454px 3.79381px rgba(255, 255, 255, 0.05), 4px 4px 12px rgba(0, 0, 0, 0.12)',
  ],
  shape: {
    borderRadius: 12,
  },
  typography: {
    body: {
      blog: {
        bold: {
          xl: {
            fontFamily: FontCollection.raleway,
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: 'bold',
            lineHeight: '160%',
          },
        },
        xl: {
          fontFamily: FontCollection.raleway,
          fontSize: '20px',
          fontStyle: 'normal',
          fontWeight: 'normal',
          lineHeight: '160%',
        },
      },
      bold: {
        s: {
          fontFamily: FontCollection.raleway,
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 'bold',
          lineHeight: '124%',
        },
      },
      regular: {
        l: {
          fontFamily: FontCollection.raleway,
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 'normal',
          lineHeight: '124%',
        },
        s: {
          fontFamily: FontCollection.raleway,
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 'normal',
          lineHeight: '160%',
        },
      },
    },
    body1: {
      boldS: {
        fontFamily: FontCollection.raleway,
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 'bold',
        lineHeight: '124%',
      },
    },
    cart: {
      font: {
        fontFamily: FontCollection.raleway,
      },
    },
    fontFamily: [
      FontCollection.raleway,
      FontCollection.inter,
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    heading: {
      desktop: {
        l: {
          fontFamily: FontCollection.inter,
          fontSize: '56px',
          fontStyle: 'normal',
          fontWeight: '900',
          lineHeight: '112%',
        },
        m: {
          fontFamily: FontCollection.inter,
          fontSize: '32px',
          fontStyle: 'normal',
          fontWeight: 'bold',
          lineHeight: '124%',
        },
        s: {
          fontFamily: FontCollection.inter,
          fontSize: '32px',
          fontStyle: 'normal',
          fontWeight: '900',
          lineHeight: '124%',
        },
      },
      mobile: {
        l: {
          fontFamily: FontCollection.inter,
          fontSize: '40px',
          fontStyle: 'normal',
          fontWeight: '900',
          lineHeight: '124%',
        },
        m: {
          fontFamily: FontCollection.inter,
          fontSize: '26px',
          fontStyle: 'normal',
          fontWeight: '900',
          lineHeight: '124%',
        },
        s: {
          fontFamily: FontCollection.inter,
          fontSize: '18px',
          fontStyle: 'normal',
          fontWeight: 'bold',
          lineHeight: '132%',
        },
        xs: {
          fontFamily: FontCollection.raleway,
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 'bold',
          lineHeight: '132%',
        },
      },
    },
    link: {
      bold: {
        l: {
          fontFamily: FontCollection.raleway,
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 'bold',
          lineHeight: '124%',
        },
        s: {
          fontFamily: FontCollection.raleway,
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 'bold',
          lineHeight: '160%',
        },
        xs: {
          fontFamily: FontCollection.raleway,
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: 'bold',
          lineHeight: '124%',
        },
      },
      mobileMenu: {
        fontFamily: FontCollection.raleway,
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 'bold',
        lineHeight: '280%',
      },
    },
    list: {
      1: {
        fontFamily: FontCollection.raleway,
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        lineHeight: '124%',
      },
      2: {
        fontFamily: FontCollection.raleway,
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        lineHeight: '124%',
      },
    },
  },
});

export default ThemeDenim;
