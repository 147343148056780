import { GetServerSidePropsContext } from 'next';

import { mobileSession } from '@constants/config';
import {
  getCountryLanguagePathCookie,
  setCountryLanguagePathCookie, TContext,
} from '@utils/cookies';
import {
  getLocaleFromLocalStorage,
  setLocaleInLocalStorage,
} from '@utils/localStorage';

type TGetLocale = (context?: TContext | null) => string | null;
export const getLocale: TGetLocale = (context = null) => {
  return mobileSession ? getLocaleFromLocalStorage() : getCountryLanguagePathCookie(context) ?? null;
};

type TSetLocale = (value: string, context?: GetServerSidePropsContext | null) => void;
export const setLocale: TSetLocale = (value, context = null) => {
  mobileSession ? setLocaleInLocalStorage(value) : setCountryLanguagePathCookie(context, value);
};
