export type TRENDING_PRODUCTS_GET_REQUEST = 'TRENDING_PRODUCTS_GET_REQUEST';
export const TRENDING_PRODUCTS_GET_REQUEST = 'TRENDING_PRODUCTS_GET_REQUEST';

export type TRENDING_PRODUCTS_GET_SUCCESS = 'TRENDING_PRODUCTS_GET_SUCCESS';
export const TRENDING_PRODUCTS_GET_SUCCESS = 'TRENDING_PRODUCTS_GET_SUCCESS';

export type TRENDING_PRODUCTS_GET_FAILED = 'TRENDING_PRODUCTS_GET_FAILED';
export const TRENDING_PRODUCTS_GET_FAILED = 'TRENDING_PRODUCTS_GET_FAILED';

export type RECENT_PRODUCTS_GET_REQUEST = 'RECENT_PRODUCTS_GET_REQUEST';
export const RECENT_PRODUCTS_GET_REQUEST = 'RECENT_PRODUCTS_GET_REQUEST';

export type RECENT_PRODUCTS_GET_SUCCESS = 'RECENT_PRODUCTS_GET_SUCCESS';
export const RECENT_PRODUCTS_GET_SUCCESS = 'RECENT_PRODUCTS_GET_SUCCESS';

export type RECENT_PRODUCTS_GET_FAILED = 'RECENT_PRODUCTS_GET_FAILED';
export const RECENT_PRODUCTS_GET_FAILED = 'RECENT_PRODUCTS_GET_FAILED';

export type CLEAR_RECENT_PRODUCTS = 'CLEAR_RECENT_PRODUCTS';
export const CLEAR_RECENT_PRODUCTS = 'CLEAR_RECENT_PRODUCTS';

export type CLEAR_RECENT_PRODUCTS_SUCCESS = 'CLEAR_RECENT_PRODUCTS_SUCCESS';
export const CLEAR_RECENT_PRODUCTS_SUCCESS = 'CLEAR_RECENT_PRODUCTS_SUCCESS';

export type CLEAR_RECENT_PRODUCTS_FAILED = 'CLEAR_RECENT_PRODUCTS_FAILED';
export const CLEAR_RECENT_PRODUCTS_FAILED = 'CLEAR_RECENT_PRODUCTS_FAILED';

export type SUITABLE_PRODUCTS_GET_REQUEST = 'SUITABLE_PRODUCTS_GET_REQUEST';
export const SUITABLE_PRODUCTS_GET_REQUEST = 'SUITABLE_PRODUCTS_GET_REQUEST';

export type SUITABLE_PRODUCTS_GET_SUCCESS = 'SUITABLE_PRODUCTS_GET_SUCCESS';
export const SUITABLE_PRODUCTS_GET_SUCCESS = 'SUITABLE_PRODUCTS_GET_SUCCESS';

export type SUITABLE_PRODUCTS_GET_FAILED = 'SUITABLE_PRODUCTS_GET_FAILED';
export const SUITABLE_PRODUCTS_GET_FAILED = 'SUITABLE_PRODUCTS_GET_FAILED';

export type HOMEPAGE_PRODUCTS_GET_REQUEST = 'HOMEPAGE_PRODUCTS_GET_REQUEST';
export const HOMEPAGE_PRODUCTS_GET_REQUEST = 'HOMEPAGE_PRODUCTS_GET_REQUEST';

export type HOMEPAGE_PRODUCTS_GET_SUCCESS = 'HOMEPAGE_PRODUCTS_GET_SUCCESS';
export const HOMEPAGE_PRODUCTS_GET_SUCCESS = 'HOMEPAGE_PRODUCTS_GET_SUCCESS';

export type HOMEPAGE_PRODUCTS_GET_FAILED = 'HOMEPAGE_PRODUCTS_GET_FAILED';
export const HOMEPAGE_PRODUCTS_GET_FAILED = 'HOMEPAGE_PRODUCTS_GET_FAILED';

export type TRANSLATION_MODAL_OPEN = 'TRANSLATION_MODAL_OPEN';
export const TRANSLATION_MODAL_OPEN = 'TRANSLATION_MODAL_OPEN';

export type TRANSLATION_MODAL_CLOSE = 'TRANSLATION_MODAL_CLOSE';
export const TRANSLATION_MODAL_CLOSE = 'TRANSLATION_MODAL_CLOSE';

export type RECENT_PRODUCTS_STATE = 'RECENT_PRODUCTS_STATE';
export const RECENT_PRODUCTS_STATE = 'RECENT_PRODUCTS_STATE';
