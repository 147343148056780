export const homePage = (country, lang) => `/${country}/${lang}`;
export const cart = 'cart';
export const cartPaymentConfirm = 'paymentConfirm';
export const orderConfirmed = 'order-confirmed';
export const cartStatusPaymentFailed = 'payment-failed';
export const smartIdFinalize = 'smartIdFinalize';

export const orderTrack = 'track';

export const orderConfirmedUrl = (country, lang, id) => homePage(country, lang) + '/' + orderConfirmed + '/' + id;
export const cartPaymentRejectedUrl = (country, lang, id) =>
  homePage(country, lang) + '/' + cart + '/' + cartStatusPaymentFailed + '/' + id;
