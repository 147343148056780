import { CacheProvider, EmotionCache } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import type { NextPage } from 'next';
import { withNavigationHistory } from 'next-navigation-history';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import React, { useEffect } from 'react';

import FontsImport from '@components/main/Fonts';
import ScrollRestore from '@components/main/ScrollRestore';
import LCP from '@components/SEO/LargestContentfulPaint';
import { googleTagEnable } from '@constants/config';
import createEmotionCache from '@utils/createEmotionCache';
import ReduxProvider from '../store/Provider';
import Theme from '../themes/Theme';
import './../wdyr';


import '@components/common/ReactMultiCarousel/assets/styles.css';

import '@components/external/intl-tel-input/intlTelInput.css';
import 'swiper/css';

import '../components/common/TopProgressBar/nprogress.css';
import '../themes/main.css';
import '../themes/swiper-pagination.css';

const AnalyticsWithSettingsCheck = dynamic(() => import('@components/main/Analytics/CheckSettings'), { ssr: false });
const TopProgressBar = dynamic(() => import('@components/common/TopProgressBar'), { ssr: false });
const GTagManager = dynamic(() => import('@components/main/GTag'), { ssr: false });
const NavigatorHistory = dynamic(() => import('@components/main/NavigatorHistory'), { ssr: false });
const ScrollBlock = dynamic(() => import('@components/main/ScrollBlock'), { ssr: false });


// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();


export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  useLayout?: () => boolean
};

type MyAppProps = AppProps & {
  emotionCache?: EmotionCache;
  Component: NextPageWithLayout
};

const MyApp: React.FunctionComponent<MyAppProps> = (props) => {

  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement?.removeChild(jssStyles);
    }

    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual';
    }
  }, []);


  // Use the layout defined at the page level, if available
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const useLayout =  Component.useLayout ? Component.useLayout() : true;

  return useLayout ? (
    <ReduxProvider pageProps={pageProps}>
      <LCP />
      <CacheProvider value={emotionCache}>
        <ThemeProvider theme={Theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon.
            {/*   <CssBaseline /> */}
          {googleTagEnable && (
            <GTagManager />
          )}
          <AnalyticsWithSettingsCheck />
          <NavigatorHistory />
          <TopProgressBar />
          <ScrollRestore>
            <Component {...pageProps} />
          </ScrollRestore>
          <ScrollBlock />
        </ThemeProvider>
      </CacheProvider>
      <FontsImport />
    </ReduxProvider>
  ) : (<>
    <Component {...pageProps} />
  </>);
};


// @ts-ignore
export default withNavigationHistory(MyApp, {
  maxHistoryLength: 50,
  overrideScrollRestoration: false,
});
