import { Reducer } from 'redux';

import { PageTypes } from '@constants/pageTypes';
import { IPageActions } from '@interfaces/page/actions';
import { IStoresActions } from '@interfaces/stores/actions';
import { IStoresReducer } from '@interfaces/stores/reducer';
import * as actionsPage from '../../constants/actions/page';
import * as actions from '../../constants/actions/stores';
import { initialState } from './initState';

export const storesReducer: Reducer<IStoresReducer, IStoresActions | IPageActions> = (state = initialState, action) => {
  switch (action.type) {
    case actions.STORES_GET_LIST_REQUEST:
      return { ...state, filterApplied: { ...state.filter }, loading: { ...state.loading, list: true }, list: [] };
    case actions.STORES_GET_LIST_SUCCESS:
      return { ...state, loading: { ...state.loading, list: false }, list: action.payload };
    case actions.STORES_GET_LIST_FAILED:
      return { ...state, loading: { ...state.loading, list: false } };
    case actions.STORES_FILTER_CHANGE:
      if (action.payload.cityId === 0) {
        return { ...state, filter: { ...state.filter, groupId: null, ...action.payload }, malls: [] };
      } else {
        return { ...state, filter: { ...state.filter, ...action.payload } };
      }
    case actions.STORES_FILTER_COUNTRY_CHANGE:
      return { ...state, filter: { ...initialState.filter, countryId: action.payload } };
    case actions.STORES_GET_BRANDS_REQUEST:
      return { ...state, loading: { ...state.loading, brands: true } };
    case actions.STORES_GET_BRANDS_SUCCESS:
      const isBrandExist = action.payload.find((brand) => brand.brandId === state.filter.brandId);

      return {
        ...state,
        brands: action.payload,
        filter: { ...state.filter, brandId: isBrandExist ? state.filter.brandId : initialState.filter.brandId },
        loading: { ...state.loading, brands: false },
      };
    case actions.STORES_GET_BRANDS_FAILED:
      return { ...state, loading: { ...state.loading, brands: false } };
    case actions.STORES_GET_CITIES_REQUEST:
      return { ...state, loading: { ...state.loading, cities: true } };
    case actions.STORES_GET_CITIES_SUCCESS:
      return { ...state, cities: action.payload, loading: { ...state.loading, cities: false } };
    case actions.STORES_GET_CITIES_FAILED:
      return { ...state, loading: { ...state.loading, cities: false } };
    case actions.STORES_GET_COUNTRIES_SUCCESS:
      return { ...state, countries: action.payload };
    case actions.STORES_GET_MALLS_REQUEST:
      return { ...state, loading: { ...state.loading, malls: true } };
    case actions.STORES_GET_MALLS_SUCCESS:
      return { ...state, malls: action.payload, loading: { ...state.loading, malls: false } };
    case actions.STORES_GET_MALLS_FAILED:
      return { ...state, loading: { ...state.loading, malls: false } };
    case actions.STORES_MAP_MODAL_OPEN:
      return { ...state, showMap: true, showMapItem: action.payload };
    case actions.STORES_MAP_MODAL_CLOSE:
      return { ...state, showMap: false, showMapItem: null };
    case actions.STORES_MODAL_GEOLOCATION_OPEN:
      return { ...state, geolocationModal: true, geolocationError: action.payload };
    case actions.STORES_MODAL_GEOLOCATION_CLOSE:
      return { ...state, geolocationModal: false, geolocationError: null };
    case actionsPage.PAGE_UNMOUNT:
      if (action.payload === PageTypes.stores) {
        return { ...initialState };
      } else {
        return state;
      }
    default:
      return state;
  }
};
