export const CART_CHANGE_PAGE = 'CART_CHANGE_PAGE';
export type CART_CHANGE_PAGE = 'CART_CHANGE_PAGE';
export const CART_CHANGE_SHIPMENT = 'CART_CHANGE_SHIPMENT';
export type CART_CHANGE_SHIPMENT = 'CART_CHANGE_SHIPMENT';
export const CART_CHANGE_PAYMENT = 'CART_CHANGE_PAYMENT';
export type CART_CHANGE_PAYMENT = 'CART_CHANGE_PAYMENT';

export type CART_PROMOCODE_SET = 'CART_PROMOCODE_SET';
export const CART_PROMOCODE_SET = 'CART_PROMOCODE_SET';
export type CART_COUPON_SETITEM = 'CART_COUPON_SETITEM';
export const CART_COUPON_SETITEM = 'CART_COUPON_SETITEM';
export type CART_GIFTCARD_SETITEM = 'CART_GIFTCARD_SETITEM';
export const CART_GIFTCARD_SETITEM = 'CART_GIFTCARD_SETITEM';
export type CART_PROMOCODE_UPDATE_ITEM = 'CART_PROMOCODE_UPDATE_ITEM';
export const CART_PROMOCODE_UPDATE_ITEM = 'CART_PROMOCODE_UPDATE_ITEM';
export type CART_PROMOCODE_REMOVE = 'CART_PROMOCODE_REMOVE';
export const CART_PROMOCODE_REMOVE = 'CART_PROMOCODE_REMOVE';

export const CART_UPDATE_REQUEST = 'CART_UPDATE_REQUEST';
export type CART_UPDATE_REQUEST = 'CART_UPDATE_REQUEST';
export const CART_UPDATE_SUCCESS = 'CART_UPDATE_SUCCESS';
export type CART_UPDATE_SUCCESS = 'CART_UPDATE_SUCCESS';
export const CART_UPDATE_FAILED = 'CART_UPDATE_FAILED';
export type CART_UPDATE_FAILED = 'CART_UPDATE_FAILED';

export const CART_ITEMS_GET_REQUEST = 'CART_ITEMS_GET_REQUEST';
export type CART_ITEMS_GET_REQUEST = 'CART_ITEMS_GET_REQUEST';
export const CART_ITEMS_GET_SUCCESS = 'CART_ITEMS_GET_SUCCESS';
export type CART_ITEMS_GET_SUCCESS = 'CART_ITEMS_GET_SUCCESS';
export const CART_ITEMS_GET_FAILED = 'CART_ITEMS_GET_FAILED';
export type CART_ITEMS_GET_FAILED = 'CART_ITEMS_GET_FAILED';

export const CART_SHIPMENT_FORM_UPDATE = 'CART_SHIPMENT_FORM_UPDATE';
export type CART_SHIPMENT_FORM_UPDATE = 'CART_SHIPMENT_FORM_UPDATE';

export const CART_ADD_ITEM_REQUEST = 'CART_ADD_ITEM_REQUEST';
export type CART_ADD_ITEM_REQUEST = 'CART_ADD_ITEM_REQUEST';
export const CART_ADD_ITEM_SUCCESS = 'CART_ADD_ITEM_SUCCESS';
export type CART_ADD_ITEM_SUCCESS = 'CART_ADD_ITEM_SUCCESS';
export const CART_ADD_ITEM_FAILED = 'CART_ADD_ITEM_FAILED';
export type CART_ADD_ITEM_FAILED = 'CART_ADD_ITEM_FAILED';

export const CART_ADD_DETAIL_ITEM_INFO = 'CART_ADD_DETAIL_ITEM_INFO';
export type CART_ADD_DETAIL_ITEM_INFO = 'CART_ADD_DETAIL_ITEM_INFO';

export const CART_DELETE_ITEM_REQUEST = 'CART_DELETE_ITEM_REQUEST';
export type CART_DELETE_ITEM_REQUEST = 'CART_DELETE_ITEM_REQUEST';
export const CART_DELETE_ITEM_SUCCESS = 'CART_DELETE_ITEM_SUCCESS';
export type CART_DELETE_ITEM_SUCCESS = 'CART_DELETE_ITEM_SUCCESS';
export const CART_DELETE_ITEM_FAILED = 'CART_DELETE_ITEM_FAILED';
export type CART_DELETE_ITEM_FAILED = 'CART_DELETE_ITEM_FAILED';

export const CART_UPDATE_ITEM_REQUEST = 'CART_UPDATE_ITEM_REQUEST';
export type CART_UPDATE_ITEM_REQUEST = 'CART_UPDATE_ITEM_REQUEST';
export const CART_UPDATE_ITEM_SUCCESS = 'CART_UPDATE_ITEM_SUCCESS';
export type CART_UPDATE_ITEM_SUCCESS = 'CART_UPDATE_ITEM_SUCCESS';
export const CART_UPDATE_ITEM_FAILED = 'CART_UPDATE_ITEM_FAILED';
export type CART_UPDATE_ITEM_FAILED = 'CART_UPDATE_ITEM_FAILED';

export const CART_GET_PRODUCTS_REQUEST = 'CART_GET_PRODUCTS_REQUEST';
export type CART_GET_PRODUCTS_REQUEST = 'CART_GET_PRODUCTS_REQUEST';
export const CART_GET_PRODUCTS_SUCCESS = 'CART_GET_PRODUCTS_SUCCESS';
export type CART_GET_PRODUCTS_SUCCESS = 'CART_GET_PRODUCTS_SUCCESS';
export const CART_GET_PRODUCTS_FAILED = 'CART_GET_PRODUCTS_FAILED';
export type CART_GET_PRODUCTS_FAILED = 'CART_GET_PRODUCTS_FAILED';

export const ORDER_CREATE_REQUEST = 'ORDER_CREATE_REQUEST';
export type ORDER_CREATE_REQUEST = 'ORDER_CREATE_REQUEST';
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS';
export type ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS';
export const ORDER_CREATE_FAILED = 'ORDER_CREATE_FAILED';
export type ORDER_CREATE_FAILED = 'ORDER_CREATE_FAILED';

export const CART_GENERATE_BY_TRANSACTION_REQUEST = 'CART_GENERATE_BY_TRANSACTION_REQUEST';
export type CART_GENERATE_BY_TRANSACTION_REQUEST = 'CART_GENERATE_BY_TRANSACTION_REQUEST';
export const CART_GENERATE_BY_TRANSACTION_SUCCESS = 'CART_GENERATE_BY_TRANSACTION_SUCCESS';
export type CART_GENERATE_BY_TRANSACTION_SUCCESS = 'CART_GENERATE_BY_TRANSACTION_SUCCESS';
export const CART_GENERATE_BY_TRANSACTION_FAILED = 'CART_GENERATE_BY_TRANSACTION_FAILED';
export type CART_GENERATE_BY_TRANSACTION_FAILED = 'CART_GENERATE_BY_TRANSACTION_FAILED';

export const CART_LOCATIONS_REQUEST = 'CART_LOCATIONS_REQUEST';
export type CART_LOCATIONS_REQUEST = 'CART_LOCATIONS_REQUEST';
export const CART_LOCATIONS_SUCCESS = 'CART_LOCATIONS_SUCCESS';
export type CART_LOCATIONS_SUCCESS = 'CART_LOCATIONS_SUCCESS';
export const CART_LOCATIONS_FAILED = 'CART_LOCATIONS_FAILED';
export type CART_LOCATIONS_FAILED = 'CART_LOCATIONS_FAILED';

export const CART_COUNTRY_REQUEST = 'CART_COUNTRY_REQUEST';
export type CART_COUNTRY_REQUEST = 'CART_COUNTRY_REQUEST';
export const CART_COUNTRY_SUCCESS = 'CART_COUNTRY_SUCCESS';
export type CART_COUNTRY_SUCCESS = 'CART_COUNTRY_SUCCESS';
export const CART_COUNTRY_FAILED = 'CART_COUNTRY_FAILED';
export type CART_COUNTRY_FAILED = 'CART_COUNTRY_FAILED';

export const CART_CUSTOMER_DELIVERY_METHODS_REQUEST = 'CART_CUSTOMER_DELIVERY_METHODS_REQUEST';
export type CART_CUSTOMER_DELIVERY_METHODS_REQUEST = 'CART_CUSTOMER_DELIVERY_METHODS_REQUEST';
export const CART_CUSTOMER_DELIVERY_METHODS_SUCCESS = 'CART_CUSTOMER_DELIVERY_METHODS_SUCCESS';
export type CART_CUSTOMER_DELIVERY_METHODS_SUCCESS = 'CART_CUSTOMER_DELIVERY_METHODS_SUCCESS';
export const CART_CUSTOMER_DELIVERY_METHODS_FAILED = 'CART_CUSTOMER_DELIVERY_METHODS_FAILED';
export type CART_CUSTOMER_DELIVERY_METHODS_FAILED = 'CART_CUSTOMER_DELIVERY_METHODS_FAILED';

export const CART_CHANGE_FORM = 'CART_CHANGE_FORM';
export type CART_CHANGE_FORM = 'CART_CHANGE_FORM';

export const CART_PAYMENT_FAILED = 'CART_PAYMENT_FAILED';
export type CART_PAYMENT_FAILED = 'CART_PAYMENT_FAILED';

export const CART_TERMS = 'CART_TERMS';
export type CART_TERMS = 'CART_TERMS';

export const ORDER_PAYMENT_CONFIRM_REQUEST = 'ORDER_PAYMENT_CONFIRM_REQUEST';
export type ORDER_PAYMENT_CONFIRM_REQUEST = 'ORDER_PAYMENT_CONFIRM_REQUEST';
export const ORDER_PAYMENT_CONFIRM_SUCCESS = 'ORDER_PAYMENT_CONFIRM_SUCCESS';
export type ORDER_PAYMENT_CONFIRM_SUCCESS = 'ORDER_PAYMENT_CONFIRM_SUCCESS';
export const ORDER_PAYMENT_CONFIRM_FAILED = 'ORDER_PAYMENT_CONFIRM_FAILED';
export type ORDER_PAYMENT_CONFIRM_FAILED = 'ORDER_PAYMENT_CONFIRM_FAILED';

export const CART_COUNT_REQUEST = 'CART_COUNT_REQUEST';
export type CART_COUNT_REQUEST = 'CART_COUNT_REQUEST';
export const CART_COUNT_SUCCESS = 'CART_COUNT_SUCCESS';
export type CART_COUNT_SUCCESS = 'CART_COUNT_SUCCESS';
export const CART_COUNT_FAILED = 'CART_COUNT_FAILED';
export type CART_COUNT_FAILED = 'CART_COUNT_FAILED';

export const CART_MOVE_ERROR_TO_WARNING = 'CART_MOVE_ERROR_TO_WARNING';
export type CART_MOVE_ERROR_TO_WARNING = 'CART_MOVE_ERROR_TO_WARNING';

export const CART_REMOVE_ERROR_TO_WARNING = 'CART_REMOVE_ERROR_TO_WARNING';
export type CART_REMOVE_ERROR_TO_WARNING = 'CART_REMOVE_ERROR_TO_WARNING';

export const CART_ERROR_CLEAR = 'CART_ERROR_CLEAR';
export type CART_ERROR_CLEAR = 'CART_ERROR_CLEAR';
