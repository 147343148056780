import {
  ICustomerPaymentMethod,
  IDeliveryMethod,
  IOrderDeliveryStatus,
  IOrderFromHistory, IOrderFromRetail,
} from '@interfaces/account/index';
import { IShipmentLocation, IShipmentMethod } from '@interfaces/cart';
import { IUserData } from '@interfaces/config/reducer';
import { IApiErrorResponse } from '@interfaces/fetchService';
import { IProductInList } from '@interfaces/product';

export interface IUserState {
  data: IUserData;
}

export interface ICustomerApiError {
  code?: string;
  detail?: string;
  errorCodeMessage?: string;
  nameField?: string;
  source?: {
    pointer: string;
  };
  title?: string;
}

export enum EDeliveryMethodPage {
  main = 'main',
  newMethod = 'newMethod',
  newMethodDetails = 'newMethodDetails'
}
export enum ECustomerEmailFinalizes {
  exists = 'exists',
  expired = 'expired',
  error = 'error',
  finish = 'finish',
  undefined = 'undefined',
}

export enum ECustomerEmailFinalizesRemind {
  confirmed = 'confirmed',
  notConfirmed = 'notConfirmed',
  undefined = 'undefined',
}

export interface IAccountStore {
  customerEmailChange: null | boolean;
  customerEmailFinalizes: ECustomerEmailFinalizes;
  customerEmailFinalizesData: IUserData | null;
  customerEmailFinalizesRemind: ECustomerEmailFinalizesRemind;
  deliveryMethodToAdd: {
    methodId: number | null
  };
  deliveryMethodDetails: null | IDeliveryMethod;
  deliveryMethodLocations: IShipmentLocation[];
  deliveryMethodPage: EDeliveryMethodPage;
  deliveryMethods: IDeliveryMethod[];
  errors: {
    changePassword: ICustomerApiError[];
    customerEmailFinalizesError: ICustomerApiError[];
    customerEmailFinalizesRemindError: ICustomerApiError[];
    deleteUser: ICustomerApiError[];
    deliveryMethodDelete: any[];
    deliveryMethodDetails: any[];
    deliveryMethodSave: ICustomerApiError[];
    deliveryMethods: any[];
    linkSocial: ICustomerApiError[];
    notifications: ICustomerApiError[];
    orderByTransaction: any[];
    orderDetails: any[];
    orderTracking: IApiErrorResponse[];
    ordersHistory: IApiErrorResponse[];
    ordersRetailHistory: IApiErrorResponse[];
    ordersRetailDetails: ICustomerApiError[];
    payments: ICustomerApiError[];
    shipmentMethods: any[];
    user: ICustomerApiError[];
  };
  loading: {
    changePassword: boolean;
    deleteUser: boolean;
    deliveryMethodDelete: boolean;
    deliveryMethodDetails: boolean;
    deliveryMethodSave: boolean;
    deliveryMethods: boolean;
    linkSocial: boolean;
    locations: boolean;
    orderByTransaction: boolean;
    orderDetails: boolean;
    orderTracking: boolean;
    ordersHistory: boolean;
    ordersRetailHistory: boolean;
    ordersRetailDetails: boolean;
    paymentMethods: boolean;
    products: boolean;
    saveDetails: boolean;
    shipmentMethods: boolean;
  };
  orderByTransaction: IOrderFromHistory | null;
  orderDetails: IOrderFromHistory | null;
  orderTracking: IOrderDeliveryStatus[];
  ordersHistory: IOrderFromHistory[];
  ordersHistoryPagination: IOrdersHistoryPagination;
  ordersRetailDetails: IOrderFromRetail | null;
  ordersRetailHistory: IOrderFromRetail[];
  ordersRetailHistoryPagination: {
    hasNextPage: boolean;
    page: number;
    size: number;
  };
  paymentMethods: ICustomerPaymentMethod[];
  productsInOrders: IProductInList[];
  shipmentMethods: IShipmentMethod[];
  user: IUserState | null;
}

export interface IOrdersHistoryPagination {
  hasNextPage: boolean;
  page: number;
  size: number;
}

export enum ECustomerSmartIdFinalizes {
  error = 'error',
  finish = 'finish',
  undefined = 'undefined',
}
