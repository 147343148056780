import { Reducer } from 'redux';

import { PageTypes } from '@constants/pageTypes';
import { IPageActions } from '@interfaces/page/actions';
import { IPageDataActions } from '@interfaces/pageData/actions';
import { IProductDetailsActions } from '@interfaces/productDetails/actions';
import { IProductDetailsReducer } from '@interfaces/productDetails/reducer';
import * as actionsPage from '../../constants/actions/page';
import * as actionsPageData from '../../constants/actions/pageData';
import * as actions from '../../constants/actions/productDetails';
import { initialState } from './initState';

export const productDetailsReducer: Reducer<IProductDetailsReducer, IProductDetailsActions | IPageDataActions | IPageActions> = (state = initialState, action): IProductDetailsReducer => {
  switch (action.type) {
    case actions.PRODUCT_DETAILS_CHANGE_OPTION:
      return { ...state, activeProduct: action.payload, errorUnSelected: false };
    case actionsPageData.PAGE_DATA_UPDATE_REQUEST:
    case actions.PRODUCT_DETAILS_GET_REQUEST:
      return {
        ...state,
        activeProduct: state.productDetailsPrefilled ? state.activeProduct : initialState.activeProduct,
        loading: true,
        productDetails: state.productDetailsPrefilled ? state.productDetails : initialState.productDetails,
      };
    case actions.PRODUCT_DETAILS_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        productDetails: action.payload.products[0] || null,
        productDetailsPrefilled: false,
      };
    case actions.PRODUCT_DETAILS_GET_FAILED:
      return { ...state, loading: false, productDetails: null, productDetailsPrefilled: false };
    case actions.PRODUCT_DETAILS_UNSELECTED_ERROR:
      return { ...state, errorUnSelected: action.payload };
    case actions.PRODUCT_DETAILS_PREFILL:
      if (action.payload && Object.keys(action.payload).length > 0) {
        return {
          ...state,
          activeProduct: {
            color: action.payload.colors[0],
            size: null,
          },
          productDetails: action.payload,
          productDetailsPrefilled: action.payload && Object.keys(action.payload).length > 0,
        };
      } else {
        return { ...state };
      }
    case actionsPage.PAGE_UNMOUNT:
      if (action.payload === PageTypes.productDetail) {
        return { ...initialState };
      } else {
        return state;
      }
    default:
      return state;
  }
};
