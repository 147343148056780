import { Reducer } from 'redux';

import { PageTypes } from '@constants/pageTypes';
import { IPageActions } from '@interfaces/page/actions';
import { IPageStore } from '@interfaces/page/store';
import * as actions from '../../constants/actions/page';
import { initialState } from './initState';

export const pageReducer: Reducer<IPageStore, IPageActions> = (state = initialState, action) => {
  switch (action.type) {
    case actions.PAGE_SIDEMENU_STATE:
      return { ...state, sideMenu: action.payload };
    case actions.PAGE_SIDEMENU_PAGE:
      return { ...state, sideMenuPage: action.payload };
    case actions.PAGE_CHANGE_SIZE:
      return { ...state, listSizeBig: action.payload };
    case actions.PAGE_COUNTRY_SELECT:
      return { ...state, countrySelect: action.payload };
    case actions.MOBILE_MENU_STATE:
      if ((Object.keys(action.payload).some((value) => ['tab'].includes(value))) && !Object.keys(action.payload).includes('gender')) {
        action.payload.gender = null;
      }
      if ((Object.keys(action.payload).some((value) => ['tab', 'gender'].includes(value))) && !Object.keys(action.payload).includes('category')) {
        action.payload.category = null;
      }
      if (!Object(action.payload).hasOwnProperty('preventInit')) {
        action.payload.preventInit = false;
      }
      return { ...state, mobileMenu: { ...state.mobileMenu, ...action.payload } };
    case actions.PAGE_MOBILE_UPDATE:
      return { ...state, mobilePageUpdate: action.payload };
    case actions.PAGE_FIRSTRUN_STATE:
      return { ...state, firstRun: action.payload };
    case actions.PAGE_SESSION_STATE:
      return { ...state, sessionLoading: action.payload };
    case actions.PAGE_CHAT_INIT:
      return { ...state, chatTawk: state.chatTawk + 1 };
    case actions.PAGE_CHAT_LOADING:
      return { ...state, chatTawkLoading: action.payload };
    case actions.PAGE_SALE_SWITCH_CAMPAIGN:
      return {
        ...state,
        salePriceSwitch: action.payload.value,
        salePriceSwitchCampaign: action.payload.value ? action.payload.campaignId || [] : [],
      };
    case actions.PAGE_UNMOUNT:
      if (action.payload === PageTypes.menu) {
        return {
          ...state,
          mobileMenuTab: null,
        };
      } else {
        return state;
      }
    default:
      return state;
  }
};
